import i18n from 'i18next';
import * as dateLocalizations from 'date-fns/locale';

export interface ILocalizedString {
    locale: string[];
    string: string[];
}

/**
 * Resolves the given localized versions of a string based on the current language with an optional fallback value.
 */
export const resolveLocalizedString = (localizedString: ILocalizedString, locale: string) => {
    if (localizedString == null) {
        return null;
    }
    if (typeof localizedString == 'string') {
        return localizedString; // nothing do to
    }

    const localeIdx = localizedString.locale.indexOf(locale);
    return localizedString.string.length > localeIdx && localizedString.string[localeIdx];
};

export const getDateLocalizations = () => {
    let locale = i18n.language;
    if (locale == 'en') {
        locale = 'enGB'; // date-fns doesn't export a default 'en'
    }
    return dateLocalizations[locale];
};

export const getCurrentLocale = () => i18n.language;
