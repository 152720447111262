import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState.tsx';
import Field, { FieldProps } from './Field.tsx';
import classnames from '../../utils/classnames.tsx';

export interface TextFieldProps
    extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'defaultValue'>,
        FieldProps {
    inputClassName?: string;
}

const TextField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onChange,
    readOnly,
    disabled,
    className,
    inputClassName,
    controls,
    inputRef = undefined,
    maxLength,
    ...props
}: TextFieldProps) => {
    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

    const handleChange = (e: any) => {
        setValue(e.target.value);
        onChange?.(e);
    };

    return (
        <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
            <Field.Input className="relative">
                <textarea
                    className={classnames(
                        'px-2 py-1.5 flex-1 min-w-0 h-24 resize-none bg-transparent outline-none',
                        inputClassName
                    )}
                    readOnly={readOnly}
                    disabled={disabled}
                    onChange={handleChange}
                    value={value || ''}
                    maxLength={maxLength}
                    {...props}
                    ref={inputRef}
                />
                {maxLength && (
                    <div className="text-xs text-secondary bottom-1 right-0.5 absolute bg-primary px-1 py-0.5 rounded pointer-events-none">{`${(value || '').length}/${maxLength}`}</div>
                )}
            </Field.Input>
            <Field.Controls>{controls}</Field.Controls>
        </Field>
    );
};

export default TextField;
