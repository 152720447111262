export const isoDateFormat = 'yyyy-MM-dd';
export const isoDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
export const isoDateTimeFormatWithTimezone = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const isoDateTimeFormatWithMs = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const isoDateTimeFormatWithMsAndTimezone = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
export const isoDateTimeFormatWithMcsAndTimezone = "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX";

export const isoTimeFormat = 'HH:mm:ss';

// Mapping from Python date format to date-fns format
const PYTHON_TO_DATE_FNS_MAPPING = Object.freeze({
    '%A': 'EEEE', //Weekday as locale's full name: (In English: Sunday, .., Saturday)(Auf Deutsch: Sonntag, .., Samstag)
    '%a': 'EEE', //Weekday abbreivated: (In English: Sun, .., Sat)(Auf Deutsch: So, .., Sa)
    '%B': 'MMMM', //Month name: (In English: January, .., December)(Auf Deutsch: Januar, .., Dezember)
    '%b': 'MMM', //Month name abbreviated: (In English: Jan, .., Dec)(Auf Deutsch: Jan, .., Dez)
    '%c': 'EEE MMM dd HH:mm:ss yyyy', //Locale's appropriate date and time representation: (English: Sun Oct 13 23:30:00 1996)(Deutsch: So 13 Oct 22:30:00 1996)
    '%d': 'dd', //Day 0 padded: (01, .., 31)
    '%f': 'SSS', //Microseconds 0 padded: (000000, .., 999999)
    '%H': 'HH', //Hour (24-Hour) 0 padded: (00, .., 23)
    '%I': 'hh', //Hour (12-Hour) 0 padded: (01, .., 12)
    '%j': 'DDD', //Day of Year 0 padded: (001, .., 366)
    '%M': 'mm', //Minute 0 padded: (01, .. 59)
    '%m': 'MM', //Month 0 padded: (01, .., 12)
    '%p': 'a', //Locale equivalent of AM/PM: (EN: AM, PM)(DE: am, pm)
    '%S': 'ss', //Second 0 padded: (00, .., 59)
    '%U': 'ww', //Week # of Year (Sunday): (00, .., 53)  All days in a new year preceding the first Sunday are considered to be in week 0.
    '%W': 'ww', //Week # of Year (Monday): (00, .., 53)  All days in a new year preceding the first Monday are considered to be in week 0.
    '%w': 'e', //Weekday as #: (0, 6)
    '%X': 'HH:mm:ss', //Locale's appropriate time representation: (EN: 23:30:00)(DE: 23:30:00)
    '%x': 'MM/dd/yyyy', //Locale's appropriate date representation: (None: 02/14/16)(EN: 02/14/16)(DE: 14.02.16)
    '%Y': 'yyyy', //Year as #: (1970, 2000, 2038, 292,277,026,596)
    '%y': 'yy', //Year without century 0 padded: (00, .., 99)
    '%Z': 'XXX', //Time zone name: ((empty), UTC, EST, CST) (empty string if the object is naive).
    '%z': 'xxx', //UTC offset in the form +HHMM or -HHMM: ((empty), +0000, -0400, +1030) Empty string if the the object is naive.
    '%%': '%', //A literal '%' character: (%)
});

export const convertPythonDateTimeFormat = (formatStr) => {
    for (let key in PYTHON_TO_DATE_FNS_MAPPING) {
        formatStr = formatStr.split(key).join(PYTHON_TO_DATE_FNS_MAPPING[key]);
    }
    return formatStr;
};
