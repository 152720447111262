import React, { ReactNode } from 'react';
import classnames from '../../../utils/classnames.tsx';
import NavigationItemBadge from './NavigationItemBadge.tsx';
import { useNavigationContext } from './NavigationContext.tsx';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { withIcon } from '../../Icon.tsx';
import Tooltip from '../../Tooltip.tsx';

export interface NavigationButtonProps {
    icon?: ReactNode;
    ellipsis?: boolean; // maybe we could use children or iconAfter instead of ellipsis
    label: string;
    active?: boolean;
    disabled?: boolean;
    badgeNumber?: number;
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
}

const EllipsisIcon = withIcon(faEllipsisVertical);

const NavigationButton = ({
    icon,
    ellipsis = false,
    label,
    active = false,
    badgeNumber = undefined,
    className,
    onClick,
    ...buttonProps
}: NavigationButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const { collapsed } = useNavigationContext();

    return (
        <button
            className={classnames(
                'relative flex w-full items-center gap-2 rounded-md px-3 py-2 text-sm text-primary h-9 transition-colors font-medium',
                'cursor-pointer hover:bg-secondary transition-[padding] ease-in-out duration-200',
                active && '!bg-brand !text-brand',
                collapsed && 'justify-start ',
                'whitespace-nowrap',
                className
            )}
            data-active={active}
            onClick={(event) => {
                onClick(event);
            }}
            {...buttonProps}
        >
            {icon ? <span className="w-4 inline-flex justify-center flex-none">{icon}</span> : null}

            {!collapsed ? (
                <Tooltip content={label} className="truncate inline-block w-auto min-w-0" side="top" overflowOnly>
                    <span className="min-w-0 truncate">{label}</span>
                </Tooltip>
            ) : null}

            {badgeNumber && badgeNumber > 0 ? (
                <NavigationItemBadge className="ml-auto">{badgeNumber}</NavigationItemBadge>
            ) : null}

            {ellipsis && !collapsed ? <EllipsisIcon className="ml-auto" /> : null}
        </button>
    );
};

export default NavigationButton;
