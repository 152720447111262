export const CUSTOMER_PROFILE_PATH = '/customer/profile';
export const CUSTOMER_NOTIFICATIONS_PATH = '/customer/notifications';
export const CUSTOMER_SSO_PATH = '/customer/sso';
export const CUSTOMER_CUSTOMIZATIONS_PATH = '/customer/customizations';

export const EDIT_PATH = '/customer/edit';

export const APPS_PATH = '/customer/apps';

export const CUSTOMER_INTEGRATIONS_PATH = '/customer/integrations';

export const CUSTOMER_INTEGRATION_AUTHENTICATION_TOKENS_PATH = '/customer/integrations/authentication-tokens';
export const CUSTOMER_INTEGRATION_SFTP_PUBLIC_KEYS_PATH = '/customer/integrations/sftp-public-keys';

export const CUSTOMER_INTEGRATION_OUTLOOK_PATH = '/customer/integrations/outlook';
export const CUSTOMER_INTEGRATION_OUTLOOK_ADD_PATH = '/customer/integrations/outlook/add';
export const CUSTOMER_INTEGRATION_OUTLOOK_EDIT_PATH = '/customer/integrations/outlook/:integrationId/edit';

export const CUSTOMER_INTEGRATION_WECLAPP_PATH = '/customer/integrations/weclapp';
export const CUSTOMER_INTEGRATION_WECLAPP_ADD_PATH = '/customer/integrations/weclapp/add';
export const CUSTOMER_INTEGRATION_WECLAPP_EDIT_PATH = '/customer/integrations/weclapp/:integrationId/edit';

export const CUSTOMER_INTEGRATION_SAGE100_PATH = '/customer/integrations/sage100';
export const CUSTOMER_INTEGRATION_SAGE100_ADD_PATH = '/customer/integrations/sage100/add';
export const CUSTOMER_INTEGRATION_SAGE100_EDIT_PATH = '/customer/integrations/sage100/:integrationId/edit';
export const CUSTOMER_INTEGRATION_SAGE100_OAUTH2_CALLBACK_PATH = '/customer/integrations/sage100/oauth2/callback';

export const CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH = '/customer/integrations/sage200professional';
export const CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_ADD_PATH = '/customer/integrations/sage200professional/add';
export const CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_EDIT_PATH =
    '/customer/integrations/sage200professional/:integrationId/edit';
export const CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_OAUTH2_CALLBACK_PATH =
    '/customer/integrations/sage200professional/oauth2/callback';

export const CUSTOMER_INTEGRATION_SMART_INBOX_PATH = '/customer/integrations/smartinbox';
export const CUSTOMER_INTEGRATION_SMART_INBOX_ADD_PATH = '/customer/integrations/smartinbox/add';

export const OUTLOOK_MANIFEST_URL = `${process.env.BACKEND_URL}providers/outlook-addin/manifest`;
