import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18n from 'i18next';
import { posthog } from 'posthog-js';
import { useQuery } from '@apollo/client';

import { useAuth } from './modules/core/utils/authentication';
import { modules } from './modules/core/utils/modules';
import NotFound from './modules/core/components/PageNotFound.tsx';
import Loader from './modules/core/components/Loader.tsx';
import { GET_ANNOUNCEMENTS } from './modules/announcements/queries';
import { FeatureTogglesProvider } from './modules/core/utils/hooks/useFeatureToggle';
import featureConfig from './modules/core/featureToggles';
import { appInsights } from './modules/core/analytics/applicationInsights';
import { loadAppEvent } from './modules/core/analytics/customEvents';

import { ApplicationContextProvider } from './modules/core/contexts/ApplicationContext';
import { DarkModeProvider } from './modules/core/utils/useDarkMode.tsx';
import { StoreProvider } from './modules/document/stores';

// order is important here
import '../stylesheets/tailwind.css';
import './app.css';

export const DARK_MODE_LOCAL_STORAGE_KEY = 'darkMode';

const App = ({ browserName }) => {
    const { t } = useTranslation();

    const { user, refetchUser, loggedIn, loading } = useAuth();
    // polling once a minute for new announcements should be enough
    const { data: announcementsData } = useQuery(GET_ANNOUNCEMENTS, { pollInterval: 60000 });

    // this helps the navigation to know when it should be collapsed
    const [activeChannelId, setActiveChannelId] = useState(null);

    useEffect(() => {
        appInsights?.trackEvent(...loadAppEvent(user));

        if (!loggedIn) return;

        if (user.language) {
            void i18n.changeLanguage(user.language.toLowerCase());
        }

        user.groups?.some(function (group) {
            if (group.name == 'Clerk' && group.memberCount > 1) {
                posthog.capture('identify_customer', { $set_once: { customer: user.customer.shortName } });
                return true;
            }
        });
    }, [loggedIn]);

    useEffect(() => {
        document.body.classList.add(browserName && `browser-${browserName}`);
    }, [browserName]);

    return (
        <ApplicationContextProvider
            user={user}
            refetchUser={refetchUser}
            loading={loading}
            channelId={activeChannelId}
            setChannelId={setActiveChannelId}
            announcements={announcementsData?.announcements || []}
        >
            <FeatureTogglesProvider featureConfig={featureConfig}>
                <DarkModeProvider>
                    <>
                        <title>{t('app.title')}</title>

                        {loading ? ( // TODO remove loading state handling here & leave it up to shells
                            <div className="w-full h-screen flex items-center justify-center">
                                <Loader className="max-w-[40rem] p-10 w-full" />
                            </div>
                        ) : (
                            <Router key="router">
                                <StoreProvider>
                                    <Routes>
                                        {modules.renderRoutes({
                                            user,
                                            loggedIn,
                                        })}
                                        <Route element={<NotFound />} path="*" />
                                    </Routes>
                                </StoreProvider>
                            </Router>
                        )}
                    </>
                </DarkModeProvider>
            </FeatureTogglesProvider>
        </ApplicationContextProvider>
    );
};

export default App;
