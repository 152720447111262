import { useAssistanceSectionContext } from '../AssistanceSection';
import classnames from '../../../../core/utils/classnames.tsx';
import * as React from 'react';

export interface FieldWrapperProps {
    ref?: React.RefObject<HTMLDivElement>;
    fieldName: string;
    fullFieldName: string;
    className?: string;
    confidenceExplanationButtonVisible: boolean;
    isConfidenceExplanationOpen: boolean;
    confidenceExplanationAlignment: 'start' | 'center' | 'end';
    // handlers
    onMouseEnter: (e) => void;
    onMouseLeave: (e) => void;
    onMouseOut: (e) => void;
    // slots
    field: React.ReactNode;
    confidenceExplanation: React.ReactNode;
}

export const useFieldWrapperProps = ({
    className,
    isConfidenceExplanationOpen,
    confidenceExplanationButtonVisible,
    confidenceExplanationAlignment,
    onMouseEnter,
    onMouseLeave,
    onMouseOut,
}: FieldWrapperProps) => {
    const { viewMode } = useAssistanceSectionContext();

    return {
        className: classnames(
            'w-full transition-all duration-200 mt-0',
            viewMode !== 'list' && 'flex flex-col',
            viewMode !== 'list' &&
                (confidenceExplanationAlignment === 'end'
                    ? 'items-end'
                    : confidenceExplanationAlignment === 'center'
                      ? 'items-center'
                      : 'items-start'),
            className
        ),
        onMouseEnter,
        onMouseLeave,
        onMouseOut,
    };
};

const FieldWrapper = (props: FieldWrapperProps) => {
    const { viewMode } = useAssistanceSectionContext();
    const fieldWrapperProps = useFieldWrapperProps(props);

    const { ref, confidenceExplanationButtonVisible, isConfidenceExplanationOpen, field, confidenceExplanation } =
        props;

    // TODO: double check if ref should be on that one
    return (
        <div {...fieldWrapperProps}>
            <div className="w-full relative" ref={ref}>
                {field}
            </div>

            <div
                className={classnames(
                    'grid overflow-hidden transition-all duration-200 grid-rows-[0fr] w-full',
                    confidenceExplanationButtonVisible && isConfidenceExplanationOpen && 'grid-rows-[1fr]',
                    viewMode !== 'list' && 'w-[450px] flex-none'
                )}
            >
                {confidenceExplanation}
            </div>
        </div>
    );
};

export default FieldWrapper;
