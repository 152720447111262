import * as React from 'react';
import { useEffect } from 'react';
import Table, { TableScrollWrapper } from '../../core/components/Table.tsx';
import classnames from '../../core/utils/classnames.tsx';
import { withIcon } from '../../core/components/Icon.tsx';
import { faCheck, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { Line } from '../../core/components/Loader.tsx';
import { useControllableState } from '../../core/utils/useControllableState.tsx';

export interface RecordTableColumn {
    key: string;
    label: string;
    render?: (record: Record) => React.ReactNode;
    className?: string;
}

interface Record {
    key: string;
    disabled?: boolean;
    [key: string]: any;
}

interface RecordTableProps {
    columns: RecordTableColumn[];
    records: Record[];
    selectable?: boolean;
    activeRowKey?: string;
    selectedRowKeys?: string[];
    highlightedRowKeys?: string[];
    onSelectedRowKeysChange?: (selectedRowKeys: string[]) => void;
    loading?: boolean;
}

export const ROW_CONTROLS_KEY = '_rowControls';

export interface RowControlIconButtonProps extends React.ComponentPropsWithRef<'button'> {
    active?: boolean;
    asChild?: boolean;
}

interface CheckboxProps extends React.ComponentPropsWithRef<'input'> {
    indeterminate?: boolean;
}

const CheckIcon = withIcon(faCheck);
const IndeterminateIcon = withIcon(faMinus);

const Checkbox = ({ indeterminate = false, className, checked, ...props }: CheckboxProps) => {
    const internalRef = React.useRef(null);
    useEffect(() => {
        if (internalRef.current) {
            internalRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    return (
        <span
            className={classnames(
                'flex relative h-4 w-4 items-center justify-center border-secondary border-solid border rounded bg-primary hover:outline-2 hover:outline-brand hover:!outline transition-colors duration-200',
                (checked || indeterminate) && 'bg-brand-default border-0'
            )}
        >
            <input
                type="checkbox"
                ref={internalRef}
                className="opacity-0 absolute w-full h-full inset-0 peer"
                checked={checked}
                {...props}
            />
            {indeterminate ? (
                <IndeterminateIcon className="h-2.5 w-2.5 text-inverted" />
            ) : (
                <CheckIcon className="hidden h-2.5 w-2.5 peer-checked:inline-block peer-checked:text-inverted" />
            )}
        </span>
    );
};

const RecordCell = ({
    column,
    columnIndex,
    record,
    selectable,
    selectedRowKeys,
    highlightedRowKeys,
    handleSelectRow,
}: {
    column: RecordTableColumn;
    columnIndex: number;
    record: Record;
    selectable: boolean;
    selectedRowKeys: string[];
    highlightedRowKeys: string[];
    handleSelectRow: (key: string) => void;
}) => {
    return (
        <Table.Cell
            key={column.key}
            className={classnames(
                'group-hover:bg-secondary transition-colors duration-200',
                column.key === ROW_CONTROLS_KEY && 'pr-2',
                selectedRowKeys.includes(record.key) && 'bg-brand',
                highlightedRowKeys.includes(record.key) && 'bg-brand',
                column.className
            )}
            sticky={column.key === ROW_CONTROLS_KEY ? 'right' : undefined}
        >
            <div className="flex gap-3 items-center">
                {column.key !== ROW_CONTROLS_KEY && columnIndex === 0 && selectable && (
                    <Checkbox
                        checked={selectedRowKeys.includes(record.key)}
                        onChange={() => handleSelectRow(record.key)}
                    />
                )}
                <div
                    className={classnames(
                        'flex flex-1',
                        column.key === ROW_CONTROLS_KEY &&
                            'gap-1 group-hover:opacity-100 transition-opacity duration-200 [&:has(*[data-state=open])]:opacity-100'
                    )}
                >
                    {column.render ? column.render(record) : record[column.key]}
                </div>
            </div>
        </Table.Cell>
    );
};

const RecordTable = ({
    columns = [],
    records = [],
    selectable = true,
    selectedRowKeys: propsSelectedRowKeys,
    highlightedRowKeys = [],
    onSelectedRowKeysChange,
    loading = false,
}: RecordTableProps) => {
    const [selectedRowKeys, setSelectedRowKeys] = useControllableState(
        [],
        propsSelectedRowKeys,
        onSelectedRowKeysChange
    );

    const handleSelectAll = () => {
        if (selectedRowKeys.length === records.length) {
            setSelectedRowKeys?.([]);
        } else {
            setSelectedRowKeys?.(records.map((record) => record.key));
        }
    };

    const handleSelectRow = (key: string) => {
        if (selectedRowKeys.includes(key)) {
            setSelectedRowKeys?.(selectedRowKeys.filter((selectedKey) => selectedKey !== key));
        } else {
            setSelectedRowKeys?.([...selectedRowKeys, key]);
        }
    };

    const isAllSelected = selectedRowKeys.length === records.length && records.length > 0;
    const isIndeterminate = selectedRowKeys.length > 0 && selectedRowKeys.length < records.length;

    return (
        <TableScrollWrapper>
            <Table className="w-full border-none table-auto">
                <Table.Head>
                    <Table.Row>
                        {columns.map((column, columnIndex) =>
                            column.key === ROW_CONTROLS_KEY ? (
                                <Table.HeadCell sticky="right" key={column.key} className={column.className} />
                            ) : (
                                <Table.HeadCell key={column.key} className={column.className}>
                                    <div className="flex gap-3 items-center">
                                        {columnIndex === 0 && selectable && (
                                            <Checkbox
                                                indeterminate={isIndeterminate}
                                                checked={isAllSelected}
                                                onChange={handleSelectAll}
                                            />
                                        )}
                                        <div>{column.label}</div>
                                    </div>
                                </Table.HeadCell>
                            )
                        )}
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {loading && records.length === 0 && (
                        <Table.Row>
                            {columns.map((column) => (
                                <Table.Cell key={column.key}>
                                    <div className="max-w-96 opacity-10">
                                        <Line />
                                    </div>
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    )}
                    {records.map((record) => (
                        <Table.Row className={record.disabled ? 'opacity-50' : ''} key={record.key}>
                            {columns.map((column, columnIndex) => (
                                <RecordCell
                                    key={`${record.key}-${column.key}`}
                                    column={column}
                                    columnIndex={columnIndex}
                                    record={record}
                                    selectable={selectable}
                                    selectedRowKeys={selectedRowKeys}
                                    highlightedRowKeys={highlightedRowKeys}
                                    handleSelectRow={handleSelectRow}
                                />
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </TableScrollWrapper>
    );
};

export default RecordTable;
