import UsersPage, { AddUserFormModal, EditUserFormModal } from './pages/UsersPage.tsx';
import Profile from './pages/ProfilePage.tsx';
import Home from './pages/Home.tsx';
import Notifications from './pages/NotificationsPage.tsx';

import { canManageUsers } from './utils';

import {
    USERS_ADD_PATH,
    USERS_EDIT_PATH,
    USERS_PATH,
    USER_NOTIFICATIONS_PATH,
    USER_PASSWORD_PATH,
    USER_PROFILE_PATH,
} from './constants';
import { HOME_PATH } from '../../constants';
import Password from './pages/PasswordPage.tsx';

export default [
    {
        path: USER_PROFILE_PATH,
        component: Profile,
    },
    {
        path: USER_NOTIFICATIONS_PATH,
        component: Notifications,
    },
    {
        path: USER_PASSWORD_PATH,
        component: Password,
    },
    {
        path: USERS_PATH,
        component: UsersPage,
        children: [
            {
                path: USERS_ADD_PATH,
                condition: ({ user }) => canManageUsers(user),
                component: (props) => <AddUserFormModal {...props} />,
            },
            {
                path: USERS_EDIT_PATH,
                condition: ({ user }) => canManageUsers(user),
                component: (props) => <EditUserFormModal {...props} />,
            },
        ],
    },
    {
        path: HOME_PATH,
        component: Home,
    },
];
