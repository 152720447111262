import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../../core/utils/link.ts';
import { OVERVIEW_PATHS, DocumentType } from '../../document/constants.ts';
import { LOGIN_PATH } from '../../login/constants.ts';

export const Home = ({ user, loggedIn }) => {
    const navigate = useNavigate();
    const customerIsActive = user?.customer?.isActive;

    useEffect(() => {
        if (!loggedIn) {
            navigate(url(LOGIN_PATH), { replace: true });
        }

        if (user?.customer?.isOrdersEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.Order]), { replace: true });
        } else if (user?.customer?.isOrderConfirmationsEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.OrderConfirmation]), { replace: true });
        } else if (user?.customer?.isInvoicesEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.Invoice]), { replace: true });
        } else if (user?.customer?.isRfqsEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.Rfq]), { replace: true });
        } else if (user?.customer?.isPropertyBillsEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.PropertyBill]), { replace: true });
        } else if (user?.customer?.isDeliveryNotesEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.DeliveryNote]), { replace: true });
        } else if (user?.customer?.isListOfServicesEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.ListOfServices]), { replace: true });
        } else if (user?.customer?.isUniversalEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.Universal]), { replace: true });
        }
    }, [user, customerIsActive]);
};

export default Home;
