import TeamsPage, { AddTeamFormModal, EditTeamFormModal } from './pages/TeamsPage.tsx';

import { canManageUsers } from '../users/utils';

import { TEAMS_ADD_PATH, TEAMS_EDIT_PATH, TEAMS_PATH } from './constants';

export default [
    {
        path: TEAMS_PATH,
        component: TeamsPage,
        children: [
            {
                path: TEAMS_ADD_PATH,
                condition: ({ user }) => canManageUsers(user),
                component: AddTeamFormModal,
            },

            {
                path: TEAMS_EDIT_PATH,
                condition: ({ user }) => canManageUsers(user),
                component: EditTeamFormModal,
            },
        ],
    },
];
