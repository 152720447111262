import React from 'react';
import { NavigationItemProps } from './NavigationItem.tsx';
import { NavigationItemListWithLoader } from './NavigationItemList.tsx';
import NavigationButton from './NavigationButton.tsx';
import classnames from '../../../utils/classnames.tsx';
import { useNavigationContext } from './NavigationContext.tsx';
import alphaNumericSorter from '../../../utils/alphaNumericSorter.ts';

const useChannels = (channels: any) => {
    channels =
        channels?.map((channel: any) => ({
            ...channel,
            openDocumentsCount: channel.openDocumentsCount || 0,
        })) || [];

    const channelsWithOpenDocuments = channels
        ?.filter((channel: any) => {
            return channel.openDocumentsCount > 0;
        })
        .sort((a: any, b: any) => alphaNumericSorter(a.name, b.name));

    const totalOpenDocuments = channelsWithOpenDocuments
        ?.map((channel: any) => channel.openDocumentsCount)
        ?.reduce((a: any, b: any) => a + b, 0);

    const channelsWithoutOpenDocuments = channels
        ?.filter((channel: any) => {
            return channel.openDocumentsCount === 0;
        })
        .sort((a: any, b: any) => alphaNumericSorter(a.name, b.name));

    return {
        channelsWithOpenDocuments,
        totalOpenDocuments,
        channelsWithoutOpenDocuments,
    };
};

interface NavigationDocumentTypeItemProps extends Omit<NavigationItemProps, 'active' | 'badgeNumber'> {
    active?: boolean | string; // string means channel is active and contains the id then
    channels: any;
    loading?: boolean;
}

const NavigationDocumentTypeItem = ({
    icon,
    label,
    channels,
    loading = false,
    active = false,
    disabled = false,
    className,
    onSelect,
}: NavigationDocumentTypeItemProps) => {
    const { collapsed } = useNavigationContext();
    const { channelsWithOpenDocuments, totalOpenDocuments, channelsWithoutOpenDocuments } = useChannels(channels);

    return (
        <li
            className={classnames(
                'flex flex-none flex-col gap-1 rounded-md',
                disabled && 'opacity-50 pointer-events-none',
                collapsed && 'gap-0',
                className
            )}
        >
            <span className="flex-none px-4 py-1">
                <NavigationButton
                    active={active === true || (collapsed && active !== false)}
                    icon={icon}
                    label={label}
                    badgeNumber={(!active || collapsed) && totalOpenDocuments}
                    onClick={(event) => onSelect?.(event, null)}
                    disabled={disabled}
                />
            </span>
            {!!active ? (
                <NavigationItemListWithLoader loading={loading}>
                    {channelsWithOpenDocuments?.map((channel: any) => (
                        <li key={channel.id}>
                            <NavigationButton
                                active={active === channel.id}
                                label={channel.name}
                                badgeNumber={channel.openDocumentsCount}
                                onClick={(event) => onSelect?.(event, channel.id)}
                                className="font-normal"
                            />
                        </li>
                    ))}
                    {channelsWithoutOpenDocuments?.map((channel: any) => (
                        <li key={channel.id}>
                            <NavigationButton
                                active={active === channel.id}
                                label={channel.name}
                                badgeNumber={channel.openDocumentsCount}
                                onClick={(event) => onSelect?.(event, channel.id)}
                                className="font-normal"
                            />
                        </li>
                    ))}
                </NavigationItemListWithLoader>
            ) : null}
        </li>
    );
};

export default NavigationDocumentTypeItem;
