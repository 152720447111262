import DocumentAssistance from '../../../document/pages/Assistance';
import {
    DELIVERY_NOTE_EXTRACT_TEXT,
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    GET_RELATED_DOCUMENT,
    RE_UPLOAD_FILE,
    REOPEN_FOR_ASSISTANCE,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../queries.ts';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../constants.ts';
import { DocumentType } from '../../../document/constants.ts';
import {
    getCustomerFieldConditionalComponents,
    getCustomerFieldGroupConditionalComponents,
} from '../../../document/components/CustomerField.tsx';
import { getAddressFieldConditionalComponents } from '../../../document/components/AddressField.tsx';
import { ArticleNumberFieldConditionalComponents } from '../../../document/components/ArticleNumberField.tsx';
import * as React from 'react';
import {
    extractText,
    fieldConfigToLegacyConfigFormat,
    fieldToFormFieldConfig,
} from '../../../document/pages/Assistance/utils.tsx';
import { merge } from 'lodash';
import { useAssistanceContext } from '../../../document/pages/Assistance/AssistanceContext.tsx';

export const documentTypeName = 'deliveryNote';

const fieldNameToGroupTypeMap = {
    supplier: 'CustomerField',
    buyer: 'CustomerField',
    deliveryAddress: 'AddressField',
};

export const getHeaderFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    headerFields,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const configs = {};

    fieldConfigToLegacyConfigFormat(fieldConfigs, configs, client, recordId, documentConfig, fieldNameToGroupTypeMap);

    // TODO: maybe this information can come from the backend to increase flexibility - for now it's fine though
    const legacyConfig = {
        supplier: {
            groupFieldProps: {
                customerNumber: {},
            },
        },
        buyer: {
            groupFieldProps: {
                customerNumber: {},
            },
            fieldProps: {
                useUpdateWarning: false,
            },
        },
        deliveryAddress: {
            groupFieldProps: {
                addressId: {},
                addressId2: {
                    // We currently use this catch-all field to differentiate between pickup & delivery addresses
                    // If this becomes a more common use case, we should use a dedicated field instead
                    required: false,
                    hidden: !headerFields.includes('deliveryAddress.addressId2'),
                },
                misc: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                email: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                phone: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
            },
        },
    };
    return merge(legacyConfig, configs);
};

export const getLineItemFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const { handlers, document, documentConfiguration } = useAssistanceContext();

    const configs = {};
    if (fieldConfigs) {
        fieldConfigs.forEach((field) => {
            configs[field.name] = fieldToFormFieldConfig(field, {
                client,
                recordId,
                documentConfig,
                documentConfiguration,
                document,
                handlers,
            });
        });
    }

    const interceptedOnReselect = ({ fieldName, bbox, pageIndex, itemIndex }) => {
        // This method extracts text within the selected bbox and triggers a simulated onChange event on
        // the AutocompleteField. This way we won't trigger onReselect with an invalid value.
        return extractText(client, recordId, bbox, pageIndex, DELIVERY_NOTE_EXTRACT_TEXT, documentTypeName).then(
            (text) => {
                const fieldNode: any = document.querySelector(`.item-${itemIndex} .field--${fieldName} input`);
                if (!fieldNode) return;

                fieldNode?.focus();

                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                    window.HTMLInputElement.prototype,
                    'value'
                ).set;
                nativeInputValueSetter.call(fieldNode, text);

                const inputEvent = new Event('input', { bubbles: true });
                fieldNode.dispatchEvent(inputEvent);
            }
        );
    };

    const legacyConfig = {
        articleNumber: {
            onReselect: interceptedOnReselect,
        },
        articleNumber2: {
            onReselect: interceptedOnReselect,
        },
        articleNumber3: {
            onReselect: interceptedOnReselect,
        },
        articlePartitionSpecificNumber: {
            onReselect: interceptedOnReselect,
        },
        quantity: {
            fieldType: 'FloatField',
        },
        convertedQuantity: {
            fieldType: 'FloatField',
        },
        unitPrice: {
            fieldType: 'FloatField',
        },
        totalPrice: {
            fieldType: 'FloatField',
        },
    };

    return merge(legacyConfig, configs);
};

const DeliveryNoteAssistance = (props) => {
    const documentConfiguration = {
        documentType: DocumentType.DeliveryNote,
        documentTypeName: 'deliveryNote',
        GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
        GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
        GET_RELATED_DOCUMENT: GET_RELATED_DOCUMENT,
        RETRY_STEP: RETRY_STEP,
        REOPEN_FOR_ASSISTANCE: REOPEN_FOR_ASSISTANCE,
        ASSISTANCE_PATH: ASSISTANCE_PATH,
        ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
        OVERVIEW_PATH: OVERVIEW_PATH,
        OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
        OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
        CHANNEL_PATH: CHANNEL_PATH,
        CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
        CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
        RE_UPLOAD_FILE: RE_UPLOAD_FILE,
        EXTRACT_TEXT: DELIVERY_NOTE_EXTRACT_TEXT,
        SEND_TO_LABELING: SEND_TO_LABELING,
        getHeaderFieldConfigs: getHeaderFieldConfigs,
        getLineItemFieldConfigs: getLineItemFieldConfigs,
        components: {
            Field: [
                ...getCustomerFieldConditionalComponents('supplier'),
                ...getCustomerFieldConditionalComponents('buyer'),
                ...getAddressFieldConditionalComponents('delivery_address'),
                ...ArticleNumberFieldConditionalComponents,
            ],
            HeaderDataFieldGroup: [
                ...getCustomerFieldGroupConditionalComponents('supplier'),
                ...getCustomerFieldGroupConditionalComponents('buyer'),
            ],
        },
    };

    return <DocumentAssistance documentConfiguration={documentConfiguration} {...props} />;
};

export default DeliveryNoteAssistance;
