import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../../core/components/Modal.tsx';
import Button from '../../../core/components/Button.tsx';
import FormField from '../../../core/components/FormField.tsx';
import StringField from '../../../core/components/Fields/StringField.tsx';

const FORM_DEFAULT_VALUES = {
    tableDataLabelName: '',
    tableFieldLabelName: '',
};

const UniversalItemTypeFormModal = ({ title, onClose, submitButtonLabel, onSubmit, data = undefined }) => {
    const { t } = useTranslation('config');

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (data) form.reset(data);
    }, [data]);

    return (
        <Modal
            visible={true}
            onClose={onClose}
            title={title}
            buttons={
                <>
                    <Button type="button" onClick={onClose}>
                        {t('configPage.document.universal.modal.cancelButton')}
                    </Button>
                    <Button variant="primary" type="submit" form="add-item-type-form">
                        {submitButtonLabel}
                    </Button>
                </>
            }
        >
            <form className="flex flex-col gap-8" onSubmit={form.handleSubmit(onSubmit)} id="add-item-type-form">
                <Controller
                    name="tableDataLabelName"
                    control={form.control}
                    rules={{ required: t('configPage.document.form.tableDataLabelNameRequired') }}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.tableDataLabelName')}
                            helpText={t('configPage.document.form.tableDataLabelNameHelpText')}
                            required={true}
                            error={formErrors.tableDataLabelName}
                        >
                            <StringField required={true} {...field} disabled={data?.tableDataLabelName} />
                        </FormField>
                    )}
                />

                <Controller
                    name="tableFieldLabelName"
                    control={form.control}
                    rules={{ required: t('configPage.document.form.tableFieldLabelNameRequired') }}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.tableFieldLabelName')}
                            helpText={t('configPage.document.form.tableFieldLabelNameHelpText')}
                            required={true}
                            error={formErrors.tableFieldLabelName}
                        >
                            <StringField required={true} {...field} />
                        </FormField>
                    )}
                />
            </form>
        </Modal>
    );
};

export default UniversalItemTypeFormModal;
