import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from '../../../core/utils/classnames.tsx';
import { useAssistanceContext } from './AssistanceContext';
import AssistanceCanvas from '../../../assistance/components/AssistanceCanvas';
import { Area, SelectionImageMap } from '../../../assistance/components/ImageMap.tsx';
import { camelCase } from 'lodash';
import { getFormFieldGroups, GROUP_SEPARATOR, useDocumentConfig } from './useDocumentConfig';
import ItemDataSection from './ItemDataSection';
import HeaderDataSection from './HeaderDataSection';
import SplitPanes, { Pane } from '../../../core/components/SplitPanes.tsx';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../core/components/Tooltip.tsx';
import { filterValidation } from '../../../assistance/utils';
import ValidationChecks from '../../../assistance/components/ValidationChecks.tsx';
import { useFeatureToggle } from '../../../core/utils/hooks/useFeatureToggle';
import Checkbox from '../../../core/components/Checkbox';
import { withIcon } from '../../../core/components/Icon';
import { faBarsFilter, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { AssistanceViewContextProvider, IEventField, useAssistanceViewContext } from './AssistanceViewContext';
import { filterFastTrack } from './utils';
import { useLocalStorage } from '../../../core/utils/hooks/useLocalStorage';
import Button from '../../../core/components/Button';
import { useConditionalComponent } from '../../../customizations/ConditionalComponentContext';

const LoadingIcon = withIcon(faSpinnerThird);
const FastTrackIcon = withIcon(faBarsFilter);

const SidebarControls = ({ isFastTrackEnabled, onFastTrackEnabledChange, fastTrackCount = 0 }: any) => {
    const { t } = useTranslation('assistance');
    const { handlers, loading, record, readOnly } = useAssistanceContext();

    const canFinishAssistance = record?.canFinishAssistance;

    const isERPLockEnabled = useFeatureToggle('ERPLock', { channel: record?.channel });
    const isERPLocked = record?.orderUnsaved?.isErpLocked ?? false;

    if (!isERPLockEnabled && readOnly) return null;

    return (
        <div className="flex flex-col gap-4 flex-none overflow-hidden p-6 border-t border-solid border-primary field-modal-root">
            {!readOnly && (
                <div className="flex gap-2">
                    <Button
                        onClick={() => onFastTrackEnabledChange(!isFastTrackEnabled)}
                        className={classnames(
                            'flex gap-2 items-center bg-primary text-primary shadow-sm rounded px-3 py-1.5 font-medium text-sm border border-solid border-secondary outline-none',
                            'hover:bg-secondary transition-colors',
                            isFastTrackEnabled && 'bg-brand text-brand border-brand'
                        )}
                        disabled={readOnly}
                    >
                        <FastTrackIcon /> {fastTrackCount}
                    </Button>

                    <Tooltip
                        content={
                            loading || !canFinishAssistance
                                ? t('finishButton.finishDisabledNote')
                                : t('finishButton.finishNote')
                        }
                        side="top"
                        long
                        className="tooltip--flex"
                        open={readOnly ? false : undefined}
                    >
                        <Button
                            variant="primary"
                            className="flex items-center justify-center w-full p-2 !bg-brand-default rounded-md text-inverted font-medium text-sm hover:enabled:!bg-brand-hover transition-colors duration-200 focus:enabled:!bg-brand-focus active:enabled:!bg-brand-active disabled:opacity-50"
                            disabled={loading || readOnly || !canFinishAssistance}
                            onClick={() => handlers?.onFinishAndContinue()}
                        >
                            {t('finishButton.finishAndContinueButton')}
                        </Button>
                    </Tooltip>
                </div>
            )}

            {isERPLockEnabled && (
                <div className="flex justify-center">
                    <label className={classnames('flex gap-2', !readOnly && 'cursor-pointer')}>
                        {loading ? (
                            <LoadingIcon spin className="text-brand" />
                        ) : (
                            <Checkbox
                                checked={isERPLocked}
                                disabled={readOnly}
                                onChange={() =>
                                    handlers?.onUpdate({
                                        fieldName: 'isErpLocked',
                                        payload: { value: !isERPLocked },
                                    })
                                }
                            />
                        )}
                        <span className="text-sm">{t(`fields.ERPLock.${readOnly ? 'read' : 'edit'}`)}</span>
                    </label>
                </div>
            )}
        </div>
    );
};

const ValidationChecksSection = ({ className, document }: any) => {
    const { i18n } = useTranslation('assistance');

    const validationChecks = filterValidation(document?.validationChecks || [], i18n);

    if (validationChecks.length === 0) return null;

    return (
        <div className="flex flex-col w-full border-b border-solid border-primary last:-mb-px relative p-6">
            <ValidationChecks checks={validationChecks} />
        </div>
    );
};

const AssistanceSidebar = ({ className, children }: any) => {
    return <aside className={classnames('flex flex-col flex-auto min-h-0 overflow-auto', className)}>{children}</aside>;
};

const AssistanceView = React.memo(() => {
    const {
        handlers: { onUpdate, onReselect },
        record,
        document,
        documentConfiguration,
        readOnly,
    } = useAssistanceContext();

    const [_isFastTrackEnabled, setIsFastTrackEnabled] = useLocalStorage('assistanceFastTrackEnabled', false);
    const isFastTrackEnabled = !readOnly && _isFastTrackEnabled;

    // At any time only one field will be active. We manage the state here as the reselection logic lives here
    const [focusField, setFocusField] = useState<IEventField | null>(null);
    const [hoverField, setHoverField] = useState<IEventField | null>(null);
    const [updateField, setUpdateField] = useState<IEventField | null>(null);
    const [reselectField, setReselectField] = useState<IEventField | null>(null);

    const [hoverItemIndex, setHoverItemIndex] = useState<number | null>(null); // e.g. lien item

    // image map

    const formsRef = useRef(null);
    const previewRef = useRef(null);

    // process configs for displaying the sections

    const configuredItemTypes = (record?.channel?.documentConfig?.fields || [])
        .filter((field) => field.options?.some((option) => option.name === 'group' && option.value === true))
        .map((field) => field.name);
    const resultItemTypes = configuredItemTypes.length > 0 ? configuredItemTypes : undefined;
    const hardcodedItemTypes = documentConfiguration?.itemTypes;
    const useDefaultItemTypes = documentConfiguration?.useDefaultItemTypes ?? true;
    const defaultItemTypes = useDefaultItemTypes ? ['line_items'] : undefined;
    const itemTypes = hardcodedItemTypes || resultItemTypes || defaultItemTypes;

    const { generalConfig, fieldConfigsByPrefix } = useDocumentConfig(record?.channel?.documentConfig, itemTypes);
    const { '': headerConfigs, ...itemConfigs } = fieldConfigsByPrefix;

    const headerFormFieldGroups = useMemo(() => getFormFieldGroups(document, headerConfigs), [document]);

    const [itemFormFieldGroups, items] = useMemo(() => {
        const items = [];
        const result = Object.fromEntries(
            Object.entries(itemConfigs).map(([prefix, fields]) => {
                if (prefix.includes(GROUP_SEPARATOR)) {
                    prefix = prefix.split(GROUP_SEPARATOR).at(-1);
                }

                const sectionItems = document?.[camelCase(prefix)] || [];
                items.push(...sectionItems);
                return [
                    prefix,
                    sectionItems.map((item, itemIndex) => getFormFieldGroups(item, fields, prefix, itemIndex).flat()),
                ];
            })
        );
        return [result, items];
    }, [document]);

    const imageMapFields = useMemo(
        () =>
            [
                ...headerFormFieldGroups.map((fieldGroup) => fieldGroup?.[0]),
                ...Object.values(itemFormFieldGroups).flatMap((fields) => fields),
            ].flat(),
        [headerFormFieldGroups, itemFormFieldGroups]
    );

    const fastTrackCount = useMemo(() => {
        const [_, { medium, low }] = filterFastTrack([
            ...headerFormFieldGroups,
            ...Object.values(itemFormFieldGroups).flatMap((fields) => fields),
        ]);
        return low + medium;
    }, [headerFormFieldGroups, itemFormFieldGroups]);

    const SidebarControlsComponent = useConditionalComponent(
        'SidebarControls',
        {
            documentTypeName: documentConfiguration?.documentTypeName,
        },
        SidebarControls
    );

    return (
        <AssistanceViewContextProvider
            onUpdate={onUpdate}
            onReselect={onReselect}
            onFocusFieldChange={setFocusField}
            onHoverFieldChange={setHoverField}
            onUpdateFieldChange={setUpdateField}
            onReselectFieldChange={setReselectField}
            onHoverItemIndexChange={setHoverItemIndex}
            onFastTrackChange={setIsFastTrackEnabled}
            isFastTrackEnabled={isFastTrackEnabled}
            generalConfig={generalConfig}
            formsRef={formsRef}
            previewRef={previewRef}
        >
            <SplitPanes initialWidths={[66, 34]} initialHeights={[66, 34]}>
                <Pane className="bg-secondary">
                    <AssistanceImageMap
                        formsRef={formsRef}
                        previewRef={previewRef}
                        items={items}
                        fields={imageMapFields}
                        focusField={focusField}
                        hoverField={hoverField}
                        hoverItemIndex={hoverItemIndex}
                        reselectField={reselectField}
                    />
                </Pane>
                <Pane>
                    <AssistanceSidebar>
                        <div
                            className="flex flex-col w-full min-h-0 flex-auto overflow-auto js-assistance-form"
                            ref={formsRef}
                        >
                            <ValidationChecksSection document={document} />

                            <HeaderDataSection
                                previewRef={previewRef}
                                formFieldGroups={headerFormFieldGroups}
                                focusField={focusField}
                                hoverField={hoverField}
                                hoverItemIndex={hoverItemIndex}
                                updateField={updateField}
                                reselectField={reselectField}
                            />

                            {Object.entries(itemFormFieldGroups).map(
                                ([itemType, formFieldGroups]: [string, [any[], any[]]]) => (
                                    <ItemDataSection
                                        key={itemType}
                                        itemType={itemType}
                                        previewRef={previewRef}
                                        formFieldGroups={formFieldGroups}
                                        focusField={focusField}
                                        hoverField={hoverField}
                                        hoverItemIndex={hoverItemIndex}
                                        updateField={updateField}
                                        reselectField={reselectField}
                                    />
                                )
                            )}
                        </div>

                        <SidebarControlsComponent
                            isFastTrackEnabled={isFastTrackEnabled}
                            onFastTrackEnabledChange={setIsFastTrackEnabled}
                            fastTrackCount={fastTrackCount}
                        />
                    </AssistanceSidebar>
                </Pane>
            </SplitPanes>
        </AssistanceViewContextProvider>
    );
});

const AssistanceImageMap = ({
    formsRef,
    previewRef,
    items,
    fields,
    focusField,
    hoverField,
    hoverItemIndex,
    reselectField,
}: any) => {
    const { onFieldReselectStart, onFieldReselectEnd, onFieldBlur } = useAssistanceViewContext();
    const { handlers, ocr } = useAssistanceContext();

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    const handleSelectionStart = useCallback(() => {
        onFieldReselectStart({ ...focusField });
    }, [focusField]);

    const handleSelectionEnd = useCallback(
        (bbox: any, pageIndex: number) => {
            // simulate the call coming from the field not the image map
            reselectField?.reselectTargetRef?.current?.dispatchEvent(
                new CustomEvent('reselect', {
                    detail: { bbox, pageIndex },
                })
            );

            // clean up
            onFieldReselectEnd(null);
            onFieldBlur(null);
        },
        [handlers?.onReselect, reselectField]
    );

    const scrollFormToItem = (itemIndex: number) => {
        const triggers = formsRef?.current.querySelectorAll('.js-form-item-group');
        const trigger = triggers?.[itemIndex];
        if (!trigger) return;

        trigger.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleImageLoad = (evt) => {
        const { width, height } = evt.currentTarget;
        setImageDimensions({ width, height });
    };

    const [imageSrc, setImageSrc] = useState(ocr?.processedFile.url);
    useEffect(() => {
        setImageSrc(ocr?.processedFile.url);
    }, [ocr?.processedFile.url]);

    return (
        <AssistanceCanvas
            artboardWidth={imageDimensions.width}
            artboardHeight={imageDimensions.height}
            viewportRef={previewRef}
        >
            <SelectionImageMap
                src={imageSrc}
                numPages={ocr?.pagesCount}
                onLoad={handleImageLoad}
                canSelect={
                    (focusField !== null && focusField?.reselectTargetRef?.current) ||
                    (reselectField !== null && reselectField.reselectTargetRef?.current)
                }
                onSelectionStart={handleSelectionStart}
                onSelectionEnd={handleSelectionEnd}
            >
                {items.map((item: any, itemIndex: number) => (
                    <Area
                        key={itemIndex}
                        data={item}
                        bbox={item?.bbox}
                        pageIndex={item?.pageIndex}
                        onClick={() => scrollFormToItem(itemIndex)}
                        className={classnames(
                            'js-image-map-row fill-transparent stroke-1 stroke-[rgba(0,0,0,0.4)] opacity-80',
                            focusField && focusField.itemIndex === itemIndex && 'opacity-80 fill-[#c5ffed]',
                            hoverField && hoverField.itemIndex === itemIndex && 'opacity-80 fill-[#c5ffed]',
                            hoverItemIndex === itemIndex && 'opacity-80 fill-[#c5ffed]'
                        )}
                    />
                ))}

                {reselectField === null &&
                    fields?.map((field, fieldIndex) => (
                        <Area
                            key={fieldIndex}
                            data={field?.data}
                            bbox={field?.bbox}
                            pageIndex={field?.pageIndex}
                            className={classnames(
                                'js-image-map-text stroke-1 stroke-confidence-high fill-[#c5ffed] opacity-80',
                                focusField &&
                                    (focusField.fieldName?.split(GROUP_SEPARATOR)[0] !== field.fieldName ||
                                        focusField.itemIndex !== field.itemIndex) &&
                                    'fill-transparent',
                                hoverField &&
                                    !(
                                        hoverField.fieldName?.split(GROUP_SEPARATOR)[0] === field.fieldName &&
                                        hoverField.itemIndex === field.itemIndex
                                    ) &&
                                    'fill-transparent'
                            )}
                        />
                    ))}
            </SelectionImageMap>
        </AssistanceCanvas>
    );
};

export default AssistanceView;
