import * as React from 'react';
import ResultView from '../../../assistance/components/ResultView.tsx';
import classnames from '../../../core/utils/classnames.tsx';
import Modal from '../../../core/components/Modal.tsx';
import { useTranslation } from 'react-i18next';

const ResultModal = ({ record, documentConfig, handlers, className, ...modalProps }: any) => {
    const { t } = useTranslation('assistance');

    const outputFile = record?.outputFile;
    const stepRun = record?.stepRun || {};
    const isFinished = stepRun?.executionStatus === 'SUCCEEDED';
    const stepRunList = record?.[documentConfig?.documentTypeName.toLowerCase() + 'processingsteprunSet'] || [];

    return (
        <Modal
            className={classnames('!max-w-[1000px]', className)}
            title={t('resultModal.title')}
            allowMaximize={true}
            {...modalProps}
        >
            <ResultView
                record={record}
                file={outputFile ? outputFile : isFinished ? document : undefined}
                stepRunList={stepRunList}
                onRetryStep={handlers.onRetryStep}
            />
        </Modal>
    );
};

export default ResultModal;
