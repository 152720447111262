import { default as React } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToaster } from '../../core/components/Toast.tsx';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import Button from '../../core/components/Button.tsx';
import DropArea from '../../core/components/DropArea.tsx';
import CheckboxGroup from '../../core/components/CheckboxGroup.tsx';
import Alert from '../../core/components/Alert.tsx';

const IMPORT_FORM_DEFAULT_VALUES = {
    files: [],
};

const ImportFormCard = ({ channel, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: IMPORT_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const files = form.watch('files');

    const handleSubmit = async (formData) => {
        try {
            await onSubmit(formData.files[0]);
            publishToast({ description: t('configPage.importExport.importForm.submitSuccess'), status: 'success' });
            form.setValue('files', []);
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.importExport.importForm.submitError'), status: 'error' });
        }
    };

    return (
        <SettingsFormCard
            onSubmit={form.handleSubmit(handleSubmit)}
            title={t('configPage.importExport.importForm.title')}
        >
            <Controller
                name="files"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.importExport.importForm.fileLabel')} error={formErrors.files}>
                        <DropArea
                            placeholder={t('configPage.importExport.importForm.filePlaceholder')}
                            accept={{ 'text/json': ['.json'] }}
                            onFilesChange={(files) => field.onChange({ target: { value: files } })}
                            files={field.value}
                            maxFiles={1}
                        />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button
                    type="submit"
                    variant="primary"
                    disabled={files.length === 0}
                    loading={form.formState.isSubmitting}
                >
                    {t('configPage.importExport.importForm.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};

const EXPORTABLE_CONFIGS = [
    'config',
    'extractorConfig',
    'xmlSerializerConfig',
    'matchingConfig',
    'emailConfig',
    'masterDataConfig',
    'sftpConfig',
    'as2Config',
    'uploadConfig',
    'discardConfig',
    'labelingConfig',
];

const EXPORT_FORM_DEFAULT_VALUES = {
    configNames: [...EXPORTABLE_CONFIGS],
};

const ExportFormCard = ({ channel, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: EXPORT_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const configNames = form.watch('configNames');

    const handleSubmit = async ({ configNames }) => {
        try {
            const response = await onSubmit(configNames);
            const configsData = response.data.orderExportConfigs?.data;

            const url = window.URL.createObjectURL(new Blob([configsData]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${channel.name}.json`);
            document.body.appendChild(link);

            // Trigger the click event on the link to start the download
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);

            publishToast({ description: t('configPage.importExport.exportForm.submitSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.importExport.exportForm.submitError'), status: 'error' });
        }
    };

    return (
        <SettingsFormCard
            onSubmit={form.handleSubmit(handleSubmit)}
            title={t('configPage.importExport.exportForm.title')}
        >
            <Alert severity="info">
                <Trans
                    t={t}
                    i18nKey="configPage.importExport.exportForm.infoAlert"
                    components={{ code: <code className="rounded px-0.5" /> }}
                />
            </Alert>

            <Controller
                name="configNames"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.importExport.exportForm.configNamesLabel')}
                        error={formErrors.configNames}
                    >
                        <CheckboxGroup
                            {...field}
                            options={
                                EXPORTABLE_CONFIGS.map((configName) => ({
                                    value: configName,
                                    label: t(`configPage.importExport.exportForm.configNames.${configName}`),
                                })) || []
                            }
                            onValueChange={(value) => field.onChange({ target: { value } })}
                        />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button
                    type="submit"
                    variant="primary"
                    disabled={configNames.length === 0}
                    loading={form.formState.isSubmitting}
                >
                    {t('configPage.importExport.exportForm.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};

const ImportExportConfig = ({ channel, onImport, onExport }) => {
    return (
        <div className="flex flex-col gap-10">
            <ImportFormCard channel={channel} onSubmit={onImport} />
            <ExportFormCard channel={channel} onSubmit={onExport} />
        </div>
    );
};

export default ImportExportConfig;
