import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from '../../../../core/utils/classnames.tsx';
import { useTranslation } from 'react-i18next';
import Page from '../../../../core/components/Page';
import OrderByDropdown from '../../../../core/components/OrderByDropdown.tsx';
import {
    buildFilterQuery,
    IFilter,
    ORDER_BY_FILTER_DEFAULT_VALUE,
    ORDER_BY_FILTER_KEY,
    parseFilterQuery,
    SEARCH_FILTER_KEY,
    USER_FILTER_URL_PARAM_KEY,
    useUserFilters,
} from '../../../../core/utils/filterQuery';
import { withIcon } from '../../../../core/components/Icon';
import { faArchive, faBarsFilter, faFlask, faInbox } from '@fortawesome/pro-regular-svg-icons';
import { useOverviewContext } from './OverviewContext';
import SearchField from './SearchField';
import StatusFilter from './StatusFilter';
import UserFilter from './UserFilter';
import DateFilter from './DateFilter';
import { useApplicationContext } from '../../../../core/contexts/ApplicationContext';
import { canSeeTestingDocuments } from '../../../../users/utils';
import { useDebounce } from '../../../../core/utils/hooks/useDebounce';
import ClientFilter from './ClientFilter';
import { endOfDay, format, formatISO, parse, parseISO, startOfDay } from 'date-fns';

const FilterIcon = withIcon(faBarsFilter);
const InboxIcon = withIcon(faInbox);
const ArchiveIcon = withIcon(faArchive);
const TestingIcon = withIcon(faFlask);

interface FilterBarProps {
    filters: IFilter[];
    onFiltersChange: (filters: IFilter[]) => void;
    onFiltersClear?: () => void;
    // used for db_immo external status right now
    renderExtraFilters?: (props: {
        filters: IFilter[];
        onFiltersChange: (filters: IFilter[]) => void;
    }) => React.ReactNode;
    activeChannel: any;
    channels: any;
    finished: boolean;
}

const TabFilter = ({
    active = false,
    className,
    ...props
}: React.ComponentPropsWithoutRef<typeof Link> & { active?: boolean }) => (
    <Link
        className={classnames(
            'border-b-2 border-solid border-transparent flex items-center justify-center gap-2 px-3 font-medium text-sm text-primary hover:text-brand transition-colors',
            'hover:border-secondary hover:text-primary',
            active && '!border-brand !text-brand',
            className
        )}
        {...props}
    />
);

const convertToDateTimeFilters = (filters: IFilter[]) => {
    const dateTimeFilters = filters.filter((f) => f.name === 'created_at' || f.name === 'finished_at');
    return dateTimeFilters.map((f) => ({
        ...f,
        value: f.value.map((v) => {
            const parsedDate = parse(v, 'yyyy-MM-dd', new Date());

            const localMidnight = ['ge', 'gt'].includes(f.expression)
                ? startOfDay(parsedDate)
                : ['le', 'lt'].includes(f.expression)
                  ? endOfDay(parsedDate)
                  : parsedDate;

            return formatISO(localMidnight);
        }),
    }));
};

const convertFromDateTimeFilters = (filters: IFilter[]) => {
    const dateTimeFilters = filters.filter((f) => f.name === 'created_at' || f.name === 'finished_at');
    return dateTimeFilters.map((f) => ({
        ...f,
        value: f.value.map((v) => {
            const parsedDate = parseISO(v);
            return format(parsedDate, 'yyyy-MM-dd');
        }),
    }));
};

const DropdownFilterBar = ({
    filters,
    onFiltersChange,
    onFiltersClear,
    renderExtraFilters,
    activeChannel,
    channels,
    finished,
}: FilterBarProps) => {
    const { t } = useTranslation('assistance');

    const listFilters = filters.filter((f) => f.name !== ORDER_BY_FILTER_KEY && f.name !== SEARCH_FILTER_KEY);

    return (
        <div className="px-6 py-2 flex flex-wrap gap-2 bg-secondary-light border-b border-solid border-secondary">
            <div className="flex-nowrap">
                <StatusFilter filters={filters} onFiltersChange={onFiltersChange} />
            </div>
            <div className="flex-nowrap">
                <ClientFilter filters={filters} onFiltersChange={onFiltersChange} />
            </div>
            <div className="flex-nowrap">
                <UserFilter
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    activeChannel={activeChannel}
                    channels={channels}
                    filterName={'created_by'}
                />
            </div>
            <div className="flex-nowrap">
                <DateFilter
                    filters={convertFromDateTimeFilters(filters)}
                    onFiltersChange={(filters) => onFiltersChange(convertToDateTimeFilters(filters))}
                    filterName="created_at"
                />
            </div>
            {finished && (
                <div className="flex-nowrap">
                    <UserFilter
                        filters={filters}
                        onFiltersChange={onFiltersChange}
                        activeChannel={activeChannel}
                        channels={channels}
                        filterName={'assisted_by'}
                    />
                </div>
            )}
            {finished && (
                <div className="flex-nowrap">
                    <DateFilter
                        filters={convertFromDateTimeFilters(filters)}
                        onFiltersChange={(filters) => onFiltersChange(convertToDateTimeFilters(filters))}
                        filterName="finished_at"
                    />
                </div>
            )}

            {renderExtraFilters && renderExtraFilters({ filters, onFiltersChange })}

            {listFilters.length > 0 && (
                <button
                    className="text-primary px-3 text-sm font-medium hover:text-primary hover:bg-secondary rounded transition-colors"
                    onClick={onFiltersClear}
                >
                    {t('overview.filters.clearAll')}
                </button>
            )}
        </div>
    );
};

const SHARED_FILTER_NAMES = ['created_by', 'created_at', 'client', 'order_by', 'search'];

const FilterBar = ({
    filters,
    onFiltersChange,
    onFiltersClear,
    renderExtraFilters,
    activeChannel,
    channels,
}: FilterBarProps) => {
    const { t } = useTranslation('assistance');

    const { user } = useApplicationContext();
    const { channelId, documentConfiguration, finished, testing, openUrl, finishedUrl, testingUrl } =
        useOverviewContext();

    const listFilters = filters.filter((f) => f.name !== ORDER_BY_FILTER_KEY && f.name !== SEARCH_FILTER_KEY);
    const [dropdownFilterBarVisible, setDropdownFilterBarVisible] = useState(listFilters.length > 0);
    useEffect(() => {
        if (!dropdownFilterBarVisible) {
            setDropdownFilterBarVisible(listFilters.length > 0);
        }
    }, [filters]);

    const uniqueListFiltersCount = new Set(listFilters.map((f) => f.name)).size;

    let orderByValue = filters.find((f) => f.name === ORDER_BY_FILTER_KEY)?.value;
    if (orderByValue && Array.isArray(orderByValue)) orderByValue = orderByValue[0];

    const handleOrderByChange = (orderBy) => {
        // replace the current order_by filter with the new one
        const newFilters = filters.filter((f) => f.name !== ORDER_BY_FILTER_KEY);
        newFilters.push({ name: ORDER_BY_FILTER_KEY, value: orderBy, exclude: false, expression: 'eq' });
        onFiltersChange(newFilters);
    };

    let searchTerm = filters.find((f) => f.name === SEARCH_FILTER_KEY)?.value || '';
    if (searchTerm && Array.isArray(searchTerm)) searchTerm = searchTerm[0];

    const [searchInputValue, setSearchInputValue] = useState(searchTerm);
    useEffect(() => {
        // if the search term is not in the filters, we need to set it
        if (searchInputValue !== searchTerm) {
            setSearchInputValue(searchTerm);
        }
    }, [searchTerm]);

    const handleSearchTermChange = (searchTerm) => {
        // only really trigger search after the user has stopped typing for 500ms
        setSearchInputValue(searchTerm);
        debouncedPropagateSearchFilter(searchTerm);
    };

    const propagateSearchFilter = useCallback(
        (searchTerm) => {
            // replace the current search filter with the new one
            const newFilters = filters.filter((f) => f.name !== SEARCH_FILTER_KEY);
            if (searchTerm) {
                newFilters.push({ name: SEARCH_FILTER_KEY, value: searchTerm, exclude: false, expression: 'eq' });
            }
            onFiltersChange(newFilters);
        },
        [filters, onFiltersChange]
    );

    const debouncedPropagateSearchFilter = useDebounce(propagateSearchFilter, 500);

    const tabSearchParams = useCallback(
        (tabKey) => {
            // to generate the tab specific search params, we need to take the filters from the local storage
            // and replace the shared once with the ones from the url if given

            const storageKey = `${documentConfiguration.documentTypeName}-${channelId}-${tabKey}`;
            const localStorageFilters: IFilter[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

            const searchParams = new URLSearchParams(document.location.search);
            const sharedFilters = filters.filter((f) => SHARED_FILTER_NAMES.includes(f.name));

            const combinedFilters = [
                ...localStorageFilters.filter(
                    (f) => !SHARED_FILTER_NAMES.includes(f.name) || f.name === ORDER_BY_FILTER_KEY
                ),
                ...sharedFilters,
            ];

            const combinedFiltersString = buildFilterQuery(combinedFilters);
            if (combinedFiltersString) {
                searchParams.set(USER_FILTER_URL_PARAM_KEY, combinedFiltersString);
            } else {
                searchParams.delete(USER_FILTER_URL_PARAM_KEY);
            }
            return searchParams;
        },
        [filters]
    );

    const orderByOptions = [
        {
            value: 'created_at',
            label: t('overview.filters.orderBy.options.createdAt'),
            isDate: true,
        },
        {
            value: 'created_by__email',
            label: t('overview.filters.orderBy.options.createdBy'),
        },
    ];

    return (
        <>
            <Page.Header className="py-0">
                <div className="flex flex-1 h-full">
                    <TabFilter to={`${openUrl}?${tabSearchParams('open').toString()}`} active={!finished && !testing}>
                        <InboxIcon /> {t('overview.header.open')}
                    </TabFilter>

                    <TabFilter to={`${finishedUrl}?${tabSearchParams('finished').toString()}`} active={finished}>
                        <ArchiveIcon /> {t('overview.header.finished')}
                    </TabFilter>

                    {testingUrl && canSeeTestingDocuments(user) && (
                        <TabFilter to={`${testingUrl}?${tabSearchParams('testing').toString()}`} active={testing}>
                            <TestingIcon /> {t('overview.header.testing')}
                        </TabFilter>
                    )}
                </div>

                <div className="py-2.5 flex gap-2">
                    <SearchField
                        placeholder={t('overview.filters.search.placeholder')}
                        value={searchInputValue}
                        onValueChange={handleSearchTermChange}
                    />
                    <Page.HeaderButton
                        active={dropdownFilterBarVisible}
                        onClick={() => setDropdownFilterBarVisible(!dropdownFilterBarVisible)}
                        variant="ghost"
                    >
                        <FilterIcon /> {t('overview.filters.toggleLabel')}{' '}
                        {uniqueListFiltersCount > 0 && `(${uniqueListFiltersCount})`}
                    </Page.HeaderButton>

                    <OrderByDropdown
                        label={t('overview.filters.orderBy.label')}
                        options={orderByOptions}
                        selected={orderByValue}
                        defaultValue={ORDER_BY_FILTER_DEFAULT_VALUE}
                        onSelectedChange={handleOrderByChange}
                        inline
                    />
                </div>
            </Page.Header>

            {dropdownFilterBarVisible && (
                <DropdownFilterBar
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    onFiltersClear={onFiltersClear}
                    renderExtraFilters={renderExtraFilters}
                    activeChannel={activeChannel}
                    channels={channels}
                    finished={finished}
                />
            )}
        </>
    );
};

export default FilterBar;
