export const OVERVIEW_PATH = '/list-of-services';
export const OVERVIEW_FINISHED_PATH = '/list-of-services/finished';
export const OVERVIEW_TESTING_PATH = '/list-of-services/testing';

export const CHANNEL_PATH = '/list-of-services/channels/:channelId';
export const CHANNEL_FINISHED_PATH = '/list-of-services/channels/:channelId/finished';
export const CHANNEL_TESTING_PATH = '/list-of-services/channels/:channelId/testing';
export const CHANNEL_CONFIG_PATH = '/list-of-services/channels/:channelId/config/:tab?';
export const CHANNEL_MASTERDATA_PATH = '/list-of-services/channels/:channelId/masterdata';

export const ASSISTANCE_PATH = '/list-of-services/:recordId';
export const ASSISTANCE_TAB_PATH = '/list-of-services/:recordId/:tab';
