import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Link, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { canManageUsers } from '../utils.ts';
import ConfirmModal from '../../core/components/ConfirmModal.tsx';
import { url } from '../../core/utils/link.ts';

import {
    DELETE_USER,
    GET_ROLES,
    GET_TEAMS,
    GET_USER,
    GET_USER_EMAIL_ADDRESSES,
    GET_USERS,
    INVITE_USER,
    RESET_PASSWORD,
    UPDATE_USER,
} from '../queries.ts';
import { USERS_ADD_PATH, USERS_EDIT_PATH, USERS_PATH } from '../constants.ts';
import { DEFAULT_LANGUAGE, LANGUAGE_OPTIONS } from '../../../constants.ts';

import Layout from '../../core/components/Layout.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import { withIcon } from '../../core/components/Icon.tsx';
import { faEllipsisV, faPlus } from '@fortawesome/pro-regular-svg-icons';
import Page from '../../core/components/Page';
import FilterDropdown from '../../core/components/Filter.tsx';
import OrderByDropdown from '../../core/components/OrderByDropdown.tsx';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import { RowControlIconButtonProps } from '../../assistance/components/RecordTable.tsx';
import DropdownMenu from '../../core/components/DropdownMenu.tsx';
import SelectField from '../../core/components/Fields/SelectField.tsx';
import { IUser } from '../interfaces.ts';
import Modal from '../../core/components/Modal.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import Button from '../../core/components/Button.tsx';
import classnames from '../../core/utils/classnames.tsx';
import { Outlet } from 'react-router';
import CheckboxGroup from '../../core/components/CheckboxGroup.tsx';
import useSearchParamState from '../../core/utils/useSearchParamState.tsx';
import useUpdateEffect from '../../core/utils/useUpdateEffect.tsx';
import { useApplicationContext } from '../../core/contexts/ApplicationContext.tsx';
import Tooltip from '../../core/components/Tooltip.tsx';
import TablePagination from '../../core/components/TablePagination.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

interface UserFormModalOutletContext {
    roles: any[];
    teams: any[];
    users: IUser[];
    totalCount: number;
    refetch: () => Promise<any>;
}

const PAGE_PARAM = 'page';
const MAX_ITEMS = 500;
const ITEMS_PER_PAGE = 10;

const MoreIcon = withIcon(faEllipsisV);
const AddIcon = withIcon(faPlus);

const FORM_DEFAULT_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    language: DEFAULT_LANGUAGE,
    roleIds: [],
    teamIds: [],
};

const UserFormModal = ({
    title,
    submitButtonLabel,
    onSubmit,
    onClose,
    user,
    roles,
    teams,
}: {
    title: string;
    submitButtonLabel: string;
    onSubmit: (formData: any) => void;
    onClose: () => void;
    user?: IUser;
    roles: any[];
    teams: any[];
}) => {
    const { t } = useTranslation('users');
    const { user: viewer } = useApplicationContext();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (user) {
            form.reset({
                ...FORM_DEFAULT_VALUES,
                ...(user && {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    language: user.language || DEFAULT_LANGUAGE,
                    roleIds: user.groups?.map((group) => group.id) || [],
                    teamIds: user.teams?.map((team) => team.id) || [],
                }),
            });
        } else if (teams) {
            form.reset({
                ...FORM_DEFAULT_VALUES,
                teamIds: teams.filter((team) => team.isDefault).map((team) => team.id),
            });
        }
    }, [user, teams]);

    return (
        <Modal
            visible={true}
            onClose={onClose}
            title={title}
            buttons={
                <>
                    <Button type="button" asChild>
                        <Link to={url(USERS_PATH)}>{t('form.cancelButton')}</Link>
                    </Button>
                    <Button variant="primary" type="submit" form="modal-form">
                        {submitButtonLabel}
                    </Button>
                </>
            }
        >
            <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)} id="modal-form">
                <Controller
                    name="firstName"
                    control={form.control}
                    rules={{ required: t('form.firstNameRequired') }}
                    render={({ field }) => (
                        <FormField label={t('form.firstName')} required={true} error={formErrors.firstName}>
                            <StringField required={true} {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="lastName"
                    control={form.control}
                    rules={{ required: t('form.lastNameRequired') }}
                    render={({ field }) => (
                        <FormField label={t('form.lastName')} required={true} error={formErrors.lastName}>
                            <StringField required={true} {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="email"
                    control={form.control}
                    rules={{ required: t('form.emailRequired') }}
                    render={({ field }) => (
                        <FormField label={t('form.email')} required={true} error={formErrors.email}>
                            <StringField required={true} {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="language"
                    control={form.control}
                    rules={{ required: t('form.languageRequired') }}
                    render={({ field }) => (
                        <FormField label={t('form.language')} required={true} error={formErrors.language}>
                            <SelectField {...field} options={LANGUAGE_OPTIONS} required />
                        </FormField>
                    )}
                />

                <Controller
                    name="roleIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('form.roles')} error={formErrors.roleIds}>
                            <CheckboxGroup
                                {...field}
                                options={roles?.map((role) => ({ value: role.id, label: role.name })) || []}
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="teamIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('form.teams')} error={formErrors.teamIds}>
                            <CheckboxGroup
                                {...field}
                                options={
                                    teams?.map((team) => ({
                                        value: team.id,
                                        label: !team.isDefault ? (
                                            team.name
                                        ) : (
                                            <span className="flex gap-2 items-center">
                                                <span>{viewer?.customer?.name || t('overview.defaultTeam')}</span>
                                                <span className="text-xs text-brand font-medium bg-brand px-1.5 py-0.5 rounded">
                                                    {t('overview.defaultTeamBadge')}
                                                </span>
                                            </span>
                                        ),
                                        disabled: team.isDefault,
                                    })) || []
                                }
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />
            </form>
        </Modal>
    );
};

export const AddUserFormModal = () => {
    const { t } = useTranslation('users');
    const { teams, roles, refetch } = useOutletContext<UserFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const [inviteUser] = useMutation(INVITE_USER);
    const handleCreate = async (formData) => {
        try {
            await inviteUser({
                variables: {
                    email: formData.email,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    groups: formData.roleIds,
                    teams: formData.teamIds,
                    language: formData.language,
                },
            });
            await refetch();
            publishToast({ description: t('form.createSuccess'), status: 'success' });
            navigate(url(USERS_PATH));
        } catch (error) {
            console.error(error);
            publishToast({ description: t('form.createError'), status: 'error' });
        }
    };

    return (
        <UserFormModal
            title={t('form.createTitle')}
            submitButtonLabel={t('form.createButton')}
            onSubmit={handleCreate}
            onClose={() => navigate(url(USERS_PATH))}
            teams={teams}
            roles={roles}
        />
    );
};

export const EditUserFormModal = () => {
    const { t } = useTranslation('users');
    const { teams, roles, refetch } = useOutletContext<UserFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const { userId } = useParams();
    const { data: editUserData } = useQuery(GET_USER, { skip: !userId, variables: { id: userId } });
    const user = editUserData?.user;

    const [updateUser] = useMutation(UPDATE_USER);
    const handleUpdate = async (formData) => {
        try {
            await updateUser({
                variables: {
                    id: user.id,
                    email: formData.email,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    groups: formData.roleIds,
                    teams: formData.teamIds,
                    language: formData.language,
                },
            });
            await refetch();
            publishToast({ description: t('form.updateSuccess'), status: 'success' });
            navigate(url(USERS_PATH));
        } catch (error) {
            publishToast({ description: t('form.updateError'), status: 'error' });
            console.error(error);
        }
    };

    return (
        <UserFormModal
            title={t('form.updateTitle')}
            submitButtonLabel={t('form.updateButton')}
            onSubmit={handleUpdate}
            onClose={() => navigate(url(USERS_PATH))}
            user={user}
            teams={teams}
            roles={roles}
        />
    );
};

const UserMoreButton = ({
    user,
    refetch,
    ...props
}: RowControlIconButtonProps & {
    user: IUser;
    refetch: () => Promise<any>;
}) => {
    const { t } = useTranslation('users');
    const { publishToast } = useToaster();

    const { user: viewer } = useApplicationContext();
    const isCurrentUser = viewer.id === user.id;

    const [deleteUser] = useMutation(DELETE_USER);
    const [resetPassword] = useMutation(RESET_PASSWORD);

    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isResetModalVisible, setResetModalVisible] = useState(false);

    const handleDeleteUser = async () => {
        try {
            await deleteUser({ variables: { id: user.id } });
            await refetch();
            publishToast({ description: t('overview.deleteSuccess', { user }) });
        } catch (error) {
            console.error(error);
            publishToast({ description: t('overview.deleteError', { user }) });
        } finally {
            setDeleteModalVisible(false);
        }
    };

    const handleResetPassword = async () => {
        try {
            await resetPassword({ variables: { email: user.email } });
            publishToast({
                description: t('overview.resetPasswordSuccess', { user, email: user.email }),
                status: 'success',
            });
        } catch (error) {
            console.error(error);
            publishToast({ description: t('overview.resetPasswordError', { user }), status: 'error' });
        } finally {
            setResetModalVisible(false);
        }
    };

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <RowControlIconButton {...props}>
                        <MoreIcon />
                    </RowControlIconButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content align="end">
                    <DropdownMenu.Item asChild>
                        <Link to={url(USERS_EDIT_PATH, { userId: user.id })}>{t('overview.moreMenu.edit')}</Link>
                    </DropdownMenu.Item>

                    <DropdownMenu.Item onClick={() => setResetModalVisible(true)}>
                        {t('overview.moreMenu.resetPassword')}
                    </DropdownMenu.Item>

                    <DropdownMenu.Separator />

                    <Tooltip content={isCurrentUser ? t('overview.moreMenu.selfNotDeletable') : ''} side="left">
                        <DropdownMenu.Item
                            className="text-error hover:text-error hover:!bg-error"
                            onClick={() => setDeleteModalVisible(true)}
                            disabled={isCurrentUser}
                        >
                            {t('overview.moreMenu.delete')}
                        </DropdownMenu.Item>
                    </Tooltip>
                </DropdownMenu.Content>
            </DropdownMenu>

            {isDeleteModalVisible && (
                <ConfirmModal
                    onConfirm={handleDeleteUser}
                    onCancel={() => setDeleteModalVisible(false)}
                    title={t('overview.confirmDeleteTitle')}
                    labelConfirm={t('overview.confirmDeleteButton')}
                    danger
                >
                    {t('overview.confirmDelete', { user })}
                </ConfirmModal>
            )}
            {isResetModalVisible && (
                <ConfirmModal
                    onConfirm={handleResetPassword}
                    onCancel={() => setResetModalVisible(false)}
                    title={t('overview.confirmResetPasswordTitle')}
                    labelConfirm={t('overview.confirmResetPasswordButton')}
                >
                    {t('overview.confirmResetPassword', { user })}
                </ConfirmModal>
            )}
        </>
    );
};

const UserRow = ({ user, moreButton }) => {
    const { t } = useTranslation('users');

    const { user: viewer } = useApplicationContext();
    const isCurrentUser = viewer.id === user.id;

    return (
        <>
            <Table.Row>
                <Table.Cell>
                    <div className="flex gap-3 justify-start items-center">
                        <div className="flex flex-col">
                            <div>
                                {user.firstName} {user.lastName}
                            </div>
                            <div className="text-sm text-secondary">{user.email}</div>
                        </div>

                        {isCurrentUser && (
                            <span className="text-xs text-brand font-medium bg-brand px-1.5 py-0.5 rounded">
                                {t('overview.youBadge')}
                            </span>
                        )}
                    </div>
                </Table.Cell>
                <Table.Cell>{user.groups.map((group) => group.name).join(', ')}</Table.Cell>
                <Table.Cell>
                    {user.teams
                        .map((team) =>
                            !team.isDefault ? team.name : viewer?.customer?.name || t('overview.defaultTeam')
                        )
                        .join(', ')}
                </Table.Cell>
                <Table.Cell>{moreButton}</Table.Cell>
            </Table.Row>
        </>
    );
};

const UsersPage = ({ user: viewer }: any) => {
    const { t } = useTranslation('users');

    const [activePage, setActivePage] = useSearchParamState(PAGE_PARAM, '1');
    const [roleFilter, setRoleFilter] = useSearchParamState('role', '');
    const [teamFilter, setTeamFilter] = useSearchParamState('team', '');
    const [sort, setSort] = useSearchParamState('sort', 'lastName');
    const [itemsPerPage, setItemsPerPage] = useSearchParamState('pageSize', ITEMS_PER_PAGE.toString());

    const [searchParams, setSearchParams] = useSearchParams();
    const handleClearAllClick = () => setSearchParams(() => ({}));
    const showClearAll = searchParams.has('role') || searchParams.has('team');

    useUpdateEffect(() => {
        setSearchParams(() => {
            // passed in search params might be outdated but window.location.search is always up-to-date
            const params = new URLSearchParams(window.location.search);
            params.delete(PAGE_PARAM);
            return params;
        });
    }, [roleFilter, teamFilter, sort, itemsPerPage]);

    const parsedActivePage = parseInt(activePage);
    const parsedItemsPerPage = parseInt(itemsPerPage);

    let [sortField, sortOrder] = sort.startsWith('-') ? [sort.slice(1), 'desc'] : [sort, 'asc'];

    const {
        data: currentData,
        previousData,
        loading,
        refetch,
    } = useQuery(GET_USERS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            from: (parsedActivePage - 1) * parsedItemsPerPage,
            size: parsedItemsPerPage,
            groupId: roleFilter || undefined,
            teamId: teamFilter || undefined,
            sortField,
            sortOrder,
        },
    });
    const data = loading ? previousData : currentData;

    const client = useApolloClient();
    const getAllUserEmailAddresses = () =>
        client.query({
            query: GET_USER_EMAIL_ADDRESSES,
            variables: {
                from: 0,
                size: 1000,
                groupId: roleFilter || undefined,
                teamId: teamFilter || undefined,
            },
        });

    const { publishToast } = useToaster();
    const handleCopyEmailAddresses = async () => {
        const data = await getAllUserEmailAddresses();
        const text = data.data?.users?.users?.map((user) => user.email).join('\n');
        navigator.clipboard
            .writeText(text)
            .then(() =>
                publishToast({
                    description: t('overview.copyEmailAddressesSuccess', { count: totalCount }),
                    status: 'success',
                })
            )
            .catch(() => publishToast({ description: t('overview.copyEmailAddressesError'), status: 'error' }));
    };

    const { data: rolesData } = useQuery(GET_ROLES, { notifyOnNetworkStatusChange: true });
    const { data: teamsData } = useQuery(GET_TEAMS, { notifyOnNetworkStatusChange: true });

    const roles = rolesData?.groups || [];
    const teams = teamsData?.teams || [];
    const users = data?.users?.users || [];
    const totalCount = data?.users?.totalCount || 0;

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('overview.title')}</Page.HeaderTitle>

                    <div className="flex gap-2">
                        <Page.HeaderButton onClick={handleCopyEmailAddresses}>
                            {t('overview.copyEmailAddresses', { count: totalCount })}
                        </Page.HeaderButton>

                        {canManageUsers(viewer) && (
                            <Page.HeaderButton asChild>
                                <Link to={url(USERS_ADD_PATH)}>
                                    <AddIcon />
                                    {t('overview.inviteEmployee')}
                                </Link>
                            </Page.HeaderButton>
                        )}
                    </div>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <div className="flex gap-2 justify-start">
                        <FilterDropdown
                            allLabel={t('overview.filters.role.all')}
                            label={t('overview.filters.role.some')}
                            options={
                                rolesData?.groups?.map((role) => ({
                                    value: role.id,
                                    label: role.name,
                                })) || []
                            }
                            selected={roleFilter ? [roleFilter] : []}
                            onSelectedChange={(values) => {
                                setRoleFilter(values[0] || '');
                            }}
                        />

                        <FilterDropdown
                            allLabel={t('overview.filters.team.all')}
                            label={t('overview.filters.team.some')}
                            options={
                                teamsData?.teams?.map((team) => ({
                                    value: team.id,
                                    label: !team.isDefault
                                        ? team.name
                                        : viewer?.customer?.name || t('overview.defaultTeam'),
                                })) || []
                            }
                            selected={teamFilter ? [teamFilter] : []}
                            onSelectedChange={(values) => setTeamFilter(values[0] || '')}
                        />

                        {showClearAll && (
                            <Button variant="ghost" onClick={handleClearAllClick}>
                                {t('overview.filters.clearAll')}
                            </Button>
                        )}

                        <OrderByDropdown
                            options={[
                                { value: 'firstName', label: t('overview.filters.sort.firstName') },
                                { value: 'lastName', label: t('overview.filters.sort.lastName') },
                            ]}
                            selected={sort}
                            onSelectedChange={setSort}
                            className="ml-auto"
                        />
                    </div>

                    <div>
                        <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeadCell>{t('overview.table.name')}</Table.HeadCell>
                                    <Table.HeadCell>{t('overview.table.roles')}</Table.HeadCell>
                                    <Table.HeadCell>{t('overview.table.teams')}</Table.HeadCell>
                                    <Table.HeadCell sticky="right" className="min-w-14 w-14" />
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {users.map((user) => (
                                    <UserRow
                                        key={user.id}
                                        user={user}
                                        moreButton={<UserMoreButton user={user} refetch={refetch} />}
                                    />
                                ))}
                            </Table.Body>
                        </Table>
                    </div>

                    {totalCount > 0 && (
                        <TablePagination
                            totalCount={totalCount}
                            activePage={parsedActivePage}
                            itemsPerPage={parsedItemsPerPage}
                            setActivePage={(page: number) => setActivePage(page ? page.toString() : '')}
                            setItemsPerPage={(itemsPerPage: number) =>
                                setItemsPerPage(itemsPerPage ? itemsPerPage.toString() : '')
                            }
                        />
                    )}
                </Page.Content>
            </Page>

            <Outlet context={{ roles, teams, users, totalCount, refetch }} />
        </Layout>
    );
};

export default UsersPage;
