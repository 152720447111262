import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { url } from '../../../core/utils/link';
import SourceFileView from '../../../assistance/components/SourceFileView.tsx';
import AssistanceHeader, { useAlert, useErrorAlert } from '../../../assistance/components/AssistanceHeader.tsx';
import AssistanceView from '../../../assistance/components/AssistanceView.tsx';
import useLock from '../../../assistance/utils/useLock';
import DiscardModal from '../../../assistance/components/DiscardModal.tsx';
import { AUTO_UPDATE_PENDING_INTERVAL } from '../../../../constants';
import { DocumentContext, useDocumentStore } from '../../stores';
import { appInsights } from '../../../core/analytics/applicationInsights';
import {
    deleteRecordEvent,
    openAssistanceEvent,
    removeDocumentEvent,
    reUploadDocumentEvent,
    toggleExtractedDocumentViewEvent,
} from '../../../core/analytics/customEvents';
import { SupportBarButton, useSupportMode } from '../../../support/components/SupportBar.tsx';
import { getLabelingStatus, LabelingStatus } from '../../constants';
import { NavigationContext } from '../../routes';
import { MasterDataBrowserModal } from '../../../masterdata/components/MasterDataBrowser.tsx';
import { ERROR_RESOLUTIONS } from '../../../core/utils/steprunConfig';
import { useApplicationContext } from '../../../core/contexts/ApplicationContext';
import Layout from '../../../core/components/Layout';
import { withIcon } from '../../../core/components/Icon';
import { faArrowUpToLine, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { AssistanceContextProvider } from './AssistanceContext';
import { getUserFilterStorageKeyFromRecord, useOverviewFilters, useUserFilters } from '../../../core/utils/filterQuery';
import useAssistanceHandlers from './useAssistanceHandlers';
import DocumentView from './AssistanceView';
import { useFocusTrap } from '../../../core/utils/hooks/useLocation';
import { ConditionalComponentContextProvider } from '../../../customizations/ConditionalComponentContext';
import ResultModal from './ResultModal';
import EmailsView from './EmailsView';
import { addHours } from 'date-fns';
import { capitalizeFirstLetter } from '../../../core/utils/string.ts';
import Button from '../../../core/components/Button.tsx';
import { unpackAndMergeDynamicSchemaFields } from '../../../core/utils';
import { MoveToAnotherChannelModal } from '../Overview/internal/MoveToAnotherChannelModal.tsx';
import DeleteDocumentsModal from '../Overview/internal/DeleteDocumentsModal.tsx';

const AZURE_WORKBOOK_BASE_URL =
    'https://portal.azure.com/#blade/AppInsightsExtension/UsageNotebookBlade/ComponentId/azure%20monitor/ConfigurationId/%2Fsubscriptions%2F844ddc72-61ee-4d3b-9f0c-283e13a30b3d%2FresourceGroups%2Fmonitoring%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2Fdb585c95-d527-434b-afdf-2c36c2c52571/Type/workbook/WorkbookTemplateName/Monitoring%20an%20Individual%20Document/NotebookParams/';

export enum TABS_OPTIONS {
    Source = 'source',
    Header = 'header',
    Document = 'document',
    Emails = 'emails',
}

export const ALL_TABS = {
    [TABS_OPTIONS.Emails]: () => <EmailsView />,
    [TABS_OPTIONS.Source]: ({ recordId, record, loading, readOnly, documentConfig, handlers }) => {
        const sourceFile = record?.sourceFile;
        return <SourceFileView file={sourceFile} readOnly={readOnly} loading={loading} />;
    },
    [TABS_OPTIONS.Document]: () => <DocumentView />,
};

const usePagination = ({ user, documentConfiguration, record }) => {
    const searchParams = new URLSearchParams(location.search);

    const channelId = searchParams.get('channel') === record?.channel?.id ? record?.channel?.id : undefined;
    const testing = record?.isTestDocument || false;
    const finished = record?.stepRun?.executionStatus === 'SUCCEEDED' || record?.deletedAt;
    const [userFilters] = useUserFilters({
        storageKey: getUserFilterStorageKeyFromRecord(documentConfiguration.documentTypeName, record),
        shouldReadLocalStorage: false,
    });
    const overviewFilters = useOverviewFilters({ userFilters, channelId, testing, finished });

    const { data: overviewData } = useQuery(documentConfiguration.GET_ASSISTANCE_OVERVIEW_DATA, {
        variables: {
            recordId: record?.id,
            filters: overviewFilters,
        },
        fetchPolicy: 'network-only',
        skip: !record?.id,
    });

    return overviewData?.[documentConfiguration?.documentTypeName + 'ProcessingRecordAssistancePagination'];
};

const UploadIcon = withIcon(faArrowUpToLine);
const ExternalLinkIcon = withIcon(faExternalLink);

const DocumentAssistance = ({ documentConfiguration, user }) => {
    const { canUseSupportMode, switchSupportUser } = useSupportMode(user);
    const { setChannelId } = useApplicationContext();

    const { recordId, tab } = useParams();

    const navigate = useNavigate();

    const { restoreFocus } = useFocusTrap();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const unlockView = searchParams.has('unlockView'); // TODO: maybe better naming
    const [showOriginalData, setShowOriginalData] = useState(false);
    const [resultModalVisible, setResultModalVisible] = useState(false);

    const { t } = useTranslation('assistance');

    const [refetchQueryParams, setRefetchQueryParams] = useState<any>({});

    const documentStore = useDocumentStore();
    const {
        data,
        error: dataError,
        loading: dataLoading,
        refetch: dataRefetch,
        startPolling,
        stopPolling,
    } = documentStore.getDocumentProcessingRecord(documentConfiguration.documentType, recordId);

    useEffect(() => {
        if (!data) return;

        unpackAndMergeDynamicSchemaFields(data);

        // Note: we cannot do this in onCompleted, because it is not called when notifyOnNetworkStatusChange is false
        const record = data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord'];
        if (record) {
            // this is triggered initially but also after re-fetching
            setRecord(record);
        }
    }, [data]);

    useEffect(() => {
        if (!data) return;

        const record = data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord'];

        const handleTabActivation = () => {
            // this is only executed when the tab is focused e.g. window is active and tab is active
            // which prevents issues when to many support users are switched simultaneously in the background
            if (!window.document.hidden && record == null && canUseSupportMode) {
                return switchSupportUser({
                    recordId,
                    documentType: documentConfiguration.documentType,
                });
            }
        };

        // Run on initial load if tab is visible
        void handleTabActivation();

        window.document.addEventListener('visibilitychange', handleTabActivation);
        window.addEventListener('focus', handleTabActivation);
        return () => {
            window.document.removeEventListener('visibilitychange', handleTabActivation);
            window.removeEventListener('focus', handleTabActivation);
        };
    }, [data, canUseSupportMode, recordId]);

    const [record, setRecord] = useState(data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']);
    const [extractedDocument, setExtractedDocument] = useState(
        data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.[
            documentConfiguration?.documentTypeName + 'Extracted'
        ]
    );
    const [ocr, setOcr] = useState(data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.ocr);

    // make sure that when the ocr is processed, the stored ocr is updated
    useEffect(() => {
        if (!data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.ocr?.processedFile.url) return;
        setOcr(data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.ocr);
    }, [data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.ocr?.processedFile.url]);

    useEffect(() => {
        // this is only called once initially
        const record = data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord'];
        setRecord(record);
        // only gets loaded initially, not when updated but record gets overwritten with response from server
        setExtractedDocument(record?.[documentConfiguration?.documentTypeName + 'Extracted']);
        setChannelId(record?.channel?.id);

        if (record?.ocr) {
            setOcr(record?.ocr);
        }

        if (record != null) {
            appInsights?.trackEvent(
                ...openAssistanceEvent(user, record, record?.[documentConfiguration?.documentTypeName + 'Unsaved'])
            );
        }
        return () => {
            setChannelId(null);
        };
    }, [data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.id]);

    // if current step run is finished => whole flow execution is finished
    const stepRun = record?.stepRun;
    const isRejected = record?.status === 'REJECTED';
    const isPending = stepRun?.executionStatus === 'PENDING';
    const isDiscarded = record?.isDiscarded;
    const isDeleted = record?.deletedAt;
    const isFinished = stepRun?.executionStatus === 'SUCCEEDED' || isRejected || isDiscarded || isDeleted;
    const isAssistanceNeeded = stepRun?.manualOnly && stepRun?.executionStatus == 'WAITING_FOR_MANUAL';

    const document = showOriginalData
        ? extractedDocument
        : record?.[documentConfiguration?.documentTypeName + 'Unsaved'];

    const { locked, lockedBy, lock, cleanLock } = useLock(record?.channel?.id, recordId, user?.id);

    const pagination = usePagination({ user, documentConfiguration, record });

    const dataRefetchWithVariables = React.useCallback(() => {
        dataRefetch(refetchQueryParams);
    }, [refetchQueryParams]);

    const handlers = useAssistanceHandlers({
        user,
        documentConfiguration,
        recordId,
        record,
        setRecord,
        dataRefetch: dataRefetchWithVariables,
        lock,
        cleanLock,
        pagination,
    });

    useEffect(() => {
        // in case no data is available yet, we don't know if assistance is needed
        if (isAssistanceNeeded === undefined) return;
        // we are waiting if the execution is not done (!isFinished) but no manual input is required
        if (isFinished ? false : !isAssistanceNeeded || isPending) {
            startPolling(AUTO_UPDATE_PENDING_INTERVAL);
        } else {
            stopPolling();
        }
    }, [isFinished, isPending, isAssistanceNeeded]);

    const dataErrorAlert = useErrorAlert(t('header.alerts.error.title'), dataError?.message);

    const defaultAlert = useAlert(record, stepRun, locked, lockedBy);

    let alert =
        dataErrorAlert || handlers?.alert || defaultAlert || (record && documentConfiguration?.getAlert?.({ record }));
    let alertButton = undefined;
    if (alert?.resolution === ERROR_RESOLUTIONS.Discard) {
        alertButton = (
            <Button onClick={handlers.onDiscardOpen} className="assistance-header__alert-button">
                {t('header.alerts.buttons.discard')}
            </Button>
        );
    } else if (alert?.resolution === ERROR_RESOLUTIONS.Retry) {
        alertButton = (
            <Button onClick={handlers.onRetryStep} className="assistance-header__alert-button">
                {t('header.alerts.buttons.retry')}
            </Button>
        );
    } else if (alert?.resolution === ERROR_RESOLUTIONS.Assistance) {
        alertButton = (
            <Button
                onClick={() => handlers.onReopenForAssistance(alert?.assistanceReason)}
                className="assistance-header__alert-button"
            >
                {t('header.alerts.buttons.assistance')}
            </Button>
        );
    }
    alert = alert ? { ...alert, button: alertButton } : undefined;

    const readOnly =
        showOriginalData || (!unlockView && (locked || isRejected || !isAssistanceNeeded || record?.deletedAt));
    const loading = dataLoading || handlers?.loading;

    const defaultTab = documentConfiguration?.defaultTab || TABS_OPTIONS.Document;
    const tabs = documentConfiguration?.tabs || ALL_TABS;

    const tabNames = Object.keys(tabs);

    useEffect(function redirectToHeaderTabOnMount() {
        if ((tab && tabNames.includes(tab)) || !defaultTab) return;
        navigate(url(documentConfiguration.ASSISTANCE_TAB_PATH, { recordId, tab: defaultTab }, { keepSearch: true }));
    }, []);

    const TabComponent = tabs[tab] || (tabNames.includes(defaultTab) ? tabs[defaultTab] : Object.values(tabs)[0]);

    const tabProps = {
        user,
        data,
        document,
        documentConfig: documentConfiguration,
        recordId,
        record,
        loading,
        readOnly,
        handlers,
        explanationToggle: true,
        setRefetchQueryParams,
        refetchQueryParams,
    };

    const navigationContext = useContext(NavigationContext);

    const [reUploadFile] = useMutation(documentConfiguration.RE_UPLOAD_FILE);
    const handleReUploadFile = (record) => {
        appInsights?.trackEvent(
            ...reUploadDocumentEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return reUploadFile({
            variables: {
                files: [],
                recordId: record?.id,
                channelId: record?.channel?.id,
                isTestingDocument: true,
            },
            fetchPolicy: 'no-cache',
        }).then((res) => {
            const recordId = res?.data?.[documentConfiguration?.documentTypeName + 'UploadFile']?.records?.[0]?.id;
            if (recordId) {
                window.open(url(documentConfiguration.ASSISTANCE_PATH, { recordId }, { keepSearch: true }));
            }
        });
    };

    const renderMLStudioSupportBarButton = () => {
        // Support button to open or send a document for labeling depending on its labeling status

        const isDocumentInMLStudio = record && getLabelingStatus(record) === LabelingStatus.Eligible;
        const translationKey = isDocumentInMLStudio
            ? 'support:supportBar.openInMlStudio'
            : 'support:supportBar.sendToMlStudio';

        const onClickHandler = isDocumentInMLStudio
            ? () => window.open(process.env.ML_STUDIO_URL + 'record/' + recordId + '/', '_blank')
            : handlers.onSendToLabeling;

        return (
            <SupportBarButton onClick={onClickHandler}>
                {t(translationKey)} <ExternalLinkIcon className="text-xs" />
            </SupportBarButton>
        );
    };

    const renderAzureWorkbookSupportBarButton = () => {
        // Support button to open the Azure Workbook for the currently open document

        const workbookUrl = new URL(AZURE_WORKBOOK_BASE_URL);

        const stepRunList =
            record?.[documentConfiguration?.documentTypeName.toLowerCase() + 'processingsteprunSet'] || [];
        const stepRunDates = stepRunList?.flatMap((stepRun) => [
            new Date(stepRun.createdAt),
            new Date(stepRun.updatedAt),
        ]);
        const maxDate = stepRunDates && stepRunDates.length > 0 ? new Date(Math.max(...stepRunDates)) : undefined;
        const minDate = stepRunDates && stepRunDates.length > 0 ? new Date(Math.min(...stepRunDates)) : undefined;
        const durationMs = maxDate && minDate ? maxDate.getTime() - minDate.getTime() : undefined;

        const prefillParams = {
            // the currently open document
            WB_link: window.location.href,
            // time range from two hours before processing to two hours after
            time_range: {
                durationMs: durationMs ? durationMs + 4 * 60 * 60 * 1000 : undefined,
                endTime: maxDate ? addHours(maxDate, 2)?.toISOString() : undefined,
            },
            record_id: record?.id,
            document_type: capitalizeFirstLetter(documentConfiguration?.documentTypeName),
        };

        //  add the prefill parameters at the end of the hash encodeURIComponent(JSON.stringify(prefillParams))
        workbookUrl.hash = `${workbookUrl.hash}${encodeURIComponent(JSON.stringify(prefillParams))}`;

        return (
            <SupportBarButton onClick={() => window.open(workbookUrl.href, '_blank')}>
                {t('support:supportBar.openInAzureWorkbook')} <ExternalLinkIcon className="text-xs" />
            </SupportBarButton>
        );
    };

    const toggleShowOriginalData = () => {
        const newValue = !showOriginalData;
        setShowOriginalData(newValue);
        appInsights?.trackEvent(...toggleExtractedDocumentViewEvent(user, record, document, { show: newValue }));
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Check for Shift + Command + X key combination
            if (e.shiftKey && e.metaKey && e.key === 'x') {
                toggleShowOriginalData();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showOriginalData]);

    const extendedHandlers = useMemo(
        () => ({
            ...handlers,
            onShowOriginalData: (show: boolean) => setShowOriginalData(show),
            showOriginalData,
            onResultModalVisibleChange: setResultModalVisible,
            resultModalVisible,
        }),
        [handlers, showOriginalData, setResultModalVisible, resultModalVisible]
    );

    const onDiscardDocumentSuccessHandler = async () => {
        appInsights?.trackEvent(
            ...removeDocumentEvent(user, record, record?.[documentConfiguration?.documentTypeName + 'Unsaved'])
        );

        return handlers.redirectToNextRecord();
    };

    const onDeleteDocumentSuccessHandler = async () => {
        appInsights?.trackEvent(
            ...deleteRecordEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );

        return handlers.redirectToNextRecord();
    };

    return (
        <NavigationContext.Provider
            value={{ ...navigationContext, channelId: record?.channel?.id, recordId: record?.id }}
        >
            <Layout
                supportBarControls={
                    <>
                        <SupportBarButton onClick={() => handleReUploadFile(record)} disabled={!record}>
                            <UploadIcon className="text-xs" /> {t('overview.recordRow.actions.debug')}
                        </SupportBarButton>
                        {renderMLStudioSupportBarButton()}
                        {renderAzureWorkbookSupportBarButton()}
                    </>
                }
            >
                <DocumentContext.Provider value={{ document }}>
                    <AssistanceContextProvider
                        documentConfiguration={documentConfiguration}
                        record={record}
                        setRecord={setRecord}
                        document={document}
                        ocr={ocr}
                        handlers={extendedHandlers}
                        tabs={tabs}
                        pagination={pagination}
                        loading={loading}
                        alert={alert}
                        readOnly={readOnly}
                        isFinished={isFinished}
                        isAssistanceNeeded={isAssistanceNeeded}
                        refetchData={dataRefetchWithVariables}
                    >
                        <ConditionalComponentContextProvider components={documentConfiguration?.components}>
                            <div className="assistance-wrapper dark-mode-ready flex-1 min-w-0 w-full flex flex-col">
                                {documentConfiguration.AssistanceHeader ? (
                                    <documentConfiguration.AssistanceHeader />
                                ) : (
                                    <AssistanceHeader />
                                )}
                                <AssistanceView>{TabComponent && <TabComponent {...tabProps} />}</AssistanceView>

                                {handlers.isDiscardDocumentVisible && (
                                    <DiscardModal
                                        documentType={documentConfiguration.documentType}
                                        initialValue={alert?.message}
                                        selectedRowKeys={[record?.id]}
                                        onClose={handlers.onDiscardClose}
                                        onSuccess={onDiscardDocumentSuccessHandler}
                                    />
                                )}
                                <MasterDataBrowserModal
                                    {...handlers.masterdataBrowserModalState}
                                    visible={handlers.masterdataBrowserModalVisible}
                                    onClose={() => {
                                        handlers.closeMasterdataBrowser();
                                        restoreFocus();
                                    }}
                                />
                                <ResultModal
                                    record={record}
                                    documentConfig={documentConfiguration}
                                    handlers={handlers}
                                    visible={resultModalVisible}
                                    onClose={() => setResultModalVisible(false)}
                                />

                                {handlers.isMoveDocumentVisible && (
                                    <MoveToAnotherChannelModal
                                        documentType={documentConfiguration.documentType}
                                        selectedRowKeys={[record?.id]}
                                        selectedChannelIds={[record?.channel?.id]}
                                        onClose={handlers.onMoveDocumentsClose}
                                        onSuccess={handlers.redirectToNextRecord}
                                    />
                                )}

                                {handlers.isDeleteDocumentVisible && (
                                    <DeleteDocumentsModal
                                        selectedRowKeys={[record?.id]}
                                        documentType={documentConfiguration.documentType}
                                        onClose={handlers.onDeleteDocumentsClose}
                                        onSuccess={onDeleteDocumentSuccessHandler}
                                    />
                                )}
                            </div>
                        </ConditionalComponentContextProvider>
                    </AssistanceContextProvider>
                </DocumentContext.Provider>
            </Layout>
        </NavigationContext.Provider>
    );
};

export default DocumentAssistance;
