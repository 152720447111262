import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToaster } from '../../core/components/Toast.tsx';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import BooleanField from '../../core/components/Fields/BooleanField.tsx';
import TextField from '../../core/components/Fields/TextField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import Button from '../../core/components/Button.tsx';

const FORM_DEFAULT_VALUES = {
    senderAs2Name: '',
    receiverUrl: '',
    receiverAs2Name: '',
    receiverVerifyCert: '',
    receiverEncryptCert: '',
    verifyReceiverCertificates: false,
};

const As2Config = ({ config, onActivate, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            senderAs2Name: config?.senderAs2Name || '',
            receiverUrl: config?.receiverUrl || '',
            receiverAs2Name: config?.receiverAs2Name || '',
            receiverVerifyCert: config?.receiverVerifyCert || '',
            receiverEncryptCert: config?.receiverEncryptCert || '',
            verifyReceiverCertificates: config?.verifyReceiverCertificates,
        });
    }, [config]);

    const handleSubmit = async (formData) => {
        try {
            if (!config) await onActivate();
            await onSubmit(formData);
            publishToast({ description: t('configPage.as2.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.as2.updateError'), status: 'error' });
        }
    };

    return (
        <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.as2.title')}>
            <Controller
                name="senderAs2Name"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.as2.form.senderAs2Name')} error={formErrors.senderAs2Name}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="receiverUrl"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.as2.form.receiverUrl')} error={formErrors.receiverUrl}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="receiverAs2Name"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.as2.form.receiverAs2Name')} error={formErrors.receiverAs2Name}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="receiverVerifyCert"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.as2.form.receiverVerifyCert')}
                        error={formErrors.receiverVerifyCert}
                    >
                        <TextField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="receiverEncryptCert"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.as2.form.receiverEncryptCert')}
                        error={formErrors.receiverEncryptCert}
                    >
                        <TextField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="verifyReceiverCertificates"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.as2.form.verifyReceiverCertificates')}
                        error={formErrors.verifyReceiverCertificates}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button type="submit" variant="primary">
                    {t('configPage.as2.form.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};

export default As2Config;
