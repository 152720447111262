import { FC, useMemo } from 'react';
import Button from '../../../../core/components/Button.tsx';
import { Controller, useForm } from 'react-hook-form';
import Modal, { IBaseModalProps } from '../../../../core/components/Modal.tsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { GET_NAVIGATION_CHANNELS } from '../../../../core/components/Navigation/internal/queries.ts';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { withIcon } from '../../../../core/components/Icon.tsx';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../../../core/utils/classnames.tsx';
import { MOVE_DOCUMENTS_BETWEEN_CHANNELS } from '../../../queries.ts';
import { useToaster } from '../../../../core/components/Toast.tsx';
import { DOCUMENT_TYPE, DocumentType, mapDocumentTypeToGqlDocumentType } from '../../../constants.ts';
import { useApplicationContext } from '../../../../core/contexts/ApplicationContext.tsx';
import { canManageAllDocumentTypes } from '../../../../users/utils.ts';

type MoveToAnotherChannelModalProps = IBaseModalProps & {
    selectedRowKeys: string[];
    selectedChannelIds: string[];
    documentType: DocumentType;
    refetch?: () => Promise<unknown>;
    onSuccess: () => void;
    onClose: () => void;
};

type MoveToAnotherChannelModalState = {
    newChannelId: string | undefined;
};

interface Channel {
    id: string;
    name: string;
    openDocumentsCount: number;
}

interface QueryResponse {
    rfqProcessingChannels: Channel[];
    orderProcessingChannels: Channel[];
    orderConfirmationProcessingChannels: Channel[];
    invoiceProcessingChannels: Channel[];
    propertyBillProcessingChannels: Channel[];
    deliveryNoteProcessingChannels: Channel[];
    listOfServicesProcessingChannels: Channel[];
    universalProcessingChannels: Channel[];
}

const CHANNEL_KEY_TO_DOCUMENT_TYPE: Record<keyof QueryResponse, DOCUMENT_TYPE> = {
    rfqProcessingChannels: DOCUMENT_TYPE.REQUEST_FOR_QUOTATION,
    orderProcessingChannels: DOCUMENT_TYPE.ORDER,
    orderConfirmationProcessingChannels: DOCUMENT_TYPE.ORDER_CONFIRMATION,
    invoiceProcessingChannels: DOCUMENT_TYPE.INVOICE,
    deliveryNoteProcessingChannels: DOCUMENT_TYPE.DELIVERY_NOTE,
    propertyBillProcessingChannels: DOCUMENT_TYPE.PROPERTY_BILL,
    listOfServicesProcessingChannels: DOCUMENT_TYPE.LIST_OF_SERVICES,
    universalProcessingChannels: DOCUMENT_TYPE.UNIVERSAL,
};

function findChannelKeyById(response: QueryResponse, targetId: string): string | null {
    const channelKeys = Object.keys(response) as Array<keyof QueryResponse>;

    for (const key of channelKeys) {
        const channels = response[key];
        if (Array.isArray(channels)) {
            const foundChannel = channels.find((channel) => channel.id === targetId);
            if (foundChannel) {
                return key;
            }
        }
    }

    return null;
}

const extractGroupKey = (source: string): string =>
    source.endsWith('ProcessingChannels') ? source.replaceAll('ProcessingChannels', '') : '';

const CheckIcon = withIcon(faCheck);

export const MoveToAnotherChannelModal: FC<MoveToAnotherChannelModalProps> = ({
    selectedRowKeys,
    selectedChannelIds,
    refetch,
    documentType,
    onClose,
    onSuccess,
    ...modalProps
}) => {
    const { t } = useTranslation('assistance');
    const { publishToast } = useToaster();
    const { user } = useApplicationContext();

    const { data: channelsData }: { data: QueryResponse } = useQuery(GET_NAVIGATION_CHANNELS, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: false,
    });

    const [moveDocuments, { loading: isSubmitting }] = useMutation(MOVE_DOCUMENTS_BETWEEN_CHANNELS);

    /*
        Workaround for bulk move in "all channels".
        If all selected documents are in the same channel, we can preselect one channel and forbid to move docs there
        If there are several channels, there is no need to preselect any of them.
     */
    const preselectedChannel = selectedChannelIds.length === 1 ? selectedChannelIds[0] : undefined;

    const form = useForm<MoveToAnotherChannelModalState>({
        defaultValues: { newChannelId: preselectedChannel },
    });

    const newChannelId = form.watch('newChannelId');

    const onSubmit = async (data: MoveToAnotherChannelModalState) => {
        const sourceDocumentType = mapDocumentTypeToGqlDocumentType[documentType];
        const targetChannelGroup = findChannelKeyById(channelsData, data.newChannelId);
        const targetDocumentType = CHANNEL_KEY_TO_DOCUMENT_TYPE[targetChannelGroup];
        const channelName = channelsData[targetChannelGroup]?.find((channel) => channel.id === data.newChannelId)?.name;

        const groupName = t(`overview.navigation.${extractGroupKey(targetChannelGroup)}`);

        try {
            await moveDocuments({
                variables: {
                    documentIds: selectedRowKeys,
                    sourceDocumentType,
                    targetDocumentType,
                    targetChannelId: data.newChannelId,
                },
            });

            await refetch?.();

            onSuccess();
            onClose();

            publishToast({
                description: t('overview.moveToAnotherChannelModal.moveActionSuccess', {
                    count: selectedRowKeys.length,
                    channelName,
                    groupName,
                }),
                status: 'success',
            });
        } catch (e) {
            console.error(e);
            publishToast({
                description: t('overview.moveToAnotherChannelModal.moveActionError', {
                    count: selectedRowKeys.length,
                }),
                status: 'error',
            });
        }
    };

    const activeChannelsData = useMemo(() => {
        const customerIsActive = !!user?.customer.isActive;
        const customer = user.customer;

        const accessCheckMapping: Record<keyof QueryResponse, boolean> = {
            rfqProcessingChannels: !!customer.isRfqsEnabled,
            orderProcessingChannels: !!customer.isOrderEnabled,
            orderConfirmationProcessingChannels: !!customer.isOrderConfirmationsEnabled,
            invoiceProcessingChannels: !!customer.isInvoicesEnabled,
            propertyBillProcessingChannels: !!customer.isPropertyBillsEnabled,
            deliveryNoteProcessingChannels: !!customer.isDeliveryNotesEnabled,
            listOfServicesProcessingChannels: !!customer.isListOfServicesEnabled,
            universalProcessingChannels: !!customer.isUniversalProcessingEnabled,
        };

        return (Object.entries(channelsData) as [keyof QueryResponse, Channel[]][])
            .filter(
                ([channelGroupKey]) =>
                    (customerIsActive && accessCheckMapping[channelGroupKey]) || canManageAllDocumentTypes(user)
            )
            .filter(([_, channels]) => channels.length > 0);
    }, [channelsData, user]);

    return (
        <Modal
            visible
            onClose={onClose}
            title={t('overview.moveToAnotherChannelModal.title', { count: selectedRowKeys.length })}
            buttons={
                <>
                    <Button type="button" onClick={onClose} loading={isSubmitting}>
                        {t('overview.moveToAnotherChannelModal.cancelButton')}
                    </Button>

                    <Button
                        variant="primary"
                        type="submit"
                        form="modal-form"
                        loading={isSubmitting}
                        disabled={newChannelId === preselectedChannel}
                    >
                        {t('overview.moveToAnotherChannelModal.moveButton', { count: selectedRowKeys.length })}
                    </Button>
                </>
            }
            {...modalProps}
        >
            <form onSubmit={form.handleSubmit(onSubmit)} id="modal-form">
                <Controller
                    name="newChannelId"
                    control={form.control}
                    render={({ field }) => (
                        <RadioGroup.Root
                            {...field}
                            onValueChange={field.onChange}
                            value={field.value}
                            className="flex flex-col gap-1"
                        >
                            {activeChannelsData.map(([key, channels]) => (
                                <div key={key} className="flex flex-col gap-1">
                                    <h3 className="text-neutral-focus text-xs leading-8">
                                        {t(`overview.navigation.${extractGroupKey(key)}`)}
                                    </h3>
                                    {channels.map((channel) => (
                                        <RadioGroup.Item
                                            key={channel.id}
                                            value={channel.id}
                                            className={classnames(
                                                `flex items-center w-full h-8 px-3 rounded-md cursor-pointer transition`,
                                                field.value === channel.id
                                                    ? 'bg-brand text-brand-default'
                                                    : 'hover:bg-secondary'
                                            )}
                                        >
                                            <span className={'w-5'}>{field.value === channel.id && <CheckIcon />}</span>
                                            <div className="ml-2 text-sm">{channel.name}</div>
                                        </RadioGroup.Item>
                                    ))}
                                </div>
                            ))}
                        </RadioGroup.Root>
                    )}
                />
            </form>
        </Modal>
    );
};
