import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { filterValidation } from '../utils.ts';
import Alert from '../../core/components/Alert.tsx';

const ValidationChecks = ({ checks = [] }: any) => {
    const { t, i18n } = useTranslation('assistance');

    const filteredChecks = filterValidation(checks, i18n);

    if (!filteredChecks.length) return null;

    return (
        <div className="flex flex-col gap-2">
            {filteredChecks.map((result: any, key) => (
                <Alert severity="warning" key={key}>
                    {result.targetValue ? (
                        <>
                            <b className="font-medium">{t('validationChecks.targetValueLabel')}:</b>{' '}
                            <span>{result.targetValue}</span>
                        </>
                    ) : (
                        <b>
                            {(result?.explanationDetails &&
                                (t(`validationChecks.${result.name}`, result.explanationDetails) as any)) ||
                                t(`validationChecks.${result.name}`)}
                        </b>
                    )}
                </Alert>
            ))}
        </div>
    );
};

export default ValidationChecks;
