import { gql } from '@apollo/client';

export const UPDATE_FIELDS_CONFIG = gql`
    mutation UpdateDocumentConfig(
        $channelId: UUID!
        $documentType: String!
        $documentConfig: DocumentConfigInput
        $fieldConfigs: [FieldConfigInput]
    ) {
        updateDocumentConfig(
            channelId: $channelId
            documentType: $documentType
            documentConfig: $documentConfig
            fieldConfigs: $fieldConfigs
        ) {
            success
        }
    }
`;

export const MOVE_DOCUMENTS_BETWEEN_CHANNELS = gql`
    mutation (
        $documentIds: [UUID!]!
        $sourceDocumentType: DocumentTypeEnum!
        $targetDocumentType: DocumentTypeEnum!
        $targetChannelId: UUID!
    ) {
        moveDocumentsBetweenChannels(
            documentIds: $documentIds
            sourceDocumentType: $sourceDocumentType
            targetDocumentType: $targetDocumentType
            targetChannelId: $targetChannelId
        ) {
            success
        }
    }
`;

export const DISCARD_DOCUMENTS = gql`
    mutation ($recordIds: [UUID!]!, $documentType: DocumentTypeEnum!, $reason: String) {
        discardDocuments(recordIds: $recordIds, documentType: $documentType, reason: $reason) {
            success
        }
    }
`;

export const DELETE_DOCUMENTS = gql`
    mutation ($recordIds: [UUID!]!, $documentType: DocumentTypeEnum!) {
        deleteDocuments(recordIds: $recordIds, documentType: $documentType) {
            success
        }
    }
`;
