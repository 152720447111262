import { useMutation, useQuery } from '@apollo/client';

import {
    faArrowRightToBracket,
    faArrowRotateRight,
    faExternalLink,
    faPencil,
    faPlus,
    faSpinnerThird,
    faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../core/utils/classnames.tsx';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withIcon } from '../../core/components/Icon.tsx';
import { GET_NAVIGATION_CHANNELS } from '../../core/components/Navigation/internal/queries.ts';
import {
    CREATE_SAGE200PROFESSIONAL_INTEGRATION,
    DELETE_SAGE200PROFESSIONAL_INTEGRATION,
    GET_SAGE200PROFESSIONAL_INTEGRATIONS,
    INTEGRATIONS_SAGE200PROFESSIONAL_OAUTH2_COMPLETE,
    TRIGGER_SAGE200PROFESSIONAL_INTEGRATION_MASTERDATA_UPDATE,
    TRIGGER_SAGE200PROFESSIONAL_OAUTH_FLOW,
    UPDATE_SAGE200PROFESSIONAL_INTEGRATION,
} from '../queries.ts';
import {
    CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_ADD_PATH,
    CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_EDIT_PATH,
    CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH,
} from '../constants.ts';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../core/components/Button.tsx';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { url } from '../../core/utils/link.ts';
import FormField from '../../core/components/FormField.tsx';
import CheckboxGroup from '../../core/components/CheckboxGroup.tsx';
import Modal from '../../core/components/Modal.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import { Outlet } from 'react-router';
import Layout from '../../core/components/Layout.tsx';
import Page from '../../core/components/Page';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { format } from 'date-fns';
import { useOauthCompleteMutation } from '../utils.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

interface Sage200ProfessionalFormModalOutletContext {
    integrations: any[];
    refetch: () => Promise<any>;
}

const AddIcon = withIcon(faPlus);
const EditIcon = withIcon(faPencil);
const DeleteIcon = withIcon(faTrashCan);
const RefreshIcon = withIcon(faArrowRotateRight);
const ExternalLinkIcon = withIcon(faExternalLink);
const LoadingIcon = withIcon(faSpinnerThird);
const LoginIcon = withIcon(faArrowRightToBracket);

const FORM_DEFAULT_VALUES = {
    name: '',
    siteId: '',
    companyId: '',
    warehouseId: '',
    lineTypeId: '',
    defaultTaxCodeId: '',
    authState: '',
    orderChannelIds: [],
};

const Sage200ProfessionalFormModal = ({
    title,
    submitButtonLabel,
    onSubmit,
    onClose,
    integration,
}: {
    title: string;
    submitButtonLabel: string;
    onSubmit: (data: any) => void;
    onClose: () => void;
    integration?: any;
}) => {
    const { t } = useTranslation('integrations');

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (integration) {
            form.reset({
                ...FORM_DEFAULT_VALUES,
                name: integration.name,
                siteId: integration.siteId,
                companyId: integration.companyId,
                warehouseId: integration.warehouseId,
                lineTypeId: integration.lineTypeId,
                defaultTaxCodeId: integration.defaultTaxCodeId,
                authState: integration.authState,
                orderChannelIds: integration.orderChannels.map((channel) => channel.id),
            });
        }
    }, [integration]);

    const { data: channelData } = useQuery(GET_NAVIGATION_CHANNELS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: false,
    });
    const orderChannels = channelData?.orderProcessingChannels || [];

    return (
        <Modal
            visible={true}
            onClose={onClose}
            title={title}
            buttons={
                <>
                    <Button type="button" asChild>
                        <Link to={url(CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH)}>
                            {t('sage200Professional.form.cancelButton')}
                        </Link>
                    </Button>
                    <Button variant="primary" type="submit" form="modal-form">
                        {submitButtonLabel}
                    </Button>
                </>
            }
        >
            <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)} id="modal-form">
                <Controller
                    name="name"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('sage200Professional.form.name')} error={formErrors.name}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="siteId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('sage200Professional.form.siteId')} error={formErrors.siteId}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="companyId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('sage200Professional.form.companyId')} error={formErrors.companyId}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="warehouseId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('sage200Professional.form.warehouseId')} error={formErrors.warehouseId}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="lineTypeId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('sage200Professional.form.lineTypeId')} error={formErrors.lineTypeId}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="defaultTaxCodeId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('sage200Professional.form.defaultTaxCodeId')}
                            error={formErrors.defaultTaxCodeId}
                        >
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="authState"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('sage200Professional.form.authState')} error={formErrors.authState}>
                            <StringField {...field} readOnly={true} />
                        </FormField>
                    )}
                />

                <Controller
                    name="orderChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('sage200Professional.form.orderChannelIds')}
                            error={formErrors.orderChannelIds}
                        >
                            <CheckboxGroup
                                {...field}
                                options={
                                    orderChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />
            </form>
        </Modal>
    );
};

export const AddSage200ProfessionalFormModal = () => {
    const { t } = useTranslation('integrations');
    const { refetch } = useOutletContext<Sage200ProfessionalFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const [createIntegration] = useMutation(CREATE_SAGE200PROFESSIONAL_INTEGRATION);
    const handleCreateIntegration = async (formData) => {
        try {
            const res = await createIntegration({
                variables: {
                    name: formData.name,
                    siteId: formData.siteId,
                    companyId: formData.companyId,
                    warehouseId: formData.warehouseId,
                    lineTypeId: formData.lineTypeId,
                    defaultTaxCodeId: formData.defaultTaxCodeId,
                    authState: formData.authState,
                    orderChannels: formData.orderChannelIds,
                },
            });
            // this one uses oauth2 so we need to redirect to the url
            window.location.href = res.data.createSage200professionalIntegration.redirectUrl;
        } catch (error) {
            console.error(error);
            publishToast({ description: t('sage200Professional.createError'), status: 'error' });
        }
    };

    return (
        <Sage200ProfessionalFormModal
            title={t('sage200Professional.form.createTitle')}
            submitButtonLabel={t('sage200Professional.form.createButton')}
            onSubmit={handleCreateIntegration}
            onClose={() => navigate(url(CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH))}
        />
    );
};

export const EditSage200ProfessionalFormModal = () => {
    const { t } = useTranslation('integrations');
    const { integrations, refetch } = useOutletContext<Sage200ProfessionalFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const { integrationId } = useParams();
    const integration = integrations?.find((integration) => integration.id === integrationId);

    const [updateIntegration] = useMutation(UPDATE_SAGE200PROFESSIONAL_INTEGRATION);
    const handleUpdateIntegration = async (formData) => {
        try {
            await updateIntegration({
                variables: {
                    id: integration.id,
                    name: formData.name,
                    siteId: formData.siteId,
                    companyId: formData.companyId,
                    warehouseId: formData.warehouseId,
                    lineTypeId: formData.lineTypeId,
                    defaultTaxCodeId: formData.defaultTaxCodeId,
                    authState: formData.authState,
                    orderChannels: formData.orderChannelIds,
                },
            });
            await refetch();
            publishToast({ description: t('sage200Professional.updateSuccess'), status: 'success' });
            navigate(url(CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH));
        } catch (error) {
            console.error(error);
            publishToast({ description: t('sage200Professional.updateError'), status: 'error' });
        }
    };

    return (
        <Sage200ProfessionalFormModal
            title={t('sage200Professional.form.updateTitle')}
            submitButtonLabel={t('sage200Professional.form.updateButton')}
            onSubmit={handleUpdateIntegration}
            onClose={() => navigate(url(CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH))}
            integration={integration}
        />
    );
};

const Sage200ProfessionalRow = ({ integration, refetch }: { integration: any; refetch: any }) => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const [deleteIntegration] = useMutation(DELETE_SAGE200PROFESSIONAL_INTEGRATION);
    const handleDeleteIntegration = async () => {
        try {
            await deleteIntegration({ variables: { id: integration.id } });
            await refetch();
            publishToast({ description: t('sage200Professional.deleteSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('sage200Professional.deleteError'), status: 'error' });
        }
    };

    const [updateMasterDataIntegration] = useMutation(TRIGGER_SAGE200PROFESSIONAL_INTEGRATION_MASTERDATA_UPDATE);
    const handleUpdateMasterDataIntegration = async () => {
        try {
            await updateMasterDataIntegration({ variables: { id: integration.id } });
            publishToast({ description: t('sage200Professional.updateMasterDataSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('sage200Professional.updateMasterDataError'), status: 'error' });
        }
    };

    const [updateOAuthFlowIntegration] = useMutation(TRIGGER_SAGE200PROFESSIONAL_OAUTH_FLOW);
    const handleOAuthFlowIntegration = async (id) => {
        try {
            const res = await updateOAuthFlowIntegration({ variables: { id } });
            window.location.href = res.data.triggerSage200professionalOauthFlow.redirectUrl;
        } catch (error) {
            console.error(error);
        }
    };

    const channels = integration ? [...integration.orderChannels] : [];

    return (
        <>
            <Table.Row>
                <Table.Cell>{integration.name}</Table.Cell>
                <Table.Cell>
                    <div className="flex gap-1.5 flex-wrap">
                        {channels.map((channel) => (
                            <span className="border-primary border border-solid bg-secondary flex gap-2 items-center px-1 rounded">
                                {channel.name}
                            </span>
                        ))}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div className="flex gap-2 items-center text-primary">
                        <span>
                            {t('sage200Professional.authState', {
                                authState: integration.authState,
                            })}
                        </span>

                        <RowControlIconButton
                            onClick={() => handleOAuthFlowIntegration(integration.id)}
                            className="text-brand"
                        >
                            <LoginIcon />
                        </RowControlIconButton>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {integration.activeMasterDataUpdate ? (
                        <span className="flex gap-2 items-center animate-pulse">
                            <span className="text-success">
                                <LoadingIcon spin />
                            </span>
                            <span>{t('sage200Professional.masterDataUpdateInProgress')}</span>
                        </span>
                    ) : (
                        <span>
                            {t('sage200Professional.lastMasterDataUpdate', {
                                updatedAt: format(new Date(integration.lastMasterDataUpdate), 'do MMMM yyyy, HH:mm'),
                            })}
                        </span>
                    )}
                </Table.Cell>
                <Table.Cell>{new Date(integration.createdAt).toLocaleString()}</Table.Cell>
                <Table.Cell>
                    <div className="flex gap-2">
                        <RowControlIconButton onClick={handleUpdateMasterDataIntegration}>
                            <RefreshIcon />
                        </RowControlIconButton>

                        <RowControlIconButton asChild>
                            <Link
                                to={url(CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_EDIT_PATH, {
                                    integrationId: integration.id,
                                })}
                                className="!text-primary hover:!text-primary"
                            >
                                <EditIcon />
                            </Link>
                        </RowControlIconButton>

                        <RowControlIconButton
                            className="hover:bg-error text-error hover:text-error"
                            onClick={handleDeleteIntegration}
                        >
                            <DeleteIcon />
                        </RowControlIconButton>
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    );
};

export const Sage200ProfessionalOauthCallbackModal = () => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const { refetch } = useOutletContext<Sage200ProfessionalFormModalOutletContext>();

    const navigate = useNavigate();

    const [completeOauth] = useOauthCompleteMutation(
        'sage200professional',
        INTEGRATIONS_SAGE200PROFESSIONAL_OAUTH2_COMPLETE
    );
    const handleCompleteOauth = async () => {
        try {
            await completeOauth();
            await refetch();
            publishToast({ description: t('sage200Professional.oauthCallbackModal.Success'), status: 'success' });
            navigate(url(CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH));
        } catch (error) {
            console.error(error);
            publishToast({ description: t('sage200Professional.oauthCallbackModal.Error'), status: 'error' });
            navigate(url(CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH));
        }
    };

    useEffect(() => {
        void handleCompleteOauth();
    }, []);

    return (
        <Modal visible={true} noCloseButton>
            <div className="flex flex-col gap-6 items-center justify-center">
                <div className="text-3xl">
                    <LoadingIcon spin />
                </div>
                <div className="text-balance text-center text-secondary">
                    {t('sage200Professional.oauthCallbackModal.description')}
                </div>
            </div>
        </Modal>
    );
};

const Sage200ProfessionalIntegrationPage = () => {
    const { t } = useTranslation('integrations');

    const {
        data: data,
        loading: loading,
        refetch: refetch,
    } = useQuery(GET_SAGE200PROFESSIONAL_INTEGRATIONS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });
    const integrations = data?.sage200professionalIntegrations || [];

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle className="flex items-center gap-2">
                        <img src="/images/external_logos/Sage_Logo_Black_RGB png.webp" className="h-5" />
                        <span>{t('sage200Professional.title')}</span>
                    </Page.HeaderTitle>

                    <div className="flex gap-2">
                        <Page.HeaderButton asChild>
                            <a href={t('sage200Professional.documentationLink')} target={'_blank'}>
                                <span className="flex gap-2 items-center">
                                    {t('sage200Professional.documentation')}
                                    <ExternalLinkIcon />{' '}
                                </span>
                            </a>
                        </Page.HeaderButton>

                        <Page.HeaderButton asChild>
                            <Link to={url(CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_ADD_PATH)}>
                                <AddIcon />
                                {t('sage200Professional.addButton')}
                            </Link>
                        </Page.HeaderButton>
                    </div>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    {
                        <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeadCell className="min-w-96 w-96">
                                        {t('sage200Professional.table.name')}
                                    </Table.HeadCell>
                                    <Table.HeadCell>{t('sage200Professional.table.authState')}</Table.HeadCell>
                                    <Table.HeadCell>{t('sage200Professional.table.channels')}</Table.HeadCell>
                                    <Table.HeadCell>
                                        {t('sage200Professional.table.masterDataUpdatedAt')}
                                    </Table.HeadCell>
                                    <Table.HeadCell>{t('sage200Professional.table.createdAt')}</Table.HeadCell>
                                    <Table.HeadCell sticky="right" className="min-w-14 w-14" />
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {integrations && integrations.length > 0 ? (
                                    integrations.map((integration) => (
                                        <Sage200ProfessionalRow
                                            key={integration.id}
                                            integration={integration}
                                            refetch={refetch}
                                        />
                                    ))
                                ) : (
                                    <Table.Row>
                                        <Table.Cell colSpan={6} className="text-center text-secondary">
                                            {t('sage200Professional.noIntegrations')}
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    }
                </Page.Content>
            </Page>

            <Outlet context={{ integrations, refetch }} />
        </Layout>
    );
};

export default Sage200ProfessionalIntegrationPage;
