import * as React from 'react';
import classnames from '../../../utils/classnames.tsx';

interface HeaderProps {
    className?: string;
    children?: React.ReactNode;
}

const PageHeader = ({ className, children }: HeaderProps) => {
    return (
        <header
            className={classnames(
                'w-full h-14 px-7 py-2 flex-none flex items-center bg-primary border-solid border-b border-primary',
                className
            )}
        >
            {children}
        </header>
    );
};

export default PageHeader;
