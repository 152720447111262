import { IModuleProps } from '../../core/utils/modules';

import { ArticleNumberField } from '../../document/components/ArticleNumberField';
import NonModalMultiSelectField from './components/MultiSelectField';
import { FieldProps, useFieldProps } from '../../document/pages/Assistance/customizable/Field';
import { useAssistanceFieldContext } from '../../document/pages/Assistance/AssistanceFieldContext';
import React from 'react';
import { useAssistanceViewContext } from '../../document/pages/Assistance/AssistanceViewContext';
import { fieldDataToFieldConfig } from '../../document/pages/Assistance/DynamicField';

const MurtfeldtMultiSelectField = (props: FieldProps) => {
    const { data, config } = useAssistanceFieldContext();
    const { generalConfig } = useAssistanceViewContext();
    const fieldConfigFromFieldData = data ? fieldDataToFieldConfig(data) : {};
    const fieldProps = useFieldProps(props);
    return <NonModalMultiSelectField {...fieldProps} {...fieldConfigFromFieldData} />;
};

export default {
    name: 'murtfeldt',
    components: {
        Field: [
            {
                condition: ({ fieldName }) => fieldName === 'raw_material',
                component: ArticleNumberField,
            },
            {
                condition: ({ fieldName }) => fieldName === 'drawing_attachments',
                component: MurtfeldtMultiSelectField,
            },
        ],
    },
} as IModuleProps;
