import * as React from 'react';
import { useEffect } from 'react';
import { useControllableState } from '../../utils/useControllableState.tsx';
import Field, { FieldProps } from './Field.tsx';

export interface DecimalFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {
    locale?: string;
    decimalSeparator?: string;
}

export const DECIMAL_VALIDATION_REGEXP = /^\d+[.|,]?\d*$/;

const DecimalField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onChange,
    onBlur,
    controls,
    className,
    readOnly,
    disabled,
    inputRef = undefined,
    // field specific props
    locale,
    decimalSeparator,
    ...props
}: DecimalFieldProps) => {
    const localizeDecimal = (value) => {
        if (!decimalSeparator || !value) return value;

        value = value.toString();

        if (value.includes('.')) {
            return value.replace('.', decimalSeparator);
        } else if (value.includes(',')) {
            return value.replace(',', decimalSeparator);
        } else {
            return value;
        }
    };

    const [value, setValue] = useControllableState(localizeDecimal(propsValue), defaultValue, onValueChange);

    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(() => {
        if (propsValue !== undefined && propsValue !== value) {
            setValue(localizeDecimal(propsValue));
        }
    }, [propsValue]);

    const handleChange = (e: any) => {
        // last char if still typing
        const re = decimalSeparator ? new RegExp(`^\\d+[.|,|${decimalSeparator}]?\\d*$`) : DECIMAL_VALIDATION_REGEXP;
        if (e.target.value && !re.test(e.target.value)) {
            e.preventDefault();
            return;
        }
        setValue(e.target.value);
        onChange?.(e);
    };

    const handleBlur = (e: any) => {
        const nextValue = localizeDecimal(value);
        if (value !== nextValue) setValue(nextValue);
        onBlur?.(e);
    };

    return (
        <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
            <Field.Input>
                <input
                    type="text"
                    inputMode="numeric"
                    value={value || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={readOnly}
                    disabled={disabled}
                    className="px-2 py-1.5 flex-1 min-w-0 bg-transparent tabular-nums outline-none"
                    {...props}
                    ref={inputRef}
                />
            </Field.Input>
            <Field.Controls>{controls}</Field.Controls>
        </Field>
    );
};

export default DecimalField;
