import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ConfirmModal from '../../core/components/ConfirmModal.tsx';
import { url } from '../../core/utils/link.ts';

import { TEAMS_ADD_PATH, TEAMS_EDIT_PATH, TEAMS_PATH } from '../constants.ts';

import Layout from '../../core/components/Layout.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import { withIcon } from '../../core/components/Icon.tsx';
import { faEllipsisV, faPlus, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import Page from '../../core/components/Page';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import { RowControlIconButtonProps } from '../../assistance/components/RecordTable.tsx';
import DropdownMenu from '../../core/components/DropdownMenu.tsx';
import { ITeam } from '../interfaces.ts';
import Modal from '../../core/components/Modal.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import Button from '../../core/components/Button.tsx';
import classnames from '../../core/utils/classnames.tsx';
import { Outlet } from 'react-router';
import useSearchParamState from '../../core/utils/useSearchParamState.tsx';
import { useApplicationContext } from '../../core/contexts/ApplicationContext.tsx';
import CheckboxGroup from '../../core/components/CheckboxGroup.tsx';
import { ADD_TEAM, DELETE_TEAM, GET_TEAM, GET_TEAMS, UPDATE_TEAM } from '../queries.ts';
import { canManageTeams } from '../../users/utils.ts';
import { GET_USERS } from '../../users/queries.ts';
import TextField from '../../core/components/Fields/TextField.tsx';
import Tooltip from '../../core/components/Tooltip.tsx';
import TablePagination from '../../core/components/TablePagination.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

interface TeamFormModalOutletContext {
    refetch: () => Promise<any>;
}

const PAGE_PARAM = 'page';
const MAX_ITEMS = 500;
const ITEMS_PER_PAGE = 10;
const COLLAPSED_ITEMS = 5;

const MoreIcon = withIcon(faEllipsisV);
const AddIcon = withIcon(faPlus);
const LoadingIcon = withIcon(faSpinnerThird);

const FORM_DEFAULT_VALUES = {
    name: '',
    description: '',
    userIds: [],
};

const UserCheckboxGroup = ({
    options,
    isLoading = false,
    ...props
}: React.ComponentPropsWithRef<typeof CheckboxGroup> & { isLoading?: boolean }) => {
    const { t } = useTranslation('teams');

    const [searchTerm, setSearchTerm] = useState('');

    const filteredOptions = options.filter((option) => !searchTerm || option.search.includes(searchTerm.toLowerCase()));

    return (
        <div className="flex flex-col gap-2">
            <StringField value={searchTerm} onValueChange={setSearchTerm} placeholder={t('form.searchUsers')} />
            {isLoading ? (
                <div className="p-4 flex justify-center gap-2 items-center text-tertiary">
                    <LoadingIcon spin /> {t('form.loadingUsers')}
                </div>
            ) : (
                <CheckboxGroup {...props} options={filteredOptions} />
            )}
        </div>
    );
};

const TeamFormModal = ({
    title,
    submitButtonLabel,
    onSubmit,
    onClose,
    team,
}: {
    title: string;
    submitButtonLabel: string;
    onSubmit: (formData: any) => void;
    onClose: () => void;
    team?: ITeam;
}) => {
    const { t } = useTranslation('teams');
    const { user: viewer } = useApplicationContext();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (team) {
            form.reset({
                ...FORM_DEFAULT_VALUES,
                ...(team && {
                    name: team.name,
                    description: team.description,
                    userIds: team.users.map((user) => user.id),
                }),
            });
        }
    }, [team]);

    const { data: usersData, loading: usersLoading } = useQuery(GET_USERS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            size: 1000, // we just want to show all users
        },
    });
    const users = usersData?.users?.users || [];

    return (
        <Modal
            visible={true}
            onClose={onClose}
            title={title}
            buttons={
                <>
                    <Button type="button" asChild>
                        <Link to={url(TEAMS_PATH)}>{t('form.cancelButton')}</Link>
                    </Button>
                    <Button variant="primary" type="submit" form="team-form">
                        {submitButtonLabel}
                    </Button>
                </>
            }
        >
            <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)} id="team-form">
                <Controller
                    name="name"
                    control={form.control}
                    rules={{ required: t('form.nameRequired') }}
                    render={({ field }) => (
                        <FormField label={t('form.name')} required={true} error={formErrors.name}>
                            <StringField required={true} {...field} disabled={team?.isDefault} />
                        </FormField>
                    )}
                />

                <Controller
                    name="description"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('form.description')} error={formErrors.description}>
                            <TextField {...field} disabled={team?.isDefault} />
                        </FormField>
                    )}
                />

                <Controller
                    name="userIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('form.users')} error={formErrors.userIds}>
                            <UserCheckboxGroup
                                {...field}
                                options={users?.map((user) => ({
                                    value: user.id,
                                    label: (
                                        <span className="flex gap-2 items-center">
                                            <span className="flex flex-col p-1">
                                                <span>
                                                    {user.firstName} {user.lastName}
                                                </span>
                                                <span className="text-secondary text-xs">{user.email}</span>
                                            </span>

                                            {viewer.id === user.id && (
                                                <span className="text-xs text-brand font-medium bg-brand px-1.5 py-0.5 rounded">
                                                    {t('form.youBadge')}
                                                </span>
                                            )}
                                        </span>
                                    ),
                                    search: `${user.firstName} ${user.lastName} ${user.email}`.toLowerCase(),
                                    disabled: team?.isDefault,
                                }))}
                                onValueChange={(value) => field.onChange({ target: { value } })}
                                isLoading={usersLoading}
                            />
                        </FormField>
                    )}
                />
            </form>
        </Modal>
    );
};

export const AddTeamFormModal = () => {
    const { t } = useTranslation('teams');
    const { refetch } = useOutletContext<TeamFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const [addTeam] = useMutation(ADD_TEAM);
    const handleCreate = async (formData) => {
        try {
            await addTeam({
                variables: {
                    name: formData.name,
                    description: formData.description,
                    users: formData.userIds,
                },
            });
            await refetch();
            publishToast({ description: t('form.createSuccess'), status: 'success' });
            navigate(url(TEAMS_PATH));
        } catch (error) {
            publishToast({ description: t('form.createError'), status: 'error' });
            console.error(error);
        }
    };

    return (
        <TeamFormModal
            title={t('form.createTitle')}
            submitButtonLabel={t('form.createButton')}
            onSubmit={handleCreate}
            onClose={() => navigate(url(TEAMS_PATH))}
        />
    );
};

export const EditTeamFormModal = () => {
    const { t } = useTranslation('teams');
    const { refetch } = useOutletContext<TeamFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const { teamId } = useParams();
    const { data: editTeamData } = useQuery(GET_TEAM, { skip: !teamId, variables: { id: teamId } });
    const team = editTeamData?.team;

    const [updateTeam] = useMutation(UPDATE_TEAM);
    const handleUpdate = async (formData) => {
        try {
            await updateTeam({
                variables: {
                    id: teamId,
                    name: formData.name,
                    description: formData.description,
                    users: formData.userIds,
                },
            });
            await refetch();
            publishToast({ description: t('form.updateSuccess'), status: 'success' });
            navigate(url(TEAMS_PATH));
        } catch (error) {
            publishToast({ description: t('form.updateError'), status: 'error' });
            console.error(error);
        }
    };

    return (
        <TeamFormModal
            title={t('form.updateTitle')}
            submitButtonLabel={t('form.updateButton')}
            onSubmit={handleUpdate}
            onClose={() => navigate(url(TEAMS_PATH))}
            team={team}
        />
    );
};

const TeamMoreButton = ({
    team,
    refetch,
    ...props
}: RowControlIconButtonProps & {
    team: ITeam;
    refetch: () => Promise<any>;
}) => {
    const { t } = useTranslation('teams');
    const { publishToast } = useToaster();

    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    const [deleteTeam] = useMutation(DELETE_TEAM);
    const handleDeleteTeam = async () => {
        try {
            await deleteTeam({ variables: { id: team.id } });
            await refetch();
            publishToast({ description: t('overview.moreMenu.deleteSuccess', { team }), status: 'success' });
        } catch (error) {
            console.error(error);
            publishToast({ description: t('overview.moreMenu.deleteError', { team }), status: 'error' });
        } finally {
            setDeleteModalVisible(false);
        }
    };

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <RowControlIconButton {...props}>
                        <MoreIcon />
                    </RowControlIconButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content align="end">
                    <Tooltip content={team.isDefault ? t('overview.moreMenu.defaultTeamNotUpdatable') : ''} side="left">
                        <DropdownMenu.Item disabled={team.isDefault} asChild>
                            <Link to={url(TEAMS_EDIT_PATH, { teamId: team.id })}>{t('overview.moreMenu.update')}</Link>
                        </DropdownMenu.Item>
                    </Tooltip>

                    <DropdownMenu.Separator />

                    <Tooltip
                        content={
                            team.isDefault
                                ? t('overview.moreMenu.defaultTeamNotDeletable')
                                : !team.isDeletable
                                  ? t('overview.moreMenu.notDeletable')
                                  : ''
                        }
                        side="left"
                    >
                        <DropdownMenu.Item
                            className="text-error hover:text-error hover:!bg-error"
                            onClick={() => setDeleteModalVisible(true)}
                            disabled={team.isDefault || !team.isDeletable}
                        >
                            {t('overview.moreMenu.delete')}
                        </DropdownMenu.Item>
                    </Tooltip>
                </DropdownMenu.Content>
            </DropdownMenu>

            {isDeleteModalVisible && (
                <ConfirmModal
                    onConfirm={handleDeleteTeam}
                    onCancel={() => setDeleteModalVisible(false)}
                    title={t('overview.confirmDeleteTitle')}
                    labelConfirm={t('overview.confirmDeleteButton')}
                    danger
                >
                    {t('overview.confirmDelete', { team })}
                </ConfirmModal>
            )}
        </>
    );
};

const TeamRow = ({ team, moreButton }) => {
    const { t } = useTranslation('teams');
    const { user: viewer } = useApplicationContext();

    const [isCollapsed, setIsCollapsed] = useState(true);

    const users = [...(team?.users || [])];
    users.sort((a, b) => a.lastName.localeCompare(b.lastName));

    const showMoreButton = users.length > COLLAPSED_ITEMS;

    return (
        <>
            <Table.Row>
                <Table.Cell>
                    <div className="flex gap-3 justify-start items-center">
                        <div>{!team.isDefault ? team.name : viewer?.customer?.name || t('overview.defaultTeam')}</div>

                        {team.isDefault && (
                            <span className="text-xs text-brand font-medium bg-brand px-1.5 py-0.5 rounded">
                                {t('overview.defaultTeamBadge')}
                            </span>
                        )}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div className="flex gap-1.5 flex-wrap">
                        {users.slice(0, isCollapsed ? COLLAPSED_ITEMS : users.length).map((user) => (
                            <span className="border-secondary border border-solid bg-secondary flex gap-2 items-center px-1 rounded">
                                {user.firstName} {user.lastName}
                            </span>
                        ))}

                        {showMoreButton && (
                            <button
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                className="text-brand hover:underline px-1 py-0.5"
                            >
                                {isCollapsed
                                    ? t('overview.more', { count: users.length - COLLAPSED_ITEMS })
                                    : t('overview.showLess')}
                            </button>
                        )}
                    </div>
                </Table.Cell>
                <Table.Cell>{moreButton}</Table.Cell>
            </Table.Row>
        </>
    );
};

const TeamsPage = ({ user: viewer }: any) => {
    const { t } = useTranslation('teams');

    const [activePage, setActivePage] = useSearchParamState(PAGE_PARAM, '1');
    const [sort, setSort] = useSearchParamState('sort', 'name');
    const [itemsPerPage, setItemsPerPage] = useSearchParamState('pageSize', ITEMS_PER_PAGE.toString());

    const parsedActivePage = parseInt(activePage);
    const parsedItemsPerPage = parseInt(itemsPerPage);

    let [sortField, sortOrder] = sort.startsWith('-') ? [sort.slice(1), 'desc'] : [sort, 'asc'];

    // TODO: right now we don't support pagination on teams
    const {
        data: currentData,
        previousData,
        loading,
        refetch,
    } = useQuery(GET_TEAMS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            from: (parsedActivePage - 1) * parsedItemsPerPage,
            size: parsedItemsPerPage,
            sortField,
            sortOrder,
        },
    });
    const data = loading ? previousData : currentData;

    const teams = data?.teams || [];
    const totalCount = data?.teams?.totalCount || 0;

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('overview.title')}</Page.HeaderTitle>

                    <div className="flex gap-2">
                        {canManageTeams(viewer) && (
                            <Page.HeaderButton asChild>
                                <Link to={url(TEAMS_ADD_PATH)}>
                                    <AddIcon />
                                    {t('overview.createTeam')}
                                </Link>
                            </Page.HeaderButton>
                        )}
                    </div>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <div>
                        <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeadCell className="min-w-96 w-96">
                                        {t('overview.table.name')}
                                    </Table.HeadCell>
                                    <Table.HeadCell>{t('overview.table.users')}</Table.HeadCell>
                                    <Table.HeadCell sticky="right" className="min-w-14 w-14" />
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {teams.map((team) => (
                                    <TeamRow
                                        key={team.id}
                                        team={team}
                                        moreButton={<TeamMoreButton team={team} refetch={refetch} />}
                                    />
                                ))}
                            </Table.Body>
                        </Table>
                    </div>

                    {totalCount > 0 && (
                        <TablePagination
                            totalCount={totalCount}
                            activePage={parsedActivePage}
                            itemsPerPage={parsedItemsPerPage}
                            setActivePage={(page: number) => setActivePage(page ? page.toString() : '')}
                            setItemsPerPage={(itemsPerPage: number) =>
                                setItemsPerPage(itemsPerPage ? itemsPerPage.toString() : '')
                            }
                        />
                    )}
                </Page.Content>
            </Page>

            <Outlet context={{ refetch }} />
        </Layout>
    );
};

export default TeamsPage;
