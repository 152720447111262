import { OVERVIEW_PATH as INVOICE_OVERVIEW_PATH } from '../products/invoices/constants';
import { OVERVIEW_PATH as ORDER_OVERVIEW_PATH } from '../products/orders/constants';
import { OVERVIEW_PATH as ORDER_CONFIRMATION_OVERVIEW_PATH } from '../products/order_confirmations/constants';
import { OVERVIEW_PATH as RFQ_OVERVIEW_PATH } from '../products/rfqs/constants';
import { OVERVIEW_PATH as PROPERTY_BILL_OVERVIEW_PATH } from '../products/db_immo/constants';
import { OVERVIEW_PATH as DELIVERY_NOTE_OVERVIEW_PATH } from '../products/delivery_notes/constants';
import { OVERVIEW_PATH as UNIVERSAL_OVERVIEW_PATH } from '../products/universal/constants';
import { OVERVIEW_PATH as LIST_OF_SERVICES_OVERVIEW_PATH } from '../products/list_of_services/constants';
import { capitalizeFirstLetter } from '../core/utils/string.ts';
import { camelCase } from 'lodash';

export enum DocumentType {
    Order,
    OrderConfirmation,
    Invoice,
    Rfq,
    PropertyBill,
    DeliveryNote,
    Universal,
    ListOfServices,
}

export const OVERVIEW_PATHS = {
    [DocumentType.Order]: ORDER_OVERVIEW_PATH,
    [DocumentType.OrderConfirmation]: ORDER_CONFIRMATION_OVERVIEW_PATH,
    [DocumentType.Invoice]: INVOICE_OVERVIEW_PATH,
    [DocumentType.Rfq]: RFQ_OVERVIEW_PATH,
    [DocumentType.PropertyBill]: PROPERTY_BILL_OVERVIEW_PATH,
    [DocumentType.DeliveryNote]: DELIVERY_NOTE_OVERVIEW_PATH,
    [DocumentType.Universal]: UNIVERSAL_OVERVIEW_PATH,
    [DocumentType.ListOfServices]: LIST_OF_SERVICES_OVERVIEW_PATH,
};

export const getDocumentType = (document?: any): DocumentType =>
    DocumentType[capitalizeFirstLetter(camelCase(document.__typename).replace('_', '')) as keyof typeof DocumentType];

export enum LabelingStatus {
    NotEligible,
    Eligible,
    Unknown,
}

export const getLabelingStatus = (record: any): LabelingStatus =>
    LabelingStatus[
        capitalizeFirstLetter(camelCase(record.labelingStatus).replace('_', '')) as keyof typeof LabelingStatus
    ];

export enum DOCUMENT_TYPE {
    ORDER = 'ORDER',
    ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
    REQUEST_FOR_QUOTATION = 'REQUEST_FOR_QUOTATION',
    INVOICE = 'INVOICE',
    DELIVERY_NOTE = 'DELIVERY_NOTE',
    PROPERTY_BILL = 'PROPERTY_BILL',
    LIST_OF_SERVICES = 'LIST_OF_SERVICES',
    UNIVERSAL = 'UNIVERSAL',
}

export const mapDocumentTypeToGqlDocumentType: Record<DocumentType, DOCUMENT_TYPE> = {
    [DocumentType.Order]: DOCUMENT_TYPE.ORDER,
    [DocumentType.OrderConfirmation]: DOCUMENT_TYPE.ORDER_CONFIRMATION,
    [DocumentType.Invoice]: DOCUMENT_TYPE.INVOICE,
    [DocumentType.Rfq]: DOCUMENT_TYPE.REQUEST_FOR_QUOTATION,
    [DocumentType.PropertyBill]: DOCUMENT_TYPE.PROPERTY_BILL,
    [DocumentType.DeliveryNote]: DOCUMENT_TYPE.DELIVERY_NOTE,
    [DocumentType.Universal]: DOCUMENT_TYPE.UNIVERSAL,
    [DocumentType.ListOfServices]: DOCUMENT_TYPE.LIST_OF_SERVICES,
};
