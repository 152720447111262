import * as React from 'react';
import classnames from '../../core/utils/classnames.tsx';
import { useTranslation } from 'react-i18next';

interface TextChartProps {
    value: string | number;
    unit?: string;
    className?: string;
}

const TextChart = ({ value, unit, className }: TextChartProps) => {
    const { i18n } = useTranslation();
    const formattedValue = value.toLocaleString(i18n.language);
    return (
        <span className={classnames('py-5 flex gap-1 justify-center items-baseline', className)}>
            <span className="font-medium text-5xl text-primary">{formattedValue}</span>
            {unit ? <span className="font-normal text-primary text-2xl">{unit}</span> : null}
        </span>
    );
};

export default TextChart;
