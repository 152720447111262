import { useMutation, useQuery } from '@apollo/client';

import { faExternalLink, faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { withIcon } from '../../core/components/Icon.tsx';
import { GET_NAVIGATION_CHANNELS } from '../../core/components/Navigation/internal/queries.ts';
import {
    CREATE_SMART_INBOX_INTEGRATION,
    DELETE_SMART_INBOX_INTEGRATION,
    GET_SMART_INBOX_INTEGRATIONS,
} from '../queries.ts';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../core/components/Button.tsx';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { url } from '../../core/utils/link.ts';
import FormField from '../../core/components/FormField.tsx';
import CheckboxGroup from '../../core/components/CheckboxGroup.tsx';
import { Outlet } from 'react-router';
import Layout from '../../core/components/Layout.tsx';
import Page from '../../core/components/Page';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';
import { CUSTOMER_INTEGRATION_SMART_INBOX_ADD_PATH, CUSTOMER_INTEGRATION_SMART_INBOX_PATH } from '../constants.ts';
import Modal from '../../core/components/Modal.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import classnames from '../../core/utils/classnames.tsx';

interface SmartInboxFormModalOutletContext {
    refetch: () => Promise<void>;
}

// mocked types based on gql query
type SmartInboxIntegrationType = {
    id: string;
    name: string;
    mailInbox: string;
    subscriptions: {
        orderChannel:
            | {
                  id: string;
                  name: string;
              }
            | undefined;
        rfqChannel:
            | {
                  id: string;
                  name: string;
              }
            | undefined;
    }[];
};

const AddIcon = withIcon(faPlus);
const ExternalLinkIcon = withIcon(faExternalLink);
const DeleteIcon = withIcon(faTrashCan);

type SmartInboxIntegrationFormValues = {
    name: string;
    tenantId: string;
    clientId: string;
    clientSecret: string;
    mailBox: string;
    rfqChannelIds: string[];
    orderChannelIds: string[];
};

const FORM_DEFAULT_VALUES: SmartInboxIntegrationFormValues = {
    name: '',
    tenantId: '',
    clientId: '',
    clientSecret: '',
    mailBox: '',
    rfqChannelIds: [],
    orderChannelIds: [],
};

const SmartInboxFormModal = ({
    title,
    submitButtonLabel,
    onSubmit,
    onClose,
}: {
    title: string;
    submitButtonLabel: string;
    onSubmit: (data: SmartInboxIntegrationFormValues) => void;
    onClose: () => void;
}) => {
    const { t } = useTranslation('integrations');

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const { data: channelData } = useQuery(GET_NAVIGATION_CHANNELS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: false,
    });

    const rfqChannels = channelData?.rfqProcessingChannels || [];

    const orderChannels = channelData?.orderProcessingChannels || [];

    return (
        <Modal
            visible={true}
            onClose={onClose}
            title={title}
            buttons={
                <>
                    <Button type="button" asChild>
                        <Link to={url(CUSTOMER_INTEGRATION_SMART_INBOX_PATH)}>{t('smartInbox.form.cancelButton')}</Link>
                    </Button>
                    <Button variant="primary" type="submit" form="modal-form">
                        {submitButtonLabel}
                    </Button>
                </>
            }
        >
            <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)} id="modal-form">
                <Controller
                    name="name"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('smartInbox.form.name')} error={formErrors.name}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="tenantId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('smartInbox.form.tenantId')} error={formErrors.tenantId}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="clientId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('smartInbox.form.clientId')} error={formErrors.clientId}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="clientSecret"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('smartInbox.form.clientSecret')}
                            helpText={t('smartInbox.form.clientSecretHelpText')}
                            error={formErrors.clientSecret}
                        >
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="mailBox"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('smartInbox.form.mailBox')} error={formErrors.mailBox}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="rfqChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('smartInbox.form.rfqChannels')} error={formErrors.rfqChannelIds}>
                            <CheckboxGroup
                                {...field}
                                options={
                                    rfqChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                // allow only one channel to be selected
                                onValueChange={(values) =>
                                    field.onChange({ target: { value: values.length ? [values.pop()] : [] } })
                                }
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="orderChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('smartInbox.form.orderChannels')} error={formErrors.orderChannelIds}>
                            <CheckboxGroup
                                {...field}
                                options={
                                    orderChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                // allow only one channel to be selected
                                onValueChange={(values) =>
                                    field.onChange({ target: { value: values.length ? [values.pop()] : [] } })
                                }
                            />
                        </FormField>
                    )}
                />
            </form>
        </Modal>
    );
};

export const AddSmartInboxFormModal = () => {
    const { t } = useTranslation('integrations');
    const { refetch } = useOutletContext<SmartInboxFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const [createIntegration] = useMutation(CREATE_SMART_INBOX_INTEGRATION);
    const handleCreateIntegration = async (formData: SmartInboxIntegrationFormValues) => {
        try {
            await createIntegration({
                variables: {
                    name: formData.name,
                    tenantId: formData.tenantId,
                    clientId: formData.clientId,
                    clientSecret: formData.clientSecret,
                    mailBox: formData.mailBox,
                    orderChannel: formData.orderChannelIds[0],
                    rfqChannel: formData.rfqChannelIds[0],
                },
            });
            await refetch();
            publishToast({ description: t('smartInbox.createSuccess'), status: 'success' });
            navigate(url(CUSTOMER_INTEGRATION_SMART_INBOX_PATH));
        } catch (error) {
            console.error(error);
            publishToast({ description: t('smartInbox.createError'), status: 'error' });
        }
    };

    return (
        <SmartInboxFormModal
            title={t('smartInbox.form.createTitle')}
            submitButtonLabel={t('smartInbox.form.createButton')}
            onSubmit={handleCreateIntegration}
            onClose={() => navigate(url(CUSTOMER_INTEGRATION_SMART_INBOX_PATH))}
        />
    );
};

const SmartInboxRow = ({
    integration,
    refetch,
}: {
    integration: SmartInboxIntegrationType;
    refetch: () => Promise<unknown>;
}) => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const [deleteIntegration] = useMutation(DELETE_SMART_INBOX_INTEGRATION);
    const handleDeleteIntegration = async () => {
        try {
            await deleteIntegration();
            await refetch();
            publishToast({ description: t('smartInbox.deleteSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('smartInbox.deleteError'), status: 'error' });
        }
    };

    const channels = integration.subscriptions
        .map((subscription) => [subscription.orderChannel, subscription.rfqChannel])
        .flat()
        .filter(Boolean);

    return (
        <>
            <Table.Row>
                <Table.Cell>{integration.name}</Table.Cell>
                <Table.Cell>{integration.mailInbox}</Table.Cell>
                <Table.Cell>
                    <div className="flex gap-1.5 flex-wrap">
                        {channels.map((channel) => (
                            <span
                                key={channel.id}
                                className="border-secondary border border-solid bg-secondary flex gap-2 items-center px-1 rounded"
                            >
                                {channel.name}
                            </span>
                        ))}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div className="flex gap-2">
                        <RowControlIconButton
                            className="hover:bg-error text-error hover:text-error"
                            onClick={handleDeleteIntegration}
                        >
                            <DeleteIcon />
                        </RowControlIconButton>
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    );
};

const SmartInboxIntegrationPage = () => {
    const { t } = useTranslation('integrations');

    const { data, loading, refetch } = useQuery(GET_SMART_INBOX_INTEGRATIONS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });
    const integrations: SmartInboxIntegrationType[] = data?.smartInboxIntegrations || [];

    // Since we can have only one active integration for now,
    // we need to load information first to calculate state of the button
    const isAddButtonDisabled = loading || integrations.length > 0;

    return (
        <Layout>
            <SettingsNavigation />
            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle className="flex items-center gap-2">
                        <span>{t('smartInbox.title')}</span>
                    </Page.HeaderTitle>

                    <div className="flex gap-2">
                        <Page.HeaderButton asChild>
                            <a href={t('smartInbox.documentationLink')} target={'_blank'}>
                                <span className="flex gap-2 items-center">
                                    {t('smartInbox.documentation')}
                                    <ExternalLinkIcon />
                                </span>
                            </a>
                        </Page.HeaderButton>

                        {isAddButtonDisabled ? (
                            <Page.HeaderButton disabled={isAddButtonDisabled}>
                                <AddIcon />
                                {t('smartInbox.addButton')}
                            </Page.HeaderButton>
                        ) : (
                            <Page.HeaderButton asChild>
                                <Link to={url(CUSTOMER_INTEGRATION_SMART_INBOX_ADD_PATH)}>
                                    <AddIcon />
                                    {t('smartInbox.addButton')}
                                </Link>
                            </Page.HeaderButton>
                        )}
                    </div>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                        <Table.Head>
                            <Table.Row>
                                <Table.HeadCell className="min-w-96 w-96">{t('smartInbox.table.name')}</Table.HeadCell>
                                <Table.HeadCell>{t('smartInbox.table.mailbox')}</Table.HeadCell>
                                <Table.HeadCell>{t('smartInbox.table.channels')}</Table.HeadCell>
                                <Table.HeadCell sticky="right" className="min-w-14 w-14" />
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {integrations && integrations.length > 0 ? (
                                integrations.map((integration) => (
                                    <SmartInboxRow key={integration.id} integration={integration} refetch={refetch} />
                                ))
                            ) : (
                                <Table.Row>
                                    <Table.Cell colSpan={6} className="text-center text-secondary">
                                        {t('smartInbox.noIntegrations')}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Page.Content>
            </Page>

            <Outlet context={{ refetch }} />
        </Layout>
    );
};

export default SmartInboxIntegrationPage;
