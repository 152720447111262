import * as React from 'react';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from '../../core/utils/classnames.tsx';
import { useQuery } from '@apollo/client';
import { GET_PAGINATED_DATA_IMPORT_RUNS } from '../queries.ts';
import { ChannelContext } from '../../document/stores';
import { AlertTitle } from '../../core/components/Alert.tsx';
import { MasterDataImportRun } from '../types.ts';
import Tooltip from '../../core/components/Tooltip.tsx';
import Layout from '../../core/components/Layout.tsx';
import Page from '../../core/components/Page';
import { withIcon } from '../../core/components/Icon.tsx';
import { faCheckCircle, faChevronRight, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { Link, useSearchParams } from 'react-router-dom';
import FilterDropdown from '../../core/components/Filter.tsx';
import Button from '../../core/components/Button.tsx';
import Table, { RowControlIconButton, TableScrollWrapper } from '../../core/components/Table.tsx';
import useSearchParamState from '../../core/utils/useSearchParamState.tsx';
import useUpdateEffect from '../../core/utils/useUpdateEffect.tsx';
import { format, formatDistanceStrict, formatDistanceToNow, formatISO, startOfDay, subDays, subMonths } from 'date-fns';
import { IFilter } from '../../core/utils/filterQuery.ts';
import { flatten } from 'lodash';
import Checkbox from '../../core/components/Checkbox.tsx';
import TablePagination from '../../core/components/TablePagination.tsx';
import { url } from '../../core/utils/link.ts';

const PAGE_PARAM = 'page';
const ITEMS_PER_PAGE = 10;

const MASTER_DATA_IMPORT_STATUS_COLOR_CLASS = {
    PENDING: '',
    RUNNING: '',
    CANCELED: 'warning',
    SUCCESS: 'success',
    FAILED: 'error',
};

const BreadcrumbIcon = withIcon(faChevronRight);
const CheckIcon = withIcon(faCheckCircle);
const ExternalLinkIcon = withIcon(faExternalLink);

const getLookupDefinitions = (masterdataConfig): Record<string, string> => {
    const lookupDefinitionIdByConcreteType = {};

    if (masterdataConfig == null) {
        return {};
    }

    for (const [key, value] of Object.entries(masterdataConfig)) {
        if (key.includes('LookupDefinitionId') && !key.includes('MappingLookupDefinitionId') && value) {
            const concreteLookupType = key.replace('LookupDefinitionId', '');
            lookupDefinitionIdByConcreteType[concreteLookupType] = value;
        }
    }

    return lookupDefinitionIdByConcreteType;
};

const formatFileSize = (size: number) => {
    const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return +(size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const StatusIndicator = ({ status, className }: any) => {
    const statusColorGroup = MASTER_DATA_IMPORT_STATUS_COLOR_CLASS[status];
    return (
        <span
            className={classnames(
                'inline-block rounded-full w-2.5 h-2.5 bg-tertiary flex-none',
                statusColorGroup == 'success' && 'bg-confidence-high',
                statusColorGroup == 'warning' && 'bg-confidence-medium',
                statusColorGroup == 'error' && 'bg-confidence-low',
                className
            )}
        />
    );
};

const StatusBadge = ({ importLog }: any) => {
    const { t, i18n } = useTranslation('masterdata');
    const statusColorGroup = MASTER_DATA_IMPORT_STATUS_COLOR_CLASS[importLog.status];

    let errorTitle, errorMessage;
    if (importLog.errorType) {
        if (i18n.exists(`masterdata:importRun.error.${importLog.errorType}.message`)) {
            errorTitle = `importRun.error.${importLog.errorType}.title`;
            errorMessage = `importRun.error.${importLog.errorType}.message`;
        } else {
            errorTitle = `importRun.error.other.title`;
            errorMessage = `importRun.error.other.message`;
        }
    }

    return (
        <Tooltip
            content={
                importLog.errorType ? (
                    <>
                        {errorTitle && <AlertTitle>{t(errorTitle)}</AlertTitle>}
                        <span>
                            <Trans
                                t={t}
                                i18nKey={errorMessage}
                                values={
                                    importLog.errorMessageKwargs != null ? JSON.parse(importLog.errorMessageKwargs) : {}
                                }
                            />
                        </span>
                    </>
                ) : i18n.exists(`masterdata:importRun.statusExplanation.${importLog.status}`) ? (
                    t(`importRun.statusExplanation.${importLog.status}`)
                ) : null
            }
        >
            <span
                className={classnames(
                    'inline-flex rounded text-xs font-medium items-baseline px-2 py-1 gap-2 bg-secondary text-primary border border-solid border-secondary',
                    statusColorGroup == 'success' && 'bg-success text-success border-success',
                    statusColorGroup == 'warning' && 'bg-warning text-warning border-warning',
                    statusColorGroup == 'error' && 'bg-error text-error border-error'
                )}
            >
                <StatusIndicator status={importLog.status} />
                {t(`importRun.status.${importLog.status}`)}
            </span>
        </Tooltip>
    );
};

const getFileFromImportLog = (importLog: any, key: string) => ({
    name: importLog[key + 'Name'] ?? importLog[key + 'Url'].split('/').pop(),
    url: importLog[key + 'Url'].startsWith('http') ? importLog[key + 'Url'] : null,
    size: importLog[key + 'Size'],
    numRows: importLog[key + 'NumRows'],
});

const ImportLogRow = ({ importLog, lookupType }) => {
    const { t } = useTranslation('masterdata');

    const inputFiles = [];
    if (importLog.inputFileUrl || importLog.inputFileName)
        inputFiles.push(getFileFromImportLog(importLog, 'inputFile'));
    if (importLog.inputFile2Url || importLog.inputFile2Name)
        inputFiles.push(getFileFromImportLog(importLog, 'inputFile2'));

    const ingestionFiles = [];
    if (importLog.ingestionFileUrl || importLog.ingestionFileName)
        ingestionFiles.push(getFileFromImportLog(importLog, 'ingestionFile'));

    return (
        <Table.Row>
            <Table.Cell sticky="left" className="whitespace-nowrap">
                <div className="flex gap-2 items-center">
                    <span>
                        {lookupType != null
                            ? t(`importRun.concreteLookupType.${lookupType}`)
                            : t(`importRun.lookupType.${importLog.lookupType}`)}
                    </span>
                    {importLog.isActiveVersion && (
                        <Tooltip content={t(`importRun.isActiveVersion`)}>
                            <CheckIcon className="text-success" />
                        </Tooltip>
                    )}
                </div>
            </Table.Cell>

            <Table.Cell className="whitespace-nowrap">
                <Tooltip content={t(`importLog.openInDjangoAdmin`)}>
                    <RowControlIconButton asChild>
                        <a
                            href={importLog.djangoAdminUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="text-brand-default hover:text-brand-hover"
                        >
                            <ExternalLinkIcon />
                        </a>
                    </RowControlIconButton>
                </Tooltip>
            </Table.Cell>

            <Table.Cell className="whitespace-nowrap">
                <StatusBadge importLog={importLog} />
            </Table.Cell>

            <Table.Cell className="whitespace-nowrap">{format(new Date(importLog.createdAt), 'PPpp')}</Table.Cell>

            <Table.Cell className="whitespace-nowrap">
                <Tooltip
                    content={
                        <span className="whitespace-nowrap">
                            {t('importLog.finishedAtTooltip', { date: format(new Date(importLog.finishedAt), 'PPpp') })}
                        </span>
                    }
                >
                    <span>
                        {importLog.finishedAt && formatDistanceStrict(importLog.createdAt, importLog.finishedAt)}
                    </span>
                </Tooltip>
            </Table.Cell>

            <Table.Cell className="whitespace-nowrap">
                {importLog.trigger ? t(`importRun.trigger.${importLog.trigger}`) : importLog.trigger}
            </Table.Cell>

            <Table.Cell>
                {inputFiles.map((file) => (
                    <div>
                        {file.url ? (
                            <a href={file.url} key={file.url} className="text-brand-default hover:text-brand-hover">
                                {file.name}
                            </a>
                        ) : (
                            file.name
                        )}
                    </div>
                ))}
            </Table.Cell>

            <Table.Cell className="whitespace-nowrap text-right">
                {inputFiles.map((file) => (
                    <div>{file.numRows}</div>
                ))}
            </Table.Cell>

            <Table.Cell className="whitespace-nowrap text-right">
                {inputFiles.map((file) => (
                    <div>{formatFileSize(file.size)}</div>
                ))}
            </Table.Cell>

            <Table.Cell>
                {ingestionFiles.map((file) => (
                    <div>
                        {file.url ? (
                            <a href={file.url} key={file.url} className="text-brand-default hover:text-brand-hover">
                                {file.name}
                            </a>
                        ) : (
                            file.name
                        )}
                    </div>
                ))}
            </Table.Cell>

            <Table.Cell className="whitespace-nowrap text-right">
                {ingestionFiles.map((file) => (
                    <div>{file.numRows}</div>
                ))}
            </Table.Cell>

            <Table.Cell className="whitespace-nowrap text-right">
                {ingestionFiles.map((file) => (
                    <div>{formatFileSize(file.size)}</div>
                ))}
            </Table.Cell>
        </Table.Row>
    );
};

export const MasterDataImportLog = ({ user, channelPath }) => {
    const { t } = useTranslation('masterdata');

    const [activePage, setActivePage] = useSearchParamState(PAGE_PARAM, '1');
    const [lookupDefinitionIdFilter, setLookupDefinitionIdFilter] = useSearchParamState('lookup', '');
    const [importIdFilter, setImportIdFilter] = useSearchParamState('import', '');
    const [statusFilter, setStatusFilter] = useSearchParamState('status', '');
    const [createdAtFilter, setCreatedAtFilter] = useSearchParamState('created', '');
    const [isActiveVersionFilter, setIsActiveVersionFilter] = useSearchParamState('active', '');
    const [itemsPerPage, setItemsPerPage] = useSearchParamState('pageSize', ITEMS_PER_PAGE.toString());

    const { activeChannel } = useContext(ChannelContext);
    const activeChannelLookupDefinitionByType = getLookupDefinitions(activeChannel?.masterDataConfig);
    const getLookupType = (row) => {
        for (let [concreteLookupType, lookupDefinitionId] of Object.entries(activeChannelLookupDefinitionByType)) {
            if (Array.isArray(lookupDefinitionId) && lookupDefinitionId.includes(row.lookupDefinitionId)) {
                return concreteLookupType;
            }
            if (row.lookupDefinitionId == lookupDefinitionId) {
                return concreteLookupType;
            }
        }
    };
    const activeChannelLookupDefinitionIds = flatten(Object.values(activeChannelLookupDefinitionByType));

    const filters: IFilter[] = [
        {
            name: 'id',
            value: importIdFilter ? [importIdFilter] : [],
            expression: 'eq',
        },
        {
            name: 'lookup_definition_id',
            value: lookupDefinitionIdFilter ? [lookupDefinitionIdFilter] : activeChannelLookupDefinitionIds,
            expression: 'eq',
        },
        {
            name: 'status',
            value: statusFilter ? [statusFilter] : [],
            expression: 'eq',
        },
        {
            name: 'created_at',
            value: createdAtFilter ? [createdAtFilter] : [],
            expression: 'gte',
        },
        {
            name: 'is_active_version',
            value: isActiveVersionFilter ? [isActiveVersionFilter] : [],
            expression: 'eq',
        },
    ].filter((filter) => filter.value.length > 0);

    const [searchParams, setSearchParams] = useSearchParams();
    const handleClearAllClick = () => setSearchParams(() => ({}));
    const showClearAll =
        searchParams.has('lookup') ||
        searchParams.has('status') ||
        searchParams.has('created') ||
        searchParams.has('active') ||
        searchParams.has('import');

    useUpdateEffect(() => {
        setSearchParams(() => {
            // passed in search params might be outdated but window.location.search is always up-to-date
            const params = new URLSearchParams(window.location.search);
            params.delete(PAGE_PARAM);
            return params;
        });
    }, [lookupDefinitionIdFilter, statusFilter, createdAtFilter, isActiveVersionFilter, itemsPerPage]);

    const parsedActivePage = parseInt(activePage);
    const parsedItemsPerPage = parseInt(itemsPerPage);

    const { data, loading } = useQuery(GET_PAGINATED_DATA_IMPORT_RUNS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
            filters: filters,
            offset: (parsedActivePage - 1) * parsedItemsPerPage,
            first: parsedItemsPerPage,
        },
    });

    const importLogs = (data?.dataImportRuns.edges.map((node) => node.node) as MasterDataImportRun[]) || [];
    const totalCount = data?.dataImportRuns.totalCount ?? 0;

    return (
        <Layout>
            <Page>
                <Page.Header className="flex items-center">
                    <Link
                        className="flex flex-shrink min-w-0 gap-3 rounded transition-colors hover:bg-secondary py-1 px-2 -mx-2 items-center cursor-pointer select-text font-semibold text-base text-secondary hover:text-primary"
                        to={url(channelPath, { channelId: activeChannel?.id || '' })}
                    >
                        {activeChannel?.name}
                    </Link>

                    <Page.HeaderTitle className="mx-3 text-xs">
                        <BreadcrumbIcon />
                    </Page.HeaderTitle>

                    <Page.HeaderTitle>{t('importLog.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <div className="flex gap-2 justify-start items-center">
                        <FilterDropdown
                            allLabel={t('importLog.filters.lookupDefinitionId.all')}
                            label={t('importLog.filters.lookupDefinitionId.some')}
                            options={Object.entries(activeChannelLookupDefinitionByType).map(
                                ([concreteLookupType, lookupDefinitionId]) => ({
                                    value: lookupDefinitionId,
                                    label: t(`importRun.concreteLookupType.${concreteLookupType}`),
                                })
                            )}
                            selected={lookupDefinitionIdFilter ? [lookupDefinitionIdFilter] : []}
                            onSelectedChange={(selected) => setLookupDefinitionIdFilter(selected[0] ?? '')}
                        />

                        <FilterDropdown
                            allLabel={t('importLog.filters.status.all')}
                            label={t('importLog.filters.status.some')}
                            options={Object.keys(MASTER_DATA_IMPORT_STATUS_COLOR_CLASS).map((status) => ({
                                value: status,
                                label: t(`importRun.status.${status}`),
                            }))}
                            selected={statusFilter ? [statusFilter] : []}
                            onSelectedChange={(selected) => setStatusFilter(selected[0] ?? '')}
                        />

                        <FilterDropdown
                            allLabel={t('importLog.filters.createdAt.all')}
                            label={t('importLog.filters.createdAt.some')}
                            options={[
                                {
                                    value: formatISO(startOfDay(new Date()), { representation: 'date' }),
                                    label: t('importLog.filters.createdAt.today'),
                                },
                                {
                                    value: formatISO(startOfDay(subDays(new Date(), 1)), { representation: 'date' }),
                                    label: t('importLog.filters.createdAt.yesterday'),
                                },
                                {
                                    value: formatISO(startOfDay(subDays(new Date(), 7)), { representation: 'date' }),
                                    label: t('importLog.filters.createdAt.lastWeek'),
                                },
                                {
                                    value: formatISO(startOfDay(subDays(new Date(), 15)), { representation: 'date' }),
                                    label: t('importLog.filters.createdAt.last15Days'),
                                },
                                {
                                    value: formatISO(startOfDay(subDays(new Date(), 30)), { representation: 'date' }),
                                    label: t('importLog.filters.createdAt.lastMonth'),
                                },
                                {
                                    value: formatISO(startOfDay(subMonths(new Date(), 3)), { representation: 'date' }),
                                    label: t('importLog.filters.createdAt.last3Months'),
                                },
                                {
                                    value: formatISO(startOfDay(subMonths(new Date(), 12)), { representation: 'date' }),
                                    label: t('importLog.filters.createdAt.lastYear'),
                                },
                            ]}
                            selected={createdAtFilter ? [createdAtFilter] : []}
                            onSelectedChange={(selected) => setCreatedAtFilter(selected[0] ?? '')}
                        />

                        {showClearAll && (
                            <Button variant="ghost" onClick={handleClearAllClick}>
                                {t('importLog.filters.clearAll')}
                            </Button>
                        )}

                        <label className="ml-auto flex gap-2 items-center">
                            <Checkbox
                                checked={isActiveVersionFilter === 'true'}
                                onChange={(e) => setIsActiveVersionFilter(e.target.checked ? 'true' : '')}
                            />

                            <span className="text-sm">{t('importLog.filters.isActiveVersion')}</span>
                        </label>
                    </div>

                    <TableScrollWrapper>
                        <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeadCell sticky="left">
                                        {t('importLog.table.lookupDefinitionId')}
                                    </Table.HeadCell>
                                    <Table.HeadCell>{t('importLog.table.djangoAdminUrl')}</Table.HeadCell>
                                    <Table.HeadCell>{t('importLog.table.status')}</Table.HeadCell>
                                    <Table.HeadCell>{t('importLog.table.createdAt')}</Table.HeadCell>
                                    <Table.HeadCell>{t('importLog.table.duration')}</Table.HeadCell>
                                    <Table.HeadCell>{t('importLog.table.trigger')}</Table.HeadCell>
                                    <Table.HeadCell className="min-w-[14rem]">
                                        {t('importLog.table.inputFileUrl')}
                                    </Table.HeadCell>
                                    <Table.HeadCell className="text-right">
                                        {t('importLog.table.inputFileNumRows')}
                                    </Table.HeadCell>
                                    <Table.HeadCell className="text-right">
                                        {t('importLog.table.inputFileSize')}
                                    </Table.HeadCell>
                                    <Table.HeadCell className="min-w-[14rem]">
                                        {t('importLog.table.ingestionFileUrl')}
                                    </Table.HeadCell>
                                    <Table.HeadCell className=" text-right">
                                        {t('importLog.table.ingestionFileNumRows')}
                                    </Table.HeadCell>
                                    <Table.HeadCell className="text-right">
                                        {t('importLog.table.ingestionFileSize')}
                                    </Table.HeadCell>
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {importLogs && importLogs.length > 0 ? (
                                    importLogs.map((importLog) => (
                                        <ImportLogRow
                                            key={user.id}
                                            importLog={importLog}
                                            lookupType={getLookupType(importLog)}
                                        />
                                    ))
                                ) : (
                                    <Table.Row>
                                        <Table.Cell colSpan={12}>
                                            <div className="py-5 flex flex-col gap-2">
                                                <div className="text-center font-medium text-secondary">
                                                    {t('importLog.noData.title')}
                                                </div>
                                                <div className="text-center text-secondary">
                                                    {t('importLog.noData.message')}
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </TableScrollWrapper>

                    {totalCount > 0 && (
                        <TablePagination
                            totalCount={totalCount}
                            activePage={parsedActivePage}
                            itemsPerPage={parsedItemsPerPage}
                            setActivePage={(page: number) => setActivePage(page ? page.toString() : '')}
                            setItemsPerPage={(itemsPerPage: number) =>
                                setItemsPerPage(itemsPerPage ? itemsPerPage.toString() : '')
                            }
                        />
                    )}
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default MasterDataImportLog;
