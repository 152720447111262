import DocumentConfiguration, { CONFIG_OPTION, DEFAULT_CONFIGS } from '../../../document/pages/Configuration';
import * as DocumentQueries from './queries.tsx';
import { GET_OVERVIEW_DATA } from '../queries.ts';
import { CHANNEL_CONFIG_PATH, CHANNEL_PATH, OVERVIEW_PATH } from '../constants.ts';
import React from 'react';
import { DocumentType } from '../../../document/constants.ts';
import OrderConfirmationXmlSerializerConfiguration from '../components/OrderConfirmationXmlSerializerConfig.tsx';

const Configuration = (props) => {
    const configOptions = {
        [CONFIG_OPTION.General]: DEFAULT_CONFIGS[CONFIG_OPTION.General],
        [CONFIG_OPTION.Document]: DEFAULT_CONFIGS[CONFIG_OPTION.Document],
        [CONFIG_OPTION.HeaderFields]: DEFAULT_CONFIGS[CONFIG_OPTION.HeaderFields],
        [CONFIG_OPTION.LineItemFields]: DEFAULT_CONFIGS[CONFIG_OPTION.LineItemFields],
        [CONFIG_OPTION.XmlSerializer]: ({ channel, documentConfiguration, handlers }) => {
            return (
                <OrderConfirmationXmlSerializerConfiguration
                    config={channel?.xmlSerializerConfig}
                    onActivate={() => handlers.onCreate('xml_serializer_config')}
                    onDeactivate={() => handlers.onDelete('xml_serializer_config')}
                    onSubmit={(formData) => handlers.onUpdate({ xmlSerializerConfig: formData })}
                    documentType={documentConfiguration.documentTypeName + 'XmlSerializerConfig'}
                />
            );
        },
        [CONFIG_OPTION.Email]: DEFAULT_CONFIGS[CONFIG_OPTION.Email],
        [CONFIG_OPTION.MasterData]: DEFAULT_CONFIGS[CONFIG_OPTION.MasterData],
        [CONFIG_OPTION.SFTP]: DEFAULT_CONFIGS[CONFIG_OPTION.SFTP],
        [CONFIG_OPTION.AS2]: DEFAULT_CONFIGS[CONFIG_OPTION.AS2],
        [CONFIG_OPTION.Upload]: DEFAULT_CONFIGS[CONFIG_OPTION.Upload],
        [CONFIG_OPTION.Discard]: DEFAULT_CONFIGS[CONFIG_OPTION.Discard],
    };

    const documentConfiguration = {
        service: 'order_confirmations',
        documentType: DocumentType.OrderConfirmation,
        documentTypeName: 'orderConfirmation',
        activeTab: 'order-confirmation',
        documentQueries: DocumentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
        configPath: CHANNEL_CONFIG_PATH,
        configOptions: configOptions,
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
