export const OVERVIEW_PATH = '/order-confirmations';
export const OVERVIEW_FINISHED_PATH = '/order-confirmations/finished';
export const OVERVIEW_TESTING_PATH = '/order-confirmations/testing';

export const CHANNEL_PATH = '/order-confirmations/channels/:channelId';
export const CHANNEL_FINISHED_PATH = '/order-confirmations/channels/:channelId/finished';
export const CHANNEL_TESTING_PATH = '/order-confirmations/channels/:channelId/testing';
export const CHANNEL_CONFIG_PATH = '/order-confirmations/channels/:channelId/config/:tab?';
export const CHANNEL_MASTERDATA_PATH = '/order-confirmations/channels/:channelId/masterdata';

export const ASSISTANCE_PATH = '/order-confirmations/:recordId';
export const ASSISTANCE_TAB_PATH = '/order-confirmations/:recordId/:tab';
