import React from 'react';

import classnames from '../../../core/utils/classnames.tsx';
import { BaseButton } from '../../../core/components/Button.tsx';

const Summary = ({ className, children, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
    return (
        <div className={classnames('flex flex-col gap-8', className)} {...props}>
            {children}
        </div>
    );
};

const SummaryHeader = ({ className, children, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
    return (
        <div
            className={classnames(
                'rounded-lg bg-secondary border border-solid border-secondary px-4 py-3 text-sm text-secondary sticky top-0 z-10',
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
};

const SummaryPosition = ({ className, children, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
    return (
        <div className={classnames('flex flex-col gap-4', className)} {...props}>
            {children}
        </div>
    );
};

const SummaryPositionHeader = ({
    className,
    children,
    controls,
    ...props
}: React.ComponentPropsWithoutRef<'div'> & {
    controls?: React.ReactNode;
}) => {
    return (
        <div className={classnames('text-sm font-medium flex gap-1 items-center', className)} {...props}>
            <span>{children}</span>

            {controls && <span className="flex gap-1">{controls}</span>}
        </div>
    );
};

const SummaryPositionHeaderIconButton = ({ className, active = false, ...props }: any) => {
    return (
        <BaseButton
            className={classnames(
                'flex h-8 w-8 items-center justify-center gap-1 rounded transition-colors hover:bg-secondary cursor-pointer text-sm font-medium text-primary hover:text-primary disabled:opacity-40 disabled:pointer-events-none',
                active && '!bg-brand !text-brand',
                className
            )}
            type="button"
            {...props}
        />
    );
};

const SummaryGroup = ({ className, children, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
    return (
        <div className={classnames('border border-solid border-primary rounded-lg', className)} {...props}>
            {children}
        </div>
    );
};

const SummaryItem = ({ className, children, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
    return (
        <div className={classnames('p-4 text-sm', className)} {...props}>
            {children}
        </div>
    );
};

const SummarySeparator = ({
    className,
    borderClassName,
    children,
    ...props
}: React.ComponentPropsWithoutRef<'div'> & {
    borderClassName?: string;
}) => {
    return (
        <div className={classnames('relative flex items-center w-full -my-2', className)} {...props}>
            <div className={classnames('border-b border-solid border-primary flex-1', borderClassName)} />

            {children && <div className="text-secondary text-sm px-4 -mt-0.5">{children}</div>}

            <div className={classnames('border-b border-solid border-primary flex-1', borderClassName)} />
        </div>
    );
};

export default Object.assign(Summary, {
    Header: SummaryHeader,
    Position: SummaryPosition,
    PositionHeader: SummaryPositionHeader,
    PositionHeaderIconButton: SummaryPositionHeaderIconButton,
    Group: SummaryGroup,
    Item: SummaryItem,
    Separator: SummarySeparator,
});
