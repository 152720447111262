import DocumentConfiguration, {
    CONFIG_GROUP,
    CONFIG_OPTION,
    createItemConfig,
    DEFAULT_CONFIG_GROUPS,
    DEFAULT_CONFIGS,
} from '../../../document/pages/Configuration';
import * as DocumentQueries from './queries.tsx';
import { UPDATE_FIELDS_CONFIG } from './queries.tsx';
import { GET_OVERVIEW_DATA } from '../queries.ts';
import { CHANNEL_CONFIG_PATH, CHANNEL_PATH, OVERVIEW_PATH } from '../constants.ts';
import React from 'react';
import { DocumentType } from '../../../document/constants.ts';
import { cloneDeep } from 'lodash';

const Configuration = (props) => {
    const configOptions = {
        [CONFIG_OPTION.General]: DEFAULT_CONFIGS[CONFIG_OPTION.General],
        [CONFIG_OPTION.Document]: DEFAULT_CONFIGS[CONFIG_OPTION.Document],
        [CONFIG_OPTION.HeaderFields]: DEFAULT_CONFIGS[CONFIG_OPTION.HeaderFields],
        OBJEKTE: createItemConfig('objekte'),
        SPITZABRECHNUNGEN: createItemConfig('spitzabrechnungen'),
        ABSCHLAGSZAHLUNGEN: createItemConfig('abschlagszahlungen'),
        [CONFIG_OPTION.Email]: DEFAULT_CONFIGS[CONFIG_OPTION.Email],
        [CONFIG_OPTION.MasterData]: DEFAULT_CONFIGS[CONFIG_OPTION.MasterData],
        [CONFIG_OPTION.Discard]: DEFAULT_CONFIGS[CONFIG_OPTION.Discard],
    };

    const configGroups = cloneDeep(DEFAULT_CONFIG_GROUPS);
    configGroups[CONFIG_GROUP.Processing] = [
        CONFIG_OPTION.Document,
        CONFIG_OPTION.HeaderFields,
        // @ts-ignore
        'OBJEKTE',
        // @ts-ignore
        'SPITZABRECHNUNGEN',
        // @ts-ignore
        'ABSCHLAGSZAHLUNGEN',
        CONFIG_OPTION.MasterData,
        CONFIG_OPTION.Discard,
    ];

    const documentConfiguration = {
        service: 'db_immo',
        documentType: DocumentType.PropertyBill,
        documentTypeName: 'propertyBill',
        activeTab: 'property-bill',
        documentQueries: DocumentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
        configPath: CHANNEL_CONFIG_PATH,
        configOptions: configOptions,
        configGroups: configGroups,
        itemTypes: ['objekte', 'spitzabrechnungen', 'abschlagszahlungen'],
        updateMutation: UPDATE_FIELDS_CONFIG,
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
