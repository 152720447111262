import React from 'react';
import classnames from '../utils/classnames.tsx';
import { Slot } from '@radix-ui/react-slot';

const SecondaryNavigationSectionLabel = ({ children, className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div className={classnames('text-sm text-secondary px-3 py-2 not-first:mt-4', className)} {...props}>
            {children}
        </div>
    );
};

const SecondaryNavigationButton = ({
    active = false,
    className,
    asChild,
    ...props
}: React.ComponentPropsWithRef<'button'> & { active?: boolean; asChild?: boolean }) => {
    const Comp = asChild ? Slot : 'button';
    return (
        <Comp
            className={classnames(
                'relative flex w-full items-center gap-2 rounded-md px-3 py-2 text-sm text-primary hover:text-primary h-9 transition-colors',
                'cursor-pointer hover:bg-secondary transition-[padding] ease-in-out duration-200',
                active && '!bg-brand !text-brand',
                className
            )}
            data-active={active}
            {...props}
        />
    );
};

const SecondaryNavigation = ({ className, ...props }: Omit<React.ComponentPropsWithRef<'nav'>, 'children'>) => {
    return (
        <nav
            className={classnames(
                'flex flex-col flex-none w-72 bg-white border-solid border-r border-primary overflow-y-auto',
                className
            )}
            {...props}
        />
    );
};

export default Object.assign(SecondaryNavigation, {
    SectionLabel: SecondaryNavigationSectionLabel,
    Button: SecondaryNavigationButton,
});
