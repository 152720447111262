import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from '../utils/classnames.tsx';
import { DARK_MODE_LOCAL_STORAGE_KEY } from '../../../App.tsx';

interface IProps {
    children?: any;
    className?: string;
}

const SAFE_SPACE = 10;

const Frame = (props: IProps) => {
    const { children, className } = props;

    const isDarkMode = !!localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY);

    const contentRef = useRef(null);
    const [mountNode, setMountNode] = useState(undefined);
    const [height, setHeight] = useState(0);

    const handleLoad = () => {
        if (!contentRef?.current) return;

        setMountNode(contentRef?.current?.contentWindow?.document?.body);

        if (contentRef?.current?.contentWindow?.document?.body.scrollHeight !== 0) {
            setHeight(contentRef?.current?.contentWindow?.document?.body.scrollHeight + SAFE_SPACE);
        }
    };

    useEffect(() => {
        contentRef?.current?.addEventListener('load', handleLoad);
        setTimeout(handleLoad, 100);
        return () => {
            contentRef?.current?.removeEventListener('load', handleLoad);
        };
    }, [contentRef?.current]);

    return (
        <iframe
            className={classnames('w-full border-none', isDarkMode && 'frame--dark-mode', className)}
            ref={contentRef}
            style={{ height, colorScheme: 'light' }}
            src="about:blank"
            allowTransparency={true}
        >
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    );
};

Frame.displayName = 'Frame';

export default Frame;
