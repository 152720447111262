import { useMutation, useQuery } from '@apollo/client';

import {
    faArrowRotateRight,
    faExternalLink,
    faPencil,
    faPlus,
    faSpinnerThird,
    faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../core/utils/classnames.tsx';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withIcon } from '../../core/components/Icon.tsx';
import { GET_NAVIGATION_CHANNELS } from '../../core/components/Navigation/internal/queries.ts';
import {
    CREATE_WECLAPP_INTEGRATION,
    DELETE_WECLAPP_INTEGRATION,
    GET_WECLAPP_INTEGRATIONS,
    TRIGGER_WECLAPP_INTEGRATION_MASTERDATA_UPDATE,
    UPDATE_WECLAPP_INTEGRATION,
} from '../queries.ts';
import {
    CUSTOMER_INTEGRATION_WECLAPP_ADD_PATH,
    CUSTOMER_INTEGRATION_WECLAPP_EDIT_PATH,
    CUSTOMER_INTEGRATION_WECLAPP_PATH,
} from '../constants.ts';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../core/components/Button.tsx';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { url } from '../../core/utils/link.ts';
import FormField from '../../core/components/FormField.tsx';
import CheckboxGroup from '../../core/components/CheckboxGroup.tsx';
import Modal from '../../core/components/Modal.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import { Outlet } from 'react-router';
import Layout from '../../core/components/Layout.tsx';
import Page from '../../core/components/Page';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { format } from 'date-fns';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

interface WeclappFormModalOutletContext {
    integrations: any[];
    refetch: () => Promise<any>;
}

const AddIcon = withIcon(faPlus);
const EditIcon = withIcon(faPencil);
const DeleteIcon = withIcon(faTrashCan);
const RefreshIcon = withIcon(faArrowRotateRight);
const ExternalLinkIcon = withIcon(faExternalLink);
const LoadingIcon = withIcon(faSpinnerThird);

const FORM_DEFAULT_VALUES = {
    name: '',
    apiKey: '',
    tenant: '',
    workistTicketCategoryId: '',
    workistImportTicketStatusIds: '',
    workistInProgressTicketStatusId: '',
    workistFinishedTicketStatusId: '',
    workistFinishedTicketCategoryId: '',
    orderChannelIds: [],
};

const WeclappFormModal = ({
    title,
    submitButtonLabel,
    onSubmit,
    onClose,
    integration,
}: {
    title: string;
    submitButtonLabel: string;
    onSubmit: (data: any) => void;
    onClose: () => void;
    integration?: any;
}) => {
    const { t } = useTranslation('integrations');

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (integration) {
            form.reset({
                ...FORM_DEFAULT_VALUES,
                name: integration.name,
                apiKey: integration.apiKey,
                tenant: integration.tenant,
                workistTicketCategoryId: integration.workistTicketCategoryId,
                workistImportTicketStatusIds: integration.workistImportTicketStatusIds
                    .replaceAll(/\[/gi, '')
                    .replaceAll(/\]/gi, '')
                    .replaceAll(/"/gi, ''),
                workistInProgressTicketStatusId: integration.workistInProgressTicketStatusId,
                workistFinishedTicketStatusId: integration.workistFinishedTicketStatusId,
                orderChannelIds: integration.orderChannels.map((channel) => channel.id),
            });
        }
    }, [integration]);

    const { data: channelData } = useQuery(GET_NAVIGATION_CHANNELS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: false,
    });
    const orderChannels = channelData?.orderProcessingChannels || [];

    return (
        <Modal
            visible={true}
            onClose={onClose}
            title={title}
            buttons={
                <>
                    <Button type="button" asChild>
                        <Link to={url(CUSTOMER_INTEGRATION_WECLAPP_PATH)}>{t('weclapp.form.cancelButton')}</Link>
                    </Button>
                    <Button variant="primary" type="submit" form="modal-form">
                        {submitButtonLabel}
                    </Button>
                </>
            }
        >
            <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)} id="modal-form">
                <Controller
                    name="name"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('weclapp.form.name')} error={formErrors.name}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="apiKey"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('weclapp.form.apiKey')} error={formErrors.apiKey}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="tenant"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('weclapp.form.tenant')} error={formErrors.tenant}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="workistTicketCategoryId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('weclapp.form.workistTicketCategoryId')}
                            error={formErrors.workistTicketCategoryId}
                        >
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="workistImportTicketStatusIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('weclapp.form.workistImportTicketStatusIds')}
                            error={formErrors.workistImportTicketStatusIds}
                        >
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="workistInProgressTicketStatusId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('weclapp.form.workistInProgressTicketStatusId')}
                            error={formErrors.workistInProgressTicketStatusId}
                        >
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="workistFinishedTicketStatusId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('weclapp.form.workistFinishedTicketStatusId')}
                            error={formErrors.workistFinishedTicketStatusId}
                        >
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="workistFinishedTicketCategoryId"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('weclapp.form.workistFinishedTicketCategoryId')}
                            error={formErrors.workistFinishedTicketCategoryId}
                        >
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="orderChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('weclapp.form.orderChannelIds')} error={formErrors.orderChannelIds}>
                            <CheckboxGroup
                                {...field}
                                options={
                                    orderChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />
            </form>
        </Modal>
    );
};

export const AddWeclappFormModal = () => {
    const { t } = useTranslation('integrations');
    const { refetch } = useOutletContext<WeclappFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const [createIntegration] = useMutation(CREATE_WECLAPP_INTEGRATION);
    const handleCreateIntegration = async (formData) => {
        try {
            await createIntegration({
                variables: {
                    name: formData.name,
                    apiKey: formData.apiKey,
                    tenant: formData.tenant,
                    workistTicketCategoryId: formData.workistTicketCategoryId,
                    workistImportTicketStatusIds: formData.workistImportTicketStatusIds,
                    workistInProgressTicketStatusId: formData.workistInProgressTicketStatusId,
                    workistFinishedTicketStatusId: formData.workistFinishedTicketStatusId,
                    workistFinishedTicketCategoryId: formData.workistFinishedTicketCategoryId,
                    orderChannels: formData.orderChannelIds,
                },
            });
            await refetch();
            publishToast({ description: t('weclapp.createSuccess'), status: 'success' });
            navigate(url(CUSTOMER_INTEGRATION_WECLAPP_PATH));
        } catch (error) {
            console.error(error);
            publishToast({ description: t('weclapp.createError'), status: 'error' });
        }
    };

    return (
        <WeclappFormModal
            title={t('weclapp.form.createTitle')}
            submitButtonLabel={t('weclapp.form.createButton')}
            onSubmit={handleCreateIntegration}
            onClose={() => navigate(url(CUSTOMER_INTEGRATION_WECLAPP_PATH))}
        />
    );
};

export const EditWeclappFormModal = () => {
    const { t } = useTranslation('integrations');
    const { integrations, refetch } = useOutletContext<WeclappFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const { integrationId } = useParams();
    const integration = integrations?.find((integration) => integration.id === integrationId);

    const [updateIntegration] = useMutation(UPDATE_WECLAPP_INTEGRATION);
    const handleUpdateIntegration = async (formData) => {
        try {
            await updateIntegration({
                variables: {
                    id: integration.id,
                    name: formData.name,
                    apiKey: formData.apiKey,
                    tenant: formData.tenant,
                    workistTicketCategoryId: formData.workistTicketCategoryId,
                    workistImportTicketStatusIds: formData.workistImportTicketStatusIds,
                    workistInProgressTicketStatusId: formData.workistInProgressTicketStatusId,
                    workistFinishedTicketStatusId: formData.workistFinishedTicketStatusId,
                    workistFinishedTicketCategoryId: formData.workistFinishedTicketCategoryId,
                    orderChannels: formData.orderChannelIds,
                },
            });
            await refetch();
            publishToast({ description: t('weclapp.updateSuccess'), status: 'success' });
            navigate(url(CUSTOMER_INTEGRATION_WECLAPP_PATH));
        } catch (error) {
            console.error(error);
            publishToast({ description: t('weclapp.updateError'), status: 'error' });
        }
    };

    return (
        <WeclappFormModal
            title={t('weclapp.form.updateTitle')}
            submitButtonLabel={t('weclapp.form.updateButton')}
            onSubmit={handleUpdateIntegration}
            onClose={() => navigate(url(CUSTOMER_INTEGRATION_WECLAPP_PATH))}
            integration={integration}
        />
    );
};

const WeclappRow = ({ integration, refetch }: { integration: any; refetch: any }) => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const [deleteIntegration] = useMutation(DELETE_WECLAPP_INTEGRATION);
    const handleDeleteIntegration = async () => {
        try {
            await deleteIntegration({ variables: { id: integration.id } });
            await refetch();
            publishToast({ description: t('weclapp.deleteSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('weclapp.deleteError'), status: 'error' });
        }
    };

    const [updateMasterDataIntegration] = useMutation(TRIGGER_WECLAPP_INTEGRATION_MASTERDATA_UPDATE);
    const handleUpdateMasterDataIntegration = async () => {
        try {
            await updateMasterDataIntegration({ variables: { id: integration.id } });
            publishToast({ description: t('weclapp.updateMasterDataSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('weclapp.updateMasterDataError'), status: 'error' });
        }
    };

    const channels = integration ? [...integration.orderChannels] : [];

    return (
        <>
            <Table.Row>
                <Table.Cell>{integration.name}</Table.Cell>
                <Table.Cell>
                    <div className="flex gap-1.5 flex-wrap">
                        {channels.map((channel) => (
                            <span className="border-primary border border-solid bg-secondary flex gap-2 items-center px-1 rounded">
                                {channel.name}
                            </span>
                        ))}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {integration.activeMasterDataUpdate ? (
                        <span className="flex gap-2 items-center animate-pulse">
                            <span className="text-success">
                                <LoadingIcon spin />
                            </span>
                            <span>{t('weclapp.masterDataUpdateInProgress')}</span>
                        </span>
                    ) : (
                        <span>
                            {t('weclapp.lastMasterDataUpdate', {
                                updatedAt: format(new Date(integration.lastMasterDataUpdate), 'do MMMM yyyy, HH:mm'),
                            })}
                        </span>
                    )}
                </Table.Cell>
                <Table.Cell>{new Date(integration.createdAt).toLocaleString()}</Table.Cell>
                <Table.Cell>
                    <div className="flex gap-2">
                        <RowControlIconButton onClick={handleUpdateMasterDataIntegration}>
                            <RefreshIcon />
                        </RowControlIconButton>

                        <RowControlIconButton asChild>
                            <Link
                                to={url(CUSTOMER_INTEGRATION_WECLAPP_EDIT_PATH, {
                                    integrationId: integration.id,
                                })}
                                className="!text-primary hover:!text-primary"
                            >
                                <EditIcon />
                            </Link>
                        </RowControlIconButton>

                        <RowControlIconButton
                            className="hover:bg-error text-error hover:text-error"
                            onClick={handleDeleteIntegration}
                        >
                            <DeleteIcon />
                        </RowControlIconButton>
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    );
};

const WeclappIntegrationPage = () => {
    const { t } = useTranslation('integrations');

    const {
        data: data,
        loading: loading,
        refetch: refetch,
    } = useQuery(GET_WECLAPP_INTEGRATIONS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });
    const integrations = data?.weclappIntegrations || [];

    return (
        <Layout>
            <SettingsNavigation />
            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle className="flex items-center gap-2">
                        <img src="/images/external_logos/weclapp-vertikal-positive.png" className="h-5" />
                        <span>{t('weclapp.title')}</span>
                    </Page.HeaderTitle>

                    <div className="flex gap-2">
                        <Page.HeaderButton asChild>
                            <a href={t('weclapp.documentationLink')} target={'_blank'}>
                                <span className="flex gap-2 items-center">
                                    {t('weclapp.documentation')}
                                    <ExternalLinkIcon />{' '}
                                </span>
                            </a>
                        </Page.HeaderButton>

                        <Page.HeaderButton asChild>
                            <Link to={url(CUSTOMER_INTEGRATION_WECLAPP_ADD_PATH)}>
                                <AddIcon />
                                {t('weclapp.addButton')}
                            </Link>
                        </Page.HeaderButton>
                    </div>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                        <Table.Head>
                            <Table.Row>
                                <Table.HeadCell className="min-w-96 w-96">{t('weclapp.table.name')}</Table.HeadCell>
                                <Table.HeadCell>{t('weclapp.table.channels')}</Table.HeadCell>
                                <Table.HeadCell>{t('weclapp.table.masterDataUpdatedAt')}</Table.HeadCell>
                                <Table.HeadCell>{t('weclapp.table.createdAt')}</Table.HeadCell>
                                <Table.HeadCell sticky="right" className="min-w-14 w-14" />
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {integrations && integrations.length > 0 ? (
                                integrations.map((integration) => (
                                    <WeclappRow key={integration.id} integration={integration} refetch={refetch} />
                                ))
                            ) : (
                                <Table.Row>
                                    <Table.Cell colSpan={6} className="text-center text-secondary">
                                        {t('weclapp.noIntegrations')}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Page.Content>
            </Page>

            <Outlet context={{ integrations, refetch }} />
        </Layout>
    );
};

export default WeclappIntegrationPage;
