import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_PAGINATED_DATA_IMPORT_RUNS } from '../queries.ts';
import { MasterDataImportRun } from '../types.ts';
import { NavigationContext } from '../../document/routes.tsx';
import { url } from '../../core/utils/link.ts';
import { withIcon } from '../../core/components/Icon.tsx';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import Tooltip from '../../core/components/Tooltip.tsx';
import { format, formatDistanceToNow } from 'date-fns';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import classnames from '../../core/utils/classnames.tsx';

interface ILookupVersionInfo {
    lookup_type: string;
    lookup_definition_id: string;
    version_id: string;
    importedAt?: string; // fetched from network for the given version_id
    isActiveVersion?: boolean; // fetched from network for the given version_id
}

interface IProps {
    versionInfo: ILookupVersionInfo[];
}

const ExternalLinkIcon = withIcon(faExternalLink);

const Badge = ({ colorClass, children, className }: any) => {
    return (
        <span
            className={classnames(
                'bg-secondary text-primary flex-none text-sm font-medium inline-block px-2.5 py-0.5 rounded w-[fit-content] hover:opacity-70 cursor-pointer',
                colorClass === 'info' && '',
                colorClass === 'warning' && 'bg-warning text-warning',
                colorClass === 'error' && 'bg-error text-error',
                colorClass === 'success' && 'bg-success text-success',
                className
            )}
        >
            {children}
        </span>
    );
};

const VersionInfoRow = ({ versionInfo, importLogUrl }: { versionInfo: ILookupVersionInfo; importLogUrl: string }) => {
    const { t } = useTranslation('masterdata');

    return (
        <Table.Row>
            <Table.Cell>{t(`importRun.concreteLookupType.${camelCase(versionInfo.lookup_type)}`)}</Table.Cell>

            <Table.Cell>
                {versionInfo.isActiveVersion != null &&
                    (versionInfo.isActiveVersion ? (
                        <Badge colorClass="success" onClick={null}>
                            {t(`versionSummary.versionStatus.active`)}
                        </Badge>
                    ) : (
                        <Badge colorClass="">{t(`versionSummary.versionStatus.outdated`)}</Badge>
                    ))}
            </Table.Cell>

            <Table.Cell>
                <Tooltip content={versionInfo.importedAt && format(versionInfo.importedAt, 'PPpp')}>
                    {versionInfo.importedAt && formatDistanceToNow(versionInfo.importedAt, { addSuffix: true })}
                </Tooltip>
            </Table.Cell>

            <Table.Cell>
                <Tooltip content={t('versionSummary.importDetailsTooltip')}>
                    <RowControlIconButton asChild>
                        <a href={importLogUrl} target="_blank">
                            <ExternalLinkIcon />
                        </a>
                    </RowControlIconButton>
                </Tooltip>
            </Table.Cell>
        </Table.Row>
    );
};

export const MasterDataVersionSummary = ({ versionInfo = [] }: IProps) => {
    if (versionInfo == null) {
        return null;
    }

    const { t } = useTranslation('masterdata');
    const { paths, channelId } = useContext(NavigationContext);

    // Ensure info is always displayed in the same order
    let versionInfoItems = [...versionInfo].sort((a, b) => (a.lookup_type < b.lookup_type ? -1 : 0));
    const dataImportRunIds = versionInfo.reduce((acc, infoItem) => [...acc, infoItem.version_id], []);

    const { data, loading } = useQuery(GET_PAGINATED_DATA_IMPORT_RUNS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
            // fetch all the referenced versions (= import runs)
            filters: [{ name: 'id', expression: 'eq', value: dataImportRunIds }],
            offset: 0,
            first: versionInfo.length,
        },
    });
    const dataImportRuns = (data?.dataImportRuns.edges.map((node) => node.node) as MasterDataImportRun[]) || [];

    // Enrich version info with data from fetched import runs (if any)
    if (dataImportRuns.length > 0) {
        let dataImportRunsById = {};
        for (let dataImportRun of dataImportRuns) {
            dataImportRunsById[dataImportRun.id] = dataImportRun;
        }

        versionInfoItems = versionInfoItems.map((infoItem) => ({
            ...infoItem,
            importedAt: dataImportRunsById[infoItem.version_id]?.finishedAt,
            isActiveVersion: dataImportRunsById[infoItem.version_id]?.isActiveVersion,
        }));
    }

    return (
        <div className="flex flex-col">
            <Table className="border-none">
                <Table.Head className="rounded-none">
                    <Table.Row className="rounded-none">
                        <Table.HeadCell className="!rounded-none">
                            {t('versionSummary.columns.masterdata')}
                        </Table.HeadCell>
                        <Table.HeadCell>{t('versionSummary.columns.version')}</Table.HeadCell>
                        <Table.HeadCell>{t('versionSummary.columns.importedAt')}</Table.HeadCell>
                        <Table.HeadCell className="!rounded-none" />
                    </Table.Row>
                </Table.Head>
                <Table.Body className="table__tbody">
                    {versionInfoItems.map((versionInfo) => (
                        <VersionInfoRow
                            key={versionInfo.version_id}
                            versionInfo={versionInfo}
                            importLogUrl={url(
                                paths.channelMasterdata,
                                { channelId },
                                { search: { import: versionInfo.version_id } }
                            )}
                        />
                    ))}
                </Table.Body>
            </Table>

            <div className="text-sm text-tertiary p-4 border-t border-solid border-primary">
                {t('versionSummary.description')}
            </div>
        </div>
    );
};
