import * as React from 'react';
import classnames from '../../core/utils/classnames.tsx';
import { isInstanceOfEmailResult, isInstanceOfFile } from '../types.ts';
import EmailPreview from './EmailPreview.tsx';
import DataViewer from './DataViewer.tsx';

const InputDataPreview = ({ data, className = '' }) => {
    if (data === undefined) {
        return <DataViewer data={data} className={classnames('block w-full', className)} />;
    } else if (isInstanceOfEmailResult(data)) {
        return (
            <EmailPreview
                email={data}
                className={classnames(
                    'block w-full min-w-0 rounded border border-solid border-primary shadow min-h-[32rem] h-[fit-content] relative max-w-[44rem] bg-primary',
                    className
                )}
                data-type="email"
            />
        );
    } else if (isInstanceOfFile(data)) {
        const [mimeType, mimeSubtype] = data?.type ? data.type.split('/') : [undefined, undefined];

        if (mimeType == 'image') {
            return (
                <div
                    className={classnames(
                        'block w-full min-w-0 rounded border border-solid border-primary shadow min-h-[32rem] h-[fit-content] relative max-w-[44rem] bg-primary overflow-hidden',
                        className
                    )}
                    data-type="image"
                >
                    <img className={classnames('', className)} src={data.url} />
                </div>
            );
        } else if (mimeType == 'application' && mimeSubtype === 'pdf') {
            return (
                <object
                    data={data.url}
                    type="application/pdf"
                    className={classnames('block w-full h-[90vh]', className)}
                    data-type="pdf"
                >
                    <iframe src={data.url} className="w-full h-full" />
                </object>
            );
        } else {
            return <DataViewer data={data} className={classnames('block w-full', className)} />;
        }
    } else {
        return <DataViewer data={data} className={classnames('block w-full', className)} />;
    }
};

export default InputDataPreview;
