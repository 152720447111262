import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Logo from '../../core/components/Logo.tsx';

import { RESET_PASSWORD } from '../queries.ts';
import { LOGIN_PATH } from '../constants.ts';
import AuthPage from '../components/AuthPage.tsx';
import Button from '../../core/components/Button.tsx';
import FormField from '../../core/components/FormField.tsx';
import { Controller, useForm } from 'react-hook-form';
import StringField from '../../core/components/Fields/StringField.tsx';

const FORM_DEFAULT_VALUES = {
    password: '',
    confirmPassword: '',
};

const ResetPasswordPage = (props) => {
    const { loggedIn, user } = props;

    const { t, i18n } = useTranslation('user');
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn && user) {
            navigate(LOGIN_PATH);
        }
    }, [user, loggedIn]);

    const { userId, token } = useParams();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const [resetPassword] = useMutation(RESET_PASSWORD);
    const handleSubmit = async ({ password, confirmPassword }) => {
        try {
            await resetPassword({
                variables: {
                    uid: userId,
                    token,
                    newPassword1: password,
                    newPassword2: confirmPassword,
                },
                context: {
                    headers: {
                        // Set the language header for the request so django can return the correct language error messages
                        'Accept-Language': i18n.language !== 'en' ? `${i18n.language}, en` : 'en',
                    },
                },
            });
            navigate(LOGIN_PATH);
        } catch (error) {
            console.error(error);
            form.setError('root', { type: 'error', message: error?.message || t('resetPassword.submitError') });
        }
    };

    return (
        <AuthPage>
            <AuthPage.Content>
                <Logo className="w-32 h-auto" />

                <AuthPage.Card>
                    <AuthPage.Title>{t('resetPassword.title')}</AuthPage.Title>

                    {formErrors.root && <AuthPage.Error>{formErrors.root.message}</AuthPage.Error>}

                    <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4">
                        <Controller
                            name="password"
                            control={form.control}
                            rules={{
                                required: t('profile.changePassword.newPasswordRequired'),
                            }}
                            render={({ field }) => (
                                <FormField
                                    label={t('profile.changePassword.newPasswordLabel')}
                                    error={formErrors.password}
                                    required
                                >
                                    <StringField
                                        {...field}
                                        type="password"
                                        placeholder={t('profile.changePassword.newPasswordPlaceholder')}
                                    />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="confirmPassword"
                            control={form.control}
                            rules={{
                                required: t('profile.changePassword.confirmPasswordRequired'),
                            }}
                            render={({ field }) => (
                                <FormField
                                    label={t('profile.changePassword.confirmPasswordLabel')}
                                    error={formErrors.confirmPassword}
                                    required
                                >
                                    <StringField
                                        {...field}
                                        type="password"
                                        placeholder={t('profile.changePassword.confirmPasswordPlaceholder')}
                                    />
                                </FormField>
                            )}
                        />

                        <div className="mt-4">
                            <Button variant="primary" className="w-full" loading={form.formState.isSubmitting}>
                                {t('login.form.submitButton')}
                            </Button>
                        </div>
                    </form>
                </AuthPage.Card>

                <div className="text-xs text-center">
                    <Link to={LOGIN_PATH} className="text-brand-default hover:text-brand-hover">
                        {t('forgotPassword.back')}
                    </Link>
                </div>
            </AuthPage.Content>

            <AuthPage.Footer />
        </AuthPage>
    );
};

export default ResetPasswordPage;
