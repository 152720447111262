import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Logo from '../../core/components/Logo.tsx';
import { FORGOT_PASSWORD } from '../queries.ts';
import { LOGIN_PATH } from '../constants.ts';
import AuthPage from '../components/AuthPage.tsx';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import Button from '../../core/components/Button.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';

const FORM_DEFAULT_VALUES = {
    email: '',
};

const ForgotPasswordPage = (props) => {
    const { loggedIn, user } = props;

    const { t } = useTranslation('user');
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn && user) {
            navigate(LOGIN_PATH);
        }
    }, [loggedIn, user]);

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const [submitted, setSubmitted] = useState(false);

    const [forgotPassword] = useMutation(FORGOT_PASSWORD);
    const handleSubmit = async ({ email }) => {
        try {
            await forgotPassword({ variables: { email } });
            setSubmitted(true);
        } catch (error) {
            console.error(error);
            form.setError('root', { type: 'error', message: t('forgotPassword.submitError') });
        }
    };

    return (
        <AuthPage>
            <AuthPage.Content>
                <Logo className="w-32 h-auto" />

                <AuthPage.Card>
                    <AuthPage.Title>{t('resetPassword.title')}</AuthPage.Title>

                    {formErrors.root && <AuthPage.Error>{formErrors.root.message}</AuthPage.Error>}
                    {submitted && <AuthPage.Success>{t('forgotPassword.submitSuccess')}</AuthPage.Success>}

                    <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4">
                        <Controller
                            name="email"
                            control={form.control}
                            rules={{
                                required: t('login.form.emailRequired'),
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: t('login.form.emailInvalid'),
                                },
                            }}
                            render={({ field }) => (
                                <FormField label={t('forgotPassword.form.email')} error={formErrors.email} required>
                                    <StringField {...field} placeholder={t('forgotPassword.form.emailPlaceholder')} />
                                </FormField>
                            )}
                        />

                        <div className="mt-4">
                            <Button variant="primary" className="w-full" loading={form.formState.isSubmitting}>
                                {t('forgotPassword.form.submitButton')}
                            </Button>
                        </div>
                    </form>
                </AuthPage.Card>

                <div className="text-xs text-center">
                    <Link to={LOGIN_PATH} className="text-brand-default hover:text-brand-hover">
                        {t('forgotPassword.back')}
                    </Link>
                </div>
            </AuthPage.Content>

            <AuthPage.Footer />
        </AuthPage>
    );
};

export default ForgotPasswordPage;
