import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState.tsx';
import Field, { FieldProps } from './Field.tsx';
import Checkbox from '../Checkbox.tsx';

export interface BooleanFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {}

const BooleanField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onChange,
    controls,
    className,
    readOnly,
    disabled,
    inputRef = undefined,
    ...props
}: BooleanFieldProps) => {
    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

    const handleChange = (e: any) => {
        setValue(e.target.checked);
        onChange?.(e);
    };

    return (
        <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
            <Field.Input>
                <label className="w-full min-w-0 px-2 h-8 flex items-center">
                    <Checkbox
                        checked={value || ''}
                        onChange={handleChange}
                        readOnly={readOnly}
                        disabled={disabled}
                        {...props}
                        ref={inputRef}
                    />
                </label>
            </Field.Input>
            <Field.Controls>{controls}</Field.Controls>
        </Field>
    );
};

export default BooleanField;
