import * as React from 'react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentType } from '../../../document/constants.ts';
import DocumentAssistance, { ALL_TABS, TABS_OPTIONS } from '../../../document/pages/Assistance';
import ListOfServicesAssistanceHeader, {
    LIST_OF_SERVICE_ASSISTANCE_MODES,
} from '../components/ListOfServicesAssistanceHeader.tsx';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../constants.ts';
import {
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_LIST_OF_SERVICES_UNSAVED_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    GET_RELATED_DOCUMENT,
    LIST_OF_SERVICES_CHAPTER_ACTION,
    RE_UPLOAD_FILE,
    REOPEN_FOR_ASSISTANCE,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../queries.ts';
import { useTranslation } from 'react-i18next';
import classnames from '../../../core/utils/classnames.tsx';
import PositionAssistanceCard from '../components/PositionAssistanceCard.tsx';
import { useAssistanceContext } from '../../../document/pages/Assistance/AssistanceContext.tsx';
import PositionArticleSetCard, {
    DRAGGABLE_ITEM_TYPE,
    DropPreview,
    Separator,
} from '../components/PositionArticleSetCard.tsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Button from '../../../core/components/Button.tsx';
import { ArticleNumberFieldConditionalComponents } from '../../../document/components/ArticleNumberField.tsx';
import { useDebounce } from '../../../core/utils/hooks/useDebounce.ts';
import HeaderDataCard from '../components/HeaderDataCard.tsx';
import {
    getCustomerFieldConditionalComponents,
    getCustomerFieldGroupConditionalComponents,
} from '../../../document/components/CustomerField.tsx';
import {
    faArrowUpRightFromSquare,
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faCrosshairs,
    faEdit,
    faEllipsisVertical,
    faListTree,
    faSpinnerThird,
    faThumbsDown,
    faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons';
import { withIcon } from '../../../core/components/Icon.tsx';
import Loader from '../../../core/components/Loader.tsx';
import { useLazyQuery, useMutation } from '@apollo/client';
import Summary from '../components/Summary.tsx';
import SplitPanes, { Pane } from '../../../core/components/SplitPanes.tsx';
import { unpackAndMergeDynamicSchemaFields } from '../../../core/utils';
import { useToaster } from '../../../core/components/Toast.tsx';
import DropdownMenu from '../../../core/components/DropdownMenu.tsx';

const SCROLL_ANCHOR_OFFSET = 24;

const ChevronDownIcon = withIcon(faChevronDown);
const ChevronUpIcon = withIcon(faChevronUp);
const LoadingIcon = withIcon(faSpinnerThird);
const ListOfServicesIcon = withIcon(faListTree);
const CeckCircleIcon = withIcon(faCheckCircle);
const ThumbsUpIcon = withIcon(faThumbsUp);
const ThumbsDownIcon = withIcon(faThumbsDown);
const EditIcon = withIcon(faEdit);
const FocusIcon = withIcon(faCrosshairs);
const ExternalLinkIcon = withIcon(faArrowUpRightFromSquare);
const MoreIcon = withIcon(faEllipsisVertical);

export interface IPosition {
    id: string;
    positionIndex: number;
    chapter: string;
    number: IFieldData;
    text: IFieldData;
    isRelevant: IFieldData;
    rejectedBy: IFieldData;
    articleSets: IArticleSet[];
    pageIndex: number;
    prediction: string;
    parent: string;
    [key: string]: any;
}

export interface IChapter {
    number: string;
    numberTotal: number;
    numberRelevant: number;
    numberCompleted: number;
    header: string;
    positions: IPosition[];
}

export const getScrollAnchors = (container: HTMLElement) => {
    return container.querySelectorAll('[data-scroll-anchor="chapterHeader"], [data-scroll-anchor]');
};

export const getClosestScrollAnchorIndex = (container: HTMLElement) => {
    // relevantNodes is a list of all nodes with data-relevant="true"
    const relevantNodes = getScrollAnchors(container);
    const offsetTop = container.getBoundingClientRect().top;

    let closestChildIndex = null;
    let minDistance = Infinity;

    relevantNodes.forEach((child, index) => {
        const childRect = child.getBoundingClientRect();
        const distance = Math.abs(childRect.top - offsetTop - SCROLL_ANCHOR_OFFSET);

        if (distance <= minDistance) {
            minDistance = distance;
            closestChildIndex = index;
        }
    });

    if (closestChildIndex !== null) {
        // with this if condition we prevent that when we're at the bottom of the list set the index to null
        // but rather keep it at the last index
        return closestChildIndex;
    }
};

export const ListOfServicesPositionsTab = ({ setRefetchQueryParams, refetchQueryParams, ...props }) => {
    const { setRecord, record, refetchData } = useAssistanceContext();
    const {
        mode,
        filterListOfServicesPositions,
        scrollAnchorIndex,
        setScrollAnchorIndex: onScrollAnchorIndexChange,
    } = useListOfServicesAssistanceContext();

    if (!record) {
        return <Loader className="p-10" />;
    }

    const scrollRef = useRef(null);

    const handleScroll = () => {
        const closestChildIndex = getClosestScrollAnchorIndex(scrollRef.current);
        onScrollAnchorIndexChange(closestChildIndex);
    };
    const debouncedHandleScroll = useDebounce(handleScroll, 300);

    const allChaptersLoaded = record.listOfServicesUnsaved
        ? record.listOfServicesUnsaved.numberOfPositions + record.listOfServicesUnsaved.numberOfPretexts ===
          record.listOfServicesUnsaved.positions.length
        : false;

    useEffect(() => {
        // jump to the last visible scroll anchor
        const scrollAnchor = getScrollAnchors(scrollRef.current)?.[scrollAnchorIndex || 0];

        scrollRef.current.scrollTo({
            top: scrollAnchor
                ? scrollAnchor.getBoundingClientRect().top -
                  scrollRef.current.getBoundingClientRect().top +
                  scrollRef.current.scrollTop -
                  SCROLL_ANCHOR_OFFSET
                : 0,
            behavior: 'instant',
        });
    }, [mode, filterListOfServicesPositions]);

    const document = record?.listOfServicesUnsaved;
    const chapterBeingLoaded = useRef(undefined);

    const [chapterMap, setChapterMap] = useState<Map<string, IChapter>>(undefined);

    useEffect(() => {
        // Set the chapter map with positions on initial load and on refetch
        if (document) {
            // add chapters with their meta data to a map
            const initialChapterMap: Map<string, IChapter> = new Map(
                document.chapters.map((chapter) => [chapter.number, { ...chapter, positions: [] }])
            );

            // add positions to the map
            document.positions.forEach((position) => {
                initialChapterMap.get(position.chapter).positions.push(position);
            });
            setChapterMap(initialChapterMap);
        }
    }, [record]);

    const [getPositions, { loading, error, data }] = useLazyQuery(GET_LIST_OF_SERVICES_UNSAVED_DATA, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: false,
    });

    const [chapterAction] = useMutation(LIST_OF_SERVICES_CHAPTER_ACTION);

    const handleChapterHeaderClick = ({ openChapter, chapter }) => {
        if (openChapter) {
            // Update the queryParams, so that on a refetch of the record we load the currently active chapter
            setRefetchQueryParams({
                ...refetchQueryParams,
                chapter: chapter,
            });

            // Run the query to get the positions for the chapter that is being opened
            getPositions({
                variables: {
                    id: record.id,
                    chapter: chapter,
                },
            });

            // Keep track of which chapter is being loaded for scrolling purposes
            chapterBeingLoaded.current = chapter;

            // Already scroll to the top of the clicked chapter header
            const chapterIndex = Array.from(chapterMap.keys()).indexOf(chapter);

            const scrollAnchor = scrollRef.current.querySelectorAll('[data-scroll-anchor="chapterHeader"]')?.[
                chapterIndex || 0
            ];

            scrollRef.current.scrollTo({
                top: scrollAnchor
                    ? scrollAnchor.getBoundingClientRect().top -
                      scrollRef.current.getBoundingClientRect().top +
                      scrollRef.current.scrollTop -
                      SCROLL_ANCHOR_OFFSET
                    : 0,
                behavior: 'instant',
            });
        } else {
            // When the chapter is closed we remove its positions from the map
            const updatedChapterMap: Map<string, IChapter> = new Map(chapterMap);
            updatedChapterMap.get(chapter).positions = [];
            setChapterMap(updatedChapterMap);
        }
    };

    const [isChapterActionLoading, setIsChapterActionLoading] = useState(false);

    const handleMarkAllRelevant = async ({ chapter }: { chapter: string }) => {
        setIsChapterActionLoading(true);
        await chapterAction({
            variables: {
                recordId: record.id,
                chapter: chapter,
                action: 'mark_all_relevant',
            },
        });
        await refetchData();
        setIsChapterActionLoading(false);
    };

    const handleMarkAllNotRelevant = async ({ chapter }: { chapter: string }) => {
        setIsChapterActionLoading(true);
        await chapterAction({
            variables: {
                recordId: record.id,
                chapter: chapter,
                action: 'mark_all_not_relevant',
            },
        });
        await refetchData();
        setIsChapterActionLoading(false);
    };

    useEffect(() => {
        if (data) {
            // Handle successfully fetched positions for a chapter
            const loadedPositions = unpackAndMergeDynamicSchemaFields(
                data.listOfServicesProcessingRecord.listOfServicesUnsaved?.positions || []
            );

            // Record needs to be up to date for patch updates to work (and to show the correct chapter in case this component is remounted)
            record.listOfServicesUnsaved.positions = loadedPositions;
            setRecord(record);

            // Update the chapter map with the new positions
            const updatedChapterMap: Map<string, IChapter> = new Map(chapterMap);
            // Delete existing positions
            updatedChapterMap.forEach((value, key) => {
                updatedChapterMap.get(key).positions = [];
            });
            // Add new positions
            updatedChapterMap.get(loadedPositions[0].chapter).positions = loadedPositions;
            setChapterMap(updatedChapterMap);
        }
    }, [data]);

    useEffect(() => {
        if (document && chapterBeingLoaded.current) {
            // Scroll to the new chapter header when the chapter map was updated
            const chapterIndex = Array.from(chapterMap.keys()).indexOf(document.positions[0].chapter);

            const scrollAnchor = scrollRef.current.querySelectorAll('[data-scroll-anchor="chapterHeader"]')?.[
                chapterIndex || 0
            ];

            scrollRef.current.scrollTo({
                top: scrollAnchor
                    ? scrollAnchor.getBoundingClientRect().top -
                      scrollRef.current.getBoundingClientRect().top +
                      scrollRef.current.scrollTop -
                      SCROLL_ANCHOR_OFFSET
                    : 0,
                behavior: 'instant',
            });

            // Finish chapter loading and any side effects
            chapterBeingLoaded.current = undefined;
        }
    }, [chapterMap]);

    const main = (
        <>
            <div className="absolute top-0 right-0 w-[calc(34%+90px)] h-full field-modal-root bg-transparent pointer-events-none"></div>
            <div
                className="flex-col w-full h-full bg-secondary-light overflow-auto p-12"
                ref={scrollRef}
                onScroll={debouncedHandleScroll}
            >
                {mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR && document && <ListOfServicesHeaderDataContainer />}
                {chapterMap &&
                    Array.from(chapterMap).map(([chapterNumber, chapterData], i) => {
                        return (
                            <ListOfServicesChapter
                                key={i}
                                filterListOfServicesPositions={
                                    mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR
                                        ? filterListOfServicesPositions
                                        : true
                                }
                                chapter={chapterNumber}
                                onChapterHeaderClick={handleChapterHeaderClick}
                                onMarkAllRelevantClick={handleMarkAllRelevant}
                                onMarkAllNotRelevantClick={handleMarkAllNotRelevant}
                                isChapterActionLoading={isChapterActionLoading}
                                loadingChapter={loading && chapterBeingLoaded.current === chapterNumber}
                                allChaptersLoaded={allChaptersLoaded}
                                {...chapterData}
                            />
                        );
                    })}
            </div>
        </>
    );

    return mode === LIST_OF_SERVICE_ASSISTANCE_MODES.SUMMARY ? (
        <SplitPanes initialWidths={[66, 34]} id="list-of-services-positions">
            <Pane>{main}</Pane>
            <Pane>
                <ListOfServicesSummaryContainer scrollRef={scrollRef} />
            </Pane>
        </SplitPanes>
    ) : (
        main
    );
};

interface IFieldData {
    value?: string;
    predictionConfidence?: number;
    confidenceExplanation?: {
        translationKey: string;
        explanationDetails?: {
            confidence: number;
            field_value: string;
        };
    };
    choices?: string[]; // list of options comes from extracted data
    // ... and more but not sure if it's needed here
    [key: string]: any;
}

export interface IArticle {
    number: IFieldData;
    description: IFieldData;
    quantity: IFieldData;
    unit: IFieldData;
}

interface IArticleSet {
    id: string;
    articles: IArticle[];
    isComplete: boolean;
}

interface IChapterProps {
    chapter: string;
    positions: IPosition[];
    numberTotal: number;
    numberRelevant: number;
    numberCompleted: number;
    header: string;
    filterListOfServicesPositions: boolean;
    onChapterHeaderClick: (args: { openChapter: boolean; chapter: string }) => void;
    onMarkAllRelevantClick: (args: { chapter: string }) => void;
    onMarkAllNotRelevantClick: (args: { chapter: string }) => void;
    isChapterActionLoading: boolean;
    loadingChapter: boolean;
    allChaptersLoaded: boolean;
}

const ListOfServicesChapter = ({
    chapter,
    positions,
    numberTotal,
    numberRelevant,
    numberCompleted,
    header,
    filterListOfServicesPositions,
    onChapterHeaderClick,
    onMarkAllRelevantClick,
    onMarkAllNotRelevantClick,
    isChapterActionLoading,
    loadingChapter,
    allChaptersLoaded,
}: IChapterProps) => {
    const { t } = useTranslation('assistance');
    const { mode } = useListOfServicesAssistanceContext();

    // Add indexInChapter to each position needed to build a valid patch update in the backend
    positions.forEach((position, index) => {
        // If all chapters are loaded we use the item index for the patch
        position.indexInChapter = allChaptersLoaded ? null : index;
    });

    // Remove positions with no text (i.e. they're placeholders)
    positions = positions.filter((position) => !!position?.text);

    const [forceDisplayPosition, setForceDisplayPosition] = useState<IPosition | null>(null);

    // Filter relevant positions; pretexts should always be displayed
    const filteredPositions = positions.filter(
        (position) =>
            position.isRelevant.value === 'True' ||
            position.prediction === 'PreText' ||
            position === forceDisplayPosition
    );

    return (
        <div className={classnames('min-h-10 w-full gap-4 text-neutral-focus')} data-scroll-anchor="chapterHeader">
            <div className={classnames('flex flex-col')}>
                <div className="flex justify-between w-3/5 items-center mb-6">
                    <span
                        onClick={() =>
                            onChapterHeaderClick({
                                openChapter: positions.length > 0 ? false : true,
                                chapter: chapter,
                            })
                        }
                        className="font-semibold text-primary text-base w-fit cursor-pointer"
                    >
                        {!header && t('listOfServicesView.chapter')} {chapter} {header}
                        {positions.length > 0 ? (
                            <ChevronUpIcon className="text-primary text-sm ml-3" />
                        ) : (
                            <ChevronDownIcon className="text-primary text-sm ml-3" />
                        )}
                        {loadingChapter && <LoadingIcon spin className="text-brand ml-3" />}
                    </span>

                    {mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR && (
                        <div className="ml-auto">
                            <DropdownMenu>
                                <DropdownMenu.Trigger asChild>
                                    <Button
                                        variant="ghost"
                                        className="w-8 h-8 flex items-center justify-center"
                                        loading={isChapterActionLoading}
                                    >
                                        {isChapterActionLoading ? (
                                            <LoadingIcon spin className="text-brand" />
                                        ) : (
                                            <MoreIcon />
                                        )}
                                    </Button>
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content align="end">
                                    <DropdownMenu.Item
                                        onClick={() => onMarkAllRelevantClick({ chapter: chapter })}
                                        className="flex gap-2 items-center"
                                    >
                                        <ThumbsUpIcon />
                                        {t('listOfServicesView.chapterMoreMenu.markAllRelevant')}
                                    </DropdownMenu.Item>
                                    <DropdownMenu.Item
                                        onClick={() => onMarkAllNotRelevantClick({ chapter: chapter })}
                                        className="flex gap-2 items-center"
                                    >
                                        <ThumbsDownIcon />
                                        {t('listOfServicesView.chapterMoreMenu.markAllNotRelevant')}
                                    </DropdownMenu.Item>
                                </DropdownMenu.Content>
                            </DropdownMenu>
                        </div>
                    )}
                </div>

                {loadingChapter && <Loader />}
                {!loadingChapter &&
                    (filterListOfServicesPositions ? filteredPositions : positions).map((position, i) => {
                        return (
                            <Fragment key={i}>
                                {position.prediction === 'PreText' && position?.number?.value !== chapter && (
                                    <span className="font-semibold mb-6 text-primary text-base">
                                        {position.number.value} {position.header}
                                    </span>
                                )}
                                <ListOfServicesPositionContainer
                                    key={position.positionIndex}
                                    setForceDisplayPosition={setForceDisplayPosition}
                                    position={position}
                                />
                            </Fragment>
                        );
                    })}
            </div>
            <div className={classnames('flex justify-start items-center gap-4 text-sm')}>
                <div>
                    <CeckCircleIcon className="mr-2" />
                    <span className="font-semibold">{numberCompleted}</span>{' '}
                    {t('listOfServicesView.completedPositions')}
                    <ThumbsUpIcon className="ml-4 mr-2" />
                    <span className="font-semibold">{numberRelevant}</span> {t('listOfServicesView.relevantPositions')}
                    <ListOfServicesIcon className="ml-4 mr-2" />
                    <span className="font-semibold">{numberTotal}</span> {t('listOfServicesView.totalPositions')}
                </div>
            </div>
            <div className={classnames('grow h-px bg-border-primary mb-12 mt-6')} />
        </div>
    );
};

interface ListOfServicesPositionContainerProps {
    position: IPosition;
    setForceDisplayPosition: (ForceDisplayPosition: IPosition | null) => void;
}

const ListOfServicesPositionContainer = ({
    position,
    setForceDisplayPosition,
}: ListOfServicesPositionContainerProps) => {
    const { handlers, readOnly, record, loading } = useAssistanceContext();
    const { t } = useTranslation('assistance');
    const { mode, filterListOfServicesPositions } = useListOfServicesAssistanceContext();

    const { publishToast } = useToaster();

    const documentConfig = record?.channel?.documentConfig;

    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

    const handleMove = useCallback((newIndex: number) => {
        setDraggedIndex(newIndex);
    }, []);

    const handleDrop = useCallback(
        (item) => {
            setDraggedIndex(null);

            return handlers.onUpdate({
                itemIndex: position.positionIndex,
                action: 'action:drag_article_set',
                payload: {
                    setIndex: item.index,
                    // depending on moving up or down, the dragged item will be inserted before or after the item
                    newDragSetIndex: draggedIndex > item.index ? draggedIndex - 1 : draggedIndex,
                    indexInChapter: position.indexInChapter,
                },
            });
        },
        [draggedIndex]
    );

    const [showArticles, setShowArticles] = useState(true);
    const isIncluded = Boolean(position.articleSets.length && position.articleSets.length > 0);

    const handleIncludedChange = useCallback(
        (isIncluded: boolean) => {
            return handlers.onUpdate({
                itemIndex: position.positionIndex,
                action: 'action:change_included',
                payload: {
                    isIncluded: isIncluded,
                },
            });
        },
        [draggedIndex]
    );

    const [isExitAnimationActive, setIsExitAnimationActive] = useState(false);

    return (
        <div
            className={classnames(
                'grid transition-all duration-500 ease-in-out origin-top',
                isExitAnimationActive ? 'grid-rows-[0fr] opacity-0' : 'grid-rows-[1fr] opacity-100'
            )}
        >
            <div
                className={classnames(
                    'relative flex justify-end items-start gap-4 mb-6',
                    isExitAnimationActive && 'mb-0'
                )}
            >
                <PositionAssistanceCard
                    number={position.number.value}
                    description={position.text?.value}
                    isRelevant={position.isRelevant.value === 'True'}
                    reason={position.rejectedBy?.value}
                    articlesCount={position.articleSets.length}
                    className={classnames(
                        'sticky -top-6',
                        mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR ? 'w-3/5 flex-none' : 'w-full'
                    )}
                    onRelevantChange={(isRelevant) => {
                        // On relevancy change, show irrelevant position for a short time even when filter is active
                        setForceDisplayPosition?.(position);
                        if (!isRelevant && filterListOfServicesPositions) {
                            setIsExitAnimationActive(true);
                            publishToast({
                                title: t('listOfServices.positionHiddenToast.title'),
                                description: t('listOfServices.positionHiddenToast.description'),
                            });
                        }
                        return handlers
                            .onUpdate({
                                action: 'action:change_relevancy',
                                itemIndex: position.positionIndex,
                                payload: { isRelevant: isRelevant, indexInChapter: position.indexInChapter },
                            })
                            .then(() => {
                                setTimeout(() => {
                                    setForceDisplayPosition?.(null);
                                    setIsExitAnimationActive(false);
                                }, 1200);
                            });
                    }}
                    pageIndex={Number(position.pageIndex) + 1}
                    isIncluded={isIncluded}
                    onIncludedChange={handleIncludedChange}
                    variant={position.prediction === 'PreText' ? 'pretext' : 'position'}
                />
                {mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR ? (
                    position.isRelevant.value === 'True' ? (
                        <DndProvider backend={HTML5Backend} context={window}>
                            <div className="w-2/5 flex flex-col gap-8 items-center sticky -top-6">
                                {draggedIndex === 0 && (
                                    <DropPreview
                                        index={0}
                                        acceptedDropKey={`${DRAGGABLE_ITEM_TYPE}-position-${position.positionIndex}`}
                                    />
                                )}

                                {position.articleSets.map((articleSet, index) => (
                                    <React.Fragment key={articleSet.id}>
                                        <PositionArticleSetCard
                                            className="w-full"
                                            acceptedDropKey={`${DRAGGABLE_ITEM_TYPE}-position-${position.positionIndex}`}
                                            setIndex={index}
                                            articles={articleSet.articles}
                                            onArticlesChange={(action, payload) => {
                                                return handlers.onUpdate({
                                                    itemIndex: position.positionIndex,
                                                    action: action, // 'action:change_article_field',
                                                    payload: {
                                                        setIndex: index,
                                                        indexInChapter: position.indexInChapter,
                                                        ...payload,
                                                    },
                                                });
                                            }}
                                            isComplete={articleSet.isComplete}
                                            onIsCompleteChange={(isComplete) => {
                                                return handlers.onUpdate({
                                                    itemIndex: position.positionIndex,
                                                    action: 'action:change_is_complete',
                                                    payload: {
                                                        setIndex: index,
                                                        setIsComplete: isComplete,
                                                        indexInChapter: position.indexInChapter,
                                                    },
                                                });
                                            }}
                                            documentConfig={documentConfig}
                                            onMove={handleMove}
                                            onDrop={handleDrop}
                                            showArticles={showArticles}
                                        />

                                        {index < position.articleSets.length - 1 &&
                                            (draggedIndex === index + 1 ? (
                                                <DropPreview
                                                    index={index + 1}
                                                    acceptedDropKey={`${DRAGGABLE_ITEM_TYPE}-position-${position.positionIndex}`}
                                                />
                                            ) : (
                                                <Separator borderClassName="border-secondary">
                                                    {t('listOfServices.articleSetCard.or')}
                                                </Separator>
                                            ))}
                                    </React.Fragment>
                                ))}

                                {draggedIndex === position.articleSets.length && (
                                    <DropPreview
                                        index={position.articleSets.length}
                                        acceptedDropKey={`${DRAGGABLE_ITEM_TYPE}-position-${position.positionIndex}`}
                                    />
                                )}

                                {position.articleSets && position.articleSets.length > 0 && (
                                    <Button
                                        disabled={readOnly || loading}
                                        onClick={() => {
                                            return handlers.onUpdate({
                                                itemIndex: position.positionIndex,
                                                action: 'action:add_article_set',
                                                payload: {
                                                    indexInChapter: position.indexInChapter,
                                                },
                                            });
                                        }}
                                    >
                                        {t('listOfServices.articleSetCard.addAlternative')}
                                    </Button>
                                )}
                            </div>
                        </DndProvider>
                    ) : (
                        <div className="w-2/5" />
                    )
                ) : null}
            </div>
        </div>
    );
};

const ListOfServicesHeaderDataContainer = (props) => {
    const { t } = useTranslation('assistance');
    const { handlers, record } = useAssistanceContext();

    const documentConfig = record?.channel?.documentConfig;

    return (
        <div className={classnames('relative flex justify-end items-start gap-4 mb-6')}>
            <div className="sticky -top-6 w-3/5 flex-none">
                <div className={classnames('flex flex-col rounded-lg border border-primary border-solid bg-secondary')}>
                    <div className="p-6 flex flex-col gap-4">
                        <div className="text-center text-sm text-primary">
                            {t('listOfServices.headerDataCard.previewText')}
                        </div>
                        <div className="flex justify-center">
                            <a
                                className="!text-primary rounded flex items-center px-3 py-1.5 font-medium text-sm border border-solid outline-none hover:bg-secondary transition-all bg-primary border-secondary shadow-sm"
                                href={record?.inputFile?.url}
                                target="_blank"
                            >
                                {t('listOfServices.headerDataCard.previewButton')} <ExternalLinkIcon className="ml-2" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-2/5 sticky -top-6 flex flex-col gap-8 items-center">
                <HeaderDataCard
                    className="w-full"
                    values={record?.listOfServicesUnsaved}
                    onValueChange={(fieldName, payload) => {
                        return handlers.onUpdate({
                            action: 'action:change_header_field',
                            fieldName: fieldName,
                            payload: payload,
                        });
                    }}
                    isComplete={record?.isHeaderCorrect}
                    onIsCompleteChange={(isComplete) => {
                        return handlers.onUpdate({ action: 'action:mark_header_correct' });
                    }}
                    documentConfig={documentConfig}
                />
            </div>
        </div>
    );
};

const SummaryRow = ({
    number,
    description,
    quantity,
    unit,
}: {
    number: string;
    description: string;
    quantity: string;
    unit: string;
}) => {
    return (
        <div className="flex gap-10 items-center">
            <div className="w-40">{number}</div>
            <div className="flex-1">{description}</div>
            <div className="w-20 text-right">{quantity}</div>
            <div className="w-20">{unit}</div>
        </div>
    );
};

const ListOfServicesSummaryContainer = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }) => {
    const { document } = useAssistanceContext();
    const { setMode, setScrollAnchorIndex } = useListOfServicesAssistanceContext();
    const { t } = useTranslation('assistance');

    const scrollAnchors: HTMLElement[] = [...getScrollAnchors(window.document.body)] as HTMLElement[];
    const positionScrollAnchors = scrollAnchors.filter((anchor) => anchor.dataset.scrollAnchor === 'position');
    const getPositionScrollAnchorIndex = (positionIndex: number) =>
        scrollAnchors.findIndex((anchor) => anchor === positionScrollAnchors[positionIndex]);

    const relevantPositions = document?.positions.filter((position) => position.isRelevant.value === 'True');

    const handleFocus = (positionIndex: number) => {
        const scrollAnchor = positionScrollAnchors[positionIndex];
        if (scrollAnchor) {
            scrollRef.current?.scrollTo({
                top: scrollAnchor
                    ? scrollAnchor.getBoundingClientRect().top -
                      scrollRef.current.getBoundingClientRect().top +
                      scrollRef.current.scrollTop -
                      SCROLL_ANCHOR_OFFSET
                    : 0,
                behavior: 'instant',
            });
        }
    };

    return (
        <div className="w-full flex-none overflow-auto h-full">
            <div className="p-5">
                <Summary>
                    <Summary.Header>
                        <SummaryRow
                            number={t('listOfServices.articleSetCard.fieldNames.number')}
                            description={t('listOfServices.articleSetCard.fieldNames.description')}
                            quantity={t('listOfServices.articleSetCard.fieldNames.quantity')}
                            unit={t('listOfServices.articleSetCard.fieldNames.unit')}
                        />
                    </Summary.Header>

                    {relevantPositions.map((position, i) => {
                        return (
                            <Summary.Position>
                                <Summary.PositionHeader
                                    controls={
                                        <>
                                            <Summary.PositionHeaderIconButton onClick={() => handleFocus(i)}>
                                                <FocusIcon />
                                            </Summary.PositionHeaderIconButton>
                                            <Summary.PositionHeaderIconButton
                                                onClick={() => {
                                                    setScrollAnchorIndex(getPositionScrollAnchorIndex(i));
                                                    setMode(LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR as 'EDITOR');
                                                }}
                                            >
                                                <EditIcon />
                                            </Summary.PositionHeaderIconButton>
                                        </>
                                    }
                                >
                                    {t('listOfServices.positionCard.position')} {position.number.value}
                                </Summary.PositionHeader>

                                {position.articleSets.map((articleSet, j) => (
                                    <>
                                        <Summary.Group>
                                            {articleSet.articles.map((article, k) => (
                                                <>
                                                    <Summary.Item>
                                                        <SummaryRow
                                                            number={article.number.value}
                                                            description={article.description.value}
                                                            quantity={article.quantity.value}
                                                            unit={article.unit.value}
                                                        />
                                                    </Summary.Item>
                                                    {k < articleSet.articles.length - 1 && (
                                                        <Summary.Separator>
                                                            {t('listOfServices.articleSetCard.and')}
                                                        </Summary.Separator>
                                                    )}
                                                </>
                                            ))}
                                        </Summary.Group>

                                        {j < position.articleSets.length - 1 && (
                                            <Summary.Separator>
                                                {t('listOfServices.articleSetCard.or')}
                                            </Summary.Separator>
                                        )}
                                    </>
                                ))}
                            </Summary.Position>
                        );
                    })}
                </Summary>
            </div>
        </div>
    );
};

const ListOfServicesAssistance = (props) => {
    const [mode, setMode] = useState<'EDITOR' | 'SUMMARY'>('EDITOR');
    const [filterListOfServicesPositions, setFilterListOfServicesPositions] = useState(true);
    const [scrollAnchorIndex, setScrollAnchorIndex] = useState(0);

    const documentConfiguration = useMemo(
        () => ({
            documentType: DocumentType.ListOfServices,
            documentTypeName: 'listOfServices',
            GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
            GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
            GET_RELATED_DOCUMENT: GET_RELATED_DOCUMENT,
            RETRY_STEP: RETRY_STEP,
            REOPEN_FOR_ASSISTANCE: REOPEN_FOR_ASSISTANCE,
            ASSISTANCE_PATH: ASSISTANCE_PATH,
            ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
            OVERVIEW_PATH: OVERVIEW_PATH,
            OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
            OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
            CHANNEL_PATH: CHANNEL_PATH,
            CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
            CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
            RE_UPLOAD_FILE: RE_UPLOAD_FILE,
            SEND_TO_LABELING: SEND_TO_LABELING,
            components: {
                Field: [
                    ...ArticleNumberFieldConditionalComponents,
                    ...getCustomerFieldConditionalComponents('customer'),
                ],
                HeaderDataFieldGroup: [...getCustomerFieldGroupConditionalComponents('customer')],
            },
            AssistanceHeader: (props) => (
                <ListOfServicesAssistanceHeader
                    {...props}
                    mode={mode}
                    onModeChange={setMode}
                    filterListOfServicesPositions={filterListOfServicesPositions}
                    onFilterListOfServicesPositionsChange={setFilterListOfServicesPositions}
                />
            ),
            tabs: {
                [TABS_OPTIONS.Source]: ALL_TABS[TABS_OPTIONS.Source],
                [TABS_OPTIONS.Document]: Positions,
            },
            firstItemsTab: TABS_OPTIONS.Document,
            defaultTab: TABS_OPTIONS.Document,
        }),
        [mode, filterListOfServicesPositions]
    );

    return (
        <ListOfServicesAssistanceContext.Provider
            value={{
                mode,
                setMode,
                filterListOfServicesPositions,
                scrollAnchorIndex,
                setScrollAnchorIndex,
            }}
        >
            <DocumentAssistance documentConfiguration={documentConfiguration} {...props} />
        </ListOfServicesAssistanceContext.Provider>
    );
};

interface IAssistanceContext {
    mode: 'EDITOR' | 'SUMMARY';
    setMode: (mode: 'EDITOR' | 'SUMMARY') => void;
    filterListOfServicesPositions: boolean;
    scrollAnchorIndex: number;
    setScrollAnchorIndex: (scrollAnchorIndex: number) => void;
}

const ListOfServicesAssistanceContext = React.createContext<IAssistanceContext>({} as IAssistanceContext);
export const useListOfServicesAssistanceContext = () => React.useContext(ListOfServicesAssistanceContext);

const Positions = React.memo((props: any) => {
    return (
        <ListOfServicesPositionsTab
            key="positions"
            {...{
                ...props,
                tab: 'positions',
            }}
        />
    );
});

export default ListOfServicesAssistance;
