import * as React from 'react';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDebounce } from '../utils/hooks/useDebounce.ts';
import classnames from '../utils/classnames.tsx';

export const SPLIT_PANE_STORAGE_WIDTH_KEY = 'splitPanesWidth';
const SPLIT_PANE_STORAGE_HEIGHT_KEY = 'splitPanesHeight';

const MINIMUM_PANE_SIZE_ABSOLUTE = 100;
const MINIMUM_PANE_SIZE_PERCENTAGE = 10;

export const ORIENTATION = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

export const SplitPanes = (props) => {
    const {
        id, // used for localStorage
        className,
        children,
        orientation = ORIENTATION.VERTICAL,
        initialWidths,
        initialHeights,
        shrinkPanels = true,
        onDragStart,
        onDragEnd,
        onUpdate,
        allowResizeLast = false,
    } = props;

    const storeWidths = (widths) => {
        const values = JSON.parse(localStorage.getItem(SPLIT_PANE_STORAGE_WIDTH_KEY) || '{}');
        values[id] = widths;
        localStorage.setItem(SPLIT_PANE_STORAGE_WIDTH_KEY, JSON.stringify(values));
        onUpdate?.(widths);
    };
    const debouncedStore = useDebounce((widths) => storeWidths(widths), 300);

    const storeHeights = (heights) => {
        const values = JSON.parse(localStorage.getItem(SPLIT_PANE_STORAGE_HEIGHT_KEY) || '{}');
        values[id] = heights;
        localStorage.setItem(SPLIT_PANE_STORAGE_HEIGHT_KEY, JSON.stringify(values));
        onUpdate?.(heights);
    };
    const debouncedStoreHeights = useDebounce((heights) => storeHeights(heights), 300);

    const panesRef = useRef(undefined);

    const [paneWidths, setPaneWidths] = useState([]);
    const [paneHeights, setPaneHeights] = useState([]);
    const [activeHandle, setActiveHandle] = useState(undefined);

    const childrenCount = React.Children.count(children);

    useLayoutEffect(() => {
        if (panesRef.current === undefined) return;

        const values = JSON.parse(localStorage.getItem(SPLIT_PANE_STORAGE_WIDTH_KEY) || '{}');
        let widths = values?.[id] || initialWidths;

        if (widths === undefined || widths.length !== childrenCount) {
            if (shrinkPanels) {
                widths = Array(childrenCount).fill(100 / childrenCount);
            } else {
                const refWidth = panesRef.current.getBoundingClientRect().width;
                const paneWidth = Math.max(refWidth / childrenCount, 100);
                widths = Array(childrenCount).fill(paneWidth);
            }
        }

        setPaneWidths(widths);

        const valuesHeights = JSON.parse(localStorage.getItem(SPLIT_PANE_STORAGE_HEIGHT_KEY) || '{}');
        let heights = valuesHeights?.[id] || initialHeights;
        if (heights === undefined || heights.length !== childrenCount) {
            if (shrinkPanels) {
                heights = Array(childrenCount).fill(100 / childrenCount);
            } else {
                const refHeight = panesRef.current.getBoundingClientRect().height;
                const paneHeight = Math.max(refHeight / childrenCount, 100);
                heights = Array(childrenCount).fill(paneHeight);
            }
        }
        setPaneHeights(heights);

        if (orientation === ORIENTATION.VERTICAL) {
            onUpdate?.(widths);
        } else {
            onUpdate?.(heights);
        }
    }, [panesRef.current, childrenCount]);

    const handleDragStart = useCallback((handleIndex, event) => {
        event.preventDefault();
        setActiveHandle(handleIndex);
        onDragStart?.(event, handleIndex);
    }, []);

    const handleDrag = useCallback(
        (handleIndex, event) => {
            if (activeHandle === undefined) return;

            event.preventDefault();

            const firstPaneIndex = activeHandle;
            const secondPaneIndex = activeHandle + 1;

            const bbox = panesRef.current.getBoundingClientRect();

            // Handle logic to change the panels' width
            if (orientation === ORIENTATION.VERTICAL) {
                const panesWidth = panesRef.current.clientWidth;
                const newWidths = [...paneWidths];

                // Resizing one panel wil affect the width of the others
                if (shrinkPanels) {
                    const offset = paneWidths.slice(0, activeHandle).reduce((prev, curr) => prev + curr, 0);

                    const handlePosition = Math.min(Math.max(0, event.clientX - bbox.left), panesWidth);
                    const handlePositionPerc = Math.round((handlePosition / panesWidth) * 100000) / 1000;

                    const maxPerc = paneWidths[firstPaneIndex] + paneWidths[secondPaneIndex];
                    const relativeHandlePositionPerc =
                        offset + Math.min(Math.max(0, handlePositionPerc - offset), maxPerc);

                    newWidths[firstPaneIndex] = Math.min(
                        Math.max(relativeHandlePositionPerc - offset, MINIMUM_PANE_SIZE_PERCENTAGE),
                        100 - MINIMUM_PANE_SIZE_PERCENTAGE
                    );
                    newWidths[secondPaneIndex] = 100 - newWidths[firstPaneIndex];
                } else {
                    // - 7 because of left of handle 3px + width 1px + right of handle 3px (thats also why we add 3 to offset
                    const offset = paneWidths.slice(0, activeHandle).reduce((prev, curr) => prev + curr + 7, 3);

                    // Resizing one panel wil not affect the width of the others
                    const handlePosition = Math.max(0, event.clientX - bbox.left);

                    newWidths[activeHandle] = Math.max(handlePosition - offset, MINIMUM_PANE_SIZE_ABSOLUTE);
                }
                setPaneWidths(newWidths);
                debouncedStore(newWidths);
            }
            // Handle logic to change the panels' height
            else {
                const panesHeight = panesRef.current.clientHeight;
                const handlePosition = Math.min(Math.max(0, event.clientY - bbox.top), panesHeight);

                const handlePositionPerc = Math.round((handlePosition / panesHeight) * 100000) / 1000;

                const offset = paneHeights.slice(0, firstPaneIndex).reduce((prev, curr) => prev + curr, 0);
                const maxPerc = paneHeights[firstPaneIndex] + paneHeights[secondPaneIndex];

                const relativeHandlePositionPerc = offset + Math.min(Math.max(0, handlePositionPerc - offset), maxPerc);

                const newHeights = [...paneHeights];

                newHeights[firstPaneIndex] = Math.min(
                    Math.max(relativeHandlePositionPerc - offset, MINIMUM_PANE_SIZE_PERCENTAGE),
                    100 - MINIMUM_PANE_SIZE_PERCENTAGE
                );
                newHeights[secondPaneIndex] = 100 - newHeights[firstPaneIndex];

                setPaneHeights(newHeights);
                debouncedStoreHeights(newHeights);
            }
        },
        [activeHandle, panesRef]
    );

    const handleDragEnd = useCallback((handleIndex, event) => {
        setActiveHandle(undefined);
        onDragEnd?.(event, handleIndex);
    }, []);

    useEffect(() => {
        const boundHandleDrag = handleDrag.bind(undefined, activeHandle);
        const boundHandleDragEnd = handleDragEnd.bind(undefined, activeHandle);

        window.addEventListener('mousemove', boundHandleDrag);
        window.addEventListener('mouseup', boundHandleDragEnd);

        return () => {
            window.removeEventListener('mousemove', boundHandleDrag);
            window.removeEventListener('mouseup', boundHandleDragEnd);
        };
    }, [activeHandle]);

    const getPanelDimensions = (panelIndex) => {
        let width = 100;
        let height = 100;

        if (orientation === ORIENTATION.HORIZONTAL) {
            height = paneHeights?.[panelIndex] || height;
        } else {
            width = paneWidths?.[panelIndex] || width;
        }

        const dimensionWidthFactor = shrinkPanels ? '%' : 'px';
        return { width: `${width}${dimensionWidthFactor}`, height: `${height}%` };
    };

    return (
        <div
            className={classnames(
                'flex h-full w-full max-h-full items-stretch',
                orientation === ORIENTATION.HORIZONTAL && 'flex-col',
                orientation === ORIENTATION.VERTICAL && 'flex-row',
                className
            )}
            ref={panesRef}
        >
            {React.Children.toArray(children).map((child: any, childIndex) => {
                return (
                    <React.Fragment key={childIndex}>
                        <child.type
                            key={child.key}
                            {...child.props}
                            dimensions={getPanelDimensions(childIndex)}
                            orientation={orientation}
                        />
                        {childIndex < childrenCount - (allowResizeLast ? 0 : 1) && (
                            <div
                                className={classnames(
                                    'group flex',
                                    orientation === ORIENTATION.HORIZONTAL && 'flex-col cursor-row-resize -mb-px',
                                    orientation === ORIENTATION.VERTICAL && 'flex-row cursor-col-resize -mr-px'
                                )}
                                onMouseDown={(event, ...eventProps) =>
                                    handleDragStart(childIndex, event, ...eventProps)
                                }
                                onMouseMove={(event, ...eventProps) => handleDrag(childIndex, event, ...eventProps)}
                                onMouseUp={(event, ...eventProps) => handleDragEnd(childIndex, event, ...eventProps)}
                            >
                                <div
                                    className={classnames(
                                        'bg-inverted z-10 opacity-0 flex-0 group-hover:opacity-5 transition-opacity',
                                        orientation === ORIENTATION.HORIZONTAL && 'w-full h-1',
                                        orientation === ORIENTATION.VERTICAL && 'w-1 h-full'
                                    )}
                                />
                                <div
                                    className={classnames(
                                        ' bg-border-primary z-10 flex-0',
                                        orientation === ORIENTATION.HORIZONTAL && 'w-full h-px',
                                        orientation === ORIENTATION.VERTICAL && 'w-px h-full'
                                    )}
                                />
                                <div
                                    className={classnames(
                                        'bg-inverted z-10 opacity-0 flex-0 group-hover:opacity-5 transition-opacity',
                                        orientation === ORIENTATION.HORIZONTAL && 'w-full h-1',
                                        orientation === ORIENTATION.VERTICAL && 'w-1 h-full'
                                    )}
                                />
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default SplitPanes;

export const Pane = ({ dimensions, children, orientation, className, ...divProps }: any) => {
    return (
        <div
            className={classnames(
                'relative flex flex-col',
                orientation === ORIENTATION.HORIZONTAL && 'w-full',
                orientation === ORIENTATION.VERTICAL && 'h-full',
                className
            )}
            style={dimensions}
            {...divProps}
        >
            {children}
        </div>
    );
};
