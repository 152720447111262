import * as React from 'react';
import { useMemo } from 'react';
import DocumentAssistance from '../../../document/pages/Assistance';
import {
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    GET_RELATED_DOCUMENT,
    ORDER_EXTRACT_TEXT,
    RE_UPLOAD_FILE,
    REOPEN_FOR_ASSISTANCE,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../queries.ts';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../constants.ts';
import { DocumentType } from '../../../document/constants.ts';
import {
    getCustomerFieldConditionalComponents,
    getCustomerFieldGroupConditionalComponents,
} from '../../../document/components/CustomerField.tsx';
import { getContactFieldConditionalComponents } from '../../../document/components/ContactField.tsx';
import { getAddressFieldConditionalComponents } from '../../../document/components/AddressField.tsx';
import { FrameworkContractNumberFieldConditionalComponents } from '../../../document/components/FrameworkContractNumberField.tsx';
import { ArticleNumberFieldConditionalComponents } from '../../../document/components/ArticleNumberField.tsx';
import {
    fieldConfigToLegacyConfigFormat,
    fieldToFormFieldConfig,
    getInterceptedOnReselect,
} from '../../../document/pages/Assistance/utils.tsx';
import { merge } from 'lodash';

export const documentTypeName = 'order';

const fieldNameToGroupTypeMap = {
    customer: 'CustomerField',
    invoiceAddress: 'AddressField',
    deliveryAddress: 'AddressField',
    customerContact: 'ContactField',
    dispatchContact: 'ContactField',
};

export const getHeaderFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    headerFields,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const configs = {};
    fieldConfigToLegacyConfigFormat(fieldConfigs, configs, client, recordId, documentConfig, fieldNameToGroupTypeMap);

    // TODO: maybe this information can come from the backend to increase flexibility - for now it's fine though
    const legacyConfig = {
        customer: {
            groupFieldProps: {
                customerNumber: {
                    hidden: false,
                },
            },
        },
        invoiceAddress: {
            groupFieldProps: {
                addressId2: {
                    hidden: !headerFields.includes('invoiceAddress.addressId2'),
                },
            },
            groupInputProps: {
                addressId2: {
                    required: false,
                },
                misc: {
                    required: false,
                },
                email: {
                    required: false,
                },
                phone: {
                    required: false,
                },
                name: {
                    required: false,
                },
                companyName: {
                    required: false,
                },
            },
        },
        deliveryAddress: {
            groupFieldProps: {
                addressId2: {
                    // We currently use this catch-all field to differentiate between pickup & delivery addresses
                    // If this becomes a more common use case, we should use a dedicated field instead
                    hidden: !headerFields.includes('deliveryAddress.addressId2'),
                },
            },
            groupInputProps: {
                addressId2: {
                    required: false,
                },
                misc: {
                    required: false,
                },
                email: {
                    required: false,
                },
                phone: {
                    required: false,
                },
                name: {
                    required: false,
                },
                companyName: {
                    required: false,
                },
            },
        },
        customerContact: {
            groupFieldProps: {
                email: {
                    hidden: !headerFields.includes('customerContact.email'),
                },
                phone: {
                    hidden: !headerFields.includes('customerContact.phone'),
                },
            },
            groupInputProps: {
                email: {
                    required: false,
                },
                phone: {
                    required: false,
                },
            },
        },
        dispatchContact: {
            groupFieldProps: {
                email: {
                    hidden: !headerFields.includes('dispatchContact.email'),
                },
                phone: {
                    hidden: !headerFields.includes('dispatchContact.phone'),
                },
            },
            groupInputProps: {
                email: {
                    required: false,
                },
                phone: {
                    required: false,
                },
            },
        },
    };

    return merge(legacyConfig, configs);
};

export const getLineItemFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    documentConfig = undefined,
    fieldConfigs = undefined,
    handlers = undefined,
    document = undefined,
    documentConfiguration = undefined
) => {
    const configs = {};

    const onReselect = getInterceptedOnReselect(client, recordId, documentConfiguration?.EXTRACT_TEXT);

    if (fieldConfigs) {
        fieldConfigs.forEach((field) => {
            configs[field.name] = fieldToFormFieldConfig(field, {
                client,
                recordId,
                documentConfig,
                documentConfiguration,
                document,
                handlers,
            });
        });
    }

    const legacyConfig = {
        articleNumber: {
            onReselect,
        },
        articleNumber2: {
            onReselect,
        },
        articleNumber3: {
            onReselect,
        },
        articlePartitionSpecificNumber: {
            onReselect,
        },
        customer: {
            onReselect,
            valueKey: 'customerNumber',
        },
        quantity: {
            fieldType: 'FloatField',
        },
        convertedQuantity: {
            fieldType: 'FloatField',
        },
        unitPrice: {
            fieldType: 'FloatField',
        },
        totalPrice: {
            fieldType: 'FloatField',
        },
    };

    return merge(legacyConfig, configs);
};

const OrderAssistance = (props) => {
    const documentConfiguration = useMemo(
        () => ({
            documentType: DocumentType.Order,
            documentTypeName: 'order',
            GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
            GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
            GET_RELATED_DOCUMENT: GET_RELATED_DOCUMENT,
            RETRY_STEP: RETRY_STEP,
            REOPEN_FOR_ASSISTANCE: REOPEN_FOR_ASSISTANCE,
            ASSISTANCE_PATH: ASSISTANCE_PATH,
            ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
            OVERVIEW_PATH: OVERVIEW_PATH,
            OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
            OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
            CHANNEL_PATH: CHANNEL_PATH,
            CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
            CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
            RE_UPLOAD_FILE: RE_UPLOAD_FILE,
            EXTRACT_TEXT: ORDER_EXTRACT_TEXT,
            SEND_TO_LABELING: SEND_TO_LABELING,
            getHeaderFieldConfigs: getHeaderFieldConfigs,
            getLineItemFieldConfigs: getLineItemFieldConfigs,
            components: {
                Field: [
                    ...getCustomerFieldConditionalComponents('customer'),
                    ...getContactFieldConditionalComponents('customer_contact'),
                    ...getAddressFieldConditionalComponents('delivery_address'),
                    ...getAddressFieldConditionalComponents('invoice_address'),
                    ...FrameworkContractNumberFieldConditionalComponents,
                    ...ArticleNumberFieldConditionalComponents,
                ],
                HeaderDataFieldGroup: [...getCustomerFieldGroupConditionalComponents('customer')],
            },
        }),
        []
    );

    return <DocumentAssistance documentConfiguration={documentConfiguration} {...props} />;
};

export default OrderAssistance;
