import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import Button from '../../core/components/Button.tsx';
import FieldsConfig, {
    convertFormDataToFieldInputs,
    convertFieldsToFormData,
    useFieldsConfig,
    useFieldsConfigNavigation,
} from './FieldsConfig.tsx';
import { camelCase } from 'lodash';

export const filterHeaderFields = (fields) => {
    const baseFields = fields?.filter((field) => !field.name.includes('__')).map((field) => field.name) || [];

    return fields?.filter((field) => {
        const [parentKey] = field.name.split('__', 1);
        return baseFields.includes(parentKey);
    });
};

const HeaderFieldsConfig = ({ config, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const fields = useMemo(() => filterHeaderFields(config?.fields || []), [config?.fields]);

    const form = useForm({ defaultValues: {} });
    useEffect(() => {
        const initialEnabledFields = fields.filter((field) => field.enabled);
        form.reset(convertFieldsToFormData(initialEnabledFields));
    }, [config?.fields]);

    const [fieldsSort, setFieldsSort] = useState([]);
    const [enabledFieldNames, setEnabledFieldNames] = useState([]);
    const enabledFields = fields.filter((field) => enabledFieldNames.includes(field.name));

    const handleSubmit = async (formData) => {
        const fieldConfigs = [...convertFormDataToFieldInputs(formData, fieldsSort || [], '')];

        try {
            await onSubmit(fieldConfigs);
            publishToast({ description: t('configPage.document.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.document.updateError'), status: 'error' });
        }
    };

    const translationPrefix = 'assistance:headerView.fieldNames';
    const { addField, deleteField } = useFieldsConfig({
        form,
        fields,
        fieldsSort,
        onFieldsSortChange: setFieldsSort,
        enabledFieldNames,
        onEnabledFieldNamesChange: setEnabledFieldNames,
    });

    const getLabel = (key: string) => t(`${translationPrefix}.${key}`);
    const nav = useFieldsConfigNavigation({
        form,
        enabledFields,
        availableFields: fields,
        fieldsSort,
        onFieldsSortChange: setFieldsSort,
        addField,
        deleteField,
        getLabel,
    });
    const fieldsFormRef = useRef(null);

    const formFields = form.watch();
    const activeFormFields = Object.entries(formFields[nav.activeField] || {});
    activeFormFields.sort((a, b) => fieldsSort.indexOf(a[0]) - fieldsSort.indexOf(b[0]));

    return (
        <SettingsFormCard
            onSubmit={form.handleSubmit(handleSubmit)}
            className="max-w-[60rem] p-0"
            formClassName="flex-col gap-0 relative"
        >
            <SettingsFormCard.Title className="p-8">{t('configPage.headerFields.title')}</SettingsFormCard.Title>

            <FieldsConfig>
                <FieldsConfig.Navigation>
                    <FieldsConfig.NavigationHeader addableFields={nav.addableFields} onAddField={nav.onAddField} />
                    <FieldsConfig.NavigationItems fields={nav.fields} fieldsFormRef={fieldsFormRef} />
                </FieldsConfig.Navigation>

                <FieldsConfig.Content>
                    <FieldsConfig.ContentHeader>
                        {nav.activeField
                            ? t('configPage.document.formHeader', {
                                  field: getLabel(nav.activeField),
                              })
                            : ''}
                    </FieldsConfig.ContentHeader>

                    <FieldsConfig.ContentForms>
                        {activeFormFields?.map(([fieldName, fieldOptions]) => {
                            const fieldDefinition = fields.find((f) => f.name === fieldName);
                            return (
                                <FieldsConfig.OptionsForm
                                    key={fieldName}
                                    label={fieldName.includes('__') ? t(`${translationPrefix}.${fieldName}`) : ''}
                                >
                                    {Object.entries(fieldOptions)?.map(([name, value]) => {
                                        const optionDefinition = fieldDefinition.options?.find((o) => o.name === name);
                                        return (
                                            <Controller
                                                // @ts-ignore
                                                name={`${nav.activeField}.${fieldName}.${name}`}
                                                control={form.control}
                                                render={({ field }) => (
                                                    <FieldsConfig.OptionsFormField
                                                        key={name}
                                                        label={t(`configPage.document.form.${camelCase(name)}`)}
                                                        defaultValue={optionDefinition.defaultValue}
                                                        valueType={optionDefinition.valueType}
                                                        nullable={
                                                            optionDefinition.isNullable != undefined
                                                                ? optionDefinition.isNullable
                                                                : true
                                                        }
                                                        // form props
                                                        value={field.value}
                                                        onValueChange={(value) => field.onChange({ target: { value } })}
                                                        onBlur={field.onBlur}
                                                    />
                                                )}
                                            />
                                        );
                                    })}
                                </FieldsConfig.OptionsForm>
                            );
                        })}
                    </FieldsConfig.ContentForms>
                </FieldsConfig.Content>
            </FieldsConfig>

            <FieldsConfig.Footer>
                <Button type="submit" variant="primary">
                    {t('configPage.document.submitButton')}
                </Button>
            </FieldsConfig.Footer>
        </SettingsFormCard>
    );
};

export default HeaderFieldsConfig;
