import { IModuleProps } from '../../core/utils/modules';

import { CountryField } from '../../document/components/AddressField';

export default {
    name: 'siemens_smart_infrastructure',
    components: {
        Field: [
            {
                condition: ({ fieldName }) => fieldName === 'end_customer__country',
                component: CountryField,
            },
        ],
    },
} as IModuleProps;
