import {
    ASSISTANCE_PATH as RFQ_ASSISTANCE_PATH,
    CHANNEL_PATH as RFQ_CHANNEL_PATH,
    OVERVIEW_PATH as RFQ_OVERVIEW_PATH,
} from '../../../../products/rfqs/constants.ts';
import {
    ASSISTANCE_PATH as ORDER_ASSISTANCE_PATH,
    CHANNEL_PATH as ORDER_CHANNEL_PATH,
    OVERVIEW_PATH as ORDER_OVERVIEW_PATH,
} from '../../../../products/orders/constants.ts';
import {
    ASSISTANCE_PATH as ORDER_CONFIRMATION_ASSISTANCE_PATH,
    CHANNEL_PATH as ORDER_CONFIRMATION_CHANNEL_PATH,
    OVERVIEW_PATH as ORDER_CONFIRMATION_OVERVIEW_PATH,
} from '../../../../products/order_confirmations/constants.ts';
import {
    ASSISTANCE_PATH as INVOICE_ASSISTANCE_PATH,
    CHANNEL_PATH as INVOICE_CHANNEL_PATH,
    OVERVIEW_PATH as INVOICE_OVERVIEW_PATH,
} from '../../../../products/invoices/constants.ts';
import {
    ASSISTANCE_PATH as PROPERTY_BILL_ASSISTANCE_PATH,
    CHANNEL_PATH as PROPERTY_BILL_CHANNEL_PATH,
    OVERVIEW_PATH as PROPERTY_BILL_OVERVIEW_PATH,
} from '../../../../products/db_immo/constants.ts';
import {
    ASSISTANCE_PATH as DELIVERY_NOTE_ASSISTANCE_PATH,
    CHANNEL_PATH as DELIVERY_NOTE_CHANNEL_PATH,
    OVERVIEW_PATH as DELIVERY_NOTE_OVERVIEW_PATH,
} from '../../../../products/delivery_notes/constants.ts';
import {
    ASSISTANCE_PATH as UNIVERSAL_ASSISTANCE_PATH,
    CHANNEL_PATH as UNIVERSAL_CHANNEL_PATH,
    OVERVIEW_PATH as UNIVERSAL_OVERVIEW_PATH,
} from '../../../../products/universal/constants.ts';
import {
    ASSISTANCE_PATH as LIST_OF_SERVICES_ASSISTANCE_PATH,
    CHANNEL_PATH as LIST_OF_SERVICES_CHANNEL_PATH,
    OVERVIEW_PATH as LIST_OF_SERVICES_OVERVIEW_PATH,
} from '../../../../products/list_of_services/constants.ts';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { url } from '../../../utils/link.ts';
import { useState } from 'react';
import { withIcon } from '../../Icon.tsx';
import {
    faBox,
    faBuildings,
    faCommentDollar,
    faFileInvoiceDollar,
    faListCheck,
    faShoppingCart,
    faCommand,
    faListTree,
} from '@fortawesome/pro-regular-svg-icons';
import { useApplicationContext } from '../../../contexts/ApplicationContext.tsx';
import {
    USER_NOTIFICATIONS_PATH,
    USER_PASSWORD_PATH,
    USER_PROFILE_PATH,
    USERS_PATH,
} from '../../../../users/constants.ts';
import { ANALYTICS_DASHBOARD_PATH } from '../../../../analytics/constants.ts';
import { CUSTOMER_INTEGRATIONS_PATH, CUSTOMER_PROFILE_PATH } from '../../../../customer/constants.ts';
import { TEAMS_PATH } from '../../../../teams/constants.ts';

const RfqIcon = withIcon(faCommentDollar);
const OrderIcon = withIcon(faShoppingCart);
const OrderConfirmationIcon = withIcon(faListCheck);
const DeliveryNoteIcon = withIcon(faBox);
const InvoiceIcon = withIcon(faFileInvoiceDollar);
const PropertyBillIcon = withIcon(faBuildings);
const ListOfServicesIcon = withIcon(faListTree);
const UniversalIcon = withIcon(faCommand);

export const navigationDocumentTypes = {
    rfq: {
        label: 'overview.navigation.rfq',
        enabledKey: 'isRfqsEnabled',
        hideIfDisabled: false,
        icon: RfqIcon,
        paths: {
            assistancePath: RFQ_ASSISTANCE_PATH,
            channelPath: RFQ_CHANNEL_PATH,
            overviewPath: RFQ_OVERVIEW_PATH,
        },
    },
    order: {
        label: 'overview.navigation.order',
        enabledKey: 'isOrdersEnabled',
        hideIfDisabled: false,
        icon: OrderIcon,
        paths: {
            assistancePath: ORDER_ASSISTANCE_PATH,
            channelPath: ORDER_CHANNEL_PATH,
            overviewPath: ORDER_OVERVIEW_PATH,
        },
    },
    orderConfirmation: {
        label: 'overview.navigation.orderConfirmation',
        enabledKey: 'isOrderConfirmationsEnabled',
        hideIfDisabled: false,
        icon: OrderConfirmationIcon,
        paths: {
            assistancePath: ORDER_CONFIRMATION_ASSISTANCE_PATH,
            channelPath: ORDER_CONFIRMATION_CHANNEL_PATH,
            overviewPath: ORDER_CONFIRMATION_OVERVIEW_PATH,
        },
    },
    invoice: {
        label: 'overview.navigation.invoice',
        enabledKey: 'isInvoicesEnabled',
        hideIfDisabled: false,
        icon: InvoiceIcon,
        paths: {
            assistancePath: INVOICE_ASSISTANCE_PATH,
            channelPath: INVOICE_CHANNEL_PATH,
            overviewPath: INVOICE_OVERVIEW_PATH,
        },
    },
    propertyBill: {
        label: 'overview.navigation.propertyBill',
        enabledKey: 'isPropertyBillsEnabled',
        hideIfDisabled: true,
        icon: PropertyBillIcon,
        paths: {
            assistancePath: PROPERTY_BILL_ASSISTANCE_PATH,
            channelPath: PROPERTY_BILL_CHANNEL_PATH,
            overviewPath: PROPERTY_BILL_OVERVIEW_PATH,
        },
    },
    deliveryNote: {
        label: 'overview.navigation.deliveryNote',
        enabledKey: 'isDeliveryNotesEnabled',
        hideIfDisabled: false,
        icon: DeliveryNoteIcon,
        paths: {
            assistancePath: DELIVERY_NOTE_ASSISTANCE_PATH,
            channelPath: DELIVERY_NOTE_CHANNEL_PATH,
            overviewPath: DELIVERY_NOTE_OVERVIEW_PATH,
        },
    },
    listOfServices: {
        label: 'overview.navigation.listOfServices',
        enabledKey: 'isListOfServicesEnabled',
        hideIfDisabled: true,
        icon: ListOfServicesIcon,
        paths: {
            assistancePath: LIST_OF_SERVICES_ASSISTANCE_PATH,
            channelPath: LIST_OF_SERVICES_CHANNEL_PATH,
            overviewPath: LIST_OF_SERVICES_OVERVIEW_PATH,
        },
    },
    universal: {
        label: 'overview.navigation.universal',
        enabledKey: 'isUniversalEnabled',
        hideIfDisabled: true,
        icon: UniversalIcon,
        paths: {
            assistancePath: UNIVERSAL_ASSISTANCE_PATH,
            channelPath: UNIVERSAL_CHANNEL_PATH,
            overviewPath: UNIVERSAL_OVERVIEW_PATH,
        },
    },
};

const navigationItems = {
    analytics: {
        paths: {
            dashboardPath: ANALYTICS_DASHBOARD_PATH,
        },
    },
    settings: {
        paths: {
            companyPath: CUSTOMER_PROFILE_PATH,
            integrationsPath: CUSTOMER_INTEGRATIONS_PATH,
            usersPath: USERS_PATH,
            teamsPath: TEAMS_PATH,
        },
    },
    user: {
        paths: {
            profilePath: USER_PROFILE_PATH,
            notificationsPath: USER_NOTIFICATIONS_PATH,
            passwordPath: USER_PASSWORD_PATH,
        },
    },
};

const pathSortOrder = ['channelPath', 'overviewPath', 'assistancePath'];
const possiblePaths = Object.entries({ ...navigationDocumentTypes, ...navigationItems }).flatMap(
    ([documentType, data]) =>
        Object.entries(data.paths).flatMap(([pathType, path]) => {
            if (pathType === 'overviewPath') {
                // we need this as matchPath doesn't support optional segments yet
                return [
                    [documentType, pathType, path],
                    [documentType, pathType, path + '/finished'],
                    [documentType, pathType, path + '/testing'],
                ];
            } else {
                // * means any following path parts e.g. tabs, to make this work the order of the paths is important
                return [[documentType, pathType, path + '/*']];
            }
        })
);
possiblePaths.sort((a, b) => pathSortOrder.indexOf(a[1]) - pathSortOrder.indexOf(b[1]));

const useNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { channelId } = useApplicationContext();

    let activeItem: [string, string | null] = ['', null];
    let activePathType = '';

    for (const [documentType, pathType, path] of possiblePaths) {
        const match = matchPath(path, location.pathname);

        if (match) {
            // channelId needs to be set explicitly on the ApplicationContext, because it is not part of the path
            activeItem = [documentType, match?.params?.channelId || channelId || null];
            activePathType = pathType;
            break;
        }
    }

    const setActiveItem = ([parent, child]: [string, string | null]) => {
        const paths = navigationDocumentTypes[parent]?.paths;
        if (paths) {
            const itemUrl = child ? url(paths.channelPath, { channelId: child }) : url(paths.overviewPath);
            navigate(itemUrl);
        }
    };

    const [collapsed, setCollapsed] = useState(null);
    const shouldCollapse = activePathType === 'assistancePath';

    return {
        activeItem,
        onSelect: setActiveItem,
        collapsed: collapsed === null ? shouldCollapse : collapsed,
        onCollapsedChange: setCollapsed,
    };
};

export default useNavigation;
