import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../core/components/Button.tsx';
import { withIcon } from '../../../core/components/Icon.tsx';
import { faFileImport } from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../../core/utils/classnames.tsx';

const InsertIcon = withIcon(faFileImport);

export interface ISuggestion {
    // this represents a lookup entity
    articleId1: string;
    articleId2?: string;
    description: string;
    orderUnits: string[];
    quantity: number;
    unit: string;
}

const Suggestions = ({
    className,
    suggestions = [],
    onSelect,
}: {
    className?: string;
    suggestions?: ISuggestion[];
    onSelect?: (item: any) => void;
}) => {
    const { t } = useTranslation('assistance');

    if (!suggestions.length) return null;

    return (
        <div className={classnames('flex flex-col gap-3 bg-secondary rounded-md p-3', className)}>
            <h4 className="text-secondary text-sm">{t('listOfServices.suggestions.title')}</h4>

            {suggestions.map((item) => (
                <div key={item.articleId1} className="flex gap-1 bg-primary rounded px-3 py-2.5 shadow-sm">
                    <div className="flex flex-col flex-1 text-sm gap-0.5">
                        <h5 className="font-semibold">
                            {item.articleId1} {item.articleId2 ? `, ${item.articleId2}` : ''}
                        </h5>
                        <p>{item.description}</p>
                    </div>

                    <Button className="flex-none" onClick={() => onSelect?.(item)} variant="ghost">
                        <InsertIcon />
                    </Button>
                </div>
            ))}
        </div>
    );
};

export default Suggestions;
