import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToaster } from '../../../core/components/Toast.tsx';
import { Controller, useForm } from 'react-hook-form';
import BooleanField from '../../../core/components/Fields/BooleanField.tsx';
import StringField from '../../../core/components/Fields/StringField.tsx';
import SettingsFormCard from '../../../core/components/SettingsFormCard.tsx';
import FormField from '../../../core/components/FormField.tsx';
import Button from '../../../core/components/Button.tsx';

const FORM_DEFAULT_VALUES = {
    enabled: false,
    serializerModule: '',
};

const ListOfServicesXmlSerializerConfig = ({ config, onActivate, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            enabled: config?.enabled,
            serializerModule: config?.serializerModule || '',
        });
    }, [config]);

    const handleSubmit = async (formData) => {
        try {
            if (!config) await onActivate();
            await onSubmit(formData);
            publishToast({ description: t('configPage.xmlSerializer.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.xmlSerializer.updateError'), status: 'error' });
        }
    };

    return (
        <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.xmlSerializer.title')}>
            <Controller
                name="enabled"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.xmlSerializer.form.enabled')} error={formErrors.enabled}>
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="serializerModule"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.xmlSerializer.form.serializerModule')}
                        error={formErrors.serializerModule}
                    >
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button type="submit" variant="primary">
                    {t('configPage.xmlSerializer.form.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};
export default ListOfServicesXmlSerializerConfig;
