import { IFeatureFlags, IModuleProps } from '../core/utils/modules';
import * as CUSTOMER_MODULES from './customerRegistry';
import i18n from 'i18next';
import { IConditionalComponents } from './ConditionalComponentContext';

interface Customer {
    shortName: string;
    customizationKey?: string;
}

export let CUSTOMIZATION_COMPONENTS: IConditionalComponents = {};

let FEATURE_FLAGS = {};

export const isFeatureEnabled = (flag: keyof IFeatureFlags): boolean => {
    return FEATURE_FLAGS[flag] === true;
};

/**
 * Provides all customer modules registered in customerRegistry.ts
 */
export const getAllCustomerModules = (): IModuleProps[] => {
    const modules = [];
    for (const key in CUSTOMER_MODULES) {
        modules.push(CUSTOMER_MODULES[key]);
    }
    return modules;
};

/**
 * Loads & applies all customizations for the given customer (if any)
 * @param customer
 */
export const loadCustomizations = (customer: Customer): void => {
    if (customer == null) {
        return;
    }

    const standardizeModuleKey = (moduleKey: string): string => {
        return moduleKey.replace('-', '_');
    };

    const allCustomerModules = getAllCustomerModules();
    const allCustomerModulesByKey = Object.fromEntries(allCustomerModules.map((module) => [module.name, module]));
    let customerModuleKey = customer.customizationKey || customer.shortName || '';
    customerModuleKey = standardizeModuleKey(customerModuleKey);

    if (_activeCustomerModuleKey == customerModuleKey) {
        return; // already loaded
    }

    const customerModule = allCustomerModulesByKey[customerModuleKey];
    if (customerModule == null) {
        return;
    }

    applyCustomizations(customerModule);
    _activeCustomerModuleKey = customerModuleKey;
};

let _activeCustomerModuleKey; // Currently active customer module

const rawAllCustomerLocales = import.meta.glob('./**/locales/*.json', { eager: true });
const allCustomerLocales = Object.entries(rawAllCustomerLocales).reduce((acc, [path, content]: [string, any]) => {
    // Returns e.g. {"workist": {"en": ... }}

    // find in path: */modules/customizations/<customerName>/locales/<langCode>.json
    const match = path.match(/\/([^\/]+)\/locales\/([^\/]+)\.json$/);
    if (match) {
        const customerName = match[1];
        const langCode = match[2];

        // Organize by customer and language
        if (!acc[customerName]) {
            acc[customerName] = {};
        }

        acc[customerName][langCode] = content.default || content;
    }

    return acc;
}, {});

/**
 * Applies any customizations from the given customer module dynamically at runtime (e.g. loading localizations)
 * Note: Customizations should be applied in a way that changing the customization key will work properly
 *
 * @param customerModule
 */
const applyCustomizations = (customerModule: IModuleProps): void => {
    const addCustomerLocales = (customerLocales): void => {
        if (customerLocales == null) {
            return;
        }

        for (const localeKey in customerLocales) {
            const customLocaleResources = customerLocales[localeKey];

            for (const namespaceKey in customLocaleResources) {
                const customLocalizations = customLocaleResources[namespaceKey];
                // Override existing keys (if any)
                i18n.addResourceBundle(localeKey, namespaceKey, customLocalizations, true, true);
            }
        }
    };

    const setCustomerComponents = (customerComponents: IConditionalComponents = {}): void => {
        CUSTOMIZATION_COMPONENTS = { ...customerComponents };
    };

    const setCustomerFeatureFlags = (featureFlags: IFeatureFlags = {}): void => {
        FEATURE_FLAGS = { ...featureFlags };
    };

    addCustomerLocales(allCustomerLocales[customerModule.name]);
    setCustomerComponents(customerModule.components);
    setCustomerFeatureFlags(customerModule.featureFlags);
};
