import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../core/components/Logo.tsx';
import { login } from '../../core/utils/authentication.ts';
import { FORGOT_PATH, PROVIDER_LOGIN_PATH } from '../constants.ts';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import Button from '../../core/components/Button.tsx';
import AuthPage from '../components/AuthPage.tsx';

const FORM_DEFAULT_VALUES = {
    email: '',
    password: '',
};

const LoginPage = (props) => {
    const { loggedIn, user } = props;

    const { t } = useTranslation('user');

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const nextLocation = searchParams.get('next') || '/';
    const nextLocationParsed = new URL(nextLocation, window.location.origin);
    const nextLocationSafe = nextLocationParsed.origin === window.location.origin ? nextLocationParsed.pathname : '/';

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const handleSubmit = async ({ email, password }) => {
        try {
            const response = await login(email, password);

            if (response.success) {
                window.location.href = nextLocationSafe;
            } else if (response.throttled) {
                form.setError('root', { type: 'throttled', message: t('login.throttledLoginText') });
            } else {
                form.setError('root', { type: 'wrongCredentials', message: t('login.wrongCredentialsText') });
            }
        } catch (error) {
            form.setError('root', { type: 'wrongCredentials', message: t('login.wrongCredentialsText') });
        }
    };

    useEffect(() => {
        if (!loggedIn || !user) return;
        navigate(nextLocation);
    }, [loggedIn, user]);

    return (
        <AuthPage>
            <AuthPage.Content>
                <Logo className="w-32 h-auto" />

                <AuthPage.Card>
                    <AuthPage.Title>{t('login.title')}</AuthPage.Title>

                    {formErrors.root && <AuthPage.Error>{formErrors.root.message}</AuthPage.Error>}

                    <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4">
                        <Controller
                            name="email"
                            control={form.control}
                            rules={{
                                required: t('login.form.emailRequired'),
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: t('login.form.emailInvalid'),
                                },
                            }}
                            render={({ field }) => (
                                <FormField label={t('login.form.email')} error={formErrors.email} required>
                                    <StringField {...field} placeholder={t('login.form.emailPlaceholder')} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="password"
                            control={form.control}
                            rules={{
                                required: t('login.form.passwordRequired'),
                            }}
                            render={({ field }) => (
                                <FormField label={t('login.form.password')} error={formErrors.password} required>
                                    <StringField
                                        {...field}
                                        type="password"
                                        placeholder={t('login.form.passwordPlaceholder')}
                                    />
                                </FormField>
                            )}
                        />

                        <div className="text-xs text-right -mt-1 -mb-2">
                            <Link to={FORGOT_PATH} className="text-brand-default hover:text-brand-hover">
                                {t('login.forgotLink')}
                            </Link>
                        </div>

                        <div className="mt-4">
                            <Button variant="primary" className="w-full" loading={form.formState.isSubmitting}>
                                {t('login.form.submitButton')}
                            </Button>
                        </div>
                    </form>
                </AuthPage.Card>

                <div className="text-xs text-center">
                    <Link
                        to={`${PROVIDER_LOGIN_PATH}?next=${nextLocation}`}
                        className="text-brand-default hover:text-brand-hover"
                    >
                        {t('login.withSSO')}
                    </Link>
                </div>
            </AuthPage.Content>

            <AuthPage.Footer />
        </AuthPage>
    );
};

export default LoginPage;
