import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { useTranslation } from 'react-i18next';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation, useQuery } from '@apollo/client';
import {
    CREATE_CUSTOMER_WEBHOOK_TOKEN,
    DELETE_CUSTOMER_WEBHOOK_TOKEN,
    GET_CUSTOMER_WEBHOOK_TOKENS,
    REFRESH_CUSTOMER_WEBHOOK_TOKEN,
} from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import classnames from '../../core/utils/classnames.tsx';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import { withIcon } from '../../core/components/Icon.tsx';
import { faArrowRotateRight, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

const RefreshIcon = withIcon(faArrowRotateRight);
const DeleteIcon = withIcon(faTrashCan);

const TOKEN_FORM_DEFAULT_VALUES = {
    name: '',
};

const AuthenticationTokenRow = ({ token, refetch }: { token: any; refetch: any }) => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const [refreshToken] = useMutation(REFRESH_CUSTOMER_WEBHOOK_TOKEN);
    const handleRefreshToken = async () => {
        try {
            await refreshToken({ variables: { tokenId: token.id } });
            await refetch();
            publishToast({ description: t('token.refreshSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('token.refreshError'), status: 'error' });
        }
    };

    const [deleteToken] = useMutation(DELETE_CUSTOMER_WEBHOOK_TOKEN);
    const handleDeleteToken = async () => {
        try {
            await deleteToken({ variables: { tokenId: token.id } });
            await refetch();
            publishToast({ description: t('token.deleteSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('token.deleteError'), status: 'error' });
        }
    };

    return (
        <>
            <Table.Row>
                <Table.Cell className="font-mono">{token.key}</Table.Cell>
                <Table.Cell>{token.name}</Table.Cell>
                <Table.Cell>{new Date(token.created).toLocaleString()}</Table.Cell>
                <Table.Cell>
                    <div className="flex gap-2">
                        <RowControlIconButton onClick={handleRefreshToken}>
                            <RefreshIcon />
                        </RowControlIconButton>

                        <RowControlIconButton
                            className="hover:bg-error text-error hover:text-error"
                            onClick={handleDeleteToken}
                        >
                            <DeleteIcon />
                        </RowControlIconButton>
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    );
};

const AuthenticationTokensPage = () => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: TOKEN_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const {
        data: tokenData,
        loading,
        refetch,
    } = useQuery(GET_CUSTOMER_WEBHOOK_TOKENS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });
    const tokens = tokenData?.customerWebhookTokens || [];

    const [createToken] = useMutation(CREATE_CUSTOMER_WEBHOOK_TOKEN);
    const handleCreateToken = async (formData) => {
        try {
            await createToken({ variables: formData });
            await refetch();
            form.reset(TOKEN_FORM_DEFAULT_VALUES);
            publishToast({ description: t('token.createSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('token.createError'), status: 'error' });
        }
    };

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('token.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    {tokens && tokens.length > 0 && (
                        <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeadCell>{t('token.table.token')}</Table.HeadCell>
                                    <Table.HeadCell>{t('token.table.name')}</Table.HeadCell>
                                    <Table.HeadCell>{t('token.table.createdAt')}</Table.HeadCell>
                                    <Table.HeadCell sticky="right" className="min-w-14 w-14" />
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {tokens.map((token) => (
                                    <AuthenticationTokenRow key={token.id} token={token} refetch={refetch} />
                                ))}
                            </Table.Body>
                        </Table>
                    )}

                    <SettingsFormCard onSubmit={form.handleSubmit(handleCreateToken)} title={t('token.form.title')}>
                        <p className="text-primary text-sm">{t('token.form.description')}</p>

                        <Controller
                            name="name"
                            control={form.control}
                            render={({ field }) => (
                                <FormField label={t('token.form.name')} error={formErrors.name}>
                                    <StringField {...field} placeholder={t('token.form.namePlaceholder')} />
                                </FormField>
                            )}
                        />

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('token.form.createButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default AuthenticationTokensPage;
