import { useTranslation } from 'react-i18next';
import Filter, { FilterDropdownOption } from '../../../../core/components/Filter.tsx';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useOverviewContext } from './OverviewContext';

export const formatCustomer = (senderCompany) => {
    if (!senderCompany) {
        return null;
    }
    if (senderCompany?.includes('(')) {
        //record.sender comes like 'CompanyName (CompanyNumber)';
        const parts = senderCompany.trim().split('(');
        if (parts?.length > 1) {
            parts[1] = parts[1].replace(/\)\s*$/, '');
        }
        return parts.filter((part) => part.length > 0);
    }
    return [senderCompany];
};

const ClientFilter = ({ filters, onFiltersChange }) => {
    const { t } = useTranslation('assistance');

    const [searchValue, setSearchValue] = React.useState('');

    const { documentConfiguration } = useOverviewContext();

    const { data } = useQuery(documentConfiguration.GET_CLIENT_TABLE_FILTER_OPTIONS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            searchTerm: searchValue,
        },
    });

    const filterName = 'client';
    const filter = filters.find((f) => f.name === filterName);

    const selected = filter ? (Array.isArray(filter.value) ? filter.value : [filter.value]) : [];

    const options = [
        ...selected.map((value) => ({
            value: value,
            label: value,
        })),
        ...(data?.[`${documentConfiguration.documentTypeName}ClientTableFilterOptions`]
            ?.filter((option) => !selected.includes(option.value))
            ?.map((option) => ({
                value: option.value,
                label: option.label,
            })) || []),
    ];

    const handleSelectedChange = (selected: string[]) => {
        const newFilters = filters.filter((f) => f.name !== filterName);
        if (selected.length) {
            newFilters.push({ name: filterName, value: selected, exclude: false, expression: 'eq' });
        }
        onFiltersChange(newFilters);
    };

    const renderOption = (option: FilterDropdownOption) => {
        if (formatCustomer(option.label) == null) {
            return <div className="text-tertiary">({t('overview.columns.notFound')})</div>;
        }
        const [name, number] = formatCustomer(option.label);
        return (
            <div className="flex flex-col space-y-1">
                <div>{name}</div>
                <div className="text-xs text-tertiary">{number || null}</div>
            </div>
        );
    };

    return (
        <Filter
            allLabel={t('overview.filters.client.all')}
            label={t('overview.filters.client.label')}
            options={options}
            selected={selected}
            onSelectedChange={handleSelectedChange}
            renderOption={renderOption}
            multiple
            showSearch
            maxOptions={20}
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
        />
    );
};

export default ClientFilter;
