import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { url } from '../../../core/utils/link';
import DiscardConfiguration from '../../components/DiscardConfig.tsx';
import EmailClientConfiguration from '../../components/EmailConfig.tsx';
import MasterDataConfiguration from '../../components/MasterDataConfig.tsx';
import SFTPConfiguration from '../../components/SftpConfig.tsx';
import UploadConfiguration from '../../components/UploadConfig.tsx';
import AS2Configuration from '../../components/As2Config.tsx';
import { appInsights } from '../../../core/analytics/applicationInsights';
import { updateChannelConfigEvent, updateChannelMetadataEvent } from '../../../core/analytics/customEvents';
import DocumentXMLSerializerConfiguration from '../../components/XmlSerializerConfig.tsx';
import SecondaryNavigation from '../../../core/components/SecondaryNavigation.tsx';
import Page from '../../../core/components/Page/index.tsx';
import Layout from '../../../core/components/Layout.tsx';
import { withIcon } from '../../../core/components/Icon.tsx';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { camelCase, kebabCase, merge, snakeCase } from 'lodash';
import GeneralConfiguration from '../../components/GeneralConfiguration.tsx';
import { DocumentConfig } from '../../components/DocumentConfig.tsx';
import { UPDATE_FIELDS_CONFIG } from '../../queries.ts';
import HeaderFieldsConfig, { filterHeaderFields } from '../../components/HeaderFieldsConfig.tsx';
import LineItemFieldsConfig, {
    DEFAULT_LINE_ITEM_TYPE,
    filterLineItemFields,
} from '../../components/LineItemFieldsConfig.tsx';
import { convertFieldsToEnabledFieldInputs } from '../../components/FieldsConfig.tsx';
import ImportExportConfig from '../../components/ImportExportConfig.tsx';
import { canSeeSupportUserConfigs } from '../../../users/utils.ts';

const BreadcrumbIcon = withIcon(faChevronRight);

export enum CONFIG_OPTION {
    General = 'GENERAL',
    Document = 'DOCUMENT',
    HeaderFields = 'HEADER_FIELDS',
    LineItemFields = 'LINE_ITEM_FIELDS',
    Email = 'EMAIL',
    Upload = 'UPLOAD',
    MasterData = 'MASTER_DATA',
    Discard = 'DISCARD',
    SFTP = 'SFTP',
    XmlSerializer = 'XML_SERIALIZER',
    AS2 = 'AS2',
    ImportExport = 'IMPORT_EXPORT',
}

export enum CONFIG_GROUP {
    General = 'GENERAL',
    Input = 'INPUT',
    Processing = 'PROCESSING',
    Output = 'OUTPUT',
    Other = 'OTHER',
}

export const DEFAULT_CONFIG_GROUPS: { [key in CONFIG_GROUP]?: CONFIG_OPTION[] } = {
    [CONFIG_GROUP.General]: [CONFIG_OPTION.General],
    [CONFIG_GROUP.Input]: [CONFIG_OPTION.Email],
    [CONFIG_GROUP.Processing]: [
        CONFIG_OPTION.Document,
        CONFIG_OPTION.HeaderFields,
        CONFIG_OPTION.LineItemFields,
        CONFIG_OPTION.MasterData,
        CONFIG_OPTION.Discard,
    ],
    [CONFIG_GROUP.Output]: [CONFIG_OPTION.AS2, CONFIG_OPTION.SFTP, CONFIG_OPTION.Upload, CONFIG_OPTION.XmlSerializer],
};

export const createItemConfig =
    (itemType) =>
    ({ channel, handlers }) => {
        return (
            <LineItemFieldsConfig
                config={channel?.documentConfig}
                onSubmit={(formData) => {
                    const itemFieldNames = filterLineItemFields(channel?.documentConfig?.fields).map(
                        (field) => field.name
                    );
                    // we need to make sure the complete list of enabled fields is send back on save including the header fields
                    const headerFieldInputs = convertFieldsToEnabledFieldInputs(
                        channel?.documentConfig?.fields?.filter((field) => !itemFieldNames.includes(field.name))
                    );
                    return handlers.onUpdateDocumentConfig({ fieldConfigs: [...headerFieldInputs, ...formData] });
                }}
                itemType={itemType}
            />
        );
    };

export const DEFAULT_CONFIGS: { [key in CONFIG_OPTION]?: any } = {
    [CONFIG_OPTION.General]: ({ channel, handlers }) => {
        return <GeneralConfiguration channel={channel} onSubmit={(formData) => handlers.onUpdate(formData)} />;
    },
    [CONFIG_OPTION.Email]: ({ channel, handlers }) => {
        return (
            <EmailClientConfiguration
                config={channel?.emailConfig}
                onActivate={() => handlers.onCreate('email_config')}
                onSubmit={(formData) => handlers.onUpdate({ emailConfig: formData })}
            />
        );
    },

    [CONFIG_OPTION.Document]: ({ channel, handlers }) => {
        const fieldInputs = convertFieldsToEnabledFieldInputs(channel?.documentConfig?.fields);
        return (
            <DocumentConfig
                config={channel?.documentConfig}
                onSubmit={(formData) =>
                    handlers.onUpdateDocumentConfig({ documentConfig: formData, fieldConfigs: fieldInputs })
                }
            />
        );
    },
    [CONFIG_OPTION.HeaderFields]: ({ channel, handlers }) => {
        return (
            <HeaderFieldsConfig
                config={channel?.documentConfig}
                onSubmit={(formData) => {
                    // we need to make sure the complete list of enabled fields is send back on save including the line item fields
                    const headerFieldNames = filterHeaderFields(channel?.documentConfig?.fields).map(
                        (field) => field.name
                    );
                    const itemFieldInputs = convertFieldsToEnabledFieldInputs(
                        channel?.documentConfig?.fields.filter((field) => !headerFieldNames.includes(field.name))
                    );
                    return handlers.onUpdateDocumentConfig({ fieldConfigs: [...itemFieldInputs, ...formData] });
                }}
            />
        );
    },
    [CONFIG_OPTION.LineItemFields]: createItemConfig(DEFAULT_LINE_ITEM_TYPE),
    [CONFIG_OPTION.MasterData]: ({ channel, handlers, user, channelId }) => {
        return (
            <MasterDataConfiguration
                user={user}
                config={channel?.masterDataConfig}
                onActivate={() => handlers.onCreate('master_data_config')}
                onSubmit={(formData) => handlers.onUpdate({ masterDataConfig: formData })}
                channelId={channelId}
                onDeleteMappings={handlers.onDeleteMappings}
            />
        );
    },

    [CONFIG_OPTION.XmlSerializer]: ({ channel, handlers }) => {
        return (
            <DocumentXMLSerializerConfiguration
                config={channel?.xmlSerializerConfig}
                onActivate={() => handlers.onCreate('xml_serializer_config')}
                onSubmit={(formData) => handlers.onUpdate({ xmlSerializerConfig: formData })}
            />
        );
    },
    [CONFIG_OPTION.Upload]: ({ channel, handlers }) => {
        return (
            <UploadConfiguration
                config={channel?.uploadConfig}
                onActivate={() => handlers.onCreate('upload_config')}
                onSubmit={(formData) => handlers.onUpdate({ uploadConfig: formData })}
            />
        );
    },
    [CONFIG_OPTION.AS2]: ({ channel, handlers }) => {
        return (
            <AS2Configuration
                config={channel?.as2Config}
                onActivate={() => handlers.onCreate('as2_config')}
                onSubmit={(formData) => handlers.onUpdate({ as2Config: formData })}
            />
        );
    },
    [CONFIG_OPTION.SFTP]: ({ channel, handlers }) => {
        return (
            <SFTPConfiguration
                config={channel?.sftpConfig}
                onActivate={() => handlers.onCreate('sftp_config')}
                onSubmit={(formData) => handlers.onUpdate({ sftpConfig: formData })}
            />
        );
    },

    [CONFIG_OPTION.Discard]: ({ channel, handlers }) => {
        return (
            <DiscardConfiguration
                config={channel?.discardConfig}
                onActivate={() => handlers.onCreate('discard_config')}
                onSubmit={(formData) => handlers.onUpdate({ discardConfig: formData })}
            />
        );
    },
};

export const SUPPORT_USER_CONFIG_GROUPS: { [key in CONFIG_GROUP]?: CONFIG_OPTION[] } = {
    [CONFIG_GROUP.Other]: [CONFIG_OPTION.ImportExport],
};

export const SUPPORT_USER_CONFIGS: { [key in CONFIG_OPTION]?: any } = {
    [CONFIG_OPTION.ImportExport]: ({ channel, handlers }) => {
        return <ImportExportConfig channel={channel} onImport={handlers.onImport} onExport={handlers.onExport} />;
    },
};

// no dash before numbers
export const customKebabCase = (str) => kebabCase(str).replace(/-(\d+)/g, '$1');
export const customSnakeCase = (str) => snakeCase(str).replace(/_(\d+)/g, '$1');

const useConfigurationHandlers = ({ user, channelId, documentConfiguration, channelRefetch }) => {
    const [createConfig] = useMutation(documentConfiguration.documentQueries.CREATE_CONFIG);
    const handleCreateConfig = (configName) => {
        return createConfig({
            variables: {
                channelId,
                configName,
            },
        }).then(() => channelRefetch());
    };

    const [deleteConfig] = useMutation(documentConfiguration.documentQueries.DELETE_CONFIG);
    const handleDeleteConfig = (configName) => {
        return deleteConfig({
            variables: {
                channelId,
                configName,
            },
        }).then(() => channelRefetch());
    };

    const [importConfigs] = useMutation(documentConfiguration.documentQueries.IMPORT_CONFIGS);
    const handleImportConfigs = (file) => {
        return importConfigs({
            variables: {
                channelId,
                file,
            },
        }).then(() => channelRefetch());
    };

    const [exportConfigs] = useMutation(documentConfiguration.documentQueries.EXPORT_CONFIGS);
    const handleExportConfigs = (configNames: string[]) => {
        return exportConfigs({
            variables: {
                channelId,
                configNames,
            },
        });
    };

    const [updateChannel] = useMutation(documentConfiguration.documentQueries.UPDATE_CHANNEL);
    const handleUpdateChannel = (updateData, { isMetadata = false } = {}) => {
        if (isMetadata) {
            appInsights?.trackEvent(...updateChannelMetadataEvent(user, channelId, documentConfiguration.documentType));
        } else {
            appInsights?.trackEvent(
                ...updateChannelConfigEvent(user, channelId, documentConfiguration.documentType, {
                    configKey: Object.keys(updateData)[0],
                })
            );
        }
        return updateChannel({
            fetchPolicy: 'no-cache',
            variables: {
                channelId,
                ...updateData,
            },
        }).then(() => channelRefetch());
    };

    const [deleteMappings] = useMutation(documentConfiguration.documentQueries.DELETE_MAPPINGS);
    const handleDeleteMappings = (date, mappingType) => {
        return deleteMappings({
            fetchPolicy: 'no-cache',
            variables: {
                channelId,
                beforeDate: date,
                mappingType,
            },
        }).then(() => channelRefetch());
    };

    const [updateDocumentConfig] = useMutation(documentConfiguration?.updateMutation || UPDATE_FIELDS_CONFIG);
    const handleUpdateDocumentConfig = (updateData) => {
        return updateDocumentConfig({
            fetchPolicy: 'no-cache',
            variables: {
                channelId,
                documentType: documentConfiguration.documentTypeName,
                ...updateData,
            },
        }).then(() => channelRefetch());
    };

    return {
        onCreate: handleCreateConfig,
        onDelete: handleDeleteConfig,
        onImport: handleImportConfigs,
        onExport: handleExportConfigs,
        onUpdate: handleUpdateChannel,
        onUpdateDocumentConfig: handleUpdateDocumentConfig,
        onDeleteMappings: handleDeleteMappings,
    };
};

const ConfigNavigationButton = ({ configOption, documentConfiguration, children }) => {
    const { channelId, tab = 'general' } = useParams();

    return (
        <SecondaryNavigation.Button key={configOption} asChild active={tab === customKebabCase(configOption)}>
            <Link to={url(documentConfiguration.configPath, { channelId, tab: customKebabCase(configOption) })}>
                {children}
            </Link>
        </SecondaryNavigation.Button>
    );
};

const ConfigurationNavigation = ({
    configOptionKeys,
    configOptionLabels,
    configGroups,
    channelId,
    documentConfiguration,
    ...props
}: React.ComponentPropsWithRef<'nav'> & {
    configOptionKeys: CONFIG_OPTION[];
    configOptionLabels: string[];
    configGroups: { [key in CONFIG_GROUP]: CONFIG_OPTION[] };
    channelId: string;
    documentConfiguration: any;
}) => {
    const { t } = useTranslation('config');

    return (
        <SecondaryNavigation {...props}>
            <div className="flex flex-col px-4 p-5 gap-1">
                {Object.entries(configGroups).map(([groupName, groupOptions]) => (
                    <React.Fragment key={groupName}>
                        <SecondaryNavigation.SectionLabel>
                            {t(`configPage.navigation.${camelCase(groupName)}Section`)}
                        </SecondaryNavigation.SectionLabel>

                        {groupOptions.map((configOption) => (
                            <ConfigNavigationButton
                                key={configOption}
                                configOption={configOption}
                                documentConfiguration={documentConfiguration}
                            >
                                {configOptionLabels[configOption]}
                            </ConfigNavigationButton>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </SecondaryNavigation>
    );
};

const DocumentConfiguration = ({ user, documentConfiguration }: any) => {
    const { channelId, tab = 'general' } = useParams();
    const { t } = useTranslation('config');

    const {
        data,
        refetch: channelRefetch,
        loading,
        previousData,
    } = useQuery(documentConfiguration.documentQueries.GET_CHANNEL_CONFIG, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: false,
        variables: {
            id: channelId,
        },
    });
    const channel = (loading ? previousData : data)?.[documentConfiguration?.documentTypeName + 'ProcessingChannel'];

    const handlers = useConfigurationHandlers({
        user,
        channelId,
        documentConfiguration,
        channelRefetch,
    });

    let configGroups = documentConfiguration?.configGroups || DEFAULT_CONFIG_GROUPS;

    // Display only the configuration options specified or use the default ones in case no information is given
    let configOptions = documentConfiguration?.configOptions || DEFAULT_CONFIGS;

    if (canSeeSupportUserConfigs(user)) {
        configGroups = merge(configGroups, SUPPORT_USER_CONFIG_GROUPS);
        configOptions = merge(configOptions, SUPPORT_USER_CONFIGS);
    }

    const configOptionKeys = Object.keys(configOptions) as CONFIG_OPTION[];
    const configOptionKey = customSnakeCase(tab).toUpperCase();
    const configOptionLabels = {
        ...Object.fromEntries(
            configOptionKeys.map((configOption) => [
                configOption,
                t(`configPage.navigation.${camelCase(configOption)}`),
            ])
        ),
        ...(documentConfiguration?.configLabels || {}),
    };
    const ConfigComponent = configOptions?.[configOptionKey];

    return (
        <Layout>
            <Page>
                <Page.Header className="flex items-center">
                    <Link
                        className="flex flex-shrink min-w-0 gap-3 rounded transition-colors hover:bg-secondary py-1 px-2 -mx-2 items-center cursor-pointer select-text font-semibold text-base text-secondary hover:text-primary"
                        to={url(documentConfiguration.channelPath, { channelId: channel?.id || '' })}
                    >
                        {channel?.name}
                    </Link>

                    <Page.HeaderTitle className="mx-3 text-xs">
                        <BreadcrumbIcon />
                    </Page.HeaderTitle>

                    <Page.HeaderTitle>{t('configPage.title')}</Page.HeaderTitle>
                </Page.Header>

                <div className="flex flex-1 min-h-0">
                    <ConfigurationNavigation
                        configOptionKeys={configOptionKeys}
                        configOptionLabels={configOptionLabels}
                        configGroups={configGroups}
                        channelId={channelId}
                        documentConfiguration={documentConfiguration}
                    />

                    <Page.Content lowered>
                        {ConfigComponent && (
                            <ConfigComponent
                                user={user}
                                channel={channel}
                                channelId={channelId}
                                documentConfiguration={documentConfiguration}
                                handlers={handlers}
                                channelRefetch={channelRefetch}
                            />
                        )}
                    </Page.Content>
                </div>
            </Page>
        </Layout>
    );
};

export default DocumentConfiguration;
