import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import { useTranslation } from 'react-i18next';
import SelectField from '../../core/components/Fields/SelectField.tsx';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation } from '@apollo/client';
import { UPDATE_PROFILE } from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import { useApplicationContext } from '../../core/contexts/ApplicationContext.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

const NOTIFICATIONS_FORM_DEFAULT_VALUES = {
    sendEmailNotification: 'true',
};

const NotificationsPage = () => {
    const { t } = useTranslation('users');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: NOTIFICATIONS_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const { user } = useApplicationContext();
    useEffect(() => {
        if (user) {
            form.reset({
                ...NOTIFICATIONS_FORM_DEFAULT_VALUES,
                sendEmailNotification: user.sendEmailNotification ? 'true' : 'false',
            });
        }
    }, [user]);

    const [updateProfile] = useMutation(UPDATE_PROFILE);
    const handleUpdateProfile = async (formData) => {
        try {
            await updateProfile({
                variables: {
                    id: user.id,
                    sendEmailNotification: formData.sendEmailNotification === 'true',
                },
            });
            publishToast({ description: t('notifications.form.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('notifications.form.updateError'), status: 'error' });
        }
    };

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('notifications.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <SettingsFormCard onSubmit={form.handleSubmit(handleUpdateProfile)}>
                        <Controller
                            name="sendEmailNotification"
                            control={form.control}
                            rules={{ required: t('notifications.form.sendEmailNotificationRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('notifications.form.sendEmailNotification')}
                                    required={true}
                                    error={formErrors.sendEmailNotification}
                                >
                                    <SelectField
                                        {...field}
                                        options={[
                                            {
                                                value: 'true',
                                                label: t('notifications.form.sendEmailNotificationOptions.yes'),
                                            },
                                            {
                                                value: 'false',
                                                label: t('notifications.form.sendEmailNotificationOptions.no'),
                                            },
                                        ]}
                                        renderOption={(option) => option.label}
                                        required
                                    />
                                </FormField>
                            )}
                        />

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('notifications.form.saveButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default NotificationsPage;
