import * as React from 'react';
import { highlight, languages } from 'prismjs/components/prism-core';
import Editor from 'react-simple-code-editor';
import classnames from '../../core/utils/classnames.tsx';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

const DataViewer = ({ data, className, language = languages.json, ...props }: any) => {
    const parsed = data ? JSON.stringify(data, null, 2) : '{}';
    return (
        <Editor
            className={classnames('leading-5 font-mono text-sm', className)}
            value={parsed}
            onValueChange={(data: any) => {}}
            highlight={(code: any) =>
                highlight(code, language)
                    .split('\n')
                    .map(
                        (line, i) =>
                            `<span class="inline-block !pl-[2.5rem] relative leading-5"><span class="absolute left-0 text-sm opacity-30 leading-5">${i}</span><span>${line}</span></span>`
                    )
                    .join('\n')
            }
            disabled={true}
            {...props}
        />
    );
};

export default DataViewer;
