import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE, LANGUAGE_OPTIONS } from '../../../constants.ts';
import SelectField from '../../core/components/Fields/SelectField.tsx';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation } from '@apollo/client';
import { UPDATE_PROFILE } from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import { useApplicationContext } from '../../core/contexts/ApplicationContext.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import i18n from 'i18next';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';
import { defaultRenderOption } from '../../core/components/Fields/AutoCompleteField.tsx';
import Tooltip from '../../core/components/Tooltip.tsx';

const PROFILE_FORM_DEFAULT_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    language: DEFAULT_LANGUAGE,
};

const ProfilePage = () => {
    const { t, i18n } = useTranslation('users');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: PROFILE_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const { user, refetchUser } = useApplicationContext();
    useEffect(() => {
        if (user) {
            form.reset({
                ...PROFILE_FORM_DEFAULT_VALUES,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                language: user.language || DEFAULT_LANGUAGE,
            });
        }
    }, [user]);

    const [updateProfile] = useMutation(UPDATE_PROFILE);
    const handleUpdateProfile = async (formData) => {
        try {
            await updateProfile({
                variables: {
                    id: user.id,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    language: formData.language,
                },
            });
            await refetchUser();

            // update the application language
            void i18n.changeLanguage(formData.language.toLowerCase());

            publishToast({ description: t('profile.form.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('profile.form.updateError'), status: 'error' });
        }
    };

    const displayLanguageOptions = LANGUAGE_OPTIONS.map((option) => ({
        ...option,
        disabled: !(i18n.options.supportedLngs || []).includes(option.value),
    }));

    console.log(i18n.options.supportedLngs);

    const renderLanguageOption = useCallback((option) => {
        const rendered = defaultRenderOption(option);
        if (!option.disabled) return rendered;
        return (
            <Tooltip content={t('profile.form.languageNotAvailable')} long>
                <div className="w-full">{rendered}</div>
            </Tooltip>
        );
    }, []);

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('profile.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <SettingsFormCard onSubmit={form.handleSubmit(handleUpdateProfile)}>
                        <Controller
                            name="firstName"
                            control={form.control}
                            rules={{ required: t('profile.form.firstNameRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('profile.form.firstName')}
                                    required={true}
                                    error={formErrors.firstName}
                                >
                                    <StringField required={true} {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="lastName"
                            control={form.control}
                            rules={{ required: t('profile.form.lastNameRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('profile.form.lastName')}
                                    required={true}
                                    error={formErrors.lastName}
                                >
                                    <StringField required={true} {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="email"
                            control={form.control}
                            rules={{ required: t('profile.form.emailRequired') }}
                            render={({ field }) => (
                                <FormField label={t('profile.form.email')} required={true} error={formErrors.email}>
                                    <StringField required={true} {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="language"
                            control={form.control}
                            rules={{ required: t('profile.form.languageRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('profile.form.language')}
                                    required={true}
                                    error={formErrors.language}
                                >
                                    <SelectField
                                        {...field}
                                        options={displayLanguageOptions}
                                        required
                                        renderOption={renderLanguageOption}
                                    />
                                </FormField>
                            )}
                        />

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('profile.form.saveButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default ProfilePage;
