import * as React from 'react';
import classnames from '../../utils/classnames.tsx';
import resolveChildren from '../../utils/resolveChildren.tsx';

interface FieldContextValue {
    disabled?: boolean;
    readOnly?: boolean;
}

const FieldContext = React.createContext<FieldContextValue>({});

export interface FieldProps {
    ref?: React.Ref<any>;
    defaultValue?: string;
    value?: string;
    onValueChange?: (value: any) => void;
    controls?: React.ReactNode;
    inputRef?: React.Ref<any>;
}

const Field = ({ className, readOnly, disabled, ...props }: any) => (
    <FieldContext.Provider value={{ readOnly, disabled }}>
        <div
            className={classnames(
                'flex w-full shadow-inner-sm border border-solid outline-transparent border-secondary rounded bg-primary text-sm text-primary transition-colors duration-200',
                !readOnly &&
                    'hover:border-tertiary focus-within:border-tertiary focus-within:!outline focus-within:outline-primary focus-within:outline-2',
                disabled && 'bg-secondary shadow-none text-primary',
                className
            )}
            {...props}
        />
    </FieldContext.Provider>
);

const FieldInput = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div className={classnames('flex flex-1 rounded overflow-hidden', className)} {...props} />
);

const FieldControls = ({ className, children, ...props }: any) => {
    const resolvedChildren = resolveChildren(children);
    const childrenWithSeparators = resolvedChildren
        .flatMap((x, i) => [<span key={i} className="border-r border-solid border-primary h-4" />, x])
        .slice(1);

    return (
        <div
            className={classnames('flex-none box-content top-0 right-0 flex items-center p-0.5 gap-0.5', className)}
            {...props}
        >
            {childrenWithSeparators}
        </div>
    );
};

const FieldControlButton = ({
    className,
    active,
    ...props
}: React.ComponentPropsWithoutRef<'button'> & { active?: boolean }) => {
    const { disabled } = React.useContext(FieldContext);
    return (
        <button
            type="button"
            className={classnames(
                'flex rounded justify-center items-center box-content min-w-7 min-h-7 text-sm text-tertiary transition-all duration-200 focus:opacity-100', // opacity-50 hover:opacity-100
                !disabled && 'hover:bg-secondary hover:text-secondary',
                disabled && '!text-tertiary',
                active && 'bg-brand text-brand',
                className
            )}
            tabIndex={-1}
            {...props}
        />
    );
};

export default Object.assign(Field, {
    Input: FieldInput,
    Controls: FieldControls,
    ControlButton: FieldControlButton,
});
