import ConfirmModal from '../../../../core/components/ConfirmModal.tsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentType, mapDocumentTypeToGqlDocumentType } from '../../../constants.ts';
import { useToaster } from '../../../../core/components/Toast.tsx';
import { useMutation } from '@apollo/client';
import { DELETE_DOCUMENTS } from '../../../queries.ts';

type DeleteDocumentsModalProps = {
    selectedRowKeys: string[];
    documentType: DocumentType;
    onClose: () => void;
    onSuccess: () => void;
    refetch?: () => Promise<unknown>;
};

const DeleteDocumentsModal: React.FC<DeleteDocumentsModalProps> = ({
    selectedRowKeys,
    documentType,
    onClose,
    onSuccess,
    refetch,
}) => {
    const { t } = useTranslation('assistance');
    const { publishToast } = useToaster();

    const [deleteDocuments] = useMutation(DELETE_DOCUMENTS);

    const onSubmit = async () => {
        try {
            await deleteDocuments({
                variables: {
                    recordIds: selectedRowKeys,
                    documentType: mapDocumentTypeToGqlDocumentType[documentType],
                },
            });

            await refetch?.();

            onSuccess();
            onClose();

            publishToast({
                description: t('deleteModal.deleteSuccess', { count: selectedRowKeys.length }),
                status: 'success',
            });
        } catch (e) {
            console.error(e);

            publishToast({ description: t('deleteModal.deleteError'), status: 'error' });
        }
    };

    return (
        <ConfirmModal
            title={t('deleteModal.title', { count: selectedRowKeys.length })}
            labelConfirm={t('deleteModal.confirmButton', { count: selectedRowKeys.length })}
            labelCancel={t('deleteModal.cancelButton')}
            onConfirm={onSubmit}
            onCancel={onClose}
            visible
            danger
        >
            <p>{t('deleteModal.message', { count: selectedRowKeys.length })}</p>
        </ConfirmModal>
    );
};

export default DeleteDocumentsModal;
