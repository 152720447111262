import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useToaster } from '../../core/components/Toast.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import FormField from '../../core/components/FormField.tsx';
import Button from '../../core/components/Button.tsx';
import IntegerField from '../../core/components/Fields/IntegerField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import BooleanField from '../../core/components/Fields/BooleanField.tsx';

const FORM_DEFAULT_VALUES = {
    enabled: false,
    inboxName: '',
    allowSentByMatchByName: false,
    enableEmailOnlyDocumentProcessing: false,
    enableEmailThreadTruncating: false,
    keepNTopmostEmailThreads: null,
};

const EmailConfig = ({ config, onActivate, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            enabled: config?.enabled,
            inboxName: config?.inboxName || '',
            allowSentByMatchByName: config?.allowSentByMatchByName,
            enableEmailOnlyDocumentProcessing: config?.enableEmailOnlyDocumentProcessing,
            enableEmailThreadTruncating: config?.enableEmailThreadTruncating,
            keepNTopmostEmailThreads: config?.keepNTopmostEmailThreads || null,
        });
    }, [config]);

    const handleSubmit = async (formData) => {
        try {
            if (!config) await onActivate();
            await onSubmit(formData);
            publishToast({ description: t('configPage.email.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.email.updateError'), status: 'error' });
        }
    };

    return (
        <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.email.title')}>
            <Controller
                name="enabled"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.email.form.enabled')} error={formErrors.enabled}>
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="inboxName"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.email.form.inboxName')} error={formErrors.inboxName}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="allowSentByMatchByName"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.email.form.allowSentByMatchByName')}
                        error={formErrors.allowSentByMatchByName}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="enableEmailOnlyDocumentProcessing"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.email.form.enableEmailOnlyDocumentProcessing')}
                        error={formErrors.enableEmailOnlyDocumentProcessing}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="enableEmailThreadTruncating"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.email.form.enableEmailThreadTruncating')}
                        error={formErrors.enableEmailThreadTruncating}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="keepNTopmostEmailThreads"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.email.form.keepNTopmostEmailThreads')}
                        error={formErrors.keepNTopmostEmailThreads}
                    >
                        <IntegerField {...field} />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button type="submit" variant="primary">
                    {t('configPage.email.form.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};

export default EmailConfig;
