import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export enum ERROR_RESOLUTIONS {
    Retry = 'retry',
    Discard = 'discard',
    Assistance = 'assistance',
}

/**
 * Configuration for default flows defined in the backend
 * Allows customization of frontend functionality for individual steps & record-specific flows.
 *
 * Note: If there is no config defined for a specific step, the `default` configuration is used.
 */
const flowsStepRunConfig = {
    default: {
        canRetry: true,
        canMarkAsCompleted: false,
        showInHistory: true,
        errorLocaleKey: 'header.alerts.executionFailed',
    },

    // - Record-specific flows

    OrderProcessingRecord: {
        import_to_target_system: {
            canRetry: false,
            canMarkAsCompleted: true,
            showInHistory: false,
            errorLocaleKey: 'header.alerts.importError',
        },
    },
};

/**
 * Resolves a step configuration for the given step and record type
 *
 * @param record - the record the flow is defined for
 * @param stepRun - the step in the record-specific flow
 */
const getSteprunConfig = (record, stepRun) => {
    let config = flowsStepRunConfig[record.__typename];
    if (config != null) {
        config = config[stepRun?.stepName];
    }
    return config || flowsStepRunConfig.default;
};

const getErrorResolution = (errorType) => {
    switch (errorType) {
        case 'PDFPageCountError':
        case 'MaxPageCountExceededError':
        case 'MaxColumnRowCountExceededError':
        case 'MimeTypeNotSupportedError':
        case 'NoDocumentGroupFoundError':
        case 'SortedOutError':
            return ERROR_RESOLUTIONS.Discard;
        case 'ValueError':
        case 'SoftTimeLimitExceeded':
        case 'TimeLimitExceeded':
        case 'MaxRetriesError':
        case 'InvalidGrantError':
            return ERROR_RESOLUTIONS.Retry;
        case 'OutputError':
        case 'GenerateOutputError':
            return ERROR_RESOLUTIONS.Assistance;
        default:
            return null;
    }
};

/**
 * Helper to be used in components that render data or actions related to flows
 *
 * @param record - the record the flow is defined for
 * @param stepRun - the step in the record-specific flow
 */
export const useSteprunInfo = (record, stepRun) => {
    const { t, i18n } = useTranslation('assistance');

    stepRun ??= record?.stepRun;
    if (record == null || stepRun == null) {
        return {
            steprunConfig: flowsStepRunConfig.default,
            steprunError: null,
        };
    }

    // Resolve record- & step-specific configuration
    const steprunConfig = getSteprunConfig(record, stepRun);
    let steprunError = {};

    const parsedError = stepRun.error != null ? JSON.parse(stepRun.error!) : null;
    const parsedContext = stepRun.context != null ? JSON.parse(stepRun.context!) : null;

    if (parsedContext && !isEmpty(parsedContext) && parsedContext?.error_type != null) {
        const errorType = parsedContext.error_type;
        const errorDetails: any = parsedContext.error_details;

        let errorTitle = t(steprunConfig.errorLocaleKey + '.title');
        let errorMessage: any = t(steprunConfig.errorLocaleKey + '.message');

        if (i18n.exists(`assistance:header.alerts.${errorType}`)) {
            errorTitle = t(`header.alerts.${errorType}.title`);
            errorMessage = t(`header.alerts.${errorType}.message`, errorDetails);
        }

        steprunError = {
            title: errorTitle,
            message: errorMessage,
            tooltip: errorTitle,
            resolution: getErrorResolution(errorType),
        };
    }

    if (parsedError && !isEmpty(parsedError)) {
        const errorType = parsedError.type;
        const errorDetails = parsedError.details;

        let errorTitle = t(steprunConfig.errorLocaleKey + '.title');
        let errorMessage: any = t(steprunConfig.errorLocaleKey + '.message');

        if (i18n.exists(`assistance:header.alerts.${errorType}`)) {
            errorTitle = t(`header.alerts.${errorType}.title`);
            errorMessage = t(`header.alerts.${errorType}.message`, errorDetails);
        }

        steprunError = {
            title: errorTitle,
            message: errorMessage,
            tooltip: errorTitle,
            resolution: getErrorResolution(errorType),
        };
    }

    const executionStatus = stepRun?.executionStatus;

    return { steprunConfig, steprunError, executionStatus };
};
