import { gql } from '@apollo/client';

export const GET_USERS = gql`
    query Users($from: Int, $size: Int, $sortField: String, $sortOrder: String, $groupId: String, $teamId: String) {
        users(
            From: $from
            size: $size
            sortField: $sortField
            sortOrder: $sortOrder
            groupId: $groupId
            teamId: $teamId
        ) {
            users {
                id
                email
                firstName
                lastName
                groups {
                    id
                    name
                }
                teams {
                    id
                    name
                    isDefault
                }
            }
            totalCount
        }
    }
`;

export const GET_USER_EMAIL_ADDRESSES = gql`
    query Users($from: Int, $size: Int, $groupId: String, $teamId: String) {
        users(From: $from, size: $size, groupId: $groupId, teamId: $teamId) {
            users {
                email
            }
        }
    }
`;

export const GET_USER = gql`
    query User($id: String!) {
        user(id: $id) {
            id
            email
            firstName
            lastName
            sendEmailNotification
            language
            showExplanationsByDefault
            groups {
                id
                name
            }
            teams {
                id
                name
            }
        }
    }
`;

export const INVITE_USER = gql`
    mutation InviteUser(
        $email: String!
        $password1: String
        $password2: String
        $firstName: String!
        $lastName: String!
        $language: String
        $groups: [String]
        $teams: [String]
    ) {
        inviteUser(
            email: $email
            password1: $password1
            password2: $password2
            firstName: $firstName
            lastName: $lastName
            language: $language
            groups: $groups
            teams: $teams
        ) {
            success
        }
    }
`;

export const UPDATE_USER = gql`
    mutation UpdateUser(
        $id: String!
        $email: String!
        $firstName: String!
        $lastName: String!
        $language: String!
        $groups: [String]
        $teams: [String]
    ) {
        updateUser(
            id: $id
            email: $email
            firstName: $firstName
            lastName: $lastName
            language: $language
            groups: $groups
            teams: $teams
        ) {
            success
        }
    }
`;

export const DELETE_USER = gql`
    mutation DeleteUser($id: String!) {
        deleteUser(id: $id) {
            success
        }
    }
`;

export const UPDATE_PROFILE = gql`
    mutation UpdateUserProfile(
        $id: String!
        $sendEmailNotification: Boolean
        $firstName: String
        $lastName: String
        $language: String
        $showExplanationsByDefault: Boolean
    ) {
        updateUserProfile(
            id: $id
            sendEmailNotification: $sendEmailNotification
            firstName: $firstName
            lastName: $lastName
            language: $language
            showExplanationsByDefault: $showExplanationsByDefault
        ) {
            success
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
        changePassword(oldPassword: $oldPassword, newPassword1: $newPassword1, newPassword2: $newPassword2) {
            success
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
        resetPassword(email: $email) {
            done
        }
    }
`;

export const GET_ROLES = gql`
    query {
        groups {
            id
            name
        }
    }
`;

export const GET_TEAMS = gql`
    query {
        teams {
            id
            name
            description
            users {
                id
                email
                firstName
                lastName
            }
            isDefault
        }
    }
`;
