import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState.tsx';
import Field, { FieldProps } from './Field.tsx';

export interface StringFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {}

const StringField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onChange,
    controls,
    className,
    readOnly,
    disabled,
    inputRef = undefined,
    ...props
}: StringFieldProps) => {
    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

    const handleChange = (e: any) => {
        setValue(e.target.value);
        onChange?.(e);
    };

    return (
        <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
            <Field.Input>
                <input
                    type="text"
                    value={value || ''}
                    onChange={handleChange}
                    className="px-2 py-1.5 flex-1 min-w-0 bg-transparent outline-none"
                    readOnly={readOnly}
                    disabled={disabled}
                    {...props}
                    ref={inputRef}
                />
            </Field.Input>
            <Field.Controls>{controls}</Field.Controls>
        </Field>
    );
};

export default StringField;
