import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_CONFIG_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_MASTERDATA_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from './constants.ts';
import Configuration from './pages/Configuration.tsx';
import Overview from './pages/Overview.tsx';
import PropertyBillAssistance from './pages/Assistance.tsx';

import { DocumentType } from '../../document/constants.ts';
import { buildRoutes } from '../../document/routes.tsx';

export default buildRoutes({
    paths: {
        allDocuments: OVERVIEW_PATH,
        allDocumentsFinished: OVERVIEW_FINISHED_PATH,
        allDocumentsTesting: OVERVIEW_TESTING_PATH,
        channelDocuments: CHANNEL_PATH,
        channelDocumentsFinished: CHANNEL_FINISHED_PATH,
        channelDocumentsTesting: CHANNEL_TESTING_PATH,
        assistance: ASSISTANCE_PATH,
        assistanceTab: ASSISTANCE_TAB_PATH,
        channelConfig: CHANNEL_CONFIG_PATH,
        channelMasterdata: CHANNEL_MASTERDATA_PATH,
    },
    pageComponents: {
        Overview,
        Configuration,
        Assistance: PropertyBillAssistance,
    },
    documentType: DocumentType.PropertyBill,
    documentTypeNavTabKey: 'property-bill',
    documentTypeEnabled: (customer) => customer?.isPropertyBillsEnabled,
});
