import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button.tsx';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    const { t } = useTranslation('errors');

    return (
        <div className="w-full h-screen flex items-center justify-center">
            <div className="w-[30rem] text-center flex flex-col gap-6">
                <h2 className="text-primary text-xl font-medium">{t('notFound.title')}</h2>
                <p className="text-secondary text-base">{t('notFound.text')}</p>
                <div>
                    <Button asChild>
                        <Link to="/">{t('notFound.button')}</Link>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
