import { generatePath } from 'react-router-dom';
import { Params } from 'react-router';

interface IOptions {
    search?: Record<string, string> | string | undefined;
    keepSearch?: boolean;
}

export const url = (path: string, params?: Params, options: IOptions = { keepSearch: false, search: undefined }) => {
    /*
    Replace variables in paths like:
    url('/orders/:recordId', { recordId: 123 }) -> '/orders/123'
    */
    const compiledPath = generatePath(path, params);

    const searchParams = new URLSearchParams(options.search);
    if (options.keepSearch) {
        const currentSearchParams = new URLSearchParams(window.location.search);
        currentSearchParams.forEach((value, key) => {
            searchParams.set(key, value);
        });
    }

    const queryString = searchParams.toString();
    return queryString ? `${compiledPath}?${queryString}` : compiledPath;
};
