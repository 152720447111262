import Pagination from './Pagination.tsx';
import SelectField from './Fields/SelectField.tsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const TablePagination = ({
    totalCount,
    activePage,
    itemsPerPage,
    setActivePage,
    setItemsPerPage,
    maxItems = Infinity,
}: {
    totalCount: number;
    activePage: number;
    itemsPerPage: number;
    setActivePage: (page: number) => void;
    setItemsPerPage: (itemsPerPage: number) => void;
    maxItems?: number;
}) => {
    const { t } = useTranslation('core');

    return (
        <div className="flex justify-between">
            <span className="text-sm text-secondary">
                {Math.min(totalCount, maxItems, (activePage - 1) * itemsPerPage + 1)} -{' '}
                {Math.min(totalCount, maxItems, activePage * itemsPerPage)} of {totalCount}
            </span>

            <Pagination
                numItems={totalCount > maxItems ? maxItems + itemsPerPage : totalCount}
                itemsPerPage={itemsPerPage}
                activePage={activePage - 1}
                onChange={(page) => setActivePage(page + 1)}
            />

            <SelectField
                required
                className="w-36"
                name="pageSize"
                value={itemsPerPage.toString()}
                options={[
                    { label: '10', value: '10' },
                    { label: '20', value: '20' },
                    { label: '50', value: '50' },
                    { label: '100', value: '100' },
                ]}
                renderValue={(value) => t('components.pageSizeSelect.option', { value })}
                onValueChange={(value) => setItemsPerPage(parseInt(value))}
            />
        </div>
    );
};

export default TablePagination;
