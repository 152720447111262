import * as React from 'react';
import * as SwitchPrimitive from '@radix-ui/react-switch';

const Switch = ({ value = undefined, onValueChange = undefined, ...switchProps }) => {
    return (
        <div className="relative overflow-hidden">
            <SwitchPrimitive.Root
                className="flex items-center justify-between cursor-pointer w-10 h-[1.375rem] bg-secondary rounded-full relative transition-colors border-none data-[state='checked']:bg-brand-focus data-[disabled]:opacity-50"
                checked={value}
                onCheckedChange={onValueChange}
                {...switchProps}
            >
                <SwitchPrimitive.Thumb className="absolute top-0.5 left-0.5 w-[1.125rem] h-[1.125rem] rounded-full bg-primary shadow-sm data-[state='checked']:left-[calc(100%-1.25rem)] data-[state='checked']:transform-[translateX(-100%)] transition-all" />
            </SwitchPrimitive.Root>
        </div>
    );
};

export default Switch;
