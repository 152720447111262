import { getCopyrightUrl, getImprintUrl, getPrivacyUrl } from '../../support/constants.ts';
import * as React from 'react';
import classnames from '../../core/utils/classnames.tsx';

const AuthPageFooter = ({ className, children, ...props }: React.ComponentPropsWithRef<'footer'>) => {
    const languageCode = navigator.language.split('-')[0] === 'de' ? 'de' : 'en';
    const currentYear = new Date().getFullYear();

    return (
        <footer
            className={classnames(
                'absolute bottom-0 left-0 right-0 p-8 flex justify-between text-sm pointer-events-none',
                className
            )}
            {...props}
        >
            <a
                href={getCopyrightUrl(languageCode)}
                className="pointer-events-auto text-brand-default hover:text-brand-hover"
            >
                &copy; {currentYear} workist &trade;
            </a>
            <span className="pointer-events-auto">
                <a href={getImprintUrl(languageCode)} className="mx-2.5 text-brand-default hover:text-brand-hover">
                    Impressum
                </a>
                <a href={getPrivacyUrl(languageCode)} className="mx-2.5 text-brand-default hover:text-brand-hover">
                    Datenschutz
                </a>
            </span>
        </footer>
    );
};

const AuthPageCard = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div
            className={classnames(
                'w-[24rem] bg-primary rounded-lg p-8 border border-secondary border-solid flex flex-col gap-6',
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
};

const AuthPageSuccess = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div className={classnames('text-success bg-success text-sm px-4 py-2.5 rounded', className)} {...props}>
            {children}
        </div>
    );
};

const AuthPageError = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div className={classnames('text-error bg-error text-sm px-4 py-2.5 rounded', className)} {...props}>
            {children}
        </div>
    );
};

const AuthPageTitle = ({ className, children, ...props }: React.ComponentPropsWithRef<'h1'>) => {
    return (
        <h1 className={classnames('text-xl font-semibold text-primary', className)} {...props}>
            {children}
        </h1>
    );
};

const AuthPageContent = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div className={classnames('flex flex-col gap-8 m-auto py-12 px-10', className)} {...props}>
            {children}
        </div>
    );
};

const AuthPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div
            className={classnames(
                'w-full min-h-screen flex items-center justify-center bg-secondary-light overflow-y-auto',
                className
            )}
            {...props}
        />
    );
};

export default Object.assign(AuthPage, {
    Content: AuthPageContent,
    Card: AuthPageCard,
    Title: AuthPageTitle,
    Error: AuthPageError,
    Success: AuthPageSuccess,
    Footer: AuthPageFooter,
});
