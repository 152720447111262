import * as React from 'react';
import classnames from '../../core/utils/classnames.tsx';

interface ChartTooltipProps {
    content: string | React.ReactNode;
    className?: string;
}

const ChartTooltip = ({ content, className }: ChartTooltipProps) => {
    return (
        <div className={classnames('bg-inverted text-inverted py-1.5 px-3 rounded shadow-lg text-sm', className)}>
            {content}
        </div>
    );
};

export default ChartTooltip;
