export const AUTO_UPDATE_INTERVAL = 20000;
export const AUTO_UPDATE_PENDING_INTERVAL = 1000;
export const HOME_PATH = '/';

export const TOAST_DURATION = 3000;

export const LANGUAGE_OPTIONS = [
    { value: 'de', label: 'Deutsch' },
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
    { value: 'pl', label: 'Polski' },
    { value: 'ja', label: '日本語 (Japanese)' },
    { value: 'ko', label: '한국어 (Korean)' },
];
export const DEFAULT_LANGUAGE = 'de';
