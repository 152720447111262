import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// COPIED FROM ML-STUDIO

const GROUP_DELIMITER = /(?:[^\s"]+|"[^"]*")+/g;
const PAIR_DELIMITER = /(?:(-?)([a-z_]+?):)?(.+)/;

const COMPARISON_VALUE = /^(<|<=|>|>=)(.+)$/;
const RANGE_VALUE = /^(.+)\.\.(.+)$/;

export const SEARCH_FILTER_KEY = 'search';
export const ORDER_BY_FILTER_KEY = 'order_by';
export const ORDER_BY_FILTER_DEFAULT_VALUE = '-created_at';

// following https://datatracker.ietf.org/doc/html/rfc7644#section-3.4.2.2
const EXPRESSION_MAPPING = {
    '<': 'lt',
    '<=': 'le',
    '>': 'gt',
    '>=': 'ge',
};

const splitPair = (str) => {
    const [match, prefix, name, value] = str.match(PAIR_DELIMITER) || [undefined, undefined, undefined, undefined];
    return {
        name,
        value, //: value.replace(/['"]+/g, ''),  // remove quotation marks
        exclude: prefix == '-',
        expression: undefined,
    };
};

const removeQuotes = (value) => value.replace(/"+/g, '');

const parseRange = (value) => {
    value = removeQuotes(value);
    const [match, rangeFrom, rangeTo] = value.match(RANGE_VALUE) || [undefined, undefined, undefined];
    return match
        ? [
              ['ge', [rangeFrom]],
              ['le', [rangeTo]],
          ]
        : [];
};

const parseComparison = (value) => {
    value = removeQuotes(value);
    const [match, expression, num] = value.match(COMPARISON_VALUE) || [undefined, undefined, undefined];
    return match ? [[EXPRESSION_MAPPING[expression], [num]]] : [];
};

const parseEqual = (value) => {
    // split by comma but prevent commas inside of quotes
    const values = value.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/).map((v) => removeQuotes(v));
    return [['eq', values]];
};

export const parseFilterQuery = (query, searchField = SEARCH_FILTER_KEY, validators = undefined) => {
    const groups = query.match(GROUP_DELIMITER) || [];
    const pairs = groups.map(splitPair);

    const parsedPairs = [];
    const searchTerms = pairs.filter((pair) => !pair.name).map((pair) => pair.value);

    for (const pair of pairs.filter((pair) => pair.name)) {
        // validators: object where key is pairName and value a method that validates one value e.g. date, number, ...
        const validator = validators?.[pair.name];
        const isRange = pair.value?.includes('..');
        const isComparison = Object.keys(EXPRESSION_MAPPING).some((key) => pair.value?.startsWith(key));

        let parsedValues;
        if (isRange) parsedValues = parseRange(pair.value);
        else if (isComparison) parsedValues = parseComparison(pair.value);
        else parsedValues = parseEqual(pair.value);

        // range returns multiple values - we want to flatten them
        // into separate pairs as we AND pairs together anyway
        for (const [expression, value] of parsedValues) {
            if (!validator || validator(value)) {
                parsedPairs.push({ ...pair, expression, value });
            }
        }
    }

    const searchTerm = searchTerms.join(' ');
    if (searchTerm && searchField) {
        parsedPairs.push({ name: searchField, value: [searchTerm], expression: 'eq', exclude: false });
    }

    return parsedPairs;
};

const buildRange = (pairs) => {
    return `${pairs[0].exclude ? '-' : ''}${pairs[0].name}:${pairs[0].value?.[0]}..${pairs[1].value?.[0]}`;
};

const buildComparison = (pair) => {
    const expression = Object.keys(EXPRESSION_MAPPING).find((key) => EXPRESSION_MAPPING[key] === pair.expression);
    return `${pair.exclude ? '-' : ''}${pair.name}:${expression}${pair.value?.[0]}`;
};

const buildEqual = (pair) => {
    let values = Array.isArray(pair.value) ? pair.value : [pair.value];
    values = values.map((value) => (value.includes(' ') ? `"${value}"` : value));
    return `${pair.exclude ? '-' : ''}${pair.name}:${values.join(',')}`;
};

export const groupBy = (xs, key) =>
    xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});

export const buildFilterQuery = (pairs, searchField = SEARCH_FILTER_KEY) => {
    // pairs like {expression: "", name: "", value: "", exclude: ""}
    const stringParts = [];

    const searchPair = pairs.find((p) => p.name === searchField);
    if (searchPair) {
        stringParts.push(searchPair.value);
    }
    const groups = groupBy(
        pairs.filter((p) => p.name !== searchField),
        'name'
    );

    for (const group of Object.values(groups)) {
        const isRange = (group as any).length > 1;
        const isComparison = Object.values(EXPRESSION_MAPPING).includes(group[0].expression);

        if (isRange) stringParts.push(buildRange(group));
        else if (isComparison) stringParts.push(buildComparison(group[0]));
        else stringParts.push(buildEqual(group[0]));
    }

    return stringParts.join(' ');
};

export interface IFilter {
    name: string;
    value: any;
    expression: string;
    exclude?: boolean;
}

export const USER_FILTER_URL_PARAM_KEY = 'query';

export const getUserFilterStorageKey = (documentTypeName: string, channelId: string = 'all', tabKey: string = 'open') =>
    `${documentTypeName}-${channelId}-${tabKey}`;

export const getUserFilterStorageKeyFromRecord = (documentTypeName: string, record?: any) => {
    const channelId = record?.channel?.id;
    const testing = record?.isTestDocument || false;
    const finished = record?.stepRun?.executionStatus === 'SUCCEEDED' || record?.deletedAt;
    const tabKey = testing ? 'testing' : finished ? 'finished' : 'open';
    return record ? getUserFilterStorageKey(documentTypeName, channelId, tabKey) : undefined;
};

export const useUserFilters = ({
    storageKey,
    shouldReadSearchParams = true,
    shouldReadLocalStorage = true,
    shouldWriteSearchParams = false,
    shouldWriteLocalStorage = false,
}: {
    storageKey: string;
    shouldReadSearchParams?: boolean;
    shouldReadLocalStorage?: boolean;
    shouldWriteSearchParams?: boolean;
    shouldWriteLocalStorage?: boolean;
}): [IFilter[], (filters: IFilter[]) => void] => {
    const [userFilters, _setUserFilters] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const setSearchParamFilters = (filters: IFilter[]) => {
        if (!shouldWriteSearchParams) return;

        const filtersString = buildFilterQuery(filters);
        setSearchParams((prev) => {
            if (filtersString) {
                prev.set(USER_FILTER_URL_PARAM_KEY, filtersString);
            } else {
                prev.delete(USER_FILTER_URL_PARAM_KEY);
            }
            return prev;
        });
    };

    const setLocalStorageFilters = (filters: IFilter[]) => {
        if (!shouldWriteLocalStorage || !storageKey) return;

        localStorage.setItem(storageKey, JSON.stringify(filters));
    };

    useEffect(() => {
        const searchParamFilters: IFilter[] = shouldReadSearchParams
            ? parseFilterQuery(searchParams.get(USER_FILTER_URL_PARAM_KEY) || '')
            : [];

        const localStorageFilters: IFilter[] =
            shouldReadLocalStorage && storageKey ? JSON.parse(localStorage.getItem(storageKey) || '[]') : [];

        // 1. open url without search params (without local storage) -> no filters
        if (searchParamFilters.length === 0 && localStorageFilters.length === 0) {
            _setUserFilters([]);
        }

        // 2. open url without search params (with local storage) -> take filters from local storage, adjust url
        else if (searchParamFilters.length === 0 && localStorageFilters.length > 0) {
            _setUserFilters(localStorageFilters);
            setSearchParamFilters(localStorageFilters);
        }

        // 3. open url with search params (without local storage) -> take filters from url, adjust local storage
        // 4. open url with search params (with local storage) -> take filters from url, adjust local storage
        else if (searchParamFilters.length > 0) {
            _setUserFilters(searchParamFilters);
            setLocalStorageFilters(searchParamFilters);
        }
    }, [storageKey, shouldReadLocalStorage, shouldReadSearchParams, shouldWriteSearchParams, shouldWriteLocalStorage]);

    const setUserFilters = (filters: IFilter[]) => {
        _setUserFilters(filters);
        setSearchParamFilters(filters); // update the url with the new filters
        setLocalStorageFilters(filters); // update the local storage with the new filters
    };

    return [userFilters, setUserFilters];
};

export const useOverviewFilters = ({ userFilters, channelId, finished, testing }) => {
    const overviewFilters = [
        // always filter testing documents except if testing is false
        {
            name: 'is_test_document',
            value: 'true',
            expression: 'eq',
            exclude: !(testing || false),
        },
    ];

    // only filter by finished tasks if not testing as testing should show all tasks
    if (testing === false) {
        overviewFilters.push({
            name: 'finished',
            value: 'true',
            expression: 'eq',
            exclude: !finished,
        });
    }

    if (channelId != null) {
        overviewFilters.push({
            name: 'channel_id',
            value: channelId,
            expression: 'eq',
            exclude: false,
        });
    }

    if (!userFilters.some((f) => f.name === ORDER_BY_FILTER_KEY)) {
        overviewFilters.push({
            name: ORDER_BY_FILTER_KEY,
            value: ORDER_BY_FILTER_DEFAULT_VALUE,
            expression: 'eq',
            exclude: false,
        });
    }

    return useMemo(() => [...overviewFilters, ...userFilters], [userFilters, channelId, finished, testing]);
};
