import React, { useEffect, useState } from 'react';
import { DARK_MODE_LOCAL_STORAGE_KEY } from '../../../App.tsx';

const DarkModeContext = React.createContext({
    isActive: false,
    toggle: () => {},
    activate: () => {},
    deactivate: () => {},
});

export const DarkModeProvider = ({ children }) => {
    const [active, setActive] = useState(!!localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY));

    const toggle = () => {
        if (active) {
            deactivate();
        } else {
            activate();
        }
    };

    const activate = () => {
        document.body.classList.add('dark');
        localStorage.setItem(DARK_MODE_LOCAL_STORAGE_KEY, '1');
        setActive(true);
    };

    const deactivate = () => {
        document.body.classList.remove('dark');
        localStorage.removeItem(DARK_MODE_LOCAL_STORAGE_KEY);
        setActive(false);
    };

    useEffect(() => {
        if (active) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, []);

    return (
        <DarkModeContext.Provider value={{ isActive: active, toggle, activate, deactivate }}>
            {children}
        </DarkModeContext.Provider>
    );
};

const useDarkMode = () => React.useContext(DarkModeContext);

export default useDarkMode;
