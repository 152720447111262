import * as React from 'react';
import classnames from '../../core/utils/classnames.tsx';
import Icon, { withIcon } from '../../core/components/Icon';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import Tooltip from '../../core/components/Tooltip.tsx';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';
import { useTranslation } from 'react-i18next';

interface ChartCardProps {
    title: string;
    helpText?: string;
    loading?: boolean;
    error?: boolean;
    className?: string;
    children?: React.ReactNode;
}

const SpinnerIcon = withIcon(faSpinnerThird);

const ChartCard = ({ title, helpText, loading, error, className, children }: ChartCardProps) => {
    const { t } = useTranslation('analytics');

    const childrenCount = React.Children.toArray(children).length;
    const hasChildren = childrenCount > 0;

    let displayChildren;
    if (loading) {
        displayChildren = <SpinnerIcon spin className="text-3xl text-tertiary" />;
    } else if (error) {
        displayChildren = <span className="text-sm text-secondary text-center">{t('dashboard.charts.error')}</span>;
    } else if (!hasChildren) {
        displayChildren = <span className="text-sm text-secondary text-center">{t('dashboard.charts.noData')}</span>;
    } else {
        displayChildren = children;
    }

    return (
        <div className={classnames('shadow-sm bg-primary p-7 flex flex-col gap-7 rounded flex-1 min-w-0', className)}>
            <h3 className="flex gap-2 items-center">
                <span className="text-primary font-medium text-sm">{title}</span>
                {helpText ? (
                    <Tooltip content={helpText} side="top">
                        <Icon icon={faInfoCircle} className="text-secondary" />
                    </Tooltip>
                ) : null}
            </h3>
            <div className="flex flex-1 items-center justify-center">{displayChildren}</div>
        </div>
    );
};

export default ChartCard;
