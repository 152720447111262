import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { format, getUnixTime, isValid } from 'date-fns';
import SplitPanes, { Pane } from '../../core/components/SplitPanes.tsx';
import Loader from '../../core/components/Loader.tsx';
import InputDataPreview from './InputDataPreview.tsx';
import { withIcon } from '../../core/components/Icon.tsx';
import { faCheckCircle, faSpinnerThird, faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import StringField from '../../core/components/Fields/StringField.tsx';
import FormField from '../../core/components/FormField.tsx';
import Button from '../../core/components/Button.tsx';
import classnames from '../../core/utils/classnames.tsx';

const formatDate = (date) => {
    const dateObj = date ? new Date(date) : null;
    return dateObj && isValid(dateObj) ? format(dateObj, 'dd.MM.yyyy, HH:mm:ss') : undefined;
};

const EXECUTION_STATUS_MAP = {
    PENDING: 'pending',
    SCHEDULED: 'pending',
    RUNNING: 'pending',
    WAITING_FOR_MANUAL: 'pending',

    SUCCEEDED: 'success',
    FAILED: 'error',
};

const LoadingIcon = withIcon(faSpinnerThird);
const SuccessIcon = withIcon(faCheckCircle);
const ErrorIcon = withIcon(faXmarkCircle);

const Status = ({ record, status, onRetryStep }) => {
    const { t } = useTranslation('assistance');

    const stepName = (status?.stepName && camelCase(status?.stepName)) || 'finished';
    const statusClassName = EXECUTION_STATUS_MAP[status?.executionStatus] || 'pending';

    const context = (status?.context && JSON.parse(status?.context)) || {};
    const fields = {
        createdAt: formatDate(status?.createdAt),
        updatedAt: formatDate(status?.updatedAt),
        ...context?.details,
    };

    return (
        <>
            <div className="flex flex-col gap-2">
                <div className="mb-2">
                    <div
                        className={classnames(
                            'text-tertiary text-sm font-medium flex gap-2 items-center',
                            statusClassName === 'error' && 'text-error',
                            statusClassName === 'success' && 'text-brand-hover'
                        )}
                    >
                        {statusClassName === 'pending' && <LoadingIcon spin />}
                        {statusClassName === 'success' && <SuccessIcon />}
                        {statusClassName === 'error' && <ErrorIcon />}
                        <div>{statusClassName && t(`resultView.steps.${stepName}`)}</div>
                    </div>
                </div>
                {Object.entries(fields).map(([fieldName, fieldValue]) => (
                    <FormField
                        label={t(`resultView.fields.${fieldName}`)}
                        key={fieldName}
                        disabled
                        className="flex flex-row items-center gap-2"
                    >
                        <StringField value={fieldValue as string} disabled />
                    </FormField>
                ))}
            </div>
        </>
    );
};

const ResultView = (props) => {
    const { record, file, stepRunList, onRetryStep } = props;
    const { t } = useTranslation('assistance');
    // make sure they are in the right order
    const sortedStepRunList = stepRunList?.sort((a, b) => {
        const aCreatedAt = getUnixTime(new Date(a.createdAt));
        const bCreatedAt = getUnixTime(new Date(b.createdAt));
        const aUpdatedAt = getUnixTime(new Date(a.updatedAt));
        const bUpdatedAt = getUnixTime(new Date(b.updatedAt));

        if (aCreatedAt > bCreatedAt) {
            return 1;
        }
        if (aCreatedAt < bCreatedAt) {
            return -1;
        }
        if (aUpdatedAt > bUpdatedAt) {
            return 1;
        }
        if (aUpdatedAt < bUpdatedAt) {
            return -1;
        }
        return 0;
    });
    const exportFile = file?.files?.[0];

    return (
        <div>
            <SplitPanes id="assistance-result">
                <Pane>
                    <div className="pr-6">
                        <div className="bg-secondary-light p-5 rounded-lg">
                            {!file ? (
                                <div className="text-tertiary text-sm text-center my-16">{t('resultView.noData')}</div>
                            ) : (
                                <InputDataPreview data={file} />
                            )}
                        </div>
                        {exportFile && (
                            <div className="mt-6 flex gap-6 justify-end">
                                <Button asChild>
                                    <a href={exportFile?.url} target="_blank">
                                        {t('resultView.downloadButton')}
                                    </a>
                                </Button>
                            </div>
                        )}
                    </div>
                </Pane>
                <Pane>
                    <div className="pl-6 py-2 flex flex-col gap-8">
                        {sortedStepRunList.length === 0 ? (
                            <Loader />
                        ) : (
                            sortedStepRunList?.map((step) => (
                                <Status key={step.id} record={record} status={step} onRetryStep={onRetryStep} />
                            ))
                        )}
                    </div>
                </Pane>
            </SplitPanes>
        </div>
    );
};

export default ResultView;
