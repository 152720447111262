import * as React from 'react';
import { useEffect, useState } from 'react';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import Editor from 'react-simple-code-editor';
import classnames from '../utils/classnames.tsx';

// tslint:disable-next-line:variable-name
export const SimpleJsonInput = ({ className, value, setValue, onBlur, ...props }: any) => {
    const [internalValue, setInternalValue] = useState(value);
    useEffect(() => setInternalValue(value), [value]);

    const [isValidJson, setIsValidJson] = useState(true);

    const handleBlur = (e) => {
        try {
            // only formatting on blur so the cursor doesn't jump around
            const parsed = JSON.parse(internalValue);

            setValue(JSON.stringify(parsed, null, 4));

            setInternalValue(JSON.stringify(parsed, null, 4));
            setIsValidJson(true);
        } catch (error) {
            // reset to last valid value
            setInternalValue(value);
            setIsValidJson(true);
        }

        // onBlur will be called on unformatted json
        onBlur?.(e);
    };

    const handleChange = (updatedValue) => {
        try {
            JSON.parse(updatedValue);

            setValue(updatedValue);

            setInternalValue(updatedValue);
            setIsValidJson(true);
        } catch (error) {
            setInternalValue(updatedValue);
            setIsValidJson(false);
        }
    };

    return (
        <div className="w-full">
            <div
                className={classnames(
                    'w-full h-auto max-h-[25rem] overflow-auto rounded bg-secondary border border-solid border-transparent',
                    !isValidJson && 'border-error',
                    className
                )}
            >
                <Editor
                    value={internalValue}
                    /* tslint:disable-next-line:jsx-no-lambda */
                    onValueChange={handleChange}
                    /* tslint:disable-next-line:jsx-no-lambda */
                    highlight={(code: any) => highlight(code, languages.json)}
                    padding={15}
                    className="w-full"
                    onBlur={handleBlur}
                    {...props}
                />
            </div>
            {!isValidJson && <span className="text-error text-sm">{props.invalidSyntaxError}</span>}
        </div>
    );
};
