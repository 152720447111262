import { useTranslation } from 'react-i18next';
import { default as React, FC, useEffect, useState } from 'react';
import ConfirmModal from '../../core/components/ConfirmModal.tsx';
import TextField from '../../core/components/Fields/TextField.tsx';
import FormField from '../../core/components/FormField.tsx';
import { useMutation } from '@apollo/client';
import { DISCARD_DOCUMENTS } from '../../document/queries.ts';
import { IBaseModalProps } from '../../core/components/Modal.tsx';
import { DocumentType, mapDocumentTypeToGqlDocumentType } from '../../document/constants.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import Button from '../../core/components/Button.tsx';

type DiscardModalProps = IBaseModalProps & {
    selectedRowKeys: string[];
    documentType: DocumentType;
    initialValue?: string;
    refetch?: () => Promise<unknown>;
    onSuccess: () => void;
    onClose: () => void;
};

const DiscardModal: FC<DiscardModalProps> = ({
    onClose,
    onSuccess,
    refetch,
    initialValue = '',
    documentType,
    selectedRowKeys,
    ...modalProps
}) => {
    const { t } = useTranslation('assistance');
    const { publishToast } = useToaster();

    const [discardDocuments, { loading: isSubmitting }] = useMutation(DISCARD_DOCUMENTS);

    const textareaRef = React.useRef<HTMLTextAreaElement>(null);

    const [reason, setReason] = useState(initialValue);

    useEffect(() => {
        if (!modalProps.visible) {
            return;
        }

        if (!reason) {
            setReason(initialValue);
        }

        // make sure the textarea is focused and the cursor is at the end of the text
        textareaRef?.current?.focus();
        setTimeout(() => {
            if (!textareaRef?.current) return;

            const val = textareaRef.current.value;
            textareaRef.current.value = '';
            textareaRef.current.value = val;
        }, 10);
    }, [modalProps.visible]);

    const onSubmit = async () => {
        try {
            await discardDocuments({
                variables: {
                    recordIds: selectedRowKeys,
                    documentType: mapDocumentTypeToGqlDocumentType[documentType],
                    reason,
                },
            });

            await refetch?.();

            onSuccess();
            onClose();

            publishToast({
                description: t('discardModal.discardSuccess', { count: selectedRowKeys.length }),
                status: 'success',
            });
        } catch (e) {
            console.error(e);

            publishToast({ description: t('discardModal.discardError'), status: 'error' });
        }
    };

    return (
        <ConfirmModal
            onConfirm={onSubmit}
            onCancel={onClose}
            title={t('discardModal.title', { count: selectedRowKeys.length })}
            buttons={
                <>
                    <Button type="button" onClick={onClose} loading={isSubmitting}>
                        {t('discardModal.cancelButton')}
                    </Button>

                    <Button variant="primary" form="modal-form" onClick={onSubmit} loading={isSubmitting}>
                        {t('discardModal.discardButton', { count: selectedRowKeys.length })}
                    </Button>
                </>
            }
            {...modalProps}
        >
            <FormField label={t('discardModal.reasonLabel')}>
                <TextField
                    ref={textareaRef}
                    value={reason}
                    onValueChange={(value: string) => setReason(value)}
                    placeholder={t('discardModal.reasonPlaceHolder')}
                />
            </FormField>
        </ConfirmModal>
    );
};

export default DiscardModal;
