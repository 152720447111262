import {
    GET_DELIVERY_NOTE_EXTRACTION_RATIO,
    GET_FILTER_OPTION_DATA,
    GET_INVOICE_EXTRACTION_RATIO,
    GET_ORDER_CONFIRMATION_EXTRACTION_RATIO,
    GET_ORDER_EXTRACTION_RATIO,
    GET_RFQ_EXTRACTION_RATIO,
    GET_UNIVERSAL_EXTRACTION_RATIO,
} from '../queries';
import { gql, useQuery } from '@apollo/client';
import alphaNumericSorter from '../../core/utils/alphaNumericSorter.ts';
import { useTranslation } from 'react-i18next';
import { format, subWeeks, subMonths, subDays, subYears } from 'date-fns';
import { isoDateFormat } from '../../core/utils/dates.ts';
import { canUseDemoMode } from '../../users/utils';
import { useApplicationContext } from '../../core/contexts/ApplicationContext';

// just something in case no document type selected yet

// Use Faked data for the demo presentation
import demoRatesDataPath from '../demoRatesData.json';

const FALLBACK_QUERY = gql`
    query {
        viewer {
            id
        }
    }
`;

const DATA_BY_DOCUMENT_TYPE = {
    rfq: {
        channels: 'rfqProcessingChannels',
        extractionRatio: 'rfqChannelsExtractionRatio',
        uploadedAndAutomated: 'rfqsUploadedAndAutomated',
        assistanceReasons: 'rfqChannelsTopAssistanceReasons',
        assistanceTimes: 'rfqChannelsAssistanceTimeAndTimeSaved',
        ratioQuery: GET_RFQ_EXTRACTION_RATIO,
    },
    order: {
        channels: 'orderProcessingChannels',
        extractionRatio: 'orderChannelsExtractionRatio',
        uploadedAndAutomated: 'ordersUploadedAndAutomated',
        assistanceReasons: 'orderChannelsTopAssistanceReasons',
        assistanceTimes: 'orderChannelsAssistanceTimeAndTimeSaved',
        ratioQuery: GET_ORDER_EXTRACTION_RATIO,
    },
    orderConfirmation: {
        channels: 'orderConfirmationProcessingChannels',
        extractionRatio: 'orderConfirmationChannelsExtractionRatio',
        uploadedAndAutomated: 'orderConfirmationsUploadedAndAutomated',
        assistanceReasons: 'orderConfirmationChannelsTopAssistanceReasons',
        assistanceTimes: 'orderConfirmationChannelsAssistanceTimeAndTimeSaved',
        ratioQuery: GET_ORDER_CONFIRMATION_EXTRACTION_RATIO,
    },
    invoice: {
        channels: 'invoiceProcessingChannels',
        extractionRatio: 'invoiceChannelsExtractionRatio',
        uploadedAndAutomated: 'invoicesUploadedAndAutomated',
        assistanceReasons: 'invoiceChannelsTopAssistanceReasons',
        assistanceTimes: 'invoiceChannelsAssistanceTimeAndTimeSaved',
        ratioQuery: GET_INVOICE_EXTRACTION_RATIO,
    },
    deliveryNote: {
        channels: 'deliveryNoteProcessingChannels',
        extractionRatio: 'deliveryNoteChannelsExtractionRatio',
        uploadedAndAutomated: 'deliveryNotesUploadedAndAutomated',
        assistanceReasons: 'deliveryNoteChannelsTopAssistanceReasons',
        assistanceTimes: 'deliveryNoteChannelsAssistanceTimeAndTimeSaved',
        ratioQuery: GET_DELIVERY_NOTE_EXTRACTION_RATIO,
    },
    universal: {
        channels: 'universalProcessingChannels',
        extractionRatio: 'universalChannelsExtractionRatio',
        uploadedAndAutomated: 'universalUploadedAndAutomated',
        assistanceReasons: 'universalChannelsTopAssistanceReasons',
        assistanceTimes: 'universalChannelsAssistanceTimeAndTimeSaved',
        ratioQuery: GET_UNIVERSAL_EXTRACTION_RATIO,
    },
};

export const useFilterOptions = () => {
    const { t } = useTranslation('analytics');

    const { data, loading, error } = useQuery(GET_FILTER_OPTION_DATA);

    const channelOptionsByDocumentType: any = {};

    const users = data?.users?.users;
    const customer = data?.viewer?.customer;

    const getChannelOptions = (documentType) => {
        const channelsKey = DATA_BY_DOCUMENT_TYPE[documentType]?.channels;
        const channels = channelsKey ? [...(data?.[channelsKey] || [])] : [];
        channels.sort((a: any, b: any) => alphaNumericSorter(a.name, b.name));
        return (
            channels?.map((channel) => ({
                label: channel.name,
                value: channel.id,
            })) || []
        );
    };

    const userOptions =
        users?.map((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
        })) || [];

    const documentTypeOptions = [];
    if (customer?.isRfqsEnabled) {
        documentTypeOptions.push({
            label: t('dashboard.filters.documentType.rfq'),
            value: 'rfq',
        });
        channelOptionsByDocumentType['rfq'] = getChannelOptions('rfq');
    }
    if (customer?.isOrdersEnabled) {
        documentTypeOptions.push({
            label: t('dashboard.filters.documentType.order'),
            value: 'order',
        });
        channelOptionsByDocumentType['order'] = getChannelOptions('order');
    }
    if (customer?.isOrderConfirmationsEnabled) {
        documentTypeOptions.push({
            label: t('dashboard.filters.documentType.orderConfirmation'),
            value: 'orderConfirmation',
        });
        channelOptionsByDocumentType['orderConfirmation'] = getChannelOptions('orderConfirmation');
    }
    if (customer?.isInvoicesEnabled) {
        documentTypeOptions.push({
            label: t('dashboard.filters.documentType.invoice'),
            value: 'invoice',
        });
        channelOptionsByDocumentType['invoice'] = getChannelOptions('invoice');
    }
    if (customer?.isDeliveryNotesEnabled) {
        documentTypeOptions.push({
            label: t('dashboard.filters.documentType.deliveryNote'),
            value: 'deliveryNote',
        });
        channelOptionsByDocumentType['deliveryNote'] = getChannelOptions('deliveryNote');
    }
    if (customer?.isUniversalEnabled) {
        documentTypeOptions.push({
            label: t('dashboard.filters.documentType.universal'),
            value: 'universal',
        });
        channelOptionsByDocumentType['universal'] = getChannelOptions('universal');
    }

    const today = new Date();

    const dateRangeOptions = [
        {
            label: t('dashboard.filters.dateRange.lastWeek'),
            value: [format(subWeeks(today, 1), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('dashboard.filters.dateRange.lastMonth'),
            value: [format(subMonths(today, 1), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('dashboard.filters.dateRange.last3Months'),
            value: [format(subDays(today, 90), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('dashboard.filters.dateRange.last6Months'),
            value: [format(subMonths(today, 6), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('dashboard.filters.dateRange.lastYear'),
            value: [format(subYears(today, 1), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
    ];

    return {
        loading,
        error: !!error,

        documentType: documentTypeOptions,
        channelsByDocumentType: channelOptionsByDocumentType,
        dateRange: dateRangeOptions,
        user: userOptions,
    };
};

export const useExtractionRatioQuery = ({
    documentType,
    dateRange,
    channels,
    users,
}: {
    documentType: string;
    dateRange?: string[];
    channels?: string[];
    users?: string[];
}) => {
    const { t, i18n } = useTranslation('analytics');

    const [startDate, endDate] = dateRange;

    const query = DATA_BY_DOCUMENT_TYPE[documentType]?.ratioQuery;
    const extractionRatioKey = DATA_BY_DOCUMENT_TYPE[documentType]?.extractionRatio;
    const uploadedAndAutomatedKey = DATA_BY_DOCUMENT_TYPE[documentType]?.uploadedAndAutomated;
    const assistanceReasonsKey = DATA_BY_DOCUMENT_TYPE[documentType]?.assistanceReasons;
    const assistanceTimesKey = DATA_BY_DOCUMENT_TYPE[documentType]?.assistanceTimes;

    const { user } = useApplicationContext();

    if (canUseDemoMode(user)) {
        let demoRatesData = JSON.parse(JSON.stringify(demoRatesDataPath));

        const start = new Date(dateRange[0]);
        const end = new Date(dateRange[1]);
        const differenceInTime = end.getTime() - start.getTime();
        const numOfDays = differenceInTime / (1000 * 3600 * 24);

        // Helper function to generate all dates between two dates
        const generateDateRange = (dateRange: string[]): string[] => {
            const dateArray: string[] = [];
            let currentDate = new Date(start);
            while (currentDate <= end) {
                dateArray.push(format(currentDate, 'yyyy-MM-dd'));
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return dateArray;
        };

        const getLimitedAndModifiedDemoRatesData = (numOfDays: number, dateRange: string[]) => {
            const allDates = generateDateRange(dateRange);

            // Helper function to take only limited number of days and change the date to the desired one in the range
            const modifyDate = (list: { date: string; [key: string]: any }[]) => {
                return list.slice(0, numOfDays).map((item, index) => ({
                    ...item,
                    date: allDates[index % allDates.length],
                }));
            };

            return {
                ...demoRatesData,
                fieldExtractionRate: modifyDate(demoRatesData.fieldExtractionRate),
                documentAssistanceTime: modifyDate(demoRatesData.documentAssistanceTime),
                documentsReceived: demoRatesData.documentsReceived * numOfDays,
                documentsAutomated: demoRatesData.documentsAutomated * numOfDays,
                timeSavedTotal: demoRatesData.timeSavedTotal * numOfDays,
            };
        };

        return getLimitedAndModifiedDemoRatesData(numOfDays, dateRange);
    }

    const {
        data: data,
        loading,
        error,
    } = useQuery(query || FALLBACK_QUERY, {
        variables: {
            channelIds: channels,
            startDate,
            endDate,
        },
        skip: !documentType,
    });

    const extractionDays = data?.[extractionRatioKey]?.extractionDays;
    const uploadedAndAutomated = data?.[uploadedAndAutomatedKey];
    const assistanceReasons = data?.[assistanceReasonsKey]?.assistanceReasons;
    const assistanceTimes = data?.[assistanceTimesKey];

    const getFieldLabel = (fieldName: string) => {
        // Probably field names should have their translation strings in a more general name space
        if (i18n.exists(`assistance:itemsView.fieldNames.${fieldName}`)) {
            return t(`assistance:itemsView.fieldNames.${fieldName}`);
        } else if (i18n.exists(`assistance:headerView.fieldNames.${fieldName}`)) {
            return t(`assistance:headerView.fieldNames.${fieldName}`);
        } else {
            return fieldName;
        }
    };
    const assistanceReasonsData = assistanceReasons?.map((item) => ({
        label: t(`dashboard.charts.assistanceReasons.${item.reason}`, { fieldName: getFieldLabel(item.fieldName) }),
        value: Math.round(item.percentage * 100),
    }));

    const fieldExtractionRateData = extractionDays?.map((item) => ({
        date: item.date,
        value: item.extractionRate,
    }));

    const documentAssistanceTimeData = assistanceTimes?.assistanceTimes?.map((item) => ({
        date: item.date,
        value: item.assistanceTime,
        docsCount: item.assistedDocs,
    }));

    return {
        loading,
        error: !!error,

        documentsReceived: uploadedAndAutomated?.uploadedCount,
        documentsAutomated: uploadedAndAutomated?.automatedCount,
        timeSavedPerDocument: assistanceTimes?.timeSavedPerDocument,
        timeSavedTotal: assistanceTimes?.totalTimeSaved,
        fieldExtractionRate: fieldExtractionRateData,
        documentAssistanceTime: documentAssistanceTimeData,
        assistanceReasons: assistanceReasonsData,
    };
};
