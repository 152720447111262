import AutoCompleteField, {
    defaultRenderOption,
    showAllFilterOption,
} from '../../../core/components/Fields/AutoCompleteField';
import SelectField from '../../../core/components/Fields/SelectField';
import StringField from '../../../core/components/Fields/StringField';
import DateField from '../../../core/components/Fields/DateField';
import TextField from '../../../core/components/Fields/TextField';
import IntegerField from '../../../core/components/Fields/IntegerField';
import DecimalField from '../../../core/components/Fields/DecimalField';
import ArrayField from '../../../core/components/Fields/ArrayField';
import BooleanField from '../../../core/components/Fields/BooleanField';
import { useAssistanceViewContext } from './AssistanceViewContext';
import { useAssistanceFieldContext } from './AssistanceFieldContext';
import * as React from 'react';
import { FieldProps, useFieldProps } from './customizable/Field';
import useReselectEventListener from './useReselectEventListener';
import useForceSelectionLookupProps from '../../components/useForceSelectionLookupProps';
import {
    renderAddressOption,
    renderClientOption,
    renderContactOption,
    renderFrameworkContractOption,
} from '../../components/useLookup';
import MultiSelectField from '../../../customizations/murtfeldt/components/MultiSelectField';

export const fieldDataToFieldConfig = ({ choices, ...props }: any) => {
    const results: any = {};

    if (choices) {
        // this is to extract field config options that is coded into the fieldData
        results.options = choices
            .filter((choice: any) => (choice?.value || choice) !== '')
            .map((choice: any) => ({
                label: choice?.label || choice,
                value: choice?.value || choice,
            }));
    }

    if (props.__typename === 'BooleanField') {
        // Because the whole dynamic schema bullshit is not coming through consistently with the config
        // we need to fall back to good old runtime type checking
        results.valueType = 'boolean';
    }

    // additionally ReadOnlyFields shouldn't exist but be StringFields with read only = true
    if (props.__typename === 'ReadOnlyField') {
        results.valueType = 'string';
        results.readOnly = true;
        results.disabled = true;
    }

    if (props.__typename === 'CustomRagDecimalExtractionField') {
        results.valueType = 'decimal';
    }
    if (props.__typename === 'CustomRagStringExtractionField') {
        results.valueType = 'string';
    }
    return results;
};

const getRenderOption = (lookupType: string) => {
    switch (lookupType) {
        case 'ClientLookupType':
            return renderClientOption;
        case 'AddressLookupType':
            return renderAddressOption;
        case 'ContactLookupType':
            return renderContactOption;
        case 'FrameworkContractLookupType':
            return renderFrameworkContractOption;
        default:
            return defaultRenderOption;
    }
};

export const getFieldComponent = ({
    valueType,
    performLookups = false,
    locale,
    decimalSeparator,
    dateFormat,
    options,
    filterOption,
    onSelectOption,
    readOnly,
    disabled,
    props,
}: any): [any, object] => {
    // Return the field component and the field specific options
    const fieldSpecificProps: any = {};

    if (readOnly !== undefined) {
        fieldSpecificProps['readOnly'] = readOnly;
    }

    if (disabled !== undefined) {
        fieldSpecificProps['disabled'] = disabled;
    }
    if (performLookups || (options && filterOption)) {
        let forceSelectionLookupProps = {};
        if (props !== undefined) {
            // `props` being undefined is currently equivalant to: CompositeField is calling `getFieldComponent`
            // TODO this should be done properly at some point, see also the `TODO: add useForceSelect for TROX` in the CompositeField component
            const { clientPartitionId, config } = useAssistanceFieldContext();
            const { value, onValueChange, onFocus, onBlur, onUpdatePayloadChange } = props;
            forceSelectionLookupProps = useForceSelectionLookupProps({
                lookupType: config?.lookupType,
                lookupDefinitionId: config?.lookupDefinitionId,
                lookupFieldName: config?.lookupFieldName,
                allowInvalidIds: config?.allowInvalidIds,
                isPartitionIdField: config?.isPartitionIdField === true,
                partitionId: clientPartitionId,
                value,
                onValueChange,
                onUpdatePayloadChange,
                onFocus,
                onBlur,
            });
            forceSelectionLookupProps = {
                ...forceSelectionLookupProps,
                renderOption: getRenderOption(config?.lookupType),
            };
        }
        return [
            AutoCompleteField,
            {
                options,
                onSelectOption,
                filterOption: performLookups ? showAllFilterOption : filterOption,
                ...fieldSpecificProps,
                ...forceSelectionLookupProps,
            },
        ];
    } else if (valueType === 'string' && options) {
        return [SelectField, { options }];
    } else if (valueType === 'list' && options) {
        return [MultiSelectField, { options }];
    } else if (valueType === 'string') {
        return [StringField, fieldSpecificProps];
    } else if (valueType === 'date') {
        return [DateField, { locale, format: dateFormat, side: 'top', ...fieldSpecificProps }];
    } else if (valueType === 'text') {
        return [TextField, fieldSpecificProps];
    } else if (valueType === 'integer') {
        return [IntegerField, fieldSpecificProps];
    } else if (valueType === 'decimal' || valueType === 'float') {
        return [DecimalField, { locale, decimalSeparator, ...fieldSpecificProps }];
    } else if (valueType === 'array' || valueType === 'list') {
        // only used for TROX article markings
        return [ArrayField, fieldSpecificProps];
    } else if (valueType === 'boolean') {
        return [BooleanField, fieldSpecificProps];
    } else {
        console.debug(`Unknown field type: ${valueType}`);
        return [null, null];
    }
};

const DynamicField = (props: FieldProps) => {
    // Dynamically determine the field component and its specific options
    const { generalConfig } = useAssistanceViewContext();
    const { data, config } = useAssistanceFieldContext() as any;
    const fieldConfigFromFieldData = data ? fieldDataToFieldConfig(data) : {};

    const [Component, fieldSpecificProps] = getFieldComponent({
        ...generalConfig,
        ...config,
        ...fieldConfigFromFieldData,
        props,
    });

    if (!Component) return null;

    const fieldProps = useFieldProps(props);

    useReselectEventListener(props.inputRef, props.onReselect);

    return <Component {...fieldProps} {...fieldSpecificProps} />;
};

export default DynamicField;
