import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import classnames from '../utils/classnames.tsx';

const Tooltip = ({
    children,
    className,
    content,
    side,
    sideOffset,
    align,
    alignOffset,
    long,
    overflowOnly = false,
    asChild = true,
}: any) => {
    const triggerRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!triggerRef?.current) return;
        triggerRef.current.addEventListener('mouseenter', () => {
            setIsOverflowing(triggerRef.current.scrollWidth > triggerRef.current.offsetWidth);
        });
    }, [triggerRef?.current]);

    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root
                open={(overflowOnly && !isOverflowing) || !content ? false : isOpen}
                onOpenChange={setIsOpen}
                delayDuration={100}
            >
                <TooltipPrimitive.Trigger ref={triggerRef} asChild={asChild}>
                    {children}
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal>
                    <TooltipPrimitive.Content
                        side={side}
                        sideOffset={sideOffset}
                        align={align}
                        alignOffset={alignOffset}
                        className={classnames(
                            'select-none rounded bg-[rgba(0,0,0,0.9)] text-[#fff] backdrop-blur-md px-2.5 py-1.5 text-sm z-[60001] max-w-64',
                            long && 'max-w-96',
                            className
                        )}
                    >
                        {content}
                    </TooltipPrimitive.Content>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
};

export default Tooltip;
