import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { useTranslation } from 'react-i18next';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_DETAILS, UPDATE_SSO_CONFIG } from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import BooleanField from '../../core/components/Fields/BooleanField.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

const CUSTOMER_SSO_FORM_DEFAULT_VALUES = {
    providerName: '',
    domain: '',
    allowSelfRegistration: 'false',
    tenantId: '',
    clientId: '',
    clientSecret: '',
};

const CustomerSsoPage = () => {
    const { t } = useTranslation('customer');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: CUSTOMER_SSO_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const { data } = useQuery(GET_COMPANY_DETAILS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {},
    });
    const ssoConfig = data?.viewer?.customer?.ssoConfig;

    useEffect(() => {
        if (ssoConfig) {
            form.reset({
                ...CUSTOMER_SSO_FORM_DEFAULT_VALUES,
                providerName: ssoConfig?.providerName || '',
                domain: ssoConfig?.domain || '',
                allowSelfRegistration: ssoConfig?.allowSelfRegistration,
                tenantId: ssoConfig?.tenantId || '',
                clientId: ssoConfig?.clientId || '',
                clientSecret: '',
            });
        }
    }, [ssoConfig]);

    const [updateSsoConfig] = useMutation(UPDATE_SSO_CONFIG);
    const handleUpdateSsoConfig = async (formData) => {
        try {
            await updateSsoConfig({ variables: { input: formData } });
            publishToast({ description: t('sso.form.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('sso.form.updateError'), status: 'error' });

            if (e.message === 'Unknown provider') {
                form.setError('providerName', { message: t('sso.form.errors.unknownProvider') });
            }
        }
    };

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('sso.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <SettingsFormCard onSubmit={form.handleSubmit(handleUpdateSsoConfig)}>
                        <Controller
                            name="providerName"
                            control={form.control}
                            render={({ field }) => (
                                <FormField label={t('sso.form.providerName')} error={formErrors.providerName}>
                                    <StringField {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="domain"
                            control={form.control}
                            render={({ field }) => (
                                <FormField label={t('sso.form.domain')} error={formErrors.domain}>
                                    <StringField {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="allowSelfRegistration"
                            control={form.control}
                            render={({ field }) => (
                                <FormField
                                    label={t('sso.form.allowSelfRegistration')}
                                    error={formErrors.allowSelfRegistration}
                                >
                                    <BooleanField {...field} value={field.value ? 'true' : ''} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="tenantId"
                            control={form.control}
                            render={({ field }) => (
                                <FormField label={t('sso.form.tenantId')} error={formErrors.tenantId}>
                                    <StringField {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="clientId"
                            control={form.control}
                            render={({ field }) => (
                                <FormField label={t('sso.form.clientId')} error={formErrors.clientId}>
                                    <StringField {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="clientSecret"
                            control={form.control}
                            render={({ field }) => (
                                <FormField
                                    label={t('sso.form.clientSecret')}
                                    helpText={t('sso.form.clientSecretHelpText')}
                                >
                                    <StringField
                                        id="clientSecret"
                                        name="clientSecret"
                                        placeholder={t('sso.form.clientSecretPlaceholder')}
                                        {...field}
                                    />
                                </FormField>
                            )}
                        />

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('profile.form.saveButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default CustomerSsoPage;
