import { useTranslation } from 'react-i18next';
import { format, addDays, subDays, subWeeks, subMonths, subYears, isValid, parse } from 'date-fns';
import { isoDateFormat } from '../../../../core/utils/dates.ts';
import DateRangeFilter from '../../../../analytics/components/DateRangeFilter';
import { camelCase } from 'lodash';
import * as React from 'react';

const DateFilter = ({ filters, onFiltersChange, filterName }) => {
    const { t } = useTranslation('assistance');

    const fromValue = filters.find((f) => f.name === filterName && f.expression === 'ge')?.value?.[0];
    const toValue = filters.find((f) => f.name === filterName && f.expression === 'le')?.value?.[0];

    const dateRange = {
        from:
            fromValue && isValid(parse(fromValue, isoDateFormat, new Date()))
                ? parse(fromValue, isoDateFormat, new Date())
                : null,
        to:
            toValue && isValid(parse(toValue, isoDateFormat, new Date()))
                ? parse(toValue, isoDateFormat, new Date())
                : null,
    };

    const today = new Date();
    const dateRangeOptions = [
        {
            label: t('overview.filters.createdAt.options.today'),
            value: [format(today, isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.yesterday'),
            value: [format(subDays(today, 1), isoDateFormat), format(subDays(today, 1), isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.lastWeek'),
            value: [format(subWeeks(today, 1), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.lastMonth'),
            value: [format(subMonths(today, 1), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.last3Months'),
            value: [format(subDays(today, 90), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.last6Months'),
            value: [format(subMonths(today, 6), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.lastYear'),
            value: [format(subYears(today, 1), isoDateFormat), format(today, isoDateFormat)].join(','),
        },
    ];

    const handleDateChange = (range) => {
        const updatedFilters = filters.filter((f) => f.name !== filterName);

        if (range.from) {
            updatedFilters.push({
                name: filterName,
                value: [format(range.from, isoDateFormat)],
                expression: 'ge',
                exclude: false,
            });
        }

        if (range.to) {
            updatedFilters.push({
                name: filterName,
                value: [format(range.to, isoDateFormat)],
                expression: 'le',
                exclude: false,
            });
        }

        onFiltersChange(updatedFilters);
    };

    return (
        <DateRangeFilter
            options={dateRangeOptions}
            label={t(`overview.filters.${camelCase(filterName)}.label`)}
            range={dateRange}
            onRangeChange={handleDateChange}
        />
    );
};

export default DateFilter;
