import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { useTranslation } from 'react-i18next';
import SelectField from '../../core/components/Fields/SelectField.tsx';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_DETAILS, UPDATE_CUSTOMER } from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import Checkbox from '../../core/components/Checkbox.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import { COUNTRY_CODES } from '../../core/utils';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

const CUSTOMER_PROFILE_FORM_DEFAULT_VALUES = {
    name: '',
    invoiceEmail: '',
    streetAddress: '',
    postalCode: '',
    city: '',
    countryCode: '',
    billingStreetAddress: '',
    billingPostalCode: '',
    billingCity: '',
    billingCountryCode: '',
};

const CustomerProfilePage = () => {
    const { t } = useTranslation('customer');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: CUSTOMER_PROFILE_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const { data } = useQuery(GET_COMPANY_DETAILS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {},
    });
    const customer = data?.viewer?.customer;

    useEffect(() => {
        if (customer) {
            form.reset({
                ...CUSTOMER_PROFILE_FORM_DEFAULT_VALUES,
                name: customer.name,
                invoiceEmail: customer.invoiceEmail,
                streetAddress: customer.streetAddress,
                postalCode: customer.postalCode,
                city: customer.city,
                countryCode: customer.countryCode,
                billingStreetAddress: isBillingSameAsShipping ? '' : customer.billingStreetAddress,
                billingPostalCode: isBillingSameAsShipping ? '' : customer.billingPostalCode,
                billingCity: isBillingSameAsShipping ? '' : customer.billingCity,
                billingCountryCode: isBillingSameAsShipping ? '' : customer.billingCountryCode,
            });
            setIsBillingSameAsShipping(customer.isBillingSameAsShipping);
        }
    }, [customer]);

    const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(customer?.isBillingSameAsShipping);

    const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
    const handleUpdateCustomer = async (formData) => {
        try {
            await updateCustomer({ variables: { input: formData } });
            publishToast({ description: t('profile.form.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('profile.form.updateError'), status: 'error' });
        }
    };

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('profile.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <SettingsFormCard onSubmit={form.handleSubmit(handleUpdateCustomer)}>
                        <Controller
                            name="name"
                            control={form.control}
                            rules={{ required: t('profile.form.nameRequired') }}
                            render={({ field }) => (
                                <FormField label={t('profile.form.name')} required={true} error={formErrors.name}>
                                    <StringField required={true} {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="invoiceEmail"
                            control={form.control}
                            rules={{ required: t('profile.form.invoiceEmailRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('profile.form.invoiceEmail')}
                                    required={true}
                                    error={formErrors.invoiceEmail}
                                >
                                    <StringField required={true} {...field} />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="streetAddress"
                            control={form.control}
                            rules={{ required: t('profile.form.streetAddressRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('profile.form.streetAddress')}
                                    required={true}
                                    error={formErrors.streetAddress}
                                >
                                    <StringField required={true} {...field} />
                                </FormField>
                            )}
                        />

                        <div className="flex gap-4">
                            <Controller
                                name="postalCode"
                                control={form.control}
                                rules={{ required: t('profile.form.postalCodeRequired') }}
                                render={({ field }) => (
                                    <FormField
                                        label={t('profile.form.postalCode')}
                                        required={true}
                                        error={formErrors.postalCode}
                                        className="flex-1"
                                    >
                                        <StringField required={true} {...field} />
                                    </FormField>
                                )}
                            />

                            <Controller
                                name="city"
                                control={form.control}
                                rules={{ required: t('profile.form.cityRequired') }}
                                render={({ field }) => (
                                    <FormField
                                        label={t('profile.form.city')}
                                        required={true}
                                        error={formErrors.city}
                                        className="flex-1"
                                    >
                                        <StringField required={true} {...field} />
                                    </FormField>
                                )}
                            />

                            <Controller
                                name="countryCode"
                                control={form.control}
                                rules={{ required: t('profile.form.countryCodeRequired') }}
                                render={({ field }) => (
                                    <FormField
                                        label={t('profile.form.countryCode')}
                                        required={true}
                                        error={formErrors.countryCode}
                                        className="flex-1"
                                    >
                                        <SelectField
                                            options={COUNTRY_CODES.map((code) => ({ label: code, value: code }))}
                                            required={true}
                                            {...field}
                                        />
                                    </FormField>
                                )}
                            />
                        </div>

                        <label className="flex items-center">
                            <Checkbox
                                checked={isBillingSameAsShipping}
                                onChange={(e) => setIsBillingSameAsShipping(e.target.checked)}
                                className="mr-2"
                            />
                            <span className="text-xs text-secondary min-w-36 flex-none flex justify-start gap-1">
                                {t('profile.form.isBillingSameAsShipping')}
                            </span>
                        </label>

                        {!isBillingSameAsShipping && (
                            <>
                                <Controller
                                    name="billingStreetAddress"
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormField
                                            label={t('profile.form.billingStreetAddress')}
                                            error={formErrors.billingStreetAddress}
                                        >
                                            <StringField {...field} />
                                        </FormField>
                                    )}
                                />

                                <div className="flex gap-4">
                                    <Controller
                                        name="billingPostalCode"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormField
                                                label={t('profile.form.billingPostalCode')}
                                                error={formErrors.billingPostalCode}
                                                className="flex-1"
                                            >
                                                <StringField {...field} />
                                            </FormField>
                                        )}
                                    />

                                    <Controller
                                        name="billingCity"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormField
                                                label={t('profile.form.billingCity')}
                                                error={formErrors.billingCity}
                                                className="flex-1"
                                            >
                                                <StringField {...field} />
                                            </FormField>
                                        )}
                                    />

                                    <Controller
                                        name="billingCountryCode"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormField
                                                label={t('profile.form.billingCountryCode')}
                                                error={formErrors.billingCountryCode}
                                                className="flex-1"
                                            >
                                                <SelectField
                                                    options={COUNTRY_CODES.map((code) => ({
                                                        label: code,
                                                        value: code,
                                                    }))}
                                                    {...field}
                                                />
                                            </FormField>
                                        )}
                                    />
                                </div>
                            </>
                        )}

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('profile.form.saveButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default CustomerProfilePage;
