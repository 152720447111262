import * as React from 'react';
import classnames from '../utils/classnames.tsx';
import DropdownMenu from './DropdownMenu.tsx';
import { withIcon } from './Icon.tsx';
import { faChevronDown, faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons';
import Button from './Button.tsx';
import StringField from './Fields/StringField.tsx';
import { useTranslation } from 'react-i18next';
import { useControllableState } from '../utils/useControllableState.tsx';
import { useEffect } from 'react';

export interface FilterDropdownOption {
    label: string;
    value: string;
}

interface FilterDropdownProps {
    allLabel?: string;
    label?: string;

    options: FilterDropdownOption[];
    renderOption?: (option: FilterDropdownOption) => React.ReactNode; // TODO maybe this is not the cleanest way to do this
    filterOptions?: (options: FilterDropdownOption[], searchValue: string) => FilterDropdownOption[];

    selected?: string[];
    onSelectedChange?: (selected: string[]) => void;
    multiple?: boolean;
    maxOptions?: number;

    searchValue?: string;
    onSearchValueChange?: (searchValue: string) => void;

    showSearch?: boolean;
    required?: boolean;
    className?: string;

    extraContent?: React.ReactNode;
    formatLabel?;
}

const ChevronDownIcon = withIcon(faChevronDown);
const ClearIcon = withIcon(faXmark);
const SearchIcon = withIcon(faMagnifyingGlass);

const defaultFilterOptions = (options: FilterDropdownOption[], searchValue: string) => {
    return options.filter(
        (option) =>
            searchValue === '' ||
            option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            option.value.toLowerCase().includes(searchValue.toLowerCase())
    );
};

export const defaultFormatLabel = ({ defaultLabel, allLabel, options, selected, searchValue, multiple }) => {
    // in case no item is selected we show the default label
    let label: string = defaultLabel;
    if (!multiple || options.length === 1 || (multiple && selected.length === 1)) {
        // in case only one option is allowed or only one option given we either show that option or the default label
        // OR in case only one option is selected we show that option
        const selectedOption = options.find((option) => option.value === selected[0]);
        label = selectedOption?.label ?? selected[0] ?? defaultLabel;
        if (label !== defaultLabel) {
            label = `${defaultLabel}: ${label}`;
        }
    } else if (selected.length === options.length && !searchValue) {
        // in case all options are selected we show the all label
        label = allLabel;
    }
    return label;
};

const FilterDropdown = ({
    allLabel,
    label: defaultLabel,
    formatLabel = defaultFormatLabel,

    options,
    renderOption,
    filterOptions = defaultFilterOptions,

    selected = [],
    onSelectedChange,
    multiple = false,
    maxOptions = 20,

    searchValue: propsSearchValue,
    onSearchValueChange,

    showSearch = false,
    required = false,
    className,
    extraContent,
}: FilterDropdownProps) => {
    const { t } = useTranslation('assistance');
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchValue, setSearchValue] = useControllableState('', propsSearchValue, onSearchValueChange);
    const [internalSearchValue, setInternalSearchValue] = React.useState('');
    const searchInputRef = React.useRef<HTMLInputElement>(null);

    const filteredOptions = options ? filterOptions(options, internalSearchValue) : [];

    useEffect(() => {
        if (isOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isOpen]);

    const handleSearchClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            onSearchValueChange?.(internalSearchValue);
        }
    };

    const handleOptionSelect = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const handleCheckedChange = (value: string) => (checked: boolean) => {
        if (checked) {
            onSelectedChange?.(multiple ? [...selected, value] : [value]);
        } else {
            onSelectedChange?.(multiple ? selected.filter((selectedValue) => selectedValue !== value) : []);
        }
    };

    const handleClear = () => {
        onSelectedChange?.([]);
    };

    const label = formatLabel({ defaultLabel, allLabel, options, selected, searchValue, multiple });

    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    return (
        <DropdownMenu open={isOpen} onOpenChange={handleOpenChange}>
            <div className="relative text-sm">
                <DropdownMenu.Trigger
                    onPointerDown={(e) => e.preventDefault()}
                    onClick={() => handleOpenChange(!isOpen)}
                    asChild
                >
                    <Button className={classnames('pr-8', className)} active={selected.length > 0}>
                        <span>{label}</span>
                        {multiple && selected.length > 1 && selected.length != options.length ? (
                            <span className="ml-1.5">({selected.length})</span>
                        ) : null}
                    </Button>
                </DropdownMenu.Trigger>

                {!required && selected.length > 0 ? (
                    <button
                        onClick={handleClear}
                        className={classnames(
                            'absolute right-0 top-0 transform flex items-center justify-center h-full px-3 text-primary',
                            (selected.length > 0 || isOpen) && 'text-brand'
                        )}
                    >
                        <ClearIcon />
                    </button>
                ) : (
                    <ChevronDownIcon
                        className={classnames(
                            'absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-primary',
                            (selected.length > 0 || isOpen) && 'text-brand'
                        )}
                    />
                )}
            </div>
            <DropdownMenu.Portal>
                <DropdownMenu.Content align="start" className="!max-h-[260px] overflow-y-auto">
                    <div className="overflow-y-auto">
                        {showSearch && (
                            <div className="p-1 relative" onClick={handleSearchClick}>
                                <SearchIcon className="absolute pointer-events-none top-1/2 left-3.5 transform -translate-y-1/2 z-10 text-sm text-tertiary" />
                                <StringField
                                    value={internalSearchValue}
                                    onValueChange={setInternalSearchValue}
                                    onKeyDown={handleSearchKeyDown}
                                    placeholder={t('overview.filters.search.placeholder')}
                                    inputRef={searchInputRef}
                                    className="hover:border-brand focus-within:border-brand focus-within:outline-surface-brand pl-5"
                                />
                            </div>
                        )}
                        {multiple ? (
                            filteredOptions?.map((option) => (
                                <DropdownMenu.CheckboxItem
                                    key={option.value}
                                    onCheckedChange={handleCheckedChange(option.value)}
                                    checked={selected.includes(option.value)}
                                    onSelect={handleOptionSelect}
                                    className="outline-none"
                                >
                                    {renderOption ? renderOption(option) : option.label}
                                </DropdownMenu.CheckboxItem>
                            ))
                        ) : (
                            <DropdownMenu.RadioGroup
                                value={selected[0]}
                                onValueChange={(value) => onSelectedChange?.([value])}
                            >
                                {filteredOptions?.map((option) => (
                                    <DropdownMenu.RadioItem
                                        key={option.value}
                                        value={option.value}
                                        className="outline-none"
                                        onSelect={handleOptionSelect}
                                    >
                                        {renderOption ? renderOption(option) : option.label}
                                    </DropdownMenu.RadioItem>
                                ))}
                            </DropdownMenu.RadioGroup>
                        )}
                    </div>
                    {extraContent}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu>
    );
};

export default FilterDropdown;
