import { ApolloClient, ApolloError, ApolloLink, InMemoryCache, useMutation } from '@apollo/client';
import { httpLink } from '../core/utils/authentication.ts';
import { capitalizeFirstLetter } from '../core/utils/string.ts';
import { useSearchParams } from 'react-router-dom';
import { GraphQLError } from 'graphql/error';

const publicClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink]),
});

export const useOauthCompleteMutation = (integrationKey, mutationFn) => {
    const [searchParams] = useSearchParams();
    const payload = Object.fromEntries(searchParams.entries());

    return useMutation(mutationFn, {
        client: publicClient,
        variables: {
            input: {
                integration: integrationKey,
                payload: JSON.stringify(payload),
            },
        },
        onCompleted: (data) => {
            const isSuccess = data?.[`trigger${capitalizeFirstLetter(integrationKey)}OauthFlowComplete`]?.success;
            if (!isSuccess) {
                throw new ApolloError({
                    graphQLErrors: [new GraphQLError('Error completing OAuth flow')],
                });
            }
        },
    });
};
