import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from '../../core/utils/classnames.tsx';
import DOMPurify from 'dompurify';
import { format } from 'date-fns';
import i18n from 'i18next';
import Frame from '../../core/components/Frame.tsx';
import { DARK_MODE_LOCAL_STORAGE_KEY } from '../../../App.tsx';
import { withIcon } from '../../core/components/Icon.tsx';
import { faArrowDownToBracket, faFile, faLanguage } from '@fortawesome/pro-regular-svg-icons';

import contentStyle from '../email-content.css?inline';
import Button from '../../core/components/Button.tsx';

const LanguageIcon = withIcon(faLanguage);
const FileIcon = withIcon(faFile);
const DownloadIcon = withIcon(faArrowDownToBracket);

export const PURIFY_CONFIG = {
    ALLOWED_TAGS: [
        'address',
        'article',
        'aside',
        'footer',
        'header',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hgroup',
        'main',
        'nav',
        'section',
        'blockquote',
        'dd',
        'div',
        'dl',
        'dt',
        'figcaption',
        'figure',
        'hr',
        'li',
        'main',
        'ol',
        'p',
        'pre',
        'ul',
        'a',
        'abbr',
        'b',
        'bdi',
        'bdo',
        'br',
        'cite',
        'code',
        'data',
        'dfn',
        'em',
        'i',
        'kbd',
        'mark',
        'q',
        'rb',
        'rp',
        'rt',
        'rtc',
        'ruby',
        's',
        'samp',
        'small',
        'span',
        'strong',
        'sub',
        'sup',
        'time',
        'u',
        'var',
        'wbr',
        'caption',
        'col',
        'colgroup',
        'table',
        'tbody',
        'td',
        'tfoot',
        'th',
        'thead',
        'tr',
        'img',
        'style',
        'html',
        'head',
        'meta',
        'comment',
    ],
    ALLOWED_ATTR: ['href', 'name', 'target', 'class', 'src', 'alt', 'width', 'height', 'id', 'style'],
    ALLOWED_URI_REGEXP:
        /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|<cid|xmpp):|[^a-z]|[^a-z+.\-]+(?:[^a-z+.\-:]|$))/i,
    WHOLE_DOCUMENT: true,
    SANITIZE_DOM: true,
};

export interface File {
    url?: string;
    type?: string;
    name?: string;
}

export interface EmailAttachment {
    cid?: string;
    contentDisposition?: string;
    url?: string;
    type?: string;
    contentType?: string;
    name?: string;
}

export interface Email {
    receivedAt?: Date;
    text?: string;
    html?: string;
    attachments?: EmailAttachment[];
}

export const useEmailBody = (email: Email) => {
    let attachmentsPerCid = Object.fromEntries(
        (email?.attachments || []).filter((a) => !!a.cid).map((a) => [a.cid, a])
    );
    const attachmentsWithoutCid = (email?.attachments || []).filter((a) => !a.cid);

    const isHtml = !!email.html;
    let emailContent = DOMPurify.sanitize(isHtml ? email.html : email.text, PURIFY_CONFIG) || '';
    emailContent = emailContent.replaceAll(/src=['"](.*?)['"]/g, (src, match) => {
        let url = match;
        const cid = `<${match.replace('cid:', '')}>`;

        if (attachmentsPerCid[cid]) {
            url = attachmentsPerCid[cid].url;
            delete attachmentsPerCid[cid];
        }

        return `src="${url}"`;
    });

    const remainingAttachments = Object.values(attachmentsPerCid).concat(attachmentsWithoutCid);

    return {
        content: emailContent,
        attachments: remainingAttachments,
    };
};

const EmailPreview = ({ email, className, ...props }: any) => {
    const { t } = useTranslation('assistance');

    const isDarkMode = !!localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY);

    const isHtml = !!email.html;
    const { content: emailContent, attachments: remainingAttachments } = useEmailBody(email);

    const date = email.receivedAt && new Date(email.receivedAt);

    return (
        <div className={classnames(className)} {...props}>
            <div className="px-6 py-5 border-b border-solid border-primary flex w-full">
                <div className="flex-auto">
                    <div className="font-medium text-base text-primary">{email?.subject}</div>
                    <div className="text-base text-primary">
                        {email?.sender?.name} &lt;{email?.sender?.email}&gt;
                    </div>
                    <div className="text-base text-primary">
                        {t('originalDataPreview.email.to')}:{' '}
                        {email?.receivers?.map((receiver) => receiver?.email).join(', ')}
                    </div>
                </div>
                <div className="flex-auto text-right text-base text-primary flex flex-col justify-between items-end">
                    <div className="text-base text-secondary">{date && format(date, 'PPP p')}</div>

                    {email.language && (
                        <div className="uppercase text-base">
                            {email?.language} <LanguageIcon />
                        </div>
                    )}

                    <a
                        className="flex items-center gap-1 text-brand-default hover:text-brand-hover text-sm"
                        href={email.sourceUrl}
                        target="_blank"
                    >
                        <DownloadIcon /> {t('originalDataPreview.email.downloadButton')}
                    </a>
                </div>
            </div>

            <div className="px-6 py-5">
                <Frame>
                    <style>{contentStyle}</style>
                    <div
                        className={classnames(
                            'email--html',
                            !isHtml && 'email--plain',
                            isDarkMode && 'email--dark-mode'
                        )}
                        dangerouslySetInnerHTML={{ __html: emailContent }}
                    />
                </Frame>
            </div>

            {!!remainingAttachments.length && (
                <div className="px-6 py-5 border-t border-solid border-primary flex gap-6 flex-wrap justify-start">
                    {remainingAttachments.map((attachment) => {
                        if (!attachment) {
                            console.error('Invalid attachment cannot be displayed.');
                            return;
                        }

                        return (
                            <a
                                className="inline-flex gap-3 items-center max-w-96 text-primary border border-solid border-primary rounded px-4 py-3 shadow-sm"
                                target="_blank"
                                href={attachment.url}
                                key={attachment.url}
                            >
                                <span className="text-sm px-1">
                                    <FileIcon className="flex-none" />
                                </span>

                                <span className="flex flex-col">
                                    <span className="text-sm text-primary font-medium">{attachment.name}</span>
                                    <span className="text-sm text-secondary">{attachment.type}</span>
                                </span>
                            </a>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default EmailPreview;
