import * as React from 'react';
import Login from './pages/LoginPage';
import ProviderLogin from './pages/ProviderLoginPage';
import ForgotPassword from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPasswordPage';
import SignUp from './pages/SignUpPage';

import {
    FORGOT_PATH,
    LOGIN_PATH,
    PROVIDER_AUTHORIZED_PATH,
    PROVIDER_LOGIN_PATH,
    PROVIDER_LOGIN_PROVIDER_PATH,
    RESET_PATH,
    SIGNUP_PATH,
} from './constants';
import { canCreateUser } from '../users/utils';

export default [
    {
        path: LOGIN_PATH,
        loginRequired: false,
        component: Login,
    },
    {
        path: PROVIDER_LOGIN_PATH,
        loginRequired: false,
        component: ProviderLogin,
    },
    {
        path: PROVIDER_LOGIN_PROVIDER_PATH,
        loginRequired: false,
        component: ProviderLogin,
    },
    {
        path: PROVIDER_AUTHORIZED_PATH,
        loginRequired: false,
        component: (props) => <ProviderLogin providerAuthorized={true} {...props} />,
    },
    {
        path: FORGOT_PATH,
        loginRequired: false,
        component: ForgotPassword,
    },
    {
        path: RESET_PATH,
        loginRequired: false,
        component: ResetPassword,
    },
    {
        path: SIGNUP_PATH,
        component: SignUp,
        condition: ({ user }) => canCreateUser(user),
    },
];
