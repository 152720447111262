import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { useTranslation } from 'react-i18next';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

const PASSWORD_FORM_DEFAULT_VALUES = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
};

const PasswordPage = () => {
    const { t } = useTranslation('users');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: PASSWORD_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const [changePassword] = useMutation(CHANGE_PASSWORD);
    const handleUpdateProfile = async (formData) => {
        try {
            await changePassword({
                variables: {
                    oldPassword: formData.oldPassword,
                    newPassword1: formData.newPassword,
                    newPassword2: formData.newPasswordConfirm,
                },
            });
            publishToast({ description: t('password.form.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('password.form.updateError'), status: 'error' });
        }
    };

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('password.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <SettingsFormCard onSubmit={form.handleSubmit(handleUpdateProfile)}>
                        <Controller
                            name="oldPassword"
                            control={form.control}
                            rules={{ required: t('password.form.oldPasswordRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('password.form.oldPassword')}
                                    required={true}
                                    error={formErrors.oldPassword}
                                >
                                    <StringField {...field} type="password" placeholder="••••••••••••" required />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="newPassword"
                            control={form.control}
                            rules={{ required: t('password.form.newPasswordRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('password.form.newPassword')}
                                    required={true}
                                    error={formErrors.newPassword}
                                >
                                    <StringField {...field} type="password" placeholder="••••••••••••" required />
                                </FormField>
                            )}
                        />

                        <Controller
                            name="newPasswordConfirm"
                            control={form.control}
                            rules={{ required: t('password.form.newPasswordConfirmRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('password.form.newPasswordConfirm')}
                                    required={true}
                                    error={formErrors.newPasswordConfirm}
                                >
                                    <StringField {...field} type="password" placeholder="••••••••••••" required />
                                </FormField>
                            )}
                        />

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('password.form.saveButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default PasswordPage;
