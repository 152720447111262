import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import { useTranslation } from 'react-i18next';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_DETAILS, UPDATE_CUSTOMER } from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

const CUSTOMER_CUSTOMIZATIONS_FORM_DEFAULT_VALUES = {
    customizationKey: '',
};

const CustomerCustomizationsPage = () => {
    const { t } = useTranslation('customer');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: CUSTOMER_CUSTOMIZATIONS_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const { data } = useQuery(GET_COMPANY_DETAILS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {},
    });
    const customer = data?.viewer?.customer;

    useEffect(() => {
        if (customer) {
            form.reset({
                ...CUSTOMER_CUSTOMIZATIONS_FORM_DEFAULT_VALUES,
                customizationKey: customer?.customizationKey || '',
            });
        }
    }, [customer]);

    const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
    const handleUpdateCustomizationConfig = async (formData) => {
        try {
            await updateCustomer({ variables: { input: formData } });
            publishToast({ description: t('customizations.form.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('customizations.form.updateError'), status: 'error' });
        }
    };

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('customizations.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <SettingsFormCard onSubmit={form.handleSubmit(handleUpdateCustomizationConfig)}>
                        <Controller
                            name="customizationKey"
                            control={form.control}
                            render={({ field }) => (
                                <FormField
                                    label={t('customizations.form.customizationKey')}
                                    error={formErrors.customizationKey}
                                >
                                    <StringField {...field} />
                                </FormField>
                            )}
                        />

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('profile.form.saveButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default CustomerCustomizationsPage;
