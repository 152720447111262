import DocumentAssistance from '../../../document/pages/Assistance';
import {
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    GET_RELATED_DOCUMENT,
    INVOICE_EXTRACT_TEXT,
    RE_UPLOAD_FILE,
    REOPEN_FOR_ASSISTANCE,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../queries.ts';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../constants.ts';
import { DocumentType } from '../../../document/constants.ts';
import {
    getCustomerFieldConditionalComponents,
    getCustomerFieldGroupConditionalComponents,
} from '../../../document/components/CustomerField.tsx';
import { getContactFieldConditionalComponents } from '../../../document/components/ContactField.tsx';
import { getAddressFieldConditionalComponents } from '../../../document/components/AddressField.tsx';
import { ArticleNumberFieldConditionalComponents } from '../../../document/components/ArticleNumberField.tsx';
import * as React from 'react';

import { fieldConfigToLegacyConfigFormat, fieldToFormFieldConfig } from '../../../document/pages/Assistance/utils.tsx';
import { merge } from 'lodash';
import { useAssistanceContext } from '../../../document/pages/Assistance/AssistanceContext.tsx';

export const documentTypeName = 'invoice';

const fieldNameToGroupTypeMap = {
    senderSupplier: 'CustomerField',
    recipientAddress: 'AddressField',
    recipientContact: 'ContactField',
    senderAddress: 'AddressField',
    senderContact: 'ContactField',
};

export const getHeaderFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    headerFields,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const configs = {};

    fieldConfigToLegacyConfigFormat(fieldConfigs, configs, client, recordId, documentConfig, fieldNameToGroupTypeMap);

    // TODO: maybe this information can come from the backend to increase flexibility - for now it's fine though
    const legacyConfig = {
        senderSupplier: {
            groupFieldProps: {},
        },
        senderAddress: {
            groupFieldProps: {
                addressId2: {
                    required: false,
                    hidden: !headerFields.includes('senderAddress.addressId2'),
                },
            },
            groupInputProps: {
                misc: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                email: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                phone: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
            },
        },
        recipientContact: {
            groupFieldProps: {
                contactId: {},
            },
        },
        senderContact: {
            groupFieldProps: {
                contactId: {},
            },
        },
    };
    return merge(legacyConfig, configs);
};

export const getLineItemFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const { handlers, document, documentConfiguration } = useAssistanceContext();

    const configs = {};
    if (fieldConfigs) {
        fieldConfigs.forEach((field) => {
            configs[field.name] = fieldToFormFieldConfig(field, {
                client,
                recordId,
                documentConfig,
                documentConfiguration,
                document,
                handlers,
            });
        });
    }

    // TODO: maybe this information can come from the backend to increase flexibility - for now it's fine though
    const legacyConfig = {
        articleNumber: {},
        articlePartitionSpecificNumber: {},
        quantity: {
            fieldType: 'FloatField',
        },
        unitPrice: {
            fieldType: 'FloatField',
        },
        totalPrice: {
            fieldType: 'FloatField',
        },
    };

    return merge(legacyConfig, configs);
};

const InvoiceAssistance = (props) => {
    const documentConfiguration = {
        documentType: DocumentType.Invoice,
        documentTypeName: 'invoice',
        GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
        GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
        GET_RELATED_DOCUMENT: GET_RELATED_DOCUMENT,
        RETRY_STEP: RETRY_STEP,
        REOPEN_FOR_ASSISTANCE: REOPEN_FOR_ASSISTANCE,
        ASSISTANCE_PATH: ASSISTANCE_PATH,
        ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
        OVERVIEW_PATH: OVERVIEW_PATH,
        OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
        OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
        CHANNEL_PATH: CHANNEL_PATH,
        CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
        CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
        RE_UPLOAD_FILE: RE_UPLOAD_FILE,
        EXTRACT_TEXT: INVOICE_EXTRACT_TEXT,
        SEND_TO_LABELING: SEND_TO_LABELING,
        getHeaderFieldConfigs: getHeaderFieldConfigs,
        getLineItemFieldConfigs: getLineItemFieldConfigs,
        components: {
            Field: [
                ...getCustomerFieldConditionalComponents('sender_supplier'),
                ...getContactFieldConditionalComponents('recipient_contact'),
                ...getContactFieldConditionalComponents('sender_contact'),
                ...getAddressFieldConditionalComponents('recipient_address'),
                ...getAddressFieldConditionalComponents('sender_address'),
                ...ArticleNumberFieldConditionalComponents,
            ],
            HeaderDataFieldGroup: [...getCustomerFieldGroupConditionalComponents('sender_supplier')],
        },
    };

    return <DocumentAssistance documentConfiguration={documentConfiguration} {...props} />;
};

export default InvoiceAssistance;
