import { useTranslation } from 'react-i18next';
import classnames from '../../../../core/utils/classnames.tsx';
import * as React from 'react';
import Modal from '../../../../core/components/Modal.tsx';
import AutomationRateChart from '../../../components/AutomationRateChart.tsx';
import { useQuery } from '@apollo/client';
import { canUseDemoMode } from '../../../../users/utils';
import { useApplicationContext } from '../../../../core/contexts/ApplicationContext';
import { format, addDays, subDays, differenceInDays } from 'date-fns';

const getDateRange = (startDate, endDate) => {
    const fromDate = new Date(startDate);
    const toDate = new Date(endDate);
    const diff = differenceInDays(toDate, fromDate);
    let range = [];
    for (let i = 0; i < diff; i++) {
        range.push(format(addDays(new Date(startDate), i), 'yyyy-MM-dd'));
    }
    return range;
};

const ChannelAutomationRateChart = React.memo((props: any) => {
    let { channelName, channelId, documentName, documentConfiguration } = props;

    const { user } = useApplicationContext();

    const now = new Date();
    const startDate = format(subDays(now, 30), 'yyyy-MM-dd');
    const endDate = format(now, 'yyyy-MM-dd');

    const query = channelId
        ? documentConfiguration.GET_CHANNEL_AUTOMATION_RATIO
        : documentConfiguration.GET_CUSTOMER_AUTOMATION_RATIO;

    const {
        data,
        error,
        loading,
        refetch: tableRefetch,
    } = useQuery(query, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false,
        variables: {
            channelId,
            startDate,
            endDate,
        },
    });

    let chartData = channelId
        ? data?.['channel' + documentName + 'AutomationRatio']
        : data?.['customer' + documentName + 'AutomationRatio'];

    if (canUseDemoMode(user)) {
        let demoDateRange = getDateRange(startDate, endDate);
        const weekOfDay = now.getDay() || 7; // getDay returns 0 for Sunday, so convert to 7 for consistency
        const demoAutomationRateBase = documentConfiguration.demoAutomationRatePath;
        let demoAutomationRate = JSON.parse(JSON.stringify(demoAutomationRateBase));
        // show automation rates until yesterday, with Saturday/Sunday dips at the correct location
        demoAutomationRate.automationDays.splice(0, weekOfDay - 1 + 5);
        const demoAutomationDays = demoDateRange.map((date, index) => {
            demoAutomationRate.automationDays[index].date = date;
            return demoAutomationRate.automationDays[index];
        });
        chartData = {
            automationDays: demoAutomationDays,
            last5DaysAutomationRatio: demoAutomationRate.last5DaysAutomationRatio,
        };
    }

    return (
        <AutomationRateChart
            label={channelName}
            data={chartData?.automationDays || []}
            automationRatio={chartData?.last5DaysAutomationRatio}
        />
    );
});

const AutomationRateModal = (props) => {
    const { t } = useTranslation('assistance');

    const { className, channelName, channelId, documentName, documentConfiguration, ...modalProps } = props;

    return (
        <Modal
            className={classnames('!max-w-[800px]', className)}
            title={t('overview.automationRateModal.title')}
            allowMaximize={true}
            {...modalProps}
        >
            <ChannelAutomationRateChart
                channelName={channelName}
                channelId={channelId}
                documentName={documentName}
                documentConfiguration={documentConfiguration}
            />
        </Modal>
    );
};

export default AutomationRateModal;
