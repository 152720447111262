import * as React from 'react';
import classnames from '../../../utils/classnames.tsx';

interface HeaderTitleProps {
    className?: string;
    children?: React.ReactNode;
}

const PageHeaderTitle = ({ className, children }: HeaderTitleProps) => {
    return <h1 className={classnames('text-primary font-semibold text-base', className)}>{children}</h1>;
};

export default PageHeaderTitle;
