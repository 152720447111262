import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToaster } from '../../../core/components/Toast.tsx';
import { Controller, useForm } from 'react-hook-form';
import SettingsFormCard from '../../../core/components/SettingsFormCard.tsx';
import Button from '../../../core/components/Button.tsx';
import BooleanField from '../../../core/components/Fields/BooleanField.tsx';
import FormField from '../../../core/components/FormField.tsx';
import TagsField from '../../../core/components/Fields/TagsField.tsx';
import StringField from '../../../core/components/Fields/StringField.tsx';

const FORM_DEFAULT_VALUES = {
    enabled: false,
    serializerModule: '',
    headerFields: [],
    lineItemFields: [],
    exportExternalDocumentLink: false,
    exportReceivedBy: false,
    exportWorkistUrl: false,
    overwritePosition: false,
    useShortExternalFileName: false,
};

const OrderConfirmationXmlSerializerConfig = ({ config, onActivate, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            enabled: config?.enabled,
            serializerModule: config?.serializerModule || '',

            headerFields: config?.headerFields || [],
            lineItemFields: config?.lineItemFields || [],
            exportExternalDocumentLink: config?.exportExternalDocumentLink || false,
            exportReceivedBy: config?.exportReceivedBy || false,
            exportWorkistUrl: config?.exportWorkistUrl || false,
            overwritePosition: config?.overwritePosition || false,
            useShortExternalFileName: config?.useShortExternalFileName || false,
        });
    }, [config]);

    const handleSubmit = async (formData) => {
        try {
            if (!config) await onActivate();
            await onSubmit(formData);
            publishToast({ description: t('configPage.xmlSerializer.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.xmlSerializer.updateError'), status: 'error' });
        }
    };

    return (
        <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.xmlSerializer.title')}>
            <Controller
                name="enabled"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.xmlSerializer.form.enabled')} error={formErrors.enabled}>
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="serializerModule"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.xmlSerializer.form.serializerModule')}
                        error={formErrors.serializerModule}
                    >
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="headerFields"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.xmlSerializer.form.headerFields')} error={formErrors.headerFields}>
                        <TagsField
                            {...field}
                            value={field.value.join(',')}
                            onValueChange={(value) => field.onChange(value.split(','))}
                        />
                    </FormField>
                )}
            />

            <Controller
                name="lineItemFields"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.xmlSerializer.form.lineItemFields')}
                        error={formErrors.lineItemFields}
                    >
                        <TagsField
                            {...field}
                            value={field.value.join(',')}
                            onValueChange={(value) => field.onChange(value.split(','))}
                        />
                    </FormField>
                )}
            />

            <Controller
                name="exportExternalDocumentLink"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.xmlSerializer.form.exportExternalDocumentLink')}
                        error={formErrors.exportExternalDocumentLink}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="exportReceivedBy"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.xmlSerializer.form.exportReceivedBy')}
                        error={formErrors.exportReceivedBy}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="exportWorkistUrl"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.xmlSerializer.form.exportWorkistUrl')}
                        error={formErrors.exportWorkistUrl}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="overwritePosition"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.xmlSerializer.form.overwritePosition')}
                        error={formErrors.overwritePosition}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="useShortExternalFileName"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.xmlSerializer.form.useShortExternalFileName')}
                        error={formErrors.useShortExternalFileName}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button type="submit" variant="primary">
                    {t('configPage.xmlSerializer.form.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};
export default OrderConfirmationXmlSerializerConfig;
