import { format, isAfter, isBefore, isValid as checkValid, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useControllableState } from '../../utils/useControllableState.tsx';
import { getDateLocalizations } from '../../utils/localization.ts';
import Popover from '../Popover.tsx';
import Field, { FieldProps } from './Field.tsx';
import { withIcon } from '../Icon.tsx';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../utils/classnames.tsx';
import Calendar from '../Calendar.tsx';
import Tooltip from '../Tooltip.tsx';
import { mergeRefs } from '../../utils/mergeRefs.tsx';

const ClearIcon = withIcon(faXmark);

export interface DateFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {
    format?: string;
    fromYear?: number | string;
    toYear?: number | string;
    fromDate?: Date;
    toDate?: Date;
    side?: 'top' | 'bottom' | 'left' | 'right';
    align?: 'start' | 'center' | 'end';
    disableDatePicker?: boolean;
    disableValidation?: boolean;
}

export const defaultDateFormat = 'dd.MM.yyyy';
export const isoDateFormat = 'yyyy-MM-dd';

const DateField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onChange,
    onBlur,
    onFocus,
    readOnly,
    disabled,
    required,
    className,
    controls,
    inputRef: propsInputRef = undefined,
    // field specific props
    format: dateFormat = defaultDateFormat, // presentation format, use german date format by default
    fromYear = 2000,
    toYear = new Date(new Date().setFullYear(new Date().getFullYear() + 25)).getFullYear(), // in 25 years
    fromDate = undefined,
    toDate = undefined,
    disableDatePicker = false,
    disableValidation = false,
    // popover props
    side,
    align,
    ...props
}: DateFieldProps) => {
    const { t } = useTranslation('assistance');

    const inputRef = useRef<HTMLInputElement>(null);

    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);
    const [inputValue, setInputValue] = useState<string>(defaultValue);

    useEffect(() => {
        // while editing don't update input value on external value change
        if (isPickerOpen) return;

        const isValidIsoDate = value && checkValid(parse(value, isoDateFormat, new Date()));
        setInputValue(isValidIsoDate ? format(parse(value, isoDateFormat, new Date()), dateFormat) : '');
    }, [value, isPickerOpen, dateFormat]);

    // validation
    toYear = typeof toYear === 'string' ? parseInt(toYear) : toYear;
    fromYear = typeof fromYear === 'string' ? parseInt(fromYear) : fromYear;
    const minDate = fromDate != null ? new Date(fromDate.setHours(0, 0, 0)) : new Date(fromYear, 0, 1);
    const maxDate = toDate != null ? new Date(toDate.setHours(0, 0, 0)) : new Date(toYear + 1, 0, 1);

    const isValidDate = (date: Date) => {
        return checkValid(date) && !isBefore(date, minDate) && !isAfter(date, maxDate);
    };

    const handleFocus = (e: any) => {
        setIsPickerOpen(true);
        if (!isPickerOpen) onFocus?.(e);
    };

    const handleBlur = (e: any) => {
        if (!isPickerOpen) onBlur?.(e);
    };

    const handleChange = (e: any) => {
        setInputValue(e.target.value);
        onChange?.(e);

        if (e.target.value === '') {
            setValue(null);
            return;
        }

        const parsedDate = parse(e.target.value, dateFormat, new Date());
        if (checkValid(parsedDate) && isValidDate(parsedDate)) {
            setValue(format(parsedDate, isoDateFormat));
        } else {
            setValue(null);
        }
    };

    const handleClear = () => {
        setInputValue('');
        setValue(null);
        inputRef.current?.focus();
    };

    const handleDateSelect = (date: Date | undefined) => {
        setInputValue(date ? format(date, dateFormat) : '');
        setValue(date ? format(date, isoDateFormat) : null);
    };

    const handleOpenChange = (open: boolean) => {
        setIsPickerOpen(open);
        if (!open) {
            // reset if closed
            const parsedDate = value ? parse(value, isoDateFormat, new Date()) : null;
            setInputValue(parsedDate && isValidDate(parsedDate) ? format(parsedDate, dateFormat) : '');
            // needed to trigger update
            // TODO: maybe not like this
            setTimeout(() => {
                onBlur?.(null);
            }, 0);
        }
    };

    const isInputValueValid = inputValue ? checkValid(parse(inputValue, dateFormat, new Date())) : !required; // empty is okay if not required

    const parsedInputDate = isInputValueValid && inputValue ? parse(inputValue, dateFormat, new Date()) : undefined;

    const isDateOutsideRange =
        isInputValueValid &&
        parsedInputDate &&
        (isBefore(parsedInputDate, minDate) || isAfter(parsedInputDate, maxDate));

    const isValid = disableValidation ? true : !isDateOutsideRange && isInputValueValid;

    let tooltipContent = '';
    if (!isValid) {
        if (isDateOutsideRange) {
            tooltipContent = t('assistance:fields.dateField.invalid', {
                minDate: format(minDate, dateFormat),
                maxDate: format(maxDate, dateFormat),
            });
        } else if (!isInputValueValid) {
            tooltipContent = t('assistance:fields.dateField.invalidFormat', { format: dateFormat });
        }
    }

    const parsedDate = value ? parse(value, isoDateFormat, new Date()) : undefined;

    return (
        <Popover open={isPickerOpen} onOpenChange={handleOpenChange}>
            <Tooltip
                content={tooltipContent}
                open={isPickerOpen && !isValid ? true : isValid ? false : undefined}
                side="bottom"
            >
                <Popover.Trigger
                    className="w-full"
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    // @ts-ignore
                    type="text"
                    disabled={readOnly}
                    asChild
                >
                    <Field
                        className={classnames(
                            !isValid &&
                                'border-confidence-low hover:border-confidence-low focus-within:border-confidence-low focus-within:outline-error',
                            className
                        )}
                        readOnly={readOnly}
                        disabled={disabled}
                        ref={ref}
                    >
                        <Field.Input>
                            <input
                                ref={mergeRefs(inputRef, propsInputRef)}
                                type="text"
                                value={inputValue || ''}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                readOnly={readOnly}
                                disabled={disabled}
                                required={required}
                                className="px-2 py-1.5 flex-1 min-w-0 bg-transparent outline-none"
                                {...props}
                            />
                        </Field.Input>
                        <Field.Controls>
                            <>
                                {!readOnly && inputValue && (
                                    <Field.ControlButton onClick={handleClear}>
                                        <ClearIcon />
                                    </Field.ControlButton>
                                )}
                                {controls}
                            </>
                        </Field.Controls>
                    </Field>
                </Popover.Trigger>
            </Tooltip>

            <Popover.Content
                className={classnames('w-auto p-4', disableDatePicker ? 'hidden' : '')}
                onOpenAutoFocus={(e) => {
                    e.preventDefault();
                }}
                side={side}
                align={align}
                style={{ minWidth: 'auto' }}
            >
                <Calendar
                    mode="single"
                    defaultMonth={parsedDate}
                    selected={parsedDate}
                    onSelect={handleDateSelect}
                    onWeekNumberClick={undefined}
                    fromYear={fromDate != null ? fromDate.getFullYear() : fromYear}
                    toYear={toDate != null ? toDate.getFullYear() : toYear}
                    toDate={toDate}
                    locale={getDateLocalizations()}
                    disabled={{
                        before: minDate ? minDate : undefined,
                        after: maxDate ? maxDate : undefined,
                    }}
                    showWeekNumber
                    fixedWeeks
                    showOutsideDays
                />
            </Popover.Content>
        </Popover>
    );
};

export default DateField;
