// Re-export hooks and contexts
export { useDocumentStore, DocumentStoreProvider, DocumentContext } from './documentStore.tsx';
export { useChannelStore, ChannelStoreProvider, ChannelContext } from './channelStore.tsx';

// Combined provider for both stores
import React from 'react';
import { DocumentStoreProvider } from './documentStore.tsx';
import { ChannelStoreProvider } from './channelStore.tsx';

export const StoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <DocumentStoreProvider>
            <ChannelStoreProvider>{children}</ChannelStoreProvider>
        </DocumentStoreProvider>
    );
};
