import * as React from 'react';
import { useRef } from 'react';
import classnames from '../utils/classnames.tsx';
import { Slot } from '@radix-ui/react-slot';
import { mergeRefs } from '../utils/mergeRefs.tsx';
import useStickyState from '../utils/useStickyState.tsx';
import { RowControlIconButtonProps } from '../../assistance/components/RecordTable.tsx';

const Table = ({ className, ...props }: React.ComponentPropsWithRef<'table'>) => {
    return (
        <table
            className={classnames(
                'table-fixed border-separate border-spacing-0 border border-primary border-solid rounded-lg',
                className
            )}
            {...props}
        />
    );
};

interface TableHead extends React.ComponentPropsWithRef<'thead'> {
    sticky?: boolean;
}

const TableHead = ({ className, sticky, ...props }: TableHead) => {
    const ref = useRef<HTMLTableSectionElement>(null);
    const { top } = useStickyState(ref, !!sticky);
    return (
        <thead
            className={classnames(
                sticky && 'sticky top-0 z-10',
                sticky && top && 'shadow [clip-path:inset(0_0_-15px_0)]',
                className
            )}
            {...props}
            data-state={sticky ? (top ? 'sticky' : 'static') : undefined}
            ref={mergeRefs(ref, props.ref)}
        />
    );
};

const TableBody = ({ className, ...props }: React.ComponentPropsWithRef<'tbody'>) => {
    return <tbody className={className} {...props} />;
};

const TableRow = ({ className, ...props }: React.ComponentPropsWithRef<'tr'>) => {
    return (
        <tr
            className={classnames('group border-b border-primary border-solid last:border-none', className)}
            {...props}
        />
    );
};

interface TableCellProps extends React.ComponentPropsWithRef<'td'> {
    sticky?: boolean | 'left' | 'right';
}

const TableCell = ({ className, sticky, ...props }: TableCellProps) => {
    const ref = useRef<HTMLTableCellElement>(null);
    const { left, right } = useStickyState(ref, !!sticky);
    return (
        <td
            className={classnames(
                'px-4 py-2 h-14 bg-primary text-primary align-middle text-sm border-b border-primary border-solid group-last:border-b-0 group-last:first:rounded-bl-lg group-last:last:rounded-br-lg',
                sticky ? (sticky === 'right' ? 'sticky right-0' : 'sticky left-0') : '',
                sticky === 'right' && right && 'shadow [clip-path:inset(0_0_0_-15px)]',
                sticky && sticky !== 'right' && left && 'shadow [clip-path:inset(0_-15px_0_0)]',
                className
            )}
            {...props}
            data-state={sticky ? (left || right ? 'sticky' : 'static') : undefined}
            ref={mergeRefs(ref, props.ref)}
        />
    );
};

interface TableHeadCellProps extends React.ComponentPropsWithRef<'th'> {
    sticky?: boolean | 'left' | 'right';
}

const TableHeadCell = ({ className, sticky, ...props }: TableHeadCellProps) => {
    const ref = useRef<HTMLTableHeaderCellElement>(null);
    const { left, right } = useStickyState(ref, !!sticky);

    return (
        <th
            className={classnames(
                'p-4 py-3 whitespace-nowrap text-secondary align-middle text-left text-sm border-b border-primary border-solid bg-secondary first:rounded-tl-lg last:rounded-tr-lg font-medium',
                sticky ? (sticky === 'right' ? 'sticky right-0' : 'sticky left-0') : '',
                sticky === 'right' && right && 'shadow [clip-path:inset(0_0_0_-15px)]',
                sticky && sticky !== 'right' && left && 'shadow [clip-path:inset(0_-15px_0_0)]',
                className
            )}
            {...props}
            data-state={sticky ? (left || right ? 'sticky' : 'static') : undefined}
            ref={mergeRefs(ref, props.ref)}
        />
    );
};

export default Object.assign(Table, {
    Head: TableHead,
    Body: TableBody,
    Row: TableRow,
    Cell: TableCell,
    HeadCell: TableHeadCell,
});

export const TableScrollWrapper = ({ children, className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div
            className={classnames(
                'overflow-x-auto overflow-y-auto border-primary border border-solid rounded-lg',
                className
            )}
            {...props}
        >
            <Slot className="border-none">{children}</Slot>
        </div>
    );
};
export const RowControlIconButton = ({ className, active = false, asChild, ...props }: RowControlIconButtonProps) => {
    const Comp = asChild ? Slot : 'button';
    return (
        <Comp
            className={classnames(
                'flex items-center justify-center gap-2 rounded transition-colors duration-200 hover:bg-tertiary w-8 h-8 cursor-pointer text-sm font-medium text-primary hover:text-primary disabled:opacity-50 disabled:pointer-events-none data-[state=open]:bg-brand data-[state=open]:text-brand',
                active && '!bg-brand !text-brand',
                className
            )}
            {...props}
        />
    );
};
