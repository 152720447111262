import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../core/components/Button.tsx';
import StringField from '../../../../core/components/Fields/StringField.tsx';
import FormField from '../../../../core/components/FormField.tsx';
import Modal from '../../../../core/components/Modal.tsx';

const FORM_DEFAULT_VALUES = {
    name: '',
};

const AddChannelModal = ({ className, children, onSubmit, onClose, data, ...modalProps }: any) => {
    const { t } = useTranslation('assistance');

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (data) {
            form.reset({ name: data.name });
        }
    }, [data]);

    return (
        <Modal
            visible={true}
            onClose={onClose}
            title={t('overview.addChannelModal.title')}
            buttons={
                <>
                    <Button type="button" onClick={onClose} loading={form.formState.isSubmitting}>
                        {t('overview.addChannelModal.cancel')}
                    </Button>

                    <Button variant="primary" type="submit" form="modal-form" loading={form.formState.isSubmitting}>
                        {t('overview.addChannelModal.confirm')}
                    </Button>
                </>
            }
            {...modalProps}
        >
            <form onSubmit={form.handleSubmit(onSubmit)} id="modal-form">
                <Controller
                    name="name"
                    control={form.control}
                    rules={{
                        required: t('overview.addChannelModal.nameRequired'),
                    }}
                    render={({ field }) => (
                        <FormField label={t('overview.addChannelModal.name')} error={formErrors.name} required>
                            <StringField {...field} />
                        </FormField>
                    )}
                />
            </form>
        </Modal>
    );
};

export default AddChannelModal;
