import { useQuery } from '@apollo/client';
import { GET_ALL_CHANNELS as GET_ORDER_CHANNELS } from '../../products/orders/queries.ts';
import { GET_ALL_CHANNELS as GET_ORDER_CONFIRMATION_CHANNELS } from '../../products/order_confirmations/queries.ts';
import { GET_ALL_CHANNELS as GET_INVOICE_CHANNELS } from '../../products/invoices/queries.ts';
import { GET_ALL_CHANNELS as GET_RFQ_CHANNELS } from '../../products/rfqs/queries.ts';
import { GET_ALL_CHANNELS as GET_PROPERTY_BILL_CHANNELS } from '../../products/db_immo/queries.ts';
import { GET_ALL_CHANNELS as GET_DELIVERY_NOTE_CHANNELS } from '../../products/delivery_notes/queries.ts';
import { GET_ALL_CHANNELS as GET_LIST_OF_SERVICES_CHANNELS } from '../../products/list_of_services/queries.ts';
import { GET_ALL_CHANNELS as GET_UNIVERSAL_CHANNELS } from '../../products/universal/queries.ts';
import { DocumentType } from '../constants.ts';
import { QueryResult } from '@apollo/client/react/types/types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

export interface IChannel {
    id: string;
    name: string;
    __typename: string;
}

interface IChannelState {
    channelId: string | null;
}

interface IChannelActions {
    setActiveChannel: (channelId: string | null) => void;
}

interface IChannelQueries {
    getDocumentChannels: (documentType: DocumentType, queryParams?: any) => QueryResult;
}

type IChannelStore = IChannelState & IChannelActions & IChannelQueries;

// Context for channel document data
export const ChannelContext = createContext({ activeChannel: null, documentType: null });

// Create channel store context
const ChannelStoreContext = createContext<IChannelStore | null>(null);

const getAllChannelsByDocumentType = {
    [DocumentType.Order]: GET_ORDER_CHANNELS,
    [DocumentType.OrderConfirmation]: GET_ORDER_CONFIRMATION_CHANNELS,
    [DocumentType.Invoice]: GET_INVOICE_CHANNELS,
    [DocumentType.Rfq]: GET_RFQ_CHANNELS,
    [DocumentType.PropertyBill]: GET_PROPERTY_BILL_CHANNELS,
    [DocumentType.DeliveryNote]: GET_DELIVERY_NOTE_CHANNELS,
    [DocumentType.ListOfServices]: GET_LIST_OF_SERVICES_CHANNELS,
    [DocumentType.Universal]: GET_UNIVERSAL_CHANNELS,
};

/**
 * Channel Store Provider component
 */
export const ChannelStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    // State for channel data
    const [channelId, setChannelId] = useState<string | null>(null);

    // Action handlers
    const setActiveChannel = useCallback((newChannelId: string | null) => {
        setChannelId(newChannelId);
    }, []);

    // Query functions that don't depend on component state
    const getDocumentChannels = useCallback((documentType: DocumentType, queryParams = {}) => {
        return useQuery(getAllChannelsByDocumentType[documentType], {
            ...queryParams,
        });
    }, []);

    // Create the store value
    const storeValue = useMemo(
        () => ({
            // State
            channelId,
            // Actions
            setActiveChannel,
            // Queries
            getDocumentChannels,
        }),
        [channelId, setActiveChannel, getDocumentChannels]
    );

    return <ChannelStoreContext.Provider value={storeValue}>{children}</ChannelStoreContext.Provider>;
};

/**
 * Custom hook to access channel store functionality
 */
export const useChannelStore = () => {
    const context = useContext(ChannelStoreContext);
    if (!context) {
        throw new Error('useChannelStore must be used within a ChannelStoreProvider');
    }
    return context;
};
