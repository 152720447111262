import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import { useTranslation } from 'react-i18next';
import SelectField from '../../core/components/Fields/SelectField.tsx';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation, useQuery } from '@apollo/client';
import { CHANGE_EMAIL_NOTIFICATION_SETTINGS, GET_COMPANY_DETAILS } from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import { getRange, makeCron, parseCron } from '../services.ts';
import { setDay, format } from 'date-fns';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

const CUSTOMER_NOTIFICATIONS_FORM_DEFAULT_VALUES = {
    option: 'always',
    day: '1',
    time: '8',
    additionalTime: '',
};

const CustomerNotificationsPage = () => {
    const { t } = useTranslation('customer');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: CUSTOMER_NOTIFICATIONS_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const { data } = useQuery(GET_COMPANY_DETAILS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {},
    });
    const customer = data?.viewer?.customer;

    useEffect(() => {
        if (customer) {
            const enabled = customer?.sendEmailNotification || true;
            const cron = customer?.emailNotificationCron || '';
            const cronParts = parseCron(cron);
            form.reset({
                ...CUSTOMER_NOTIFICATIONS_FORM_DEFAULT_VALUES,
                option: !enabled ? 'never' : cronParts.option ? cronParts.option : 'always',
                day: cronParts.day || '1',
                time: cronParts.time || '8',
                additionalTime: cronParts.additionalTime || '',
            });
        }
    }, [customer]);

    const [changeNotifications] = useMutation(CHANGE_EMAIL_NOTIFICATION_SETTINGS);
    const handleUpdateCustomer = async (formData) => {
        try {
            await changeNotifications({
                variables: {
                    sendEmailNotification: formData.option != 'never',
                    emailNotificationCron: ['daily', 'weekly'].includes(formData.option)
                        ? makeCron(formData.option, formData.day, formData.time, formData.additionalTime)
                        : '',
                },
            });
            publishToast({ description: t('notifications.form.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('notifications.form.updateError'), status: 'error' });
        }
    };

    const options = {
        never: t('notifications.form.options.never'),
        always: t('notifications.form.options.always'),
        daily: t('notifications.form.options.daily'),
        weekly: t('notifications.form.options.weekly'),
    };
    const timeOptions = getRange(6, 21).map((hour) => [hour.toString(), `${hour.toString().padStart(2, '0')}:00`]);
    const dayOptions = getRange(1, 6).map((day) => [day.toString(), format(setDay(new Date(), day), 'EEEE')]);

    const option = form.watch('option');

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('notifications.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    <SettingsFormCard onSubmit={form.handleSubmit(handleUpdateCustomer)}>
                        <Controller
                            name="option"
                            control={form.control}
                            rules={{ required: t('notifications.form.optionRequired') }}
                            render={({ field }) => (
                                <FormField
                                    label={t('notifications.form.option')}
                                    required={true}
                                    error={formErrors.option}
                                >
                                    <SelectField
                                        options={Object.entries(options).map(([key, value]) => ({
                                            label: value,
                                            value: key,
                                        }))}
                                        required={true}
                                        renderOption={(option) => option.label}
                                        {...field}
                                    />
                                </FormField>
                            )}
                        />

                        {option == 'weekly' && (
                            <Controller
                                name="day"
                                control={form.control}
                                rules={{ required: t('notifications.form.dayRequired') }}
                                render={({ field }) => (
                                    <FormField
                                        label={t('notifications.form.day')}
                                        required={true}
                                        error={formErrors.day}
                                    >
                                        <SelectField
                                            options={dayOptions.map(([key, value]) => ({ label: value, value: key }))}
                                            required={true}
                                            renderOption={(option) => option.label}
                                            {...field}
                                        />
                                    </FormField>
                                )}
                            />
                        )}

                        {['daily', 'weekly'].includes(option) && (
                            <Controller
                                name="time"
                                control={form.control}
                                rules={{ required: t('notifications.form.timeRequired') }}
                                render={({ field }) => (
                                    <FormField
                                        label={t('notifications.form.time')}
                                        required={true}
                                        error={formErrors.time}
                                    >
                                        <SelectField
                                            options={timeOptions.map(([key, value]) => ({ label: value, value: key }))}
                                            required={true}
                                            renderOption={(option) => option.label}
                                            {...field}
                                        />
                                    </FormField>
                                )}
                            />
                        )}

                        {option == 'daily' && (
                            <Controller
                                name="additionalTime"
                                control={form.control}
                                render={({ field }) => (
                                    <FormField
                                        label={t('notifications.form.additionalTime')}
                                        error={formErrors.additionalTime}
                                    >
                                        <SelectField
                                            options={timeOptions.map(([key, value]) => ({ label: value, value: key }))}
                                            renderOption={(option) => option.label}
                                            {...field}
                                        />
                                    </FormField>
                                )}
                            />
                        )}

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('notifications.form.saveButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default CustomerNotificationsPage;
