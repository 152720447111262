import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { camelCase, snakeCase } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import Button from '../../core/components/Button.tsx';
import FormField from '../../core/components/FormField.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import BooleanField from '../../core/components/Fields/BooleanField.tsx';
import TagsField from '../../core/components/Fields/TagsField.tsx';
import { PercentageSliderField } from './SliderField.tsx';
import InlineSelect from './InlineSelect.tsx';
import { format } from 'date-fns';
import { convertPythonDateTimeFormat } from '../../core/utils/dates.ts';
import Switch from '../../core/components/Switch.tsx';
import JsonField from '../../core/components/Fields/JsonField.tsx';

const ValidationCheckList = ({ value, onValueChange, onBlur }) => {
    const { t } = useTranslation('config');

    const validationChecks = [
        'forgotten_line_items',
        'date_order',
        'erp_lock',
        'line_item_sum',
        'max_quantity',
        'pos_number',
        'remove_bulk_order_summary',
        'total_amount',
        'customer_internal_note',
        'order_matching',
        'offer_matching',
        'multiple_sales_orgs',
        'thousand_separator_quantity',
        'unique_order_number',
    ];

    const requiredValidationChecks = [];

    const addValidationCheck = (name) => {
        onValueChange([...value, name]);
    };

    const removeValidationCheck = (name) => {
        onValueChange(value?.filter((item) => item !== name) || []);
    };

    const handleToggleSwitch = (name, checked) => {
        if (checked) {
            addValidationCheck(name);
        } else {
            removeValidationCheck(name);
        }
        onBlur?.(null);
    };

    return (
        <div className="flex flex-col">
            {validationChecks.map((name) => (
                <label className="text-primary text-sm flex justify-between items-center hover:bg-secondary-light p-1 rounded">
                    <span>{t(`configPage.document.validationCheckNames.${name}`)}</span>
                    <Switch
                        value={value?.includes(name) || requiredValidationChecks.includes(name)}
                        onValueChange={(checked) => handleToggleSwitch(name, checked)}
                        disabled={requiredValidationChecks.includes(name)}
                    />
                </label>
            ))}
        </div>
    );
};

const FORM_DEFAULT_VALUES = {
    automationEnabled: false,
    headerConfidenceThreshold: '',
    lineItemConfidenceThreshold: '',
    dateFormat: '',
    decimalSeparator: ',',
    conversionStyle: '',
    conversionOverrideOriginalFields: false,
    bestEffortConversion: false,
    partitionSpecificUnitConversion: false,
    validationChecks: [],
    keywordsToAssistance: [],

    // documentTypeSpecific
    backfillingEnabled: false,
    documentClassificationThreshold: false,
    variantExtractionExamples: undefined,
    defaultCurrency: 'EUR',
};

export const DocumentConfig = ({ config, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const documentConfig: any = useMemo(() => {
        return config ? Object.fromEntries(config.options.map((option) => [camelCase(option.name), option.value])) : {};
    }, [config]);

    const handleSubmit = async (formData) => {
        try {
            await onSubmit({
                options: Object.entries(formData)
                    .filter(([optionName, optionValue]) => {
                        // documentTypeSpecific options
                        if (optionName === 'backfillingEnabled' && !('backfillingEnabled' in documentConfig))
                            return false;
                        if (
                            optionName === 'variantExtractionExamples' &&
                            !('variantExtractionExamples' in documentConfig)
                        )
                            return false;
                        if (optionName === 'defaultCurrency' && !('defaultCurrency' in documentConfig)) return false;

                        return true;
                    })
                    .map(([optionName, optionValue]: any) => {
                        return {
                            name: snakeCase(optionName),
                            value: optionValue,
                        };
                    }),
            });
            publishToast({ description: t('configPage.document.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.document.updateError'), status: 'error' });
        }
    };

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            automationEnabled: documentConfig?.automationEnabled,
            headerConfidenceThreshold: documentConfig?.headerConfidenceThreshold || '',
            lineItemConfidenceThreshold: documentConfig?.lineItemConfidenceThreshold || '',
            dateFormat: documentConfig?.dateFormat || '',
            decimalSeparator: documentConfig?.decimalSeparator || ',',
            conversionStyle: documentConfig?.conversionStyle || '',
            conversionOverrideOriginalFields: documentConfig?.conversionOverrideOriginalFields,
            bestEffortConversion: documentConfig?.bestEffortConversion,
            partitionSpecificUnitConversion: documentConfig?.partitionSpecificUnitConversion,
            validationChecks: documentConfig?.validationChecks || [],
            keywordsToAssistance: documentConfig?.keywordsToAssistance || [],

            // documentTypeSpecific
            backfillingEnabled: documentConfig?.backfillingEnabled,
            documentClassificationThreshold: documentConfig?.documentClassificationThreshold,
            variantExtractionExamples: documentConfig?.variantExtractionExamples,
            defaultCurrency: documentConfig?.defaultCurrency || 'EUR',
        });
    }, [config]);

    const sampleDate = new Date();
    sampleDate.setMonth(0);
    sampleDate.setDate(31);

    const choices = ['%d.%m.%Y', '%d/%m/%Y', '%d-%m-%Y', '%m/%d/%Y'];

    const formatSample = (value) => `567${value}89`;

    const showDocumentTypeSpecific =
        'backfillingEnabled' in documentConfig ||
        'documentClassificationThreshold' in documentConfig ||
        'variantExtractionExamples' in documentConfig ||
        'defaultCurrency' in documentConfig;

    return (
        <div className="flex flex-col gap-10">
            <SettingsFormCard
                onSubmit={form.handleSubmit(handleSubmit)}
                title={t('configPage.document.generalSections.automation')}
            >
                <Controller
                    name="automationEnabled"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.automationEnabled')}
                            error={formErrors.automationEnabled}
                        >
                            <BooleanField {...field} value={field.value ? 'true' : ''} />
                        </FormField>
                    )}
                />

                <Controller
                    name="headerConfidenceThreshold"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.headerConfidenceThreshold')}
                            error={formErrors.headerConfidenceThreshold}
                        >
                            <PercentageSliderField {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="lineItemConfidenceThreshold"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.lineItemConfidenceThreshold')}
                            error={formErrors.lineItemConfidenceThreshold}
                        >
                            <PercentageSliderField {...field} />
                        </FormField>
                    )}
                />

                <div className="flex justify-end">
                    <Button type="submit" variant="primary">
                        {t('configPage.document.submitButton')}
                    </Button>
                </div>
            </SettingsFormCard>

            <SettingsFormCard
                onSubmit={form.handleSubmit(handleSubmit)}
                title={t('configPage.document.generalSections.dataFormatting')}
            >
                <Controller
                    name="dateFormat"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('configPage.document.form.dateFormat')} error={formErrors.dateFormat}>
                            <InlineSelect
                                value={field.value}
                                onValueChange={(value) => field.onChange({ target: { value } })}
                                allowCustomValue={true}
                                customSample={(value) =>
                                    value &&
                                    !choices.includes(value) &&
                                    format(sampleDate, convertPythonDateTimeFormat(value))
                                }
                                options={choices.map((choice) => ({
                                    label: choice,
                                    value: choice,
                                    sample: format(sampleDate, convertPythonDateTimeFormat(choice)),
                                }))}
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="decimalSeparator"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.decimalSeparator')}
                            error={formErrors.decimalSeparator}
                        >
                            <InlineSelect
                                value={field.value}
                                onValueChange={(value) => field.onChange({ target: { value } })}
                                allowCustomValue={false}
                                customSample={(value) => value && !['.', ','].includes(value) && formatSample(value)}
                                options={[
                                    {
                                        label: t('configPage.document.separators.comma'),
                                        value: ',',
                                        sample: formatSample(','),
                                    },
                                    {
                                        label: t('configPage.document.separators.dot'),
                                        value: '.',
                                        sample: formatSample('.'),
                                    },
                                ]}
                            />
                        </FormField>
                    )}
                />

                <div className="flex justify-end">
                    <Button type="submit" variant="primary">
                        {t('configPage.document.submitButton')}
                    </Button>
                </div>
            </SettingsFormCard>

            <SettingsFormCard
                onSubmit={form.handleSubmit(handleSubmit)}
                title={t('configPage.document.generalSections.unitConversion')}
            >
                <Controller
                    name="conversionStyle"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.conversionStyle')}
                            error={formErrors.conversionStyle}
                        >
                            <InlineSelect
                                value={field.value}
                                onValueChange={(value) => field.onChange({ target: { value } })}
                                allowCustomValue={true}
                                options={[
                                    // TODO: should this be public?
                                    {
                                        label: t('configPage.document.conversionStyles.NO_CONVERSION'),
                                        value: 'NO_CONVERSION',
                                    },
                                    { label: t('configPage.document.conversionStyles.ROUND_UP'), value: 'ROUND_UP' },
                                    {
                                        label: t('configPage.document.conversionStyles.THIRTY_PERCENT_ROUND'),
                                        value: 'THIRTY_PERCENT_ROUND',
                                    },
                                ]}
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="conversionOverrideOriginalFields"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.conversionOverrideOriginalFields')}
                            error={formErrors.conversionOverrideOriginalFields}
                        >
                            <BooleanField {...field} value={field.value ? 'true' : ''} />
                        </FormField>
                    )}
                />

                <Controller
                    name="bestEffortConversion"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.bestEffortConversion')}
                            error={formErrors.bestEffortConversion}
                        >
                            <BooleanField {...field} value={field.value ? 'true' : ''} />
                        </FormField>
                    )}
                />

                <Controller
                    name="partitionSpecificUnitConversion"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.partitionSpecificUnitConversion')}
                            error={formErrors.partitionSpecificUnitConversion}
                        >
                            <BooleanField {...field} value={field.value ? 'true' : ''} />
                        </FormField>
                    )}
                />

                <div className="flex justify-end">
                    <Button type="submit" variant="primary">
                        {t('configPage.document.submitButton')}
                    </Button>
                </div>
            </SettingsFormCard>

            <SettingsFormCard
                onSubmit={form.handleSubmit(handleSubmit)}
                title={t('configPage.document.generalSections.validation')}
            >
                <Controller
                    name="validationChecks"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.validationChecks')}
                            error={formErrors.validationChecks}
                        >
                            <ValidationCheckList
                                value={field.value}
                                onValueChange={(value) => field.onChange({ target: { value } })}
                                onBlur={field.onBlur}
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="keywordsToAssistance"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('configPage.document.form.keywordsToAssistance')}
                            error={formErrors.keywordsToAssistance}
                        >
                            <TagsField
                                {...field}
                                value={field.value.join(',')}
                                onValueChange={(value) => field.onChange(value.split(','))}
                            />
                        </FormField>
                    )}
                />

                <div className="flex justify-end">
                    <Button type="submit" variant="primary">
                        {t('configPage.document.submitButton')}
                    </Button>
                </div>
            </SettingsFormCard>

            {showDocumentTypeSpecific && (
                <SettingsFormCard
                    onSubmit={form.handleSubmit(handleSubmit)}
                    title={t('configPage.document.generalSections.documentTypeSpecific')}
                >
                    {'backfillingEnabled' in documentConfig && (
                        <Controller
                            name="backfillingEnabled"
                            control={form.control}
                            render={({ field }) => (
                                <FormField
                                    label={t('configPage.document.form.backfillingEnabled')}
                                    error={formErrors.backfillingEnabled}
                                >
                                    <BooleanField {...field} value={field.value ? 'true' : ''} />
                                </FormField>
                            )}
                        />
                    )}

                    {'variantExtractionExamples' in documentConfig && (
                        <Controller
                            name="variantExtractionExamples"
                            control={form.control}
                            render={({ field }) => (
                                <FormField
                                    label={t('configPage.document.form.variantExtractionExamples')}
                                    error={formErrors.variantExtractionExamples}
                                >
                                    <JsonField {...field} />
                                </FormField>
                            )}
                        />
                    )}

                    {'defaultCurrency' in documentConfig && (
                        <Controller
                            name="defaultCurrency"
                            control={form.control}
                            render={({ field }) => (
                                <FormField
                                    label={t('configPage.document.form.defaultCurrency')}
                                    error={formErrors.defaultCurrency}
                                >
                                    <InlineSelect
                                        value={field.value}
                                        onValueChange={(value) => field.onChange({ target: { value } })}
                                        allowCustomValue={true}
                                        options={[
                                            { label: 'EUR', value: 'EUR' },
                                            { label: 'USD', value: 'USD' },
                                        ]}
                                    />
                                </FormField>
                            )}
                        />
                    )}

                    {'documentClassificationThreshold' in documentConfig && (
                        <Controller
                            name="documentClassificationThreshold"
                            control={form.control}
                            render={({ field }) => (
                                <FormField
                                    label={t('configPage.document.form.documentClassificationThreshold')}
                                    error={formErrors.documentClassificationThreshold}
                                >
                                    <PercentageSliderField {...field} />
                                </FormField>
                            )}
                        />
                    )}

                    <div className="flex justify-end">
                        <Button type="submit" variant="primary">
                            {t('configPage.document.submitButton')}
                        </Button>
                    </div>
                </SettingsFormCard>
            )}
        </div>
    );
};
