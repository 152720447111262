import * as React from 'react';
import classnames from '../utils/classnames.tsx';
import { typeOfComponent } from '../utils/children.ts';
import { withIcon } from './Icon.tsx';
import {
    faCheckCircle,
    faExclamationCircle,
    faInfoCircle,
    faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';

const InfoIcon = withIcon(faInfoCircle);
const WarningIcon = withIcon(faTriangleExclamation);
const ErrorIcon = withIcon(faExclamationCircle);
const SuccessIcon = withIcon(faCheckCircle);

const SEVERITY_LEVELS = ['info', 'warning', 'error', 'success'];

interface IProps {
    severity?: string;
    children?: any;
    className?: string;
}

export const Alert = ({ severity, className, children }: IProps) => {
    const severityLevel = SEVERITY_LEVELS.includes(severity) ? severity : SEVERITY_LEVELS[0];

    const content = React.Children.map(children, (child) => {
        if (child && ['AlertTitle', 'AlertContent'].includes(typeOfComponent(child))) return child;
        return <AlertContent>{child}</AlertContent>;
    });

    return (
        <div
            className={classnames(
                'bg-secondary text-primary text-sm px-4 py-3 rounded flex gap-2',
                severityLevel === 'info' && '',
                severityLevel === 'warning' && 'bg-warning text-warning',
                severityLevel === 'error' && 'bg-error text-error',
                severityLevel === 'success' && 'bg-success text-success',
                className
            )}
        >
            <span className="flex-none">
                {severityLevel === 'info' && <InfoIcon />}
                {severityLevel === 'warning' && <WarningIcon />}
                {severityLevel === 'error' && <ErrorIcon />}
                {severityLevel === 'success' && <SuccessIcon />}
            </span>
            <div>{content}</div>
        </div>
    );
};
Alert.displayName = 'Alert';

export const AlertTitle = (props) => <h4 className="font-medium text-sm">{props.children}</h4>;
AlertTitle.displayName = 'AlertTitle';

export const AlertContent = (props) => <div className="text-sm">{props.children}</div>;
AlertContent.displayName = 'AlertContent';

export default Alert;
