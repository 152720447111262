import { withIcon } from '../../../core/components/Icon';
import {
    faArrowRight,
    faDatabase,
    faExclamationCircle,
    faFile,
    faGearCode,
    faQuestionCircle,
    faSparkles,
    faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { format, isValid, parse } from 'date-fns';
import {
    isoDateFormat,
    isoDateTimeFormat,
    isoDateTimeFormatWithMcsAndTimezone,
    isoDateTimeFormatWithMs,
    isoDateTimeFormatWithMsAndTimezone,
    isoDateTimeFormatWithTimezone,
    isoTimeFormat,
} from '../../../core/utils/dates.ts';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { uniq } from 'lodash';
import { fieldIsGreen, fieldIsRed, fieldIsYellow } from './utils.tsx';
import { useAssistanceFieldContext } from './AssistanceFieldContext.tsx';
import classnames from '../../../core/utils/classnames.tsx';
import { useAssistanceContext } from './AssistanceContext.tsx';
import { url } from '../../../core/utils/link.ts';
import { useQuery } from '@apollo/client';

const DatabaseIcon = withIcon(faDatabase);
const MagicIcon = withIcon(faSparkles);
const UserIcon = withIcon(faUser);
const DocumentIcon = withIcon(faFile);
const CustomizationIcon = withIcon(faGearCode);
const ErrorIcon = withIcon(faExclamationCircle);
const UnknownIcon = withIcon(faQuestionCircle);
const ArrowRight = withIcon(faArrowRight);

const SUPPORTED_DATE_FORMATS = [
    isoDateFormat,
    isoDateTimeFormat,
    isoDateTimeFormatWithTimezone,
    isoTimeFormat,
    isoDateTimeFormatWithMs,
    isoDateTimeFormatWithMsAndTimezone,
    isoDateTimeFormatWithTimezone,
    isoDateTimeFormatWithMcsAndTimezone,
];

const localizeExplanationDetails = (t: any, explanationDetails: any, dateFormat: string = 'L', i18n: any) => {
    if (!explanationDetails) return explanationDetails;

    const dateFieldNames = ['field_value', 'expected_value', 'obtained_value', 'time_stamp'];
    for (const dateFieldName of dateFieldNames) {
        if (explanationDetails?.[dateFieldName]) {
            // Try each supported format
            for (const supportedFormat of SUPPORTED_DATE_FORMATS) {
                try {
                    const parsedDate = parse(explanationDetails[dateFieldName], supportedFormat, new Date());

                    if (isValid(parsedDate)) {
                        explanationDetails[dateFieldName] = format(parsedDate, dateFormat);
                        break; // Stop trying formats once we find a valid one
                    }
                } catch (e) {
                    // Continue to next format
                }
            }
        }
    }

    explanationDetails['threshold_unit'] =
        explanationDetails?.threshold_matching === 'date' ? t(`assistance:explanation.threshold_unit_days`) : '';

    if (i18n.exists(`assistance:explanation.${explanationDetails?.document_matching_name}`)) {
        explanationDetails['document_matching_name'] = t(
            `assistance:explanation.${explanationDetails?.document_matching_name}`
        );
    }

    return explanationDetails;
};

const legacyExplanationDetails = (translationKey: string, explanationDetails: any): [string, any] => {
    // Basically we changed a translation so the old values wouldn't be found anymore
    // If you add more cases please make sure to add the date as well so we can remove it later

    // 2024-07-04: split between multiple_good_results and multiple_good_article_results
    if (translationKey === 'explanation.multiple_good_results' && explanationDetails?.article_number1) {
        return ['explanation.multiple_good_article_results', explanationDetails];
    }

    // 2025-03-21: added table to confidence explanation
    if (translationKey === 'explanation.historical_matches' && explanationDetails?.mapping_search_fields) {
        return ['explanation.historical_matches_with_table', explanationDetails];
    }

    return [translationKey, explanationDetails];
};

enum MatchingResult {
    no_document_found = 'no_document_found',
    no_document_item_found = 'no_document_item_found',
    values_not_match = 'values_not_match',
    values_match = 'values_match',
    value_in_range = 'value_in_range',
    deviation_accepted = 'deviation_accepted',
    ignore_matching = 'ignore_matching',
}

const documentMatchingTextMessagesAndStyles = {
    no_document_found: ['error', 'OC-01'], // case 1
    no_document_item_found: ['error', 'OC-02'], // case 2
    field_matching_required_or_optional_matching_ignored: ['neutral', 'OC-12'], // case 23
    field_mandatory__matching_required__field_empty: ['error', 'OC-10'], // case 12
    field_mandatory__matching_optional__field_empty: ['neutral', 'OC-10'], // case 13
    field_mandatory__matching_required__threshold__field_not_empty__values_match: ['success', 'OC-03'], // case 6
    field_mandatory__matching_required__threshold__field_not_empty__value_in_range: ['warning', 'OC-06'], // case 7
    field_mandatory__matching_required__threshold__field_not_empty__values_not_match: ['error', 'OC-11'], // case 8
    field_mandatory__matching_required__threshold__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 9
    field_mandatory__matching_required__field_not_empty__values_match: ['success', 'OC-03'], // case 3
    field_mandatory__matching_required__field_not_empty__values_not_match: ['error', 'OC-04'], // case 4
    field_mandatory__matching_required__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 5
    field_mandatory__matching_optional__field_not_empty__values_match: ['neutral', 'OC-07'], // case 10
    field_mandatory__matching_optional__field_not_empty__values_not_match: ['warning', 'OC-09'], // case 11
    field_optional__matching_required__threshold__field_not_empty__values_match: ['success', 'OC-03'], // case 17
    field_optional__matching_required__threshold__field_not_empty__value_in_range: ['success', 'OC-06'], // case 18
    field_optional__matching_required__threshold__field_not_empty__values_not_match: ['error', 'OC-11'], // case 19
    field_optional__matching_required__threshold__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 20
    field_optional__matching_required__field_not_empty__values_match: ['success', 'OC-03'], // case 14
    field_optional__matching_required__field_not_empty__values_not_match: ['error', 'OC-04'], // case 15
    field_optional__matching_required__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 16
    field_optional__matching_optional__field_not_empty__values_match: ['neutral', 'OC-07'], // case 21
    field_optional__matching_optional__field_not_empty__values_not_match: ['neutral', 'OC-09'], // case 22
    // added cases
    // field_optional__matching_required__field_empty: ['error', 'OC-10'], // case 12
    // field_optional__matching_optional__field_empty: ['neutral', 'OC-10'], // case 13
};

const documentMatchingAcceptDeviationVisible = [
    'field_mandatory__matching_required__field_not_empty__values_not_match', // case 4
    'field_mandatory__matching_required__threshold__field_not_empty__value_in_range', // case 7
    'field_mandatory__matching_required__threshold__field_not_empty__values_not_match', // case 8
    'field_optional__matching_required__field_not_empty__values_not_match', // case 15
    'field_optional__matching_required__threshold__field_not_empty__values_not_match', // case 19
    'field_matching_required_or_optional_matching_ignored', // case 23
];

const documentMatchingActions = {
    field_mandatory__matching_required__field_not_empty__values_not_match: 'document_matching_accept_deviation', // case 4
    field_mandatory__matching_required__threshold__field_not_empty__value_in_range:
        'document_matching_accept_deviation', // case 7
    field_mandatory__matching_required__threshold__field_not_empty__values_not_match:
        'document_matching_accept_deviation', // case 8
    field_optional__matching_required__field_not_empty__values_not_match: 'document_matching_accept_deviation', // case 15
    field_optional__matching_required__threshold__field_not_empty__values_not_match:
        'document_matching_accept_deviation', // case 19
    field_matching_required_or_optional_matching_ignored: 'document_matching_restore_matching', // case 23
};

export const getDocumentMatchingKey = (explanationDetails: any, isFieldRequired = false, isFieldEmpty = false) => {
    const matchingResult: MatchingResult = explanationDetails?.document_matching_result;

    const isMatchingRequired: boolean = explanationDetails?.block_automation;
    const hasThreshold = explanationDetails?.comparison_type !== 'exact_match';

    let key = '';

    if (matchingResult === 'no_document_found') {
        key = 'no_document_found';
    } else if (matchingResult === 'no_document_item_found') {
        key = 'no_document_item_found';
    } else if (matchingResult === 'ignore_matching') {
        key = 'field_matching_required_or_optional_matching_ignored'; // case 23
    } else {
        // we don't need to check for not supported as only the list above is supported
        key += isFieldRequired ? 'field_mandatory' : 'field_optional';
        key += isMatchingRequired ? '__matching_required' : '__matching_optional';
        key += hasThreshold ? '__threshold' : '';
        key += isFieldEmpty ? '__field_empty' : '__field_not_empty';
        key += !isFieldEmpty ? '__' + matchingResult : '';
    }

    return key;
};

const getDocumentMatchingTextMessagesAndStyles = (key: string) => {
    // previously "not_supported" -> instead of red error we fall back to extraction logic and show neutral
    return documentMatchingTextMessagesAndStyles[key] || ['neutral', ''];
};

export const getDocumentMatchingSeverity = (key: string) => {
    return getDocumentMatchingTextMessagesAndStyles(key)[0];
};

export const useDocumentMatchingExplanationMessage = (
    confidenceExplanation: any,
    dateFormat: string,
    isFieldRequired = false,
    isFieldEmpty = false
) => {
    const { t, i18n } = useTranslation('assistance');

    const explanationDetails: any = confidenceExplanation?.explanationDetails || {};
    explanationDetails['document_matching_name'] = explanationDetails['document_matching_name'] || 'order';
    explanationDetails['threshold_unit'] = t(`assistance:explanation.${explanationDetails?.comparison_type}`);

    const matchingResult: MatchingResult = explanationDetails?.document_matching_result;
    if (matchingResult == null) {
        return { message: '', severity: '', acceptDeviationVisible: false, Icon: undefined };
    }

    const key = getDocumentMatchingKey(explanationDetails, isFieldRequired, isFieldEmpty);
    const [severity, translationKey] = getDocumentMatchingTextMessagesAndStyles(key);
    if (!translationKey) {
        // no document matching explanation available
        console.warn('No document matching explanation available', key);
        return { message: '', severity: '', acceptDeviationVisible: false, Icon: undefined };
    }

    const acceptVisible = documentMatchingAcceptDeviationVisible.includes(key);
    const acceptAction = documentMatchingActions[key];

    const localizedExplanationDetails = localizeExplanationDetails(t, explanationDetails, dateFormat, i18n);
    const message = translationKey ? t(`explanation.${translationKey}`, localizedExplanationDetails) : '';
    const Icon = severity === 'error' ? ErrorIcon : getExplanationIcon(confidenceExplanation.translationKey); // fallback to confidence explanation icon

    return { message, severity, acceptVisible, acceptAction, Icon };
};

const getExplanationIcon = (translationKey: any) => {
    if (
        [
            'explanation.master_data_match',
            'explanation.extracted_article_number_and_description',
            'explanation.extracted_article_description',
            'explanation.extracted_article_number',
            'explanation.multiple_good_results',
            'explanation.multiple_good_article_results',
        ].includes(translationKey)
    ) {
        return DatabaseIcon;
    } else if (
        ['explanation.historical_matches', 'explanation.historical_matches_with_table'].includes(translationKey)
    ) {
        return MagicIcon;
    } else if (translationKey === 'explanation.user_correction') {
        return UserIcon;
    } else if (['explanation.extracted_value', 'explanation.extracted_value_from_llm'].includes(translationKey)) {
        return DocumentIcon;
    } else if (['explanation.decimal_quantity', 'explanation.out_of_range_validation_error'].includes(translationKey)) {
        return ErrorIcon;
    } else if (translationKey === 'explanation.customization_info') {
        return CustomizationIcon;
    } else {
        return UnknownIcon;
    }
};

const ConfidenceExplanationMappingValue = ({ cell }: { cell: string | string[] }) => {
    const { t } = useTranslation('assistance');

    const value = Array.isArray(cell) ? cell[0] : cell;
    const otherValues = Array.isArray(cell) ? cell.slice(1) : [];
    const [showAll, setShowAll] = useState(false);

    return (
        <div className="whitespace-pre-line font-medium align-top">
            <p>{value || <em>({t('explanationTable.noValue')})</em>}</p>

            {otherValues.length > 0 ? (
                !showAll ? (
                    <p>
                        <a
                            className="font-normal italic hover:underline cursor-pointer"
                            onClick={() => setShowAll(true)}
                        >
                            {t('explanationTable.andOthers', { count: otherValues.length })}
                        </a>
                    </p>
                ) : (
                    otherValues.map((value: string, index: number) => <p key={index}>{value}</p>)
                )
            ) : null}
        </div>
    );
};

const ConfidenceExplanationMappingTable = React.memo(({ rows }: { rows: [string | string[], string | string[]][] }) => {
    const { t } = useTranslation('assistance');

    const { data, config, value } = useAssistanceFieldContext();

    const borderClassName = classnames(
        'border-secondary',
        fieldIsGreen({ data, config, value }, { includeMatching: false }) && 'border-success',
        fieldIsYellow({ data, config, value }, { includeMatching: false }) && 'border-warning',
        fieldIsRed({ data, config, value }, { includeMatching: false }) && 'border-error'
    );

    const searchValues = rows
        .map((row) => row[0])
        .filter((value) => (Array.isArray(value) ? value.length > 0 && value[0] : value));
    if (searchValues.length === 0) searchValues.push('');

    const resultValues = rows
        .map((row) => row[1])
        .filter((value) => (Array.isArray(value) ? value.length > 0 && value[0] : value));
    if (resultValues.length === 0) resultValues.push('');

    return (
        <table className="w-full table-fixed">
            <tbody>
                <tr>
                    <td className="whitespace-pre-line pb-2 align-top">{t('explanationTable.extracted')}:</td>
                    <td className="w-10 whitespace-pre-line align-top text-center relative" rowSpan={2}>
                        <div className="flex h-full flex-col overflow-auto absolute items-center gap-2 w-10">
                            <div className={classnames('w-0 flex-1 border-l', borderClassName)} />
                            <ArrowRight />
                            <div className={classnames('w-0 flex-1 border-l', borderClassName)} />
                        </div>
                    </td>
                    <td className="whitespace-pre-line align-top">{t('explanationTable.learned')}:</td>
                </tr>
                <tr>
                    <td className="align-top">
                        <div className="flex flex-col gap-2">
                            {searchValues.map((value, index) => (
                                <ConfidenceExplanationMappingValue key={index} cell={value} />
                            ))}
                        </div>
                    </td>
                    <td className="align-top">
                        <div className="flex flex-col gap-2">
                            {resultValues.map((value, index) => (
                                <ConfidenceExplanationMappingValue key={index} cell={value} />
                            ))}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
});

const ConfidenceExplanationRelatedDocument = React.memo(({ relatedDocumentPath }: { relatedDocumentPath: string }) => {
    const { t } = useTranslation('assistance');
    const { documentConfiguration } = useAssistanceContext();

    const [_, recordId] = relatedDocumentPath.split('/');

    // apollo takes care of caching
    const { data: relatedDocumentData } = useQuery(documentConfiguration.GET_RELATED_DOCUMENT, {
        variables: { id: recordId },
    });
    const relatedDocument = relatedDocumentData?.orderProcessingRecord;

    return (
        <div>
            {relatedDocument && (
                <span>
                    {t('explanation.relatedDocument', { date: format(relatedDocument?.assistedAt, 'P') })}{' '}
                    <a
                        href={url(documentConfiguration.ASSISTANCE_PATH, { recordId })}
                        target="_blank"
                        className="underline hover:opacity-70 transition-all"
                    >
                        {relatedDocument?.subject}
                    </a>
                </span>
            )}
        </div>
    );
});

export const useConfidenceExplanationMessage = (
    confidenceExplanation: any,
    dateFormat: string,
    fieldName: string,
    fieldTypeName: any
) => {
    const { t, i18n } = useTranslation('assistance');

    const localizedExplanationDetails = localizeExplanationDetails(
        t,
        confidenceExplanation?.explanationDetails,
        dateFormat,
        i18n
    );
    const [translationKey, explanationDetails] = legacyExplanationDetails(
        confidenceExplanation.translationKey,
        localizedExplanationDetails
    );

    let message = translationKey ? t(`${translationKey}`, explanationDetails) : '';

    const mappingSearchFields = confidenceExplanation?.explanationDetails?.mapping_search_fields;
    const mappingResultFields = confidenceExplanation?.explanationDetails?.mapping_result_fields;
    const relatedDocument = confidenceExplanation?.explanationDetails?.related_document;

    const getSearchFieldValues = (fieldKeys: string[]): string[] =>
        uniq(fieldKeys.flatMap((key) => (mappingSearchFields[key] || '').split('\n')).filter(Boolean));
    const getResultFieldValues = (fieldKeys: string[]): string[] =>
        uniq(fieldKeys.flatMap((key) => (mappingResultFields[key] || '').split('\n')).filter(Boolean));

    const extraContent = useMemo(() => {
        const components = [];

        if (
            mappingSearchFields &&
            mappingResultFields &&
            translationKey === 'explanation.historical_matches_with_table'
        ) {
            if (fieldTypeName === 'CustomerField') {
                const extractedNameFields = ['sender_name', 'delivery_receiver_name', 'invoice_receiver_name'];
                const extractedAddressFields = ['sender_address', 'delivery_address', 'invoice_address'];
                const rows: [string | string[], string][] = [
                    [mappingSearchFields.client_id, mappingResultFields.client_id],
                    [getSearchFieldValues(extractedNameFields), mappingResultFields.name],
                    [getSearchFieldValues(extractedAddressFields), mappingResultFields.address],
                ];
                components.push(<ConfidenceExplanationMappingTable rows={rows} />);
            } else if (fieldTypeName === 'ContactField') {
                const rows: [string, string][] = [
                    [mappingSearchFields.contact_id, mappingResultFields.contact_id],
                    [mappingSearchFields.name, mappingResultFields.name],
                    [mappingSearchFields.email, mappingResultFields.email],
                    [mappingSearchFields.phone, mappingResultFields.phone],
                ];
                components.push(<ConfidenceExplanationMappingTable rows={rows} />);
            } else if (fieldTypeName === 'AddressField') {
                const rows: [string, string][] = [
                    [mappingSearchFields.address_id, mappingResultFields.address_id],
                    [mappingSearchFields.name, mappingResultFields.name],
                    [mappingSearchFields.misc, mappingResultFields.misc],
                    [mappingSearchFields.street_and_nr, mappingResultFields.street_and_nr],
                    [mappingSearchFields.company_name, mappingResultFields.company_name],
                    [mappingSearchFields.postcode, mappingResultFields.postcode],
                    [mappingSearchFields.city, mappingResultFields.city],
                    [mappingSearchFields.country, mappingResultFields.country],
                    [mappingSearchFields.email, mappingResultFields.email],
                    [mappingSearchFields.phone, mappingResultFields.phone],
                ];
                components.push(<ConfidenceExplanationMappingTable rows={rows} />);
            } else if (fieldName === 'unit') {
                // Unit mapping
                const rows: [string | string[], string | string[]][] = [
                    [mappingSearchFields.unit, mappingResultFields.unit],
                    [mappingSearchFields.article_number, ''],
                    [mappingSearchFields.article_description, ''],
                ];
                components.push(<ConfidenceExplanationMappingTable rows={rows} />);
            } else {
                // Fallback for article number field
                const extractedSearchIdFields = ['supplier_article_number', 'debtor_article_number', 'ean'];
                const extractedResultIdFields = [
                    'article_partition_specific_number',
                    'article_number',
                    'article_number_2',
                    'article_number_3',
                ];
                const rows: [string | string[], string | string[]][] = [
                    [getSearchFieldValues(extractedSearchIdFields), getResultFieldValues(extractedResultIdFields)],
                    [mappingSearchFields.article_description, ''],
                    [mappingSearchFields.unit, ''],
                ];
                components.push(<ConfidenceExplanationMappingTable rows={rows} />);
            }
        }

        if (relatedDocument) {
            components.push(<ConfidenceExplanationRelatedDocument relatedDocumentPath={relatedDocument} />);
        }

        return components.length > 0 ? components : null;
    }, [confidenceExplanation]);

    return { message, Icon: getExplanationIcon(translationKey), extraContent };
};
