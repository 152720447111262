import * as React from 'react';
import Loader from '../../core/components/Loader.tsx';
import InputDataPreview from './InputDataPreview.tsx';

const SourceFileView = ({ file, readOnly, loading }: any) => {
    return (
        <div className="flex justify-center bg-secondary-light w-full overflow-auto">
            {file === undefined ? (
                <Loader className="p-8 m-0 max-w-[62rem]" />
            ) : (
                <InputDataPreview
                    data={file}
                    className="data-[type=pdf]:h-full data-[type=pdf]:max-h-full data-[type=email]:my-8 data-[type=image]:my-8"
                />
            )}
        </div>
    );
};

export default SourceFileView;
