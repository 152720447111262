export const OVERVIEW_PATH = '/universal';
export const OVERVIEW_FINISHED_PATH = '/universal/finished';
export const OVERVIEW_TESTING_PATH = '/universal/testing';

export const CHANNEL_PATH = '/universal/channels/:channelId';
export const CHANNEL_FINISHED_PATH = '/universal/channels/:channelId/finished';
export const CHANNEL_TESTING_PATH = '/universal/channels/:channelId/testing';
export const CHANNEL_CONFIG_PATH = '/universal/channels/:channelId/config/:tab?';
export const CHANNEL_MASTERDATA_PATH = '/universal/channels/:channelId/masterdata';

export const ASSISTANCE_PATH = '/universal/:recordId';
export const ASSISTANCE_TAB_PATH = '/universal/:recordId/:tab';
