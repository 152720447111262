import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState.tsx';
import Field, { FieldProps } from './Field.tsx';
import classnames from '../../utils/classnames.tsx';
import { withIcon } from '../Icon.tsx';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useMemo, useRef, useState } from 'react';
import { mergeRefs } from '../../utils/mergeRefs.tsx';
import { ValueSuggestions } from './AutoCompleteField.tsx';

const RemoveIcon = withIcon(faXmark);

interface TagProps extends React.ComponentPropsWithRef<'div'> {
    isRemovable?: boolean;
    onRemove?: () => void;
}

const Tag = ({ className, isRemovable = true, onRemove, children, ...props }: TagProps) => {
    return (
        <div
            className={classnames(
                'border-secondary border border-solid bg-secondary flex gap-2 items-center px-1 rounded',
                className
            )}
            {...props}
        >
            <span>{children}</span>
            {isRemovable && (
                <button type="button" onClick={onRemove} className="flex items-center justify-center">
                    <RemoveIcon />
                </button>
            )}
        </div>
    );
};

export interface TagsFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {
    inputValue?: string;
    onInputValueChange?: (value: string) => void;
    submitKeys?: string[];
    separator?: string;
    renderTag?: (props: TagProps) => React.ReactNode;

    options?: {
        label: string;
        value: string;
        [key: string]: any;
    }[];
    filterOption?: (value: string, search: string, isDirty?: boolean) => number;
}

const TagsField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onChange,
    controls,
    className,
    readOnly,
    disabled,
    inputRef: propsInputRef = undefined,
    onBlur,
    onFocus,
    required,

    submitKeys = ['Tab'], // 'Enter' is handled by options list
    separator = ',',

    options = [],
    filterOption,

    renderTag,

    ...props
}: TagsFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [showSuggestions, setShowSuggestions] = useState(false);

    const [inputValue, setInputValue] = useState('');

    // value stored as string with separator
    const [stringValue, setStringValue] = useControllableState(defaultValue, propsValue, onValueChange);

    const value = useMemo(
        () => (stringValue ? stringValue.split(separator).filter(Boolean) : []),
        [stringValue, separator]
    );
    const setValue = useCallback(
        (value) => {
            setStringValue(value.join(separator));
        },
        [setStringValue, separator]
    );

    const handleKeyDown = (event) => {
        // Prevent default behavior for submit or separator keys
        if (![...submitKeys, separator].includes(event.key) || !inputValue) return;
        event.preventDefault();

        setValue([...value, inputValue]);
        setInputValue('');

        onBlur?.(null);
    };

    const handleFocus = (event) => {
        setShowSuggestions(true);
        onFocus?.(event);
    };

    const handleBlur = (event) => {
        if (inputValue) {
            setValue([...value, inputValue]);
            setInputValue('');
        }

        onBlur?.(event);
    };

    const handlePaste = (event) => {
        const pasteValue = (event.clipboardData || (window as any).clipboardData).getData('text');
        if (pasteValue.includes(separator)) {
            event.preventDefault();

            const newValues = pasteValue.split(separator).map((v) => v.trim());
            setValue([...value, ...newValues]);
            setInputValue('');

            onBlur?.(null);
        }
    };

    const handleChange = (value) => {
        setInputValue(value);
    };

    const handleSelectOption = (option) => {
        setValue([...value, option.value]);
        setInputValue('');
        inputRef.current?.focus();
    };

    const TagComponent = useMemo(() => renderTag || Tag, [renderTag]);

    return (
        <ValueSuggestions
            inputRef={inputRef}
            isOpen={showSuggestions}
            onOpenChange={setShowSuggestions}
            onSelectOption={handleSelectOption}
            options={
                inputValue
                    ? [
                          {
                              label: inputValue,
                              value: inputValue,
                          },
                          ...options,
                      ]
                    : options
            }
            isDirty={!!inputValue}
            value={inputValue}
            filterOption={filterOption}
        >
            <Field
                className={className}
                readOnly={readOnly}
                disabled={disabled}
                ref={ref}
                onFocus={inputRef.current?.focus}
            >
                <Field.Input>
                    <div className="flex flex-wrap gap-1.5 p-1.5 w-full min-w-0">
                        {value.map((tag, index) => (
                            <TagComponent
                                key={index}
                                isRemovable={!readOnly}
                                onRemove={() => {
                                    setValue(value.filter((_, i) => i !== index));
                                    onBlur?.(null);
                                }}
                            >
                                {tag}
                            </TagComponent>
                        ))}

                        <ValueSuggestions.Input
                            type="text"
                            value={inputValue}
                            className="flex-1 !min-w-20 bg-transparent !p-0 !pl-1 outline-none"
                            readOnly={readOnly}
                            disabled={disabled}
                            onKeyDown={handleKeyDown}
                            onValueChange={handleChange}
                            onPaste={handlePaste}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            required={required ? value.length === 0 : false}
                            {...props}
                            ref={mergeRefs(inputRef, propsInputRef)}
                        />
                    </div>
                </Field.Input>
                <Field.Controls>{controls}</Field.Controls>
            </Field>
        </ValueSuggestions>
    );
};

export default TagsField;
