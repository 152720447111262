import { useTranslation } from 'react-i18next';
import Filter, { FilterDropdownOption } from '../../../../core/components/Filter.tsx';
import * as React from 'react';
import { useOverviewContext } from './OverviewContext';
import { PROCESSING_STATUS_COLOR_CLASS } from '../../../../assistance/constants';
import classnames from '../../../../core/utils/classnames.tsx';
import { canSeeDeletedDocuments } from '../../../../users/utils';
import { useApplicationContext } from '../../../../core/contexts/ApplicationContext';

export const OPEN_STATUS_FILTER_CHOICES = {
    PENDING: 'PENDING',
    ON_HOLD: 'ON_HOLD',
    ASSISTANCE_NEEDED: 'ASSISTANCE_NEEDED',
    REJECTED: 'REJECTED',
    FAILED: 'FAILED',
};

export const FINISHED_STATUS_FILTER_CHOICES = {
    FINISHED: 'FINISHED',
    DISCARDED: 'DISCARDED',
};

export const FINISHED_STATUS_FILTER_CHOICES_WITH_DELETED = {
    ...FINISHED_STATUS_FILTER_CHOICES,
    DELETED: 'DELETED',
};

export const TESTING_STATUS_FILTER_CHOICES = {
    ...OPEN_STATUS_FILTER_CHOICES,
    ...FINISHED_STATUS_FILTER_CHOICES,
    FINISHED_TESTING: 'FINISHED_TESTING',
};

export const StatusIndicator = ({ status, className }: any) => {
    const statusColorGroup = PROCESSING_STATUS_COLOR_CLASS[status];
    return (
        <span
            className={classnames(
                'inline-block rounded-full w-2.5 h-2.5 bg-tertiary flex-none',
                statusColorGroup == 'success' && 'bg-confidence-high',
                statusColorGroup == 'warning' && 'bg-confidence-medium',
                statusColorGroup == 'error' && 'bg-confidence-low',
                className
            )}
        />
    );
};

const StatusFilter = ({ filters, onFiltersChange }) => {
    const { t } = useTranslation('assistance');

    const { user } = useApplicationContext();

    const filterName = 'status';
    const filter = filters.find((f) => f.name === filterName);

    const selected = filter ? (Array.isArray(filter.value) ? filter.value : [filter.value]) : [];
    const handleSelectedChange = (selected: string[]) => {
        const newFilters = filters.filter((f) => f.name !== filterName);
        if (selected.length) {
            newFilters.push({ name: filterName, value: selected, exclude: false, expression: 'eq' });
        }
        onFiltersChange(newFilters);
    };

    const { testing, finished } = useOverviewContext();
    const statusFilterChoices =
        !testing && !finished
            ? OPEN_STATUS_FILTER_CHOICES
            : !testing
              ? canSeeDeletedDocuments(user)
                  ? FINISHED_STATUS_FILTER_CHOICES_WITH_DELETED
                  : FINISHED_STATUS_FILTER_CHOICES
              : TESTING_STATUS_FILTER_CHOICES;

    const statusOptions = Object.keys(statusFilterChoices).map((status) => ({
        value: status,
        label: t(`overview.filters.status.options.${status}`),
    }));

    const renderOption = (option: FilterDropdownOption) => (
        <span className="flex items-baseline px-1 gap-2">
            <StatusIndicator status={option.value} />
            {t(`overview.filters.status.options.${option.value}`)}
        </span>
    );

    return (
        <Filter
            label={t('overview.filters.status.label')}
            options={statusOptions}
            selected={selected}
            onSelectedChange={handleSelectedChange}
            renderOption={renderOption}
        />
    );
};

export default StatusFilter;
