import * as React from 'react';
import { useEffect } from 'react';
import * as Toast from './modules/core/components/Toast.tsx';
import { createRoot } from 'react-dom/client';
import 'regenerator-runtime/runtime';
import { merge } from 'lodash';
import { detect } from 'detect-browser';
import i18n, { Resource } from 'i18next';

import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { ApolloProvider } from '@apollo/client/react';
import * as Sentry from '@sentry/react';

import Loader from './modules/core/components/Loader.tsx';
import App from './App';
import { modules } from './modules/core/utils/modules';
import { initAppInsights } from './modules/core/analytics/applicationInsights';
import { client } from './modules/core/utils/authentication';

import analytics from './modules/analytics';
import login from './modules/login';
import invoices from './modules/products/invoices';
import orders from './modules/products/orders';
import orderConfirmations from './modules/products/order_confirmations';
import rfqs from './modules/products/rfqs';
import propertyBills from './modules/products/db_immo';
import delivery_notes from './modules/products/delivery_notes';
import universal from './modules/products/universal';
import list_of_services from './modules/products/list_of_services';
import teams from './modules/teams';
import users from './modules/users';
import customer from './modules/customer';
import assistance from './modules/assistance';
import { PostHogProvider } from 'posthog-js/react';
import { setDefaultOptions } from 'date-fns';
import { de, enUS, ja, ko, pl, fr } from 'date-fns/locale';
import { DEFAULT_LANGUAGE } from './constants.ts';

// Initialize all modules: register routes, localizations, etc
modules.populate([
    // Documents
    invoices,
    orders,
    orderConfirmations,
    rfqs,
    delivery_notes,
    propertyBills, // customer-specific (DBImmo)
    universal,
    list_of_services,
    // Shared
    analytics,
    assistance,
    // Account management
    login,
    teams,
    users,
    customer,
]);

const translations = import.meta.glob('../locales/**/*.json', { eager: true });

const legacyLocaleResources = Object.entries(translations).reduce((acc, [path, module]: [string, any]) => {
    const [, lang, file] = path.match(/\.\.\/locales\/(\w+)\/(.+)\.json/) || [];
    if (!lang || !file) return acc;

    acc[lang] = acc[lang] || {};
    acc[lang][file] = module.default;
    return acc;
}, {});

const localeResources = merge(legacyLocaleResources, modules.getLocales()) as Resource;
const dateLocales = {
    en: enUS,
    de: de,
    fr: fr,
    pl: pl,
    ja: ja,
    ko: ko,
};

void i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        // lng: DEFAULT_LANGUAGE, -> should be recognized by the language detector
        fallbackLng: 'en',
        supportedLngs: Object.keys(localeResources),
        ns: Object.keys(localeResources),
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false,
        },
        load: 'languageOnly',
        returnEmptyString: true,
        resources: localeResources,
    });

// Set initial default locale
setDefaultOptions({ locale: dateLocales[i18n.language] || dateLocales.en });

// Update date-fns locale when language changes
i18n.on('languageChanged', (lng) => {
    const langCode = lng.split('-')[0]; // Handle 'en-US' format
    setDefaultOptions({ locale: dateLocales[langCode] || dateLocales.en });
});

const browser = detect();

let browserSupported = false;
// handle the case where we don't detect the browser
switch (browser && browser.name) {
    case 'edge-chromium':
        browserSupported = true;
        break;
    case 'edge':
        browserSupported = true;
        break;
    case 'chrome':
        browserSupported = true;
        break;
    case 'firefox':
        browserSupported = true;
        break;
    case 'safari':
        browserSupported = true;
        break;
}

const InitializedApp = initAppInsights(({ browserName }) => {
    // only temporarily to figure out which lib to use
    useEffect(() => {
        // copied from https://developers.heap.io/docs/web
        window.heap = window.heap || [];
        window.heap.load = function (e, t: object | undefined = undefined) {
            window.heap.appid = e;
            window.heap.config = t = t || {};
            let r = document.createElement('script');
            r.type = 'text/javascript';
            r.async = true;
            r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';
            let a = document.getElementsByTagName('script')[0];
            a.parentNode?.insertBefore(r, a);
            let n = function (e) {
                return function () {
                    window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                };
            };
            let p = [
                'addEventProperties',
                'addUserProperties',
                'clearEventProperties',
                'identify',
                'resetIdentity',
                'removeEventProperty',
                'setEventProperties',
                'track',
                'unsetEventProperty',
            ];
            for (let o = 0; o < p.length; o++) window.heap[p[o]] = n(p[o]);
        };

        if (process.env.HEAP_APP_ID) {
            window.heap.load(process.env.HEAP_APP_ID);
        }
    }, []);

    return (
        <PostHogProvider
            apiKey={process.env.POSTHOG_KEY as string}
            options={{
                api_host: process.env.POSTHOG_HOST,
                persistence: 'memory',
                disable_persistence: true,
            }}
        >
            <ApolloProvider client={client}>
                <App browserName={browserName} />
            </ApolloProvider>
        </PostHogProvider>
    );
});

if (browserSupported) {
    if (process.env.RUN_ENV != 'development') {
        Sentry.init({
            dsn: 'https://cdeb31cc54d4ece8d3d688e4231c4c98@sentry.workist.com/7',
            environment: process.env.RUN_ENV as string,
        } as any);
    }

    const root = createRoot(document.getElementById('root')!);
    const modalRoot = document.getElementById('modal-root');

    root.render(
        <React.Suspense
            fallback={
                <div className="w-full h-screen flex items-center justify-center">
                    <Loader className="max-w-[40rem] p-10 w-full" />
                </div>
            }
        >
            <Toast.Provider>
                <InitializedApp browserName={browser?.name} />
                <Toast.Viewport />
            </Toast.Provider>
        </React.Suspense>
    );
} else {
    document.write(
        '<div>Your browser is unfortunately currently not supported. Please download a current version of Chrome, Firefox, Edge or Safari.</div>'
    );
}
