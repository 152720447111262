import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { IBaseModalProps as ModalProps } from './Modal.tsx';
import Button from './Button.tsx';

export interface IProps extends ModalProps {
    onConfirm: any;
    onCancel: any;
    confirmDisabled?: boolean;
    labelConfirm?: string;
    labelCancel?: string;
    buttons?: Array<React.ReactNode>;
    danger?: boolean;
}

const ConfirmModal = ({
    children,
    onConfirm,
    onCancel,
    buttons,
    labelConfirm,
    labelCancel,
    danger = false,
    confirmDisabled,
    title,
    ...modalProps
}: IProps) => {
    const { t } = useTranslation('core');

    buttons = buttons || [
        // default buttons
        <Button key="cancel" onClick={() => onCancel()}>
            {labelCancel ?? t('components.confirmModal.cancel')}
        </Button>,
        <Button
            key="confirm"
            onClick={() => onConfirm()}
            className={danger && 'bg-error-default hover:bg-error-hover'}
            disabled={confirmDisabled}
            variant="primary"
        >
            {labelConfirm ?? t('components.confirmModal.ok')}
        </Button>,
    ];

    return (
        <Modal buttons={buttons} onClose={onCancel} noHeader={!title} title={title} {...modalProps}>
            {children}
        </Modal>
    );
};

export default ConfirmModal;
