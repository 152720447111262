import Page from './internal/Page.tsx';
import PageContent from './internal/PageContent.tsx';
import PageHeader from './internal/PageHeader.tsx';
import PageHeaderTitle from './internal/PageHeaderTitle.tsx';
import PageHeaderButton, { PageHeaderIconButton } from './internal/PageHeaderButton.tsx';

export default Object.assign(Page, {
    Content: PageContent,
    Header: PageHeader,
    HeaderTitle: PageHeaderTitle,
    HeaderButton: PageHeaderButton,
    HeaderIconButton: PageHeaderIconButton,
});
