import * as React from 'react';
import classnames from '../utils/classnames.tsx';
import { useDropzone } from 'react-dropzone';
import { useControllableState } from '../utils/useControllableState.tsx';
import Button from './Button.tsx';
import { withIcon } from './Icon.tsx';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

interface IProps {
    className?: string;
    placeholder: string;
    placeholderActive?: string;
    placeholderInvalid?: string;
    maxFiles?: number;
    onFilesChange?: (files: any[]) => void;
    accept?: { [key: string]: string[] };
    files?: any[];
}

interface IPropsFile {
    file: any;
    onRemove?: (file: any) => void;
}

const RemoveIcon = withIcon(faTrash);

const DropAreaFile = ({ file, onRemove }: IPropsFile) => {
    return (
        <div className="w-full flex justify-between items-center border border-solid border-primary rounded p-3 shadow-sm">
            <span className="text-sm font-medium px-1">{file.name}</span>
            <Button variant="ghost" onClick={onRemove}>
                <RemoveIcon />
            </Button>
        </div>
    );
};

const DropArea = ({
    className,
    placeholder,
    placeholderActive,
    placeholderInvalid,
    maxFiles = 0,
    files: propsFiles,
    onFilesChange,
    accept = {
        'application/pdf': ['.pdf'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/tiff': ['.tiff', '.tif'],
        'application/vnd.ms-excel': ['.xls', '.csv'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'text/csv': ['.csv'],
        'message/rfc822': ['.eml', '.msg'],
        'text/html': ['.html', '.htm'],
        'text/xml': ['.x83'],
    },
}: IProps) => {
    const [files, setFiles] = useControllableState([], propsFiles, onFilesChange);

    const handleDrop = (acceptedFiles) => {
        setFiles((files) => [...files, ...acceptedFiles]);
    };
    const handleRemoveFile = (file) => {
        setFiles(files.filter((f) => f !== file));
    };

    const multiple = maxFiles !== 1;
    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop: handleDrop,
        multiple,
        accept,
    });

    let displayPlaceholder = placeholder;
    if (isDragReject && placeholderInvalid) displayPlaceholder = placeholderInvalid;
    else if (isDragActive && placeholderActive) displayPlaceholder = placeholderActive;

    return (
        <div className={classnames('flex flex-col gap-2', className)}>
            {(!files.length || multiple) && (
                <div
                    className={classnames(
                        'w-full h-40 rounded flex items-center justify-center bg-secondary-light hover:bg-secondary transition-colors'
                    )}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <span className="text-sm text-tertiary">{displayPlaceholder}</span>
                </div>
            )}

            {!!files.length && (
                <div className="flex flex-col gap-2">
                    {files.map((file, i) => (
                        <DropAreaFile key={i} file={file} onRemove={() => handleRemoveFile(file)} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropArea;
