import { useAssistanceViewContext } from './AssistanceViewContext';
import classnames from '../../../core/utils/classnames.tsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const AssistanceSectionEmptyState = ({ className, children, ...props }: any) => {
    const { t } = useTranslation('assistance');

    const { isFastTrackEnabled, onFastTrackChange } = useAssistanceViewContext();

    return (
        <div
            className={classnames(
                'flex flex-col items-center justify-center p-10 gap-4 bg-secondary text-sm rounded-lg w-full',
                className
            )}
            {...props}
        >
            <span className="text-secondary">{t('fastTrack.emptyState.description')}</span>
            {isFastTrackEnabled && (
                <button
                    className="text-brand-default hover:text-brand-hover font-medium"
                    onClick={() => onFastTrackChange(false)}
                >
                    {t('fastTrack.emptyState.button')}
                </button>
            )}
        </div>
    );
};

export default AssistanceSectionEmptyState;
