import classnames from '../utils/classnames.tsx';
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';

const FormField = ({
    className,
    label,
    required = false,
    disabled = false,
    children,
    error,
    helpText,
    ...props
}: React.ComponentPropsWithRef<'div'> & {
    label: string;
    required?: boolean;
    disabled?: boolean;
    error?: any;
    helpText?: any;
}) => {
    return (
        <div className={classnames('flex flex-col gap-2', className)} {...props}>
            <label className="text-xs text-secondary min-w-36 flex-none flex justify-start gap-1">
                <span className="truncate">{label}</span> {required && <span className="text-error">*</span>}
            </label>
            <div className="flex gap-1 flex-1 min-w-0 flex-col">
                <Slot
                    className={classnames(
                        !disabled &&
                            'hover:!border-confidence-high focus-within:border-confidence-high focus-within:outline-surface-brand',
                        error && 'border-confidence-low'
                    )}
                    // @ts-ignore
                    required={required}
                >
                    {children}
                </Slot>
            </div>
            {!error?.message && helpText && <span className="text-secondary text-xs">{helpText}</span>}
            {error?.message && <span className="text-confidence-low text-xs">{error.message}</span>}
        </div>
    );
};

export default FormField;
