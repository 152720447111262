import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import SettingsFormCard from '../../../core/components/SettingsFormCard.tsx';
import { useToaster } from '../../../core/components/Toast.tsx';
import Button from '../../../core/components/Button.tsx';
import FieldsConfig, {
    convertFieldsToFormData,
    convertFormDataToFieldInputs,
    useFieldsConfig,
    useFieldsConfigNavigation,
} from '../../../document/components/FieldsConfig.tsx';
import { camelCase } from 'lodash';
import SelectField from '../../../core/components/Fields/SelectField.tsx';
import TextField from '../../../core/components/Fields/TextField.tsx';
import { withIcon } from '../../../core/components/Icon.tsx';
import { faEllipsisVertical, faPlus } from '@fortawesome/pro-regular-svg-icons';
import DropdownMenu from '../../../core/components/DropdownMenu.tsx';

const AddIcon = withIcon(faPlus);
const MoreIcon = withIcon(faEllipsisVertical);

export const isGroupField = (field) =>
    field.options?.some((option) => option.name === 'group' && option.value === true);
export const isItemField = (field, groupNames = undefined) =>
    !isGroupField(field) &&
    field.name.includes('__') &&
    (!groupNames || groupNames.includes(field.name.split('__')[0]));
export const isHeaderField = (field) => !isGroupField(field) && !field.name.includes('__');

const getDefaultUniversalOptionsValues = () => {
    return [
        {
            name: 'is_required',
            valueType: 'boolean',
            defaultValue: true,
        },
        {
            name: 'data_label_name',
            valueType: 'string',
            defaultValue: null,
        },
        {
            name: 'data_type',
            valueType: 'string',
            defaultValue: 'string',
        },
        {
            name: 'field_label_name',
            valueType: 'string',
            defaultValue: null,
        },
        {
            name: 'data_label_instructions',
            valueType: 'string',
            defaultValue: null,
        },
    ];
};

export const filterUniversalFields = (fields, itemType) => {
    const groupNames = fields?.filter((field) => isGroupField(field)).map((field) => field.name);
    // for header field we filter out all groups and nested fields, for everything else we filter by the group name
    return fields?.filter((field) =>
        itemType
            ? field.name.startsWith(`${itemType}__`)
            : !groupNames.includes(field.name) && !field.name.includes('__')
    );
};

export const adjustUniversalFields = (fields, itemType) => {
    return fields?.map((field) => ({
        ...field,
        name: field.name.replace(`${itemType}__`, ''),
    }));
};

const EmptyState = ({ onAddClick }) => {
    const { t } = useTranslation('config');
    return (
        <div className="flex flex-col items-center justify-center gap-5 border-t border-solid border-secondary h-[28rem] pb-6">
            <p className="text-center font-medium text-primary">
                {t('configPage.document.universal.emptyState.title')}
            </p>
            <p className="text-center text-secondary mb-5">
                {t('configPage.document.universal.emptyState.description')}
            </p>
            <Button onClick={onAddClick} variant="primary" className="flex gap-2 items-center">
                <AddIcon />
                {t('configPage.document.universal.emptyState.button')}
            </Button>
        </div>
    );
};

const UniversalFieldsConfig = ({ config, onSubmit, onEdit, onDelete, itemType }) => {
    const { t, i18n } = useTranslation('config');
    const { publishToast } = useToaster();

    const [fields, setFields] = useState([]);
    const [fieldsSort, setFieldsSort] = useState([]);

    const form = useForm({ defaultValues: {}, mode: 'onTouched' });
    useEffect(() => {
        const fields = adjustUniversalFields(filterUniversalFields(config?.fields || [], itemType), itemType);
        setFields(fields);
        form.reset(convertFieldsToFormData(fields));
    }, [config?.fields]);

    const handleSubmit = async (formData) => {
        const fieldConfigs = [...convertFormDataToFieldInputs(formData, fieldsSort || [], itemType)];

        try {
            await onSubmit(fieldConfigs);
            publishToast({ description: t('configPage.document.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.document.updateError'), status: 'error' });
        }
    };

    const handleAddClick = () => {
        const fieldNameToAdd = 'untitled' + fields.length;
        const defaultOptionsValues = getDefaultUniversalOptionsValues();
        form.setValue(
            // @ts-ignore
            `${fieldNameToAdd}.${fieldNameToAdd}`,
            Object.fromEntries(defaultOptionsValues.map((option) => [option.name, option.defaultValue]))
        );
        setFields([{ name: fieldNameToAdd, options: defaultOptionsValues, position: 0, enabled: true }, ...fields]);
        nav.setActiveField(fieldNameToAdd);
    };

    const handleDeleteUniversalField = (fieldName) => {
        setFields(fields.filter((field) => field.name !== fieldName));
    };

    const { addField, deleteField } = useFieldsConfig({
        form,
        fields,
        fieldsSort,
        onFieldsSortChange: setFieldsSort,
        onDeleteField: handleDeleteUniversalField,
    });

    const getLabel = (key: string) => {
        // @ts-ignore
        const dataLabelName = form.getValues(`${key}.${key}.field_label_name`);
        return dataLabelName || t('configPage.document.form.untitled');
    };
    const nav = useFieldsConfigNavigation({
        form,
        enabledFields: fields,
        availableFields: fields,
        fieldsSort,
        onFieldsSortChange: setFieldsSort,
        addField,
        deleteField,
        getLabel,
    });
    const fieldsFormRef = useRef(null);

    const formFields = form.watch();
    const activeFormFields = Object.entries(formFields[nav.activeField] || {});
    activeFormFields.sort((a, b) => fieldsSort.indexOf(a[0]) - fieldsSort.indexOf(b[0]));

    // prevent duplicated data label names
    const otherDataLabelNames = fields
        .filter((field) => field.name !== nav.activeField)
        .map((field) => {
            // @ts-ignore
            return form.getValues(`${field.name}.${field.name}.data_label_name`);
        });

    return (
        <SettingsFormCard
            onSubmit={form.handleSubmit(handleSubmit)}
            className="max-w-[60rem] p-0"
            formClassName="flex-col gap-0 relative"
        >
            <SettingsFormCard.Title className="p-8 flex justify-between items-center">
                <span>{itemType === '' ? t('configPage.navigation.headerFields') : itemType}</span>
                <div className="flex gap-2 items-center">
                    <DropdownMenu>
                        <DropdownMenu.Trigger asChild disabled={!itemType}>
                            <Button className="px-px w-8 ml-6">
                                <MoreIcon />
                            </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content align="end">
                            <DropdownMenu.Item onClick={onEdit}>
                                {t('configPage.document.universal.moreTabOptions.edit')}
                            </DropdownMenu.Item>

                            <DropdownMenu.Item
                                onClick={onDelete}
                                className="text-error hover:text-error hover:!bg-error"
                            >
                                {t('configPage.document.universal.moreTabOptions.delete')}
                            </DropdownMenu.Item>
                        </DropdownMenu.Content>
                    </DropdownMenu>
                </div>
            </SettingsFormCard.Title>

            {!(fields && fields.length > 0) ? (
                <EmptyState onAddClick={handleAddClick} />
            ) : (
                <FieldsConfig>
                    <FieldsConfig.Navigation>
                        <div className="border-t border-b border-secondary border-solid h-16 font-semibold py-0 pl-8 pr-4 flex justify-between items-center text-sm flex-none">
                            <h2 className="fields-config__title">{t('configPage.document.configuredFields')}</h2>
                            <Button className="px-px w-8" type="button" onClick={handleAddClick}>
                                <AddIcon />
                            </Button>
                        </div>
                        <FieldsConfig.NavigationItems fields={nav.fields} fieldsFormRef={fieldsFormRef} />
                    </FieldsConfig.Navigation>

                    <FieldsConfig.Content>
                        <FieldsConfig.ContentHeader>
                            {nav.activeField
                                ? t('configPage.document.formHeader', {
                                      field: getLabel(nav.activeField),
                                  })
                                : ''}
                        </FieldsConfig.ContentHeader>

                        <FieldsConfig.ContentForms>
                            {activeFormFields?.map(([fieldName, fieldOptions]) => {
                                const fieldDefinition = fields.find((f) => f.name === fieldName);
                                return (
                                    <FieldsConfig.OptionsForm key={fieldName}>
                                        {Object.entries(fieldOptions)?.map(([name, value]) => {
                                            const optionDefinition = fieldDefinition.options?.find(
                                                (o) => o.name === name
                                            );

                                            let component: any;
                                            let componentProps = {};
                                            let required = false;

                                            if (name === 'data_label_instructions') {
                                                component = TextField;
                                                required = true;
                                                componentProps = {
                                                    maxLength: 500,
                                                };
                                            }

                                            if (name == 'data_type') {
                                                component = SelectField;
                                                componentProps = {
                                                    options: [
                                                        {
                                                            label: t('configPage.document.form.string'),
                                                            value: 'string',
                                                        },
                                                        {
                                                            label: t('configPage.document.form.decimal'),
                                                            value: 'decimal',
                                                        },
                                                        {
                                                            label: t('configPage.document.form.date'),
                                                            value: 'date',
                                                        },
                                                    ],
                                                    renderOption: (option) => option.label,
                                                };
                                                required = true;
                                            }

                                            if (name == 'field_label_name') {
                                                required = true;
                                            }

                                            if (name == 'data_label_name') {
                                                required = true;
                                            }

                                            // When the value is not 'undefined' is because it has already been saved into the DB,
                                            // therefore it should be displays as read only.
                                            if (name === 'data_label_name' && optionDefinition?.value != null) {
                                                componentProps = {
                                                    readOnly: true,
                                                    disabled: true,
                                                };
                                            }

                                            return (
                                                <Controller
                                                    // @ts-ignore
                                                    name={`${nav.activeField}.${fieldName}.${name}`}
                                                    control={form.control}
                                                    rules={{
                                                        required: required
                                                            ? t('configPage.document.form.requiredField')
                                                            : undefined,
                                                        validate: (value) => {
                                                            if (
                                                                name === 'data_label_name' &&
                                                                otherDataLabelNames.includes(value)
                                                            ) {
                                                                return t('configPage.document.form.duplicatedField');
                                                            }
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <FieldsConfig.OptionsFormField
                                                            key={name}
                                                            // wrapper props
                                                            label={t(`configPage.document.form.${camelCase(name)}`)}
                                                            error={
                                                                form.formState.errors[nav.activeField]?.[fieldName]?.[
                                                                    name
                                                                ]
                                                            }
                                                            helpText={
                                                                i18n.exists(
                                                                    `config:configPage.document.form.${camelCase(name)}HelpText`
                                                                )
                                                                    ? t(
                                                                          `configPage.document.form.${camelCase(name)}HelpText`
                                                                      )
                                                                    : ''
                                                            }
                                                            // field props
                                                            defaultValue={optionDefinition.defaultValue}
                                                            valueType={optionDefinition.valueType}
                                                            nullable={false}
                                                            required={required}
                                                            component={component}
                                                            componentProps={{
                                                                placeholder: t(
                                                                    `configPage.document.form.${camelCase(name)}`
                                                                ),
                                                                ...componentProps,
                                                            }}
                                                            // form props
                                                            value={field.value}
                                                            onValueChange={(value) =>
                                                                field.onChange({ target: { value } })
                                                            }
                                                            onBlur={field.onBlur}
                                                        />
                                                    )}
                                                />
                                            );
                                        })}
                                    </FieldsConfig.OptionsForm>
                                );
                            })}
                        </FieldsConfig.ContentForms>
                    </FieldsConfig.Content>
                </FieldsConfig>
            )}

            <FieldsConfig.Footer>
                <Button type="submit" variant="primary">
                    {t('configPage.document.submitButton')}
                </Button>
            </FieldsConfig.Footer>
        </SettingsFormCard>
    );
};

export default UniversalFieldsConfig;
