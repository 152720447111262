import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToaster } from '../../core/components/Toast.tsx';
import { Controller, useForm } from 'react-hook-form';
import StringField from '../../core/components/Fields/StringField.tsx';
import Button from '../../core/components/Button.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import IntegerField from '../../core/components/Fields/IntegerField.tsx';
import FormField from '../../core/components/FormField.tsx';

const FORM_DEFAULT_VALUES = {
    sftpHost: '',
    sftpHostKey: '',
    sftpPort: null,
    sftpUser: '',
    sftpPassword: '',
};

const SftpConfig = ({ config, onActivate, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            sftpHost: config?.sftpHost || '',
            sftpHostKey: config?.sftpHostKey || '',
            sftpPort: config?.sftpPort || null,
            sftpUser: config?.sftpUser || '',
            sftpPassword: config?.sftpPassword || '',
        });
    }, [config]);

    const handleSubmit = async (formData) => {
        try {
            if (!config) await onActivate();
            await onSubmit(formData);
            publishToast({ description: t('configPage.sftp.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.sftp.updateError'), status: 'error' });
        }
    };

    return (
        <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.sftp.title')}>
            <Controller
                name="sftpHost"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.sftp.form.sftpHost')} error={formErrors.sftpHost}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="sftpHostKey"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.sftp.form.sftpHostKey')} error={formErrors.sftpHostKey}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="sftpPort"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.sftp.form.sftpPort')} error={formErrors.sftpPort}>
                        <IntegerField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="sftpUser"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.sftp.form.sftpUser')} error={formErrors.sftpUser}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="sftpPassword"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.sftp.form.sftpPassword')} error={formErrors.sftpPassword}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button type="submit" variant="primary">
                    {t('configPage.sftp.form.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};

export default SftpConfig;
