import { default as React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../core/components/ConfirmModal.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import { Controller, useForm } from 'react-hook-form';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import Button from '../../core/components/Button.tsx';
import Modal from '../../core/components/Modal.tsx';
import DropArea from '../../core/components/DropArea.tsx';
import {
    ADDRESS_LIST_COLUMNS,
    ARTICLE_LIST_COLUMNS,
    CLIENT_LIST_COLUMNS,
    CONTACT_LIST_COLUMNS,
    CONVERSION_FACTOR_LIST_COLUMNS,
    OFFER_MATCHING_LIST_COLUMNS,
    ORDER_MATCHING_LIST_COLUMNS,
} from '../../masterdata/fileSchemas.ts';
import { withIcon } from '../../core/components/Icon.tsx';
import { faArrowRotateLeft, faArrowUpToLine } from '@fortawesome/pro-regular-svg-icons';
import { uploadMasterdataFiles } from '../../masterdata/queries.ts';
import Tooltip from '../../core/components/Tooltip.tsx';
import DateField from '../../core/components/Fields/DateField.tsx';

const UploadIcon = withIcon(faArrowUpToLine);
const ResetIcon = withIcon(faArrowRotateLeft);

const FORM_DEFAULT_VALUES = {
    authToken: '',
    clientLookupDefinitionId: '',
    receiverLookupDefinitionId: '',
    contactLookupDefinitionId: '',
    emailLookupDefinitionId: '',
    articleLookupDefinitionId: '',
    debitorArticleLookupDefinitionId: '',
    conversionFactorLookupDefinitionId: '',
    invoiceAddressLookupDefinitionId: '',
    deliveryAddressLookupDefinitionId: '',
    clientMappingLookupDefinitionId: '',
    contactMappingLookupDefinitionId: '',
    articleMappingLookupDefinitionId: '',
    vatIdCheckMappingLookupDefinitionId: '',
    invoiceAddressMappingLookupDefinitionId: '',
    deliveryAddressMappingLookupDefinitionId: '',
    orderMatchingLookupDefinitionId: '',
    offerMatchingLookupDefinitionId: '',
};

const UUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const LookupField = ({ name, form, sampleFileUrl, columns }: any) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const [importModalVisible, setImportModalVisible] = useState(false);

    const [files, setFiles] = useState([]);

    const handleImport = async () => {
        try {
            await uploadMasterdataFiles(files, lookupDefinitionId);
            publishToast({ description: t('configPage.masterData.importSuccess'), status: 'success' });
            setFiles([]);
            setImportModalVisible(false);
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.masterData.importError'), status: 'error' });
        }
    };

    const lookupDefinitionId = form.watch(name);
    const isDisabled = !lookupDefinitionId;
    const error = form.formState.errors[name];

    return (
        <>
            <div className="flex gap-2 items-end">
                <Controller
                    name={name}
                    control={form.control}
                    rules={{
                        validate: (value) =>
                            !value || UUID_PATTERN.test(value) || t('configPage.masterData.form.invalidUuid'),
                    }}
                    render={({ field }) => (
                        <FormField label={t(`configPage.masterData.form.${name}`)} error={error} className="flex-1">
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Tooltip content={isDisabled ? t('configPage.masterData.importDisabledTooltip') : null}>
                    <Button
                        type="button"
                        onClick={() => setImportModalVisible(true)}
                        disabled={isDisabled}
                        className="flex gap-2 items-center"
                    >
                        <UploadIcon />
                        {t('configPage.masterData.importButton')}
                    </Button>
                </Tooltip>
            </div>

            <Modal
                visible={importModalVisible}
                onClose={() => {
                    setImportModalVisible(false);
                    setFiles([]);
                }}
                title={t('configPage.masterData.importModal.title')}
            >
                <div className="text-sm text-secondary flex flex-col gap-4">
                    <DropArea
                        placeholder={t('configPage.masterData.importModal.filesPlaceholder')}
                        accept={{ 'text/csv': ['.csv'] }}
                        onFilesChange={setFiles}
                        files={files}
                        maxFiles={1}
                    />

                    <p>{t('configPage.masterData.importModal.csvNote')}</p>
                    <p className="font-mono">{columns?.join(', ')}</p>
                    {sampleFileUrl && (
                        <p>
                            <a target="_blank" href={sampleFileUrl}>
                                {t('configPage.masterData.importModal.downloadSampleFile')}
                            </a>
                        </p>
                    )}
                </div>

                <div className="flex justify-end">
                    <Button type="submit" variant="primary" onClick={handleImport} disabled={files.length === 0}>
                        {t('configPage.masterData.importModal.submitButton')}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

const MappingField = ({ name, form, onReset }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const [resetModalVisible, setResetModalVisible] = useState(false);
    const [date, setDate] = useState('');

    const handleSubmit = async () => {
        try {
            await onReset(date);
            publishToast({ description: t('configPage.masterData.resetSuccess'), status: 'success' });
            setResetModalVisible(false);
            setDate(''); // Reset date after modal is closed
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.masterData.resetError'), status: 'error' });
        }
    };

    const handleCancel = () => {
        setResetModalVisible(false);
        setDate(''); // Reset date when modal is canceled
    };

    const isDisabled = !form.watch(name);
    const error = form.formState.errors[name];

    return (
        <>
            <div className="flex gap-2 items-end">
                <Controller
                    name={name}
                    control={form.control}
                    rules={{
                        validate: (value) =>
                            !value || UUID_PATTERN.test(value) || t('configPage.masterData.form.invalidUuid'),
                    }}
                    render={({ field }) => (
                        <FormField label={t(`configPage.masterData.form.${name}`)} error={error} className="flex-1">
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <Tooltip content={isDisabled ? t('configPage.masterData.resetDisabledTooltip') : null}>
                    <Button
                        type="button"
                        onClick={() => setResetModalVisible(true)}
                        disabled={isDisabled}
                        className="flex gap-2 items-center"
                    >
                        <ResetIcon />
                        {t('configPage.masterData.resetButton')}
                    </Button>
                </Tooltip>
            </div>

            <ConfirmModal
                visible={resetModalVisible}
                onConfirm={handleSubmit}
                onCancel={handleCancel}
                title={t('configPage.masterData.resetModal.title')}
                danger
                confirmDisabled={!date}
            >
                <div className="form">
                    <p>{t('configPage.masterData.resetModal.description')}</p>
                    <DateField
                        placeholder={t('configPage.masterData.resetModal.datePlaceholder')}
                        value={date}
                        onValueChange={setDate}
                    />
                </div>
            </ConfirmModal>
        </>
    );
};

const MasterDataConfig = ({ config, onSubmit, onDeleteMappings = undefined }: any) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            authToken: config?.authToken || '',

            clientLookupDefinitionId: config?.clientLookupDefinitionId || '',
            receiverLookupDefinitionId: config?.receiverLookupDefinitionId || '',
            contactLookupDefinitionId: config?.contactLookupDefinitionId || '',
            emailLookupDefinitionId: config?.emailLookupDefinitionId || '',

            articleLookupDefinitionId: config?.articleLookupDefinitionId || '',
            debitorArticleLookupDefinitionId: config?.debitorArticleLookupDefinitionId || '',
            conversionFactorLookupDefinitionId: config?.conversionFactorLookupDefinitionId || '',

            invoiceAddressLookupDefinitionId: config?.invoiceAddressLookupDefinitionId || '',
            deliveryAddressLookupDefinitionId: config?.deliveryAddressLookupDefinitionId || '',
            clientMappingLookupDefinitionId: config?.clientMappingLookupDefinitionId || '',
            contactMappingLookupDefinitionId: config?.contactMappingLookupDefinitionId || '',

            articleMappingLookupDefinitionId: config?.articleMappingLookupDefinitionId || '',
            vatIdCheckMappingLookupDefinitionId: config?.vatIdCheckMappingLookupDefinitionId || '',

            invoiceAddressMappingLookupDefinitionId: config?.invoiceAddressMappingLookupDefinitionId || '',
            deliveryAddressMappingLookupDefinitionId: config?.deliveryAddressMappingLookupDefinitionId || '',

            orderMatchingLookupDefinitionId: config?.orderMatchingLookupDefinitionId || '',
            offerMatchingLookupDefinitionId: config?.offerMatchingLookupDefinitionId || '',
        });
    }, [config]);

    const handleSubmit = async (formData) => {
        try {
            await onSubmit(formData);
            publishToast({ description: t('configPage.masterData.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.masterData.updateError'), status: 'error' });
        }
    };

    return (
        <div className="flex flex-col gap-10">
            <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.masterData.title')}>
                <Controller
                    name="authToken"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('configPage.masterData.form.authToken')} error={formErrors.authToken}>
                            <StringField {...field} />
                        </FormField>
                    )}
                />

                <div className="flex justify-end">
                    <Button type="submit" variant="primary">
                        {t('configPage.email.form.submitButton')}
                    </Button>
                </div>
            </SettingsFormCard>

            <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.masterData.lookupTitle')}>
                <LookupField
                    name="clientLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/client_list.csv"
                    columns={CLIENT_LIST_COLUMNS}
                />

                <LookupField
                    name="receiverLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/client_list.csv"
                    columns={CLIENT_LIST_COLUMNS}
                />

                <LookupField
                    name="contactLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/contact_list.csv"
                    columns={CONTACT_LIST_COLUMNS}
                />

                <LookupField name="emailLookupDefinitionId" form={form} />

                <LookupField
                    name="invoiceAddressLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/address_list.csv"
                    columns={ADDRESS_LIST_COLUMNS}
                />

                <LookupField
                    name="deliveryAddressLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/address_list.csv"
                    columns={ADDRESS_LIST_COLUMNS}
                />

                <LookupField
                    name="articleLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/article_list.csv"
                    columns={ARTICLE_LIST_COLUMNS}
                />

                <LookupField
                    name="debitorArticleLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/article_list.csv"
                    columns={ARTICLE_LIST_COLUMNS}
                />

                <LookupField
                    name="conversionFactorLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/conversion_factor_list.csv"
                    columns={CONVERSION_FACTOR_LIST_COLUMNS}
                />

                <div className="flex justify-end">
                    <Button type="submit" variant="primary">
                        {t('configPage.email.form.submitButton')}
                    </Button>
                </div>
            </SettingsFormCard>

            <SettingsFormCard
                onSubmit={form.handleSubmit(handleSubmit)}
                title={t('configPage.masterData.mappingTitle')}
            >
                <MappingField
                    name="clientMappingLookupDefinitionId"
                    form={form}
                    onReset={(date) => onDeleteMappings(date, 'CLIENT')}
                />

                <MappingField
                    name="contactMappingLookupDefinitionId"
                    form={form}
                    onReset={(date) => onDeleteMappings(date, 'CONTACT')}
                />

                <MappingField
                    name="invoiceAddressMappingLookupDefinitionId"
                    form={form}
                    onReset={(date) => onDeleteMappings(date, 'INVOICE_ADDRESS')}
                />

                <MappingField
                    name="deliveryAddressMappingLookupDefinitionId"
                    form={form}
                    onReset={(date) => onDeleteMappings(date, 'DELIVERY_ADDRESS')}
                />

                <MappingField
                    name="articleMappingLookupDefinitionId"
                    form={form}
                    onReset={(date) => onDeleteMappings(date, 'ARTICLE')}
                />

                <MappingField
                    name="vatIdCheckMappingLookupDefinitionId"
                    form={form}
                    onReset={(date) => onDeleteMappings(date, 'VAT_ID_CHECK')}
                />

                <div className="flex justify-end">
                    <Button type="submit" variant="primary">
                        {t('configPage.email.form.submitButton')}
                    </Button>
                </div>
            </SettingsFormCard>

            <SettingsFormCard
                onSubmit={form.handleSubmit(handleSubmit)}
                title={t('configPage.masterData.matchingTitle')}
            >
                <LookupField
                    name="orderMatchingLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/order_matching_list.csv"
                    columns={ORDER_MATCHING_LIST_COLUMNS}
                />

                <LookupField
                    name="offerMatchingLookupDefinitionId"
                    form={form}
                    sampleFileUrl="/samples/csv/offer_matching_list.csv"
                    columns={OFFER_MATCHING_LIST_COLUMNS}
                />

                <div className="flex justify-end">
                    <Button type="submit" variant="primary">
                        {t('configPage.email.form.submitButton')}
                    </Button>
                </div>
            </SettingsFormCard>
        </div>
    );
};

export default MasterDataConfig;
