import React from 'react';
import classnames from '../utils/classnames.tsx';

const SettingsFormCard = ({
    title,
    className,
    formClassName,
    children,
    onSubmit,
    ...props
}: React.ComponentPropsWithRef<'section'> & {
    title?: string;
    onSubmit?: (formData: any) => void;
    formClassName?: string;
}) => {
    return (
        <section
            {...props}
            className={classnames(
                'w-full max-w-[48rem] border border-solid border-primary bg-primary flex flex-col rounded-lg p-8 gap-6',
                className
            )}
        >
            {title && <SettingsFormCardTitle>{title}</SettingsFormCardTitle>}

            <form className={classnames('flex flex-col gap-6', formClassName)} onSubmit={onSubmit}>
                {children}
            </form>
        </section>
    );
};

const SettingsFormCardTitle = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div className={classnames('text-lg font-medium text-primary', className)} {...props} />
);

export default Object.assign(SettingsFormCard, { Title: SettingsFormCardTitle });
