import * as React from 'react';

// @ts-ignore
import LogoSvg from '../../../../static/images/workist_logo.svg?react';
import classnames from '../utils/classnames.tsx';

const Logo = ({ className, ...props }: React.ComponentPropsWithRef<'svg'>) => {
    return <LogoSvg className={classnames('text-primary', className)} {...props} />;
};

export default Logo;
