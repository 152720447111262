import * as React from 'react';
import classnames from '../../../utils/classnames.tsx';

interface PageProps {
    className?: string;
    children?: React.ReactNode;
}

const Page = ({ className, children }: PageProps) => {
    return <main className={classnames('w-full flex-none h-full flex flex-col', className)}>{children}</main>;
};

export default Page;
