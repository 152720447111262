import { useTranslation } from 'react-i18next';
import Page from '../../../../core/components/Page';
import classnames from '../../../../core/utils/classnames.tsx';
import { canManageChannels } from '../../../../users/utils';
import { useApplicationContext } from '../../../../core/contexts/ApplicationContext';
import DropdownMenu from '../../../../core/components/DropdownMenu';
import { Link } from 'react-router-dom';
import { url } from '../../../../core/utils/link';
import * as React from 'react';
import { useOverviewContext } from './OverviewContext';
import { withIcon } from '../../../../core/components/Icon';
import { faArrowToTop, faEllipsisVertical, faPlus, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

const AddIcon = withIcon(faPlus);
const UploadIcon = withIcon(faArrowToTop);
const MoreIcon = withIcon(faEllipsisVertical);
const LoadingIcon = withIcon(faSpinnerThird);

export const AllChannelsHeader = ({ user, onAddChannelVisibleChange, onAutomationRateModalVisibleChange }) => {
    const { t } = useTranslation('assistance');

    return (
        <Page.Header className="justify-between">
            <Page.HeaderTitle>{t('overview.navigation.allChannels')}</Page.HeaderTitle>

            <div className="flex gap-2">
                <Page.HeaderButton
                    className={classnames(!canManageChannels(user) && 'menu-item--disabled')}
                    onClick={() => onAddChannelVisibleChange(true)}
                >
                    <AddIcon />
                    {t('overview.header.actions.addChannel')}
                </Page.HeaderButton>

                <DropdownMenu>
                    <DropdownMenu.Trigger asChild>
                        <Page.HeaderIconButton>
                            <MoreIcon />
                        </Page.HeaderIconButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content align="end">
                        <DropdownMenu.Item onClick={() => onAutomationRateModalVisibleChange(true)}>
                            {t('overview.header.actions.showAutomationRate')}
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu>
            </div>
        </Page.Header>
    );
};

export const SingleChannelHeader = ({
    activeChannel,
    onCopyEmailAddress,
    onUploadFileModalVisibleChange,
    onDuplicateChannelVisibleChange,
    onAutomationRateModalVisibleChange,
    documentConfiguration,
}) => {
    const { t } = useTranslation('assistance');

    const { user } = useApplicationContext();
    const { channelId } = useOverviewContext();

    return (
        <Page.Header className="justify-between">
            <Page.HeaderTitle>{activeChannel?.name || <LoadingIcon spin className="text-brand" />}</Page.HeaderTitle>

            <div className="flex gap-2">
                <DropdownMenu>
                    <DropdownMenu.Trigger asChild>
                        <Page.HeaderButton>
                            <UploadIcon />
                            {t('overview.header.actions.uploadFile')}
                        </Page.HeaderButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content align="end">
                        <DropdownMenu.Item
                            onClick={() => onUploadFileModalVisibleChange(true)}
                            disabled={!user.customer?.isActive}
                        >
                            {t('overview.header.actions.uploadFileComputer')}
                        </DropdownMenu.Item>

                        <DropdownMenu.Item
                            onClick={() => onCopyEmailAddress(activeChannel.emailAddress)}
                            className="flex flex-col items-start"
                            disabled={!activeChannel?.emailAddress}
                        >
                            <span>{t('overview.header.actions.copyEmailAddress')}</span>
                            <span className="text-xs text-tertiary">
                                {activeChannel?.emailAddress?.length > 32
                                    ? activeChannel?.emailAddress.slice(0, 29) + '...'
                                    : activeChannel?.emailAddress}
                            </span>
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu>

                <DropdownMenu>
                    <DropdownMenu.Trigger asChild>
                        <Page.HeaderIconButton>
                            <MoreIcon />
                        </Page.HeaderIconButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content align="end">
                        <DropdownMenu.Item onClick={() => onAutomationRateModalVisibleChange(true)}>
                            {t('overview.header.actions.showAutomationRate')}
                        </DropdownMenu.Item>

                        <DropdownMenu.Separator />

                        <DropdownMenu.Item
                            disabled={!canManageChannels(user)}
                            onClick={() => onDuplicateChannelVisibleChange(true)}
                        >
                            {t('overview.header.actions.duplicateChannel')}
                        </DropdownMenu.Item>

                        <DropdownMenu.Item disabled={!canManageChannels(user)} asChild>
                            <Link to={url(documentConfiguration.constants.CHANNEL_CONFIG_PATH, { channelId })}>
                                {t('overview.header.actions.manageChannel')}
                            </Link>
                        </DropdownMenu.Item>

                        <DropdownMenu.Item asChild>
                            <Link to={url(documentConfiguration.constants.CHANNEL_MASTERDATA_PATH, { channelId })}>
                                {t('overview.header.actions.manageMasterdata')}
                            </Link>
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu>
            </div>
        </Page.Header>
    );
};
