import * as React from 'react';
import { SimpleJsonInput } from '../JsonInput.tsx';
import { useEffect, useState } from 'react';
import Field from './Field.tsx';
import { useControllableState } from '../../utils/useControllableState.tsx';

const JsonField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onChange,
    readOnly,
    disabled,
    className,
    inputClassName,
    controls,
    inputRef = undefined,
    ...props
}: any) => {
    // comes in as object, goes out as object
    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

    const [internalValue, setInternalValue] = useState<string>(JSON.stringify(value, null, 2) || '');
    useEffect(() => setInternalValue(JSON.stringify(value, null, 2) || ''), [value]);

    const handleBlur = () => {
        // only update real value when blurred, so we don't try to parse intermediate values
        try {
            onValueChange(JSON.parse(internalValue));
        } catch (error) {
            // reset to initial value
            setInternalValue(JSON.stringify(value, null, 4));
        }
    };

    return <JsonStringField value={internalValue} onValueChange={setInternalValue} onBlur={handleBlur} {...props} />;
};

export const JsonStringField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onChange,
    readOnly,
    disabled,
    className,
    inputClassName,
    controls,
    inputRef = undefined,
    onBlur,
    ...props
}: any) => {
    // comes in as string, goes out as string
    return (
        <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
            <Field.Input>
                <SimpleJsonInput
                    className="json-field outline-none"
                    value={propsValue}
                    setValue={onValueChange}
                    invalidSyntaxError="Invalid syntax"
                    onBlur={onBlur}
                />
            </Field.Input>
            <Field.Controls>{controls}</Field.Controls>
        </Field>
    );
};

export default JsonField;
