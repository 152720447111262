import { useEffect, useState } from 'react';

const findScrollParent = (element) => {
    if (!element) return null;

    let parent = element.parentElement;
    while (parent) {
        const { overflow, overflowY, overflowX } = window.getComputedStyle(parent);
        if (/(auto|scroll)/.test(overflow) || /(auto|scroll)/.test(overflowY) || /(auto|scroll)/.test(overflowX)) {
            return parent;
        }
        parent = parent.parentElement;
    }
    return document.documentElement; // Fallback to viewport if no scrollable parent
};

const useStickyState = (ref, active = false) => {
    const [sticky, setSticky] = useState({
        top: false,
        bottom: false,
        left: false,
        right: false,
    });

    useEffect(() => {
        if (!active) return;

        const element = ref.current;
        if (!element) return;

        const parent = findScrollParent(element);
        if (!parent) return;

        // Get which sides can be sticky
        const style = window.getComputedStyle(element);
        const stickyTop = style.top !== 'auto';
        const stickyBottom = style.bottom !== 'auto';
        const stickyLeft = style.left !== 'auto';
        const stickyRight = style.right !== 'auto';

        // cache inside the function to avoid state circular dependencies
        let prevSticky = { top: false, bottom: false, left: false, right: false };

        const checkSticky = () => {
            const newSticky = {
                top: stickyTop && parent.scrollTop > 0,
                bottom: stickyBottom && parent.scrollTop + parent.clientHeight < parent.scrollHeight,
                left: stickyLeft && parent.scrollLeft > 0,
                right: stickyRight && parent.scrollLeft + parent.clientWidth < parent.scrollWidth,
            };

            // Only update state if values actually changed
            if (
                prevSticky.top !== newSticky.top ||
                prevSticky.bottom !== newSticky.bottom ||
                prevSticky.left !== newSticky.left ||
                prevSticky.right !== newSticky.right
            ) {
                setSticky(newSticky);
                prevSticky = newSticky;
            }
        };

        parent.addEventListener('scroll', checkSticky);
        window.addEventListener('resize', checkSticky);
        checkSticky();

        const observer = new ResizeObserver((entries) => {
            checkSticky();
        });
        observer.observe(parent);

        return () => {
            parent.removeEventListener('scroll', checkSticky);
            window.removeEventListener('resize', checkSticky);
            observer.disconnect();
        };
    }, [active]);

    return sticky;
};

export default useStickyState;
