import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import { useTranslation } from 'react-i18next';
import Button from '../../core/components/Button.tsx';
import Page from '../../core/components/Page';
import Layout from '../../core/components/Layout.tsx';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_CUSTOMER_SFTP_PUBLIC_KEY, DELETE_CUSTOMER_SFTP_PUBLIC_KEY, GET_SFTP_PUBLIC_KEYS } from '../queries.ts';
import { useToaster } from '../../core/components/Toast.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import classnames from '../../core/utils/classnames.tsx';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import { withIcon } from '../../core/components/Icon.tsx';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import TextField from '../../core/components/Fields/TextField.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

const DeleteIcon = withIcon(faTrashCan);

const PUBLIC_KEY_FORM_DEFAULT_VALUES = {
    publicKeyBody: '',
};

const PublicKeyRow = ({ publicKey, refetch }: { publicKey: any; refetch: any }) => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const [deleteSftpPublicKey] = useMutation(DELETE_CUSTOMER_SFTP_PUBLIC_KEY);
    const handleDeleteToken = async () => {
        try {
            await deleteSftpPublicKey({ variables: { publicKeyId: publicKey.SshPublicKeyId } });
            await refetch();
            publishToast({ description: t('sftpKey.deleteSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('sftpKey.deleteError'), status: 'error' });
        }
    };

    return (
        <>
            <Table.Row>
                <Table.Cell className="font-mono break-all max-w-96">{publicKey.SshPublicKeyBody}</Table.Cell>
                <Table.Cell>{new Date(publicKey.DateImported).toLocaleString()}</Table.Cell>
                <Table.Cell>
                    <RowControlIconButton
                        className="hover:bg-error text-error hover:text-error"
                        onClick={handleDeleteToken}
                    >
                        <DeleteIcon />
                    </RowControlIconButton>
                </Table.Cell>
            </Table.Row>
        </>
    );
};

const SftpPublicKeysPage = () => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: PUBLIC_KEY_FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const {
        data: sftpPublicKeyData,
        loading,
        refetch,
    } = useQuery(GET_SFTP_PUBLIC_KEYS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });
    const sftpPublicKeys = sftpPublicKeyData?.viewer?.customer?.sftpPublicKeys || [];

    const [createSftpPublicKey] = useMutation(CREATE_CUSTOMER_SFTP_PUBLIC_KEY);
    const handleCreateToken = async (formData) => {
        try {
            await createSftpPublicKey({ variables: formData });
            await refetch();
            form.reset(PUBLIC_KEY_FORM_DEFAULT_VALUES);
            publishToast({ description: t('sftpKey.createSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('sftpKey.createError'), status: 'error' });
        }
    };

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle>{t('sftpKey.title')}</Page.HeaderTitle>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    {sftpPublicKeys && sftpPublicKeys.length > 0 && (
                        <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeadCell>{t('sftpKey.table.key')}</Table.HeadCell>
                                    <Table.HeadCell>{t('sftpKey.table.createdAt')}</Table.HeadCell>
                                    <Table.HeadCell sticky="right" className="min-w-14 w-14" />
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {sftpPublicKeys.map((publicKey) => (
                                    <PublicKeyRow key={publicKey.id} publicKey={publicKey} refetch={refetch} />
                                ))}
                            </Table.Body>
                        </Table>
                    )}

                    <SettingsFormCard onSubmit={form.handleSubmit(handleCreateToken)} title={t('sftpKey.form.title')}>
                        <p className="text-primary text-sm">{t('sftpKey.form.description')}</p>

                        <Controller
                            name="publicKeyBody"
                            control={form.control}
                            render={({ field }) => (
                                <FormField label={t('sftpKey.form.publicKeyBody')} error={formErrors.publicKeyBody}>
                                    <TextField {...field} placeholder={t('sftpKey.form.publicKeyBodyPlaceholder')} />
                                </FormField>
                            )}
                        />

                        <div className="flex justify-end">
                            <Button type="submit" variant="primary">
                                {t('sftpKey.form.createButton')}
                            </Button>
                        </div>
                    </SettingsFormCard>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default SftpPublicKeysPage;
