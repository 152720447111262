import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import classnames from '../utils/classnames.tsx';
import { useControllableState } from '../utils/useControllableState.tsx';
import { withIcon } from './Icon.tsx';
import {
    faDownLeftAndUpRightToCenter,
    faUpRightAndDownLeftFromCenter,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import Button from './Button.tsx';

export interface IBaseModalProps {
    className?: string;
    children?: any;
    visible?: boolean;
    onVisibleChange?: (visible: boolean) => void;
    // the modal is considered dismissible if onClose != null (note: visibility is still controlled externally)
    onClose?: () => void; // deprecated, use onVisibleChange instead
    title?: React.ReactNode | string;
    buttons?: any;
    noHeader?: boolean;
    noCloseButton?: boolean;
    containerRef?: any;
    allowMaximize?: boolean;
}

const CloseIcon = withIcon(faXmark);
const MaximizeIcon = withIcon(faUpRightAndDownLeftFromCenter);
const MinimizeIcon = withIcon(faDownLeftAndUpRightToCenter);

const Modal = ({
    className,
    visible = true,
    onVisibleChange,
    allowMaximize = false,
    buttons,
    title,
    noHeader,
    noCloseButton,
    onClose,
    children,
    containerRef,
}: IBaseModalProps) => {
    const defaultContainerRef = useRef(document.getElementById('modal-root'));
    const modalContainerRef = containerRef || defaultContainerRef;

    const [open, setOpen] = useControllableState(false, visible, onVisibleChange);
    const [isMaximized, setIsMaximized] = useState(false);
    useEffect(() => setOpen(visible), [visible]);

    const handleOpenChange = (open) => {
        setOpen(open);
        if (!open) onClose?.();
    };

    const handleWrapperClick = (event) => {
        // if not clicking on the modal itself, close the modal
        if (!event.target.closest('.js-modal')) {
            handleOpenChange(false);
        }
    };

    if (!modalContainerRef?.current) return null;

    return (
        <Dialog.Root open={open} onOpenChange={handleOpenChange}>
            <Dialog.Portal container={modalContainerRef?.current}>
                <Dialog.Overlay className="absolute inset-0 bg-[rgba(0,0,0,0.75)] overflow-auto flex flex-col justify-start items-center py-[10vh] px-5 z-[21000]" />

                <Dialog.Content
                    onOpenAutoFocus={(event) => event.preventDefault()}
                    className={classnames(
                        'flex justify-center absolute top-0 left-0 w-full h-[100vh] overflow-auto flex-1 py-[10vh] pointer-events-auto',
                        isMaximized && 'p-5',
                        'js-modal' // needed for popovers to work
                    )}
                    onClick={handleWrapperClick}
                >
                    <div
                        className={classnames(
                            'flex flex-col min-w-[28rem] max-w-[38rem] w-full flex-none h-[min-content] bg-primary rounded-xl z-[21000]',
                            isMaximized &&
                                'w-[calc(100vw-30px)] h-[calc(100vh-30px)] min-w-[calc(100vw-30px)] max-w-[calc(100vw-30px)]',
                            className
                        )}
                    >
                        {!noHeader && (
                            <div
                                className={classnames(
                                    'flex justify-between items-center gap-8 pt-4 px-8 pb-3 border-b border-solid border-primary flex-none',
                                    !title && 'justify-end pb-0 border-none -mb-1'
                                )}
                            >
                                {title && (
                                    <Dialog.Title className="text-lg font-medium text-primary max-w-[32rem]">
                                        {title}
                                    </Dialog.Title>
                                )}

                                <div className="flex gap-1">
                                    {allowMaximize && (
                                        <Button
                                            variant="ghost"
                                            tabIndex={-1} // unfocusable (will autofocus the first child input instead)
                                            className="w-9 h-9 p-0 flex items-center justify-center text-tertiary"
                                            onClick={() => setIsMaximized((value) => !value)}
                                        >
                                            {isMaximized ? <MinimizeIcon /> : <MaximizeIcon />}
                                        </Button>
                                    )}

                                    {!noCloseButton && (
                                        <Dialog.Close asChild>
                                            <Button
                                                variant="ghost"
                                                tabIndex={-1} // unfocusable (will autofocus the first child input instead)
                                                className="w-9 h-9 -mr-4 p-0 flex items-center justify-center text-tertiary"
                                                onClick={() => handleOpenChange(false)}
                                            >
                                                <CloseIcon />
                                            </Button>
                                        </Dialog.Close>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className={classnames('text-base text-primary flex flex-col flex-1', noHeader && 'pt-4')}>
                            <div className={classnames('w-full flex flex-col gap-2 p-8 pt-4', !noHeader && 'pt-6')}>
                                {children}
                            </div>
                            {buttons && (
                                <div className="flex gap-4 justify-end px-8 py-4 bg-secondary rounded-b-xl">
                                    {buttons}
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default Modal;
