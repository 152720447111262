import React, { useEffect, useRef } from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import uniqueId from 'lodash/uniqueId';
import { useControllableState } from '../../core/utils/useControllableState.tsx';
import IntegerField from '../../core/components/Fields/IntegerField.tsx';

export const DECIMAL_VALIDATION_REGEXP = /^\d+[.|,]?\d*$/;

const Slider = ({ showInput = true, value, onChange, onBlur, min = 0, max = 100, step = 1, ...props }: any) => {
    return (
        <div className="flex gap-5 w-full items-center">
            <SliderPrimitive.Root
                {...props}
                className="relative flex items-center select-none w-full flex-1 h-5"
                defaultValue={[min]}
                min={min}
                max={max}
                step={step}
                value={value != undefined ? [value] : undefined}
                onValueChange={(value) => onChange?.({ target: { value: value[0] } })}
                onBlur={(e) => onBlur?.(e)}
            >
                <SliderPrimitive.Track className="bg-secondary relative flex-1 rounded-full h-1">
                    <SliderPrimitive.Range className="absolute bg-brand-hover rounded-full h-full" />
                </SliderPrimitive.Track>
                <SliderPrimitive.Thumb className="block w-4 h-4 bg-primary rounded-full shadow-sm hover:shadow cursor-grab border border-solid border-primary outline-brand" />
            </SliderPrimitive.Root>

            {showInput && (
                <IntegerField
                    className="w-12"
                    min={min}
                    max={max}
                    step={step}
                    value={value.toString()}
                    onChange={(e) => onChange?.(e)}
                    onBlur={(e) => onBlur?.(e)}
                />
            )}
        </div>
    );
};

const SliderField = ({
    label,
    value: controlledValue,
    setValue: setControlledValue,
    initialValue = undefined,
    onValueChange,
    inputProps = {},
    locale = 'en_US',
    onClear = undefined,
    onChange = undefined,
    ...sliderProps
}: any) => {
    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (initialValue !== undefined && initialValue !== value ? setValue(initialValue) : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        if (event.target.value != undefined && !DECIMAL_VALIDATION_REGEXP.test(event.target.value)) {
            event.preventDefault();
            return;
        }

        const value = event.target.value !== '' ? parseFloat(event.target.value) : undefined;

        setValue(value);
        onChange?.(value);
        if (inputProps?.onChange) inputProps?.onChange(value);
    };

    const handleBlur = (event, ...eventProps) => {
        if (inputProps?.onBlur) inputProps?.onBlur(event, ...eventProps);
        if (onValueChange && initialValue !== value) onValueChange(value !== undefined && value !== '' ? value : null);
    };

    const formatDecimal = (value, locale) => {
        if (locale && value) {
            const newValue = value.toString();
            // In case the number is still being written, just return it
            if (newValue.slice(-1) == '.' || newValue.slice(-1) == ',') {
                return value;
            }
            locale = locale.replace('_', '-');
            value = new Number(newValue.replace(',', '.'));
            value = value.toLocaleString(locale, { useGrouping: false });
        }

        return value;
    };

    return (
        <Slider
            id={fieldId}
            {...inputProps}
            value={formatDecimal(value, locale) == undefined ? '' : formatDecimal(value, locale)}
            onChange={handleChange}
            onValueChange={handleChange}
            onBlur={handleBlur}
            {...sliderProps}
        />
    );
};

export const PercentageSliderField = ({ initialValue, value, onValueChange, onChange, ...props }: any) => {
    return (
        <SliderField
            value={value ? value * 100 : undefined}
            initialValue={initialValue ? initialValue * 100 : undefined}
            onValueChange={(value) => value !== undefined && onValueChange(value / 100)}
            onChange={(e) => e !== undefined && onChange({ target: { value: e / 100 } })}
            min={0}
            max={100}
            step={1}
            {...props}
        />
    );
};

export default SliderField;
