import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../core/components/DropdownMenu';
import Button from '../../core/components/Button.tsx';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { withIcon } from '../../core/components/Icon.tsx';
import { faExclamationCircle, faGripDotsVertical, faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../core/utils/classnames.tsx';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Switch from '../../core/components/Switch.tsx';
import { useControllableState } from '../../core/utils/useControllableState.tsx';
import useStickyState from '../../core/utils/useStickyState.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import DecimalField from '../../core/components/Fields/DecimalField.tsx';
import DateField from '../../core/components/Fields/DateField.tsx';
import BooleanField from '../../core/components/Fields/BooleanField.tsx';
import IntegerField from '../../core/components/Fields/IntegerField.tsx';
import TagsField from '../../core/components/Fields/TagsField.tsx';
import JsonField from '../../core/components/Fields/JsonField.tsx';
import { Slot } from '@radix-ui/react-slot';

// based on ValueType from backend
const FIELDS = {
    string: StringField,
    integer: IntegerField,
    float: DecimalField,
    decimal: DecimalField,
    boolean: BooleanField,
    date: DateField,
    datetime: DateField,
    dict: JsonField,
    list: TagsField,
};

const DRAGGABLE_ITEM_TYPE = 'ITEM';
const ErrorIcon = withIcon(faExclamationCircle);
const DragIcon = withIcon(faGripDotsVertical);
const AddIcon = withIcon(faPlus);
const RemoveIcon = withIcon(faXmark);

export const convertFieldsToFormData = (fields) => {
    const formData = {};
    fields?.forEach((field) => {
        const [groupName, _] = field.name.split('__');
        if (!formData[groupName]) formData[groupName] = {};
        formData[groupName][field.name] = Object.fromEntries(
            field.options
                .filter((option) => !option.hasOwnProperty('configurable') || option.configurable)
                .map((option) => [option.name, option.value])
        );
    });
    return formData;
};

export const convertFormDataToFieldInputs = (formData, fieldsSort, itemType) => {
    // if not itemType is given, we are working on header data

    const fields = Object.assign({}, ...Object.values(formData));

    return Object.entries(fields).map(([fieldName, fieldConfig]: any) => ({
        fieldName: itemType ? `${itemType}__${fieldName}` : fieldName,
        position: fieldsSort.indexOf(fieldName) >= 0 ? fieldsSort.indexOf(fieldName) : undefined,
        options: Object.entries(fieldConfig).map(([optionName, optionValue]) => ({
            name: optionName,
            value: optionValue,
        })),
    }));
};

export const convertFieldsToEnabledFieldInputs = (fields) => {
    // "Field Input" is the way the backend expects to receive the data
    return (
        fields
            ?.filter((field) => field.enabled)
            .map((field) => ({
                fieldName: field.name,
                position: field.position,
                options: field.options
                    ?.filter((option) => !option.hasOwnProperty('configurable') || option.configurable)
                    .map((option) => ({
                        name: option.name,
                        value: option.value,
                    })),
            })) || []
    );
};

const useDragDrop = ({
    index,
    itemType,
    name,
    onMove,
    onDragStart,
    onDrop,

    dragRef,
    previewRef,
    itemRef,
}: {
    index: number;
    itemType: string;
    name: string;
    onMove?: (dragIndex: number, hoverIndex: number) => void;
    onDragStart?: (item: any) => void;
    onDrop?: (item: any) => void;

    dragRef: any;
    previewRef: any;
    itemRef: any;
}) => {
    const [, drop] = useDrop({
        accept: itemType,
        hover(item: any, monitor) {
            if (!dragRef.current || !previewRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = itemRef.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            onMove?.(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: itemType,
        item: () => {
            const item = { index, name };
            onDragStart?.(item);
            return item;
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            onDrop?.(item);
        },
    });

    drag(dragRef);
    drop(dragPreview(previewRef));
    drop(itemRef);

    return { isDragging };
};

export const useFieldsConfig = ({
    form,
    fields,
    fieldsSort: propsFieldsSort,
    onFieldsSortChange,
    enabledFieldNames: propsEnabledFieldNames,
    onEnabledFieldNamesChange,
    onAddField,
    onDeleteField,
}: {
    form: any;
    fields: any[];
    fieldsSort?: any;
    onFieldsSortChange?: any;
    enabledFieldNames?: any;
    onEnabledFieldNamesChange?: any;
    onAddField?: any;
    onDeleteField?: any;
}) => {
    const [enabledFieldNames, setEnabledFieldNames] = useControllableState(
        [],
        propsEnabledFieldNames,
        onEnabledFieldNamesChange
    );
    const [fieldsSort, setFieldsSort] = useControllableState([], propsFieldsSort, onFieldsSortChange);

    useEffect(() => {
        const nextEnabledFields = fields.filter((field) => field.enabled);
        setEnabledFieldNames(nextEnabledFields.map((field) => field.name));

        // only base fields, no subfields in sort
        nextEnabledFields.sort((a, b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return 1;
            } else if (b.position === undefined) {
                return -1;
            } else {
                return a.position - b.position;
            }
        });
        setFieldsSort(nextEnabledFields.map((field) => field.name));
    }, [fields]);

    const getFieldAndSubfields = (fieldName) =>
        fields.filter((field) => field.name === fieldName || field.name.startsWith(`${fieldName}__`));

    const addField = (fieldName) => {
        let fieldNamesToAdd = null;

        // if the selected field has subfields, add them as well
        fieldNamesToAdd = getFieldAndSubfields(fieldName)
            .filter((field) => !enabledFieldNames.includes(field.name))
            .map((field) => field.name);

        // update field on the form to use default values as initial values
        fieldNamesToAdd.forEach((fieldNameToAdd) => {
            const field = fields.find((field) => field.name === fieldNameToAdd);
            const groupName = field ? field.name.split('__')[0] : fieldNameToAdd;
            form.setValue(
                `${groupName}.${fieldNameToAdd}`,
                Object.fromEntries(field.options.map((option) => [option.name, option.defaultValue]))
            );
        });

        setEnabledFieldNames((prev) => [...prev, ...fieldNamesToAdd]);

        // add after groupName if found otherwise at the beginning
        const groupName = fieldName.split('__')[0];
        setFieldsSort((prev) => {
            const index = prev.indexOf(groupName);
            return [...prev.slice(0, index + 1), ...fieldNamesToAdd, ...prev.slice(index + 1)];
        });

        onAddField?.(fieldName);
    };

    const deleteField = (fieldName, subfield = undefined) => {
        const deletableFieldNames = getFieldAndSubfields(fieldName)
            .filter((field) => enabledFieldNames.includes(field.name))
            .map((field) => field.name);

        // 2 because the field itself is included in deletableFieldNames
        const isLastSubfield = deletableFieldNames.length === 2;

        // if the selected field has subfields, delete them as well
        // otherwise delete the field itself and if it is the last the whole group
        let fieldNamesToDelete = subfield && !isLastSubfield ? [`${fieldName}__${subfield}`] : deletableFieldNames;
        fieldNamesToDelete = fieldNamesToDelete.length === 0 ? [fieldName] : fieldNamesToDelete;

        setEnabledFieldNames((prev) => prev.filter((fieldName) => !fieldNamesToDelete.includes(fieldName)));
        setFieldsSort((prev) => prev.filter((fieldName) => !fieldNamesToDelete.includes(fieldName)));

        const formValues = form.getValues();
        fieldNamesToDelete.forEach((fieldNameToDelete) => {
            const [groupName, _] = fieldNameToDelete.split('__');
            const groupValues = formValues[groupName];
            delete groupValues[fieldNameToDelete];
            form.setValue(`${groupName}`, groupValues);
        });

        onDeleteField?.(fieldName);
    };

    return {
        addField,
        deleteField,
        fieldsSort,
        setFieldsSort,
        enabledFieldNames,
        setEnabledFieldNames,
    };
};

export const useFieldsConfigNavigation = ({
    form,
    availableFields,
    enabledFields,
    addField,
    deleteField,
    fieldsSort = [],
    onFieldsSortChange,
    getLabel,
}) => {
    const { t } = useTranslation('config');

    const [activeField, setActiveField] = useState(null);
    const [unsavedFieldsSort, setUnsavedFieldsSort] = useState([]);
    useEffect(() => {
        setUnsavedFieldsSort(fieldsSort);
        if (!activeField || !enabledFields.find((f) => f.name === activeField)) {
            // if the active field is not in the list of configured fields, select the first one
            setActiveField(fieldsSort[0]);
        }
    }, [fieldsSort]);

    const fieldAndSubfieldNames = {};
    enabledFields?.forEach((field) => {
        // fill a look-up object with all subfield names and their parent field
        const [parent, child] = field.name.split('__', 2);

        if (!fieldAndSubfieldNames[parent]) {
            fieldAndSubfieldNames[parent] = [];
        }

        if (child) {
            fieldAndSubfieldNames[parent].push(child);
        }
    });

    const handleFieldDrop = (item) => {
        // This method cleans up the sorting and ensures an order like:
        // ["field", "field__...", "field2", "field2__..."]

        setActiveField(item.name);

        // Group fields by their base
        const baseFields = unsavedFieldsSort.filter((f) => !f.includes('__'));
        const sortedFields = baseFields.reduce((acc, baseField) => {
            acc.push(baseField);

            // Add all subfields for this base
            const subfields = unsavedFieldsSort.filter((f) => f.startsWith(baseField + '__'));
            acc.push(...subfields);

            return acc;
        }, []);

        onFieldsSortChange(sortedFields);
    };

    const handleFieldMove = (dragIndex, hoverIndex) => {
        // resort item from fieldIndices based on dragIndex and hoverIndex
        setUnsavedFieldsSort((prev) => {
            const newIndices = [...prev];
            newIndices.splice(hoverIndex, 0, newIndices.splice(dragIndex, 1)[0]);
            return newIndices;
        });
    };

    const handleFieldDragStart = (item) => {
        setActiveField(item.name);
    };

    const handleAddField = (fieldName) => {
        addField(fieldName);
        setActiveField(fieldName);
    };

    const handleAddSubfield = (fieldName) => (subfield) => {
        addField(`${fieldName}__${subfield}`);
        // set parent field as active
        setActiveField(fieldName);
    };

    const fieldNames = Object.keys(fieldAndSubfieldNames);
    fieldNames.sort((a, b) => unsavedFieldsSort.indexOf(a) - unsavedFieldsSort.indexOf(b));

    const addableFields = availableFields
        .filter((field) => !field.name.includes('__') && !enabledFields.find((f) => f.name === field.name))
        .map((field) => ({
            label: getLabel(field.name),
            name: field.name,
        }));
    addableFields.sort((a, b) => a.label.localeCompare(b.label));

    const isFieldRequired = (fieldName, subfieldName = undefined) => {
        const fullName = subfieldName ? `${fieldName}__${subfieldName}` : fieldName;
        // fieldName is basically the group name
        return form.getValues(`${fieldName}.${fullName}.is_required`) ?? true;
    };

    return {
        activeField,
        setActiveField,

        onAddField: handleAddField,
        addableFields,

        fieldsSort: unsavedFieldsSort,
        fieldNames,
        fields: fieldNames.map((fieldName) => ({
            name: fieldName,
            label: getLabel(fieldName),
            note: enabledFields.find((f) => f.name.startsWith(`${fieldName}__`))
                ? t(`configPage.document.fieldNotes.subfields`, {
                      count: fieldAndSubfieldNames[fieldName].length,
                  })
                : isFieldRequired(fieldName)
                  ? t('configPage.document.fieldNotes.required')
                  : t('configPage.document.fieldNotes.optional'),
            index: unsavedFieldsSort.indexOf(fieldName),
            active: activeField === fieldName,
            onDrop: handleFieldDrop,
            onMove: handleFieldMove,
            onDragStart: handleFieldDragStart,
            onClick: () => setActiveField(fieldName),
            onDelete: () => deleteField(fieldName),

            onAddSubfield: handleAddSubfield(fieldName),
            addableSubfields: availableFields
                .filter(
                    (f) =>
                        f.name.startsWith(`${fieldName}__`) &&
                        !fieldAndSubfieldNames[fieldName].includes(f.name.split('__', 2)[1])
                )
                .map((f) => {
                    const [parent, child] = f.name.split('__', 2);
                    return {
                        name: child,
                        label: getLabel(`${parent}__${child}`),
                    };
                })
                .sort((a, b) => a.label.localeCompare(b.label)),

            subfields: fieldAndSubfieldNames[fieldName]
                .map((subfieldName) => ({
                    name: `${fieldName}__${subfieldName}`,
                    label: getLabel(`${fieldName}__${subfieldName}`),
                    note: isFieldRequired(fieldName, subfieldName)
                        ? t('configPage.document.fieldNotes.required')
                        : t('configPage.document.fieldNotes.optional'),

                    index: unsavedFieldsSort.indexOf(`${fieldName}__${subfieldName}`),

                    onDrop: handleFieldDrop,
                    onMove: handleFieldMove,
                    onDragStart: handleFieldDragStart,

                    onDelete: () => deleteField(fieldName, subfieldName),
                }))
                // sort by name in unsavedFieldsSort order
                .sort((a, b) => {
                    return unsavedFieldsSort.indexOf(a.name) - unsavedFieldsSort.indexOf(b.name);
                }),
        })),
    };
};

const FieldsConfigNavigationHeader = ({ addableFields, onAddField }) => {
    const { t } = useTranslation('config');

    return (
        <div className="border-t border-b border-primary border-solid h-16 font-semibold py-0 pl-8 pr-4 flex justify-between items-center text-sm flex-none">
            <h2 className="font-medium text-primary">{t('configPage.document.configuredFields')}</h2>
            <DropdownMenu>
                <DropdownMenu.Trigger>
                    <Button
                        disabled={addableFields === undefined || addableFields.length === 0}
                        className="px-px w-8"
                        type="button"
                    >
                        <AddIcon />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="start" className="!max-h-[20rem]">
                    {addableFields.map((field) => (
                        <DropdownMenu.Item
                            key={field.name}
                            onClick={(event) => {
                                // close after selecting the last item
                                if (addableFields.length > 1) {
                                    event.stopPropagation();
                                    event.preventDefault();
                                }
                                onAddField(field.name);
                            }}
                        >
                            {field.label}
                        </DropdownMenu.Item>
                    ))}
                </DropdownMenu.Content>
            </DropdownMenu>
        </div>
    );
};

const FieldsConfigNavigationItem = ({
    name: fieldName,
    label,
    note,
    active,
    index,
    errorIcon = undefined,

    subfields = undefined,
    addableSubfields = undefined,

    onClick,
    onDrop,
    onMove,
    onDragStart,
    onDelete,
    onAddSubfield,

    scrollToFormSection = undefined,
}) => {
    const dragRef = useRef(null);
    const previewRef = useRef(null);
    const itemRef = useRef(null);

    const { isDragging } = useDragDrop({
        index,
        itemType: DRAGGABLE_ITEM_TYPE,
        name: fieldName,
        onMove,
        onDragStart,
        onDrop,
        dragRef,
        previewRef,
        itemRef,
    });

    return (
        <li
            className={classnames(
                'flex flex-col cursor-pointer w-full rounded text-sm hover:bg-secondary-light',
                active && 'bg-secondary-light',
                isDragging && 'bg-secondary-light select-none'
            )}
            ref={itemRef}
        >
            <span
                className={classnames(
                    'group flex gap-1 w-full rounded py-3 pl-1 pr-4 items-center transition-colors',
                    active && 'bg-[#ecf6f3] hover:bg-[#dcede7] dark:bg-[#152a29] dark:hover:bg-[#224442]',
                    isDragging && 'opacity-0'
                )}
                onClick={(event) => {
                    scrollToFormSection?.(fieldName);
                    onClick?.(event);
                }}
                ref={previewRef}
            >
                <span
                    className={classnames(
                        'opacity-0 group-hover:opacity-100 text-secondary transition-all cursor-grab'
                    )}
                    ref={dragRef}
                >
                    <DragIcon />
                </span>

                <span className="flex flex-col min-w-0 gap-1">
                    <span className={classnames('text-primary truncate text-sm', active && 'text-brand')}>{label}</span>
                    <span className="truncate text-secondary text-xs">{note}</span>
                </span>

                <div className="ml-auto">
                    {errorIcon && <ErrorIcon className="fields-nav-item__error-icon" />}

                    <div
                        className={classnames(
                            'flex items-center justify-between gap-1 ml-auto transition-all opacity-0 hover:opacity-100 group-hover:opacity-100',
                            active && 'opacity-100'
                        )}
                    >
                        {addableSubfields?.length ? (
                            <DropdownMenu>
                                <DropdownMenu.Trigger>
                                    <Button
                                        variant="ghost"
                                        className="opacity-50 hover:opacity-100 !p-0 w-8 h-8 transition-all"
                                        type="button"
                                    >
                                        <AddIcon />
                                    </Button>
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content align="start" className="overflow-y-auto max-h-96">
                                    {addableSubfields.map(({ name, label }) => (
                                        <DropdownMenu.Item
                                            key={name}
                                            onClick={(event) => {
                                                // close after selecting the last item
                                                if (addableSubfields.length > 1) {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }
                                                onAddSubfield(name);
                                            }}
                                        >
                                            {label}
                                        </DropdownMenu.Item>
                                    ))}
                                </DropdownMenu.Content>
                            </DropdownMenu>
                        ) : null}

                        <Button
                            variant="ghost"
                            className="opacity-50 hover:opacity-100 !p-0 w-8 h-8 transition-all"
                            onClick={() => onDelete()}
                            type="button"
                        >
                            <RemoveIcon />
                        </Button>
                    </div>
                </div>
            </span>

            {!isDragging && subfields?.length && active ? (
                <ul>
                    {subfields.map(({ name, label, note, onDelete: onDeleteSubfield, ...props }) => {
                        return (
                            <FieldsConfigNavigationSubItem
                                key={name}
                                name={name}
                                label={label}
                                note={note}
                                onDelete={() => onDeleteSubfield()}
                                scrollToFormSection={scrollToFormSection}
                                {...props}
                            />
                        );
                    })}
                </ul>
            ) : null}
        </li>
    );
};

const FieldsConfigNavigationSubItem = ({
    index,
    name: fieldName,
    label,
    note,
    onDelete,
    scrollToFormSection = undefined,
    onMove,
    onDragStart,
    onDrop,
}: any) => {
    const dragRef = useRef(null);
    const itemRef = useRef(null);

    const groupName = fieldName.split('__')[0];
    const itemType = `${DRAGGABLE_ITEM_TYPE}_${groupName}`;

    const { isDragging } = useDragDrop({
        index,
        itemType,
        name: groupName,
        onMove,
        onDragStart,
        onDrop,
        dragRef,
        previewRef: itemRef,
        itemRef,
    });

    return (
        <li
            className={classnames(
                'flex flex-col cursor-pointer w-full rounded text-sm hover:bg-secondary transition-all',
                isDragging && 'bg-secondary select-none'
            )}
            onClick={() => scrollToFormSection?.(fieldName)}
            ref={itemRef}
        >
            <span
                className={classnames(
                    'group flex gap-1 w-full rounded py-2 pl-1 pr-4 items-center transition-colors',
                    isDragging && 'opacity-0'
                )}
            >
                <span
                    className={classnames(
                        'opacity-0 group-hover:opacity-100 text-secondary transition-all cursor-grab'
                    )}
                    ref={dragRef}
                >
                    <DragIcon />
                </span>

                <span className="flex flex-col min-w-0 gap-1 pl-4 text-primary">
                    <span className="truncate text-sm">{label}</span>
                    {note && <span className="truncate text-secondary text-xs">{note}</span>}
                </span>

                <div className="ml-auto">
                    <div className="flex items-center justify-between gap-1 ml-auto transition-all opacity-0 hover:opacity-100 group-hover:opacity-100">
                        <Button
                            variant="ghost"
                            className="opacity-50 hover:opacity-100 !p-0 w-8 h-8 transition-all"
                            onClick={() => onDelete(fieldName)}
                            type="button"
                        >
                            <RemoveIcon />
                        </Button>
                    </div>
                </div>
            </span>
        </li>
    );
};

const FieldsConfigNavigationItems = ({ fields, fieldsFormRef = undefined }) => {
    const scrollToFormSection = (name) => {
        const fieldsForm = fieldsFormRef?.current;
        const formSection = fieldsForm?.querySelector(`.js-section[data-name="${name}"]`);

        if (formSection) {
            formSection.parentNode.scrollTo({
                top: formSection.offsetTop - 30,
                behavior: 'smooth',
            });
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="flex justify-between items-start w-full p-4 overflow-auto">
                <ul className="flex flex-col gap-2.5 w-full">
                    {fields.map((field) => (
                        <FieldsConfigNavigationItem
                            key={field.name}
                            {...field}
                            scrollToFormSection={scrollToFormSection}
                        />
                    ))}
                </ul>
            </div>
        </DndProvider>
    );
};

const FieldsConfigNavigation = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return <div {...props} className={classnames('w-80', className)} />;
};

const FieldsConfigContent = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return <div {...props} className={classnames('flex-1 border-l border-solid border-primary', className)} />;
};

const FieldsConfigContentHeader = ({ className, ...props }: React.ComponentPropsWithRef<'h2'>) => (
    <h2
        {...props}
        className={classnames(
            'border-t border-b border-primary border-solid h-16 font-medium text-primary py-0 pl-8 pr-4 flex justify-between items-center text-sm flex-none',
            className
        )}
    />
);

const FieldsConfigContentForms = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return <div {...props} className={classnames('p-8 flex flex-col gap-10', className)} />;
};

const FieldsConfigOptionsForm = ({ label = '', children }) => {
    return (
        <div className="flex flex-col gap-2 -m-1">
            {label && <h3 className="font-semibold text-primary text-sm py-1">{label}</h3>}
            <div className="flex flex-col gap-6">{children}</div>
        </div>
    );
};

const FieldsConfigOptionsFormField = ({
    label,
    helpText = undefined,
    error = undefined,
    value,
    onValueChange,
    onBlur,
    defaultValue,
    valueType,
    nullable,
    required = false,
    component = undefined,
    componentProps = {},
}) => {
    const isBoolean = valueType === 'boolean';

    const handleValueChange = (value) => {
        onValueChange(value);
    };

    const handleToggleSwitch = (checked) => {
        if (isBoolean) {
            onValueChange(checked);
        } else if (nullable) {
            onValueChange(checked ? (defaultValue ?? '') : null);
        }
        onBlur?.(null);
    };

    // TODO: maybe move to upper layer
    const FieldComponent = component || FIELDS[valueType] || StringField;
    const showSwitch = isBoolean || nullable;
    const showField = !isBoolean && ((nullable && value != undefined) || !nullable);

    return (
        <div className="flex flex-col gap-2">
            <label className="text-primary text-sm flex justify-between items-center hover:bg-secondary-light rounded transition-all">
                <span>
                    {label} {required && <span className="text-error">*</span>}
                </span>
                {showSwitch && (
                    <Switch value={isBoolean ? value : value != undefined} onValueChange={handleToggleSwitch} />
                )}
            </label>

            {showField && (
                <Slot
                    className={classnames(
                        'hover:!border-confidence-high focus-within:border-confidence-high focus-within:outline-surface-brand',
                        error && 'border-confidence-low'
                    )}
                    // @ts-ignore
                    required={required}
                >
                    <FieldComponent
                        {...componentProps}
                        value={value}
                        onValueChange={handleValueChange}
                        required={required}
                        onBlur={onBlur}
                    />
                </Slot>
            )}

            {!error?.message && helpText && <span className="text-secondary text-xs">{helpText}</span>}
            {error?.message && <span className="text-confidence-low text-xs">{error.message}</span>}
        </div>
    );
};

const FieldsConfigFooter = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const ref = useRef<HTMLDivElement>(null);
    const { bottom } = useStickyState(ref, true);
    return (
        <div
            {...props}
            className={classnames(
                'flex justify-end p-8 border-t border-solid border-primary sticky -bottom-7 bg-primary rounded-b-lg',
                bottom &&
                    'shadow-[0_-1px_3px_0_rgba(0,0,0,0.1),0_-1px_2px_-1px_rgba(0,0,0,0.1)] [clip-path:inset(-15px_0_0_0)]',
                className
            )}
            ref={ref}
        />
    );
};

const FieldsConfig = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return <div {...props} className={classnames('flex', className)} />;
};

export default Object.assign(FieldsConfig, {
    Content: FieldsConfigContent,
    ContentHeader: FieldsConfigContentHeader,
    ContentForms: FieldsConfigContentForms,
    Navigation: FieldsConfigNavigation,
    NavigationHeader: FieldsConfigNavigationHeader,
    NavigationItems: FieldsConfigNavigationItems,
    OptionsForm: FieldsConfigOptionsForm,
    OptionsFormField: FieldsConfigOptionsFormField,
    Footer: FieldsConfigFooter,
});
