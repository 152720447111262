import * as React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useToaster } from '../../core/components/Toast.tsx';
import { GET_TEAMS } from '../../teams/queries.ts';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import SelectField from '../../core/components/Fields/SelectField.tsx';
import IntegerField from '../../core/components/Fields/IntegerField.tsx';
import Button from '../../core/components/Button.tsx';
import { useApplicationContext } from '../../core/contexts/ApplicationContext.tsx';

const FORM_DEFAULT_VALUES = {
    name: '',
    teamId: undefined,
    emailInbox: '',
    timePerDocumentBeforeWorkist: null,
};

const useTeamOptions = () => {
    const { t } = useTranslation('config');
    const { user: viewer } = useApplicationContext();

    const { data: teamsData } = useQuery(GET_TEAMS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {},
    });
    return teamsData?.teams.map((team) => ({
        value: team.id,
        label: !team.isDefault ? team.name : viewer?.customer?.name || t('teams:overview.defaultTeam'),
    }));
};

const GeneralConfiguration = ({ channel, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            name: channel?.name || '',
            teamId: channel?.team?.id || undefined,
            emailInbox: channel?.emailAddress || '',
            timePerDocumentBeforeWorkist: channel?.timePerDocumentBeforeWorkist || null,
        });
    }, [channel]);

    const handleSubmit = async (formData) => {
        if ('timePerDocumentBeforeWorkist' in formData && formData['timePerDocumentBeforeWorkist']) {
            formData['timePerDocumentBeforeWorkist'] = parseInt(formData['timePerDocumentBeforeWorkist']);
        }

        try {
            await onSubmit(formData);
            publishToast({ description: t('configPage.general.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.general.updateError'), status: 'error' });
        }
    };

    const teamOptions = useTeamOptions();

    return (
        <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.general.title')}>
            <Controller
                name="name"
                control={form.control}
                rules={{ required: t('configPage.general.form.nameRequired') }}
                render={({ field }) => (
                    <FormField label={t('configPage.general.form.name')} required error={formErrors.name}>
                        <StringField {...field} required />
                    </FormField>
                )}
            />

            <Controller
                name="teamId"
                control={form.control}
                rules={{ required: t('configPage.general.form.teamRequired') }}
                render={({ field }) => (
                    <FormField label={t('configPage.general.form.teamId')} required error={formErrors.teamId}>
                        <SelectField
                            {...field}
                            required
                            options={teamOptions}
                            renderOption={(option) => option.label}
                        />
                    </FormField>
                )}
            />

            <Controller
                name="emailInbox"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.general.form.emailInbox')} error={formErrors.emailInbox}>
                        <StringField {...field} disabled />
                    </FormField>
                )}
            />

            <Controller
                name="timePerDocumentBeforeWorkist"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.general.form.timePerDocumentBeforeWorkist')}
                        error={formErrors.timePerDocumentBeforeWorkist}
                    >
                        <IntegerField {...field} />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button type="submit" variant="primary">
                    {t('configPage.general.form.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};

export default GeneralConfiguration;
