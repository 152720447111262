import { useParams as useRouterParams } from 'react-router-dom';
import { useChannelStore } from '../../../document/stores';
import { useEffect } from 'react';

/**
 * Custom hook for retrieving the current channel from the URL params & syncing it to the store.
 */
export const useCurrentChannel = () => {
    const { channelId } = useRouterParams();
    const { setActiveChannel } = useChannelStore();

    useEffect(
        function _syncActiveChannelToStore() {
            setActiveChannel(channelId);
        },
        [channelId, setActiveChannel]
    );

    return { channelId };
};
