import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import classnames from '../utils/classnames.tsx';
import { useEffect, useState } from 'react';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = ({
    className,
    style,
    align = 'center',
    sideOffset = 4,
    ...props
}: React.ComponentPropsWithRef<typeof PopoverPrimitive.Content>) => {
    const [isInModal, setIsInModal] = useState(false);
    useEffect(() => {
        // Note: if we could access outside trigger ref we could check if it's in a modal
        const modal = document.querySelector('.js-modal');
        if (modal) {
            setIsInModal(true);
        }
    }, []);

    return (
        <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content
                align={align}
                sideOffset={sideOffset}
                className={classnames(
                    'z-50 w-72 rounded-lg border border-solid border-primary bg-primary p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 overflow-auto pointer-events-auto',
                    className
                )}
                style={{
                    maxHeight: 'calc(var(--radix-popover-content-available-height) - 10px)',
                    minWidth: 'var(--radix-popover-trigger-width)',
                    zIndex: isInModal ? 50000 : 20000, // radix default is 20000
                    ...style,
                }}
                onWheel={(e) => {
                    e.stopPropagation();
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                {...props}
            />
        </PopoverPrimitive.Portal>
    );
};
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export default Object.assign(Popover, {
    Trigger: PopoverTrigger,
    Content: PopoverContent,
    Anchor: PopoverPrimitive.Anchor,
});
