import DocumentConfiguration from '../../../document/pages/Configuration';
import * as DocumentQueries from './queries.tsx';
import { GET_OVERVIEW_DATA } from '../queries.ts';
import { CHANNEL_CONFIG_PATH, CHANNEL_PATH, OVERVIEW_PATH } from '../constants.ts';
import React from 'react';
import { DocumentType } from '../../../document/constants.ts';

const Configuration = (props) => {
    const documentConfiguration = {
        service: 'invoices',
        documentType: DocumentType.Invoice,
        documentTypeName: 'invoice',
        activeTab: 'invoice',
        documentQueries: DocumentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
        configPath: CHANNEL_CONFIG_PATH,
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
