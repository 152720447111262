import * as React from 'react';
import { useEffect, useState } from 'react';
import classnames from '../../core/utils/classnames.tsx';

import { Bbox } from '../types.ts';

export interface IArea /* extends React.SVGAttributes<SVGRectElement> */ {
    bbox?: Bbox;
    pageIndex?: number;
    numPages?: number;
    disabled?: boolean;
    data?: any;
    className?: string;
    visible?: boolean;

    fill?: string;
    stroke?: string;
    strokeWidth?: number;
    borderRadius?: number;
    padding?: number;

    onClick?: any;
    onMouseMove?: any;
    onMouseDown?: any;
    onMouseUp?: any;
    onMouseEnter?: any;
    onMouseLeave?: any;
}

export const getBboxForImageMap = (bbox, pageIndex = 0, numPages = 1) => {
    if (bbox === undefined || bbox === null || !bbox?.length) return;

    let [[x1, y1], [x2, y2]] = bbox;

    y1 = (y1 + pageIndex) / numPages;
    y2 = (y2 + pageIndex) / numPages;

    return [
        [x1, y1],
        [x2, y2],
    ];
};

/*
I was thinking about using React.memo here with a custom shouldUpdate method based
on what properties would change how the area is drawn.
Sadly state is used in e.g. onClick as well so if state changes those words
need to be redrawn. Therefore I decided to keep it simple.

Anyway - for hover and colors consider using CSS as we don't
need to redraw all areas then.
 */

export const Area = React.memo((props: IArea) => {
    const {
        bbox = [
            [0, 0],
            [0, 0],
        ],
        pageIndex = 0,
        numPages = 1,
        disabled = false,
        borderRadius = 1.5, // TODO: if this should become a library remove this one
        padding = 0.001, // TODO: if this should become a library remove this one
        data,
        className,
        onClick,
        onMouseMove,
        onMouseDown,
        onMouseUp,
        onMouseEnter,
        onMouseLeave,
        ...svgProps
    } = props;
    const scaledBbox = getBboxForImageMap(bbox, pageIndex, numPages);
    if (!scaledBbox) return;

    const [left, top, right, bottom] = scaledBbox.flat();
    const formatPos = (val) => `${val * 100}%`;

    const scaledPadding = padding / numPages;
    const real_left = Math.min(left, right);
    const real_top = Math.min(top, bottom);
    const real_right = Math.max(left, right);
    const real_bottom = Math.max(top, bottom);
    const positionProps = {
        x: formatPos(real_left - 2 * padding),
        y: formatPos(real_top - scaledPadding),
        width: formatPos(real_right - real_left + 4 * padding),
        height: formatPos(real_bottom - real_top + 2 * scaledPadding),
    };

    return (
        <rect
            {...positionProps}
            {...svgProps}
            onClick={(e) => onClick?.(e, data)}
            onMouseMove={(e) => onMouseMove?.(e, data)}
            onMouseDown={(e) => onMouseDown?.(e, data)}
            onMouseUp={(e) => onMouseUp?.(e, data)}
            onMouseEnter={(e) => onMouseEnter?.(e, data)}
            onMouseLeave={(e) => onMouseLeave?.(e, data)}
            rx={borderRadius}
            ry={borderRadius}
            className={classnames('mix-blend-multiply stroke-transparent transition-colors', className)}
            style={disabled ? { pointerEvents: 'none' } : null}
        />
    );
});

export interface IProps {
    children?: any;
    numPages?: number;
    className?: string;

    src: string;
    fillColor?: string;
    strokeColor?: string;
    lineWidth?: number;
    height?: string;

    onLoad?: any;
    onClick?: any;
    onMouseMove?: any;
    onMouseDown?: any;
    onMouseUp?: any;
    onMouseEnter?: any;
    onMouseLeave?: any;
}

export const ImageMap = (props: IProps) => {
    const { children, className, src, onLoad, numPages = 1, ...svgProps } = props;

    const [dimensions, setDimensions] = useState([0, 0]);
    const [imageWidth, imageHeight] = dimensions;

    // auto scale to source image dimensions
    useEffect(() => {
        const newImg = new Image();
        newImg.onload = (e) => {
            const imageHeight = newImg.height;
            const imageWidth = newImg.width;
            setDimensions([imageWidth, imageHeight]);
            onLoad?.(e);
        };
        newImg.src = src; // this must be done AFTER setting onload
    }, [src]);

    return (
        <svg
            width="100%"
            height="100%"
            {...svgProps}
            viewBox={`0 0 ${imageWidth} ${imageHeight}`}
            className={className}
        >
            <image className="pointer-events-none w-full h-full" href={src} />
            {React.Children.map(
                children,
                (child, i) => child && <child.type key={child.key} {...child.props} numPages={numPages} />
            )}
        </svg>
    );
};

export default ImageMap;

export const getMousePositionRelative = (evt) => {
    const pos = evt.currentTarget.getBoundingClientRect();
    return {
        x: (evt.clientX - pos.left) / pos.width,
        y: (evt.clientY - pos.top) / pos.height,
    };
};

export const sortBbox = (bbox) => {
    const [x1, y1] = bbox[0];
    const [x2, y2] = bbox[1];

    const top = x1 < x2 ? x1 : x2;
    const bottom = x1 > x2 ? x1 : x2;
    const left = y1 < y2 ? y1 : y2;
    const right = y1 > y2 ? y1 : y2;

    return [
        [top, left],
        [bottom, right],
    ];
};

export const getBboxFromImageMap = (bbox, numPages = 1) => {
    if (bbox === undefined) return bbox;

    let [[x1, y1], [x2, y2]] = bbox;
    y1 = (y1 * numPages) % 1;
    y2 = (y2 * numPages) % 1;

    return [
        [x1, y1],
        [x2, y2],
    ];
};

export const getPageIndexForBbox = (bbox, numPages = 1) => {
    if (bbox === undefined) return bbox;

    return Math.floor(bbox[0][1] * numPages);
};

export const SelectionImageMap = React.memo(
    ({
        canSelect = false,
        className,
        onSelectionStart,
        onSelection,
        onSelectionEnd,
        children,
        numPages,
        ...mapProps
    }: any) => {
        const [isDrag, setIsDrag] = useState(false);
        const [selectedArea, setSelectedArea] = useState([0, 0, 0, 0]); // [startX, startY, endX, endY]
        const getSelectedBbox = React.useCallback(
            (flatBbox) =>
                sortBbox([
                    [flatBbox[0], flatBbox[1]],
                    [flatBbox[2], flatBbox[3]],
                ]) as Bbox,
            []
        );
        const getPageIndex = React.useCallback(
            (flatBbox) => getPageIndexForBbox(getSelectedBbox(flatBbox), numPages),
            [numPages, getSelectedBbox]
        );
        const getScaledBbox = React.useCallback(
            (flatBbox) => getBboxFromImageMap(getSelectedBbox(flatBbox), numPages),
            [numPages, getSelectedBbox]
        );

        // Memoize these calculations to prevent unnecessary recalculations
        const pageIndex = React.useMemo(() => getPageIndex(selectedArea), [selectedArea, getPageIndex]);
        const scaledSelectedBbox = React.useMemo(() => getScaledBbox(selectedArea), [selectedArea, getScaledBbox]);

        // reset selected area everytime canSelect is turned on or off
        useEffect(() => {
            setIsDrag(false);
            setSelectedArea([0, 0, 0, 0]);
        }, [canSelect]);

        const onMouseDown = React.useCallback(
            (e) => {
                if (mapProps.onMouseDown) mapProps.onMouseDown(e);
                if (!canSelect) return;
                setIsDrag(true);
                const pos = getMousePositionRelative(e);
                const bbox = [pos.x, pos.y, pos.x, pos.y];
                setSelectedArea(bbox);
                if (onSelectionStart) onSelectionStart(getScaledBbox(bbox), getPageIndex(bbox));
            },
            [canSelect, mapProps.onMouseDown, onSelectionStart]
        );

        const onMouseMove = React.useCallback(
            (e) => {
                if (mapProps.onMouseMove) mapProps.onMouseMove(e);
                if (!isDrag || !canSelect) return;
                const pos = getMousePositionRelative(e);
                const [x1, y1] = selectedArea;
                const bbox = [x1, y1, pos.x, pos.y];

                // Use requestAnimationFrame to limit updates to browser's refresh rate
                requestAnimationFrame(() => {
                    setSelectedArea(bbox);
                    if (onSelection) onSelection(getScaledBbox(bbox), getPageIndex(bbox));
                });
            },
            [isDrag, canSelect, selectedArea, mapProps.onMouseMove, onSelection]
        );

        const onMouseUp = React.useCallback(
            (e) => {
                if (mapProps.onMouseUp) mapProps.onMouseUp(e);
                if (!canSelect) return;
                setIsDrag(false);
                if (onSelectionEnd) onSelectionEnd(scaledSelectedBbox, pageIndex);
                setSelectedArea([0, 0, 0, 0]); // reset
            },
            [canSelect, mapProps.onMouseUp, onSelectionEnd, scaledSelectedBbox, pageIndex]
        );

        // TODO: maybe there is smarter way
        // after initial mousedown x1 == x2 and y1 == y2
        const isDisabled = !(selectedArea[0] == selectedArea[2] && selectedArea[1] == selectedArea[3]);

        // Only re-render the selection area when it actually changes
        const selectionArea = React.useMemo(
            () => (
                <Area
                    key="selection"
                    bbox={scaledSelectedBbox}
                    pageIndex={pageIndex}
                    className="stroke-1 cursor-crosshair pointer-events-none stroke-confidence-high fill-[#c5ffed] opacity-80 [contain:strict]"
                    padding={0}
                />
            ),
            [scaledSelectedBbox, pageIndex]
        );

        return (
            <ImageMap
                {...mapProps}
                numPages={numPages}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                className={classnames(className, canSelect && 'cursor-crosshair')}
            >
                {React.Children.map(
                    children,
                    (child, i) => child && <child.type key={child.key} {...child.props} disabled={isDisabled} />
                )}

                {selectionArea}
            </ImageMap>
        );
    }
);
