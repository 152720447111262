import React, { ReactNode } from 'react';
import classnames from '../../../utils/classnames.tsx';
import { useNavigationContext } from './NavigationContext.tsx';

interface NavigationItemBadgeProps {
    className?: string;
    children?: ReactNode;
}

const NavigationItemBadge = ({ children, className }: NavigationItemBadgeProps) => {
    const { collapsed } = useNavigationContext();
    return (
        <span
            className={classnames(
                'flex h-5 items-center justify-center rounded-xl bg-decorative-hover px-1.5 py-1 text-xs text-inverted font-normal',
                collapsed &&
                    'absolute right-1 top-1 h-2 w-2 rounded-full px-0 py-0 shadow-[0_0_0_2px_var(--surface-primary)]',
                className
            )}
        >
            {!collapsed ? children : null}
        </span>
    );
};

export default NavigationItemBadge;
