import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FieldProps, useFieldProps } from '../../document/pages/Assistance/customizable/Field';
import { useTranslation } from 'react-i18next';
import { useAssistanceViewContext } from '../../document/pages/Assistance/AssistanceViewContext';
import { useAssistanceFieldContext } from '../../document/pages/Assistance/AssistanceFieldContext';
import { resolveLocalizedString } from '../../core/utils/localization.ts';
import StringField from '../../core/components/Fields/StringField';
import Field from '../../core/components/Fields/Field';
import { fieldDataToFieldConfig, getFieldComponent } from '../../document/pages/Assistance/DynamicField';
import Alert, { AlertTitle } from '../../core/components/Alert.tsx';
import { isObject } from 'lodash';
import { withIcon } from '../../core/components/Icon';
import { faSliders, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import useReselectEventListener from '../../document/pages/Assistance/useReselectEventListener';
import { defaultFilterOption, ForceSelectionAutoCompleteField } from '../../core/components/Fields/AutoCompleteField';
import Modal from '../../core/components/Modal.tsx';
import Button from '../../core/components/Button.tsx';
import FormField from '../../core/components/FormField.tsx';

const LoadingIcon = withIcon(faSpinnerThird);
const OpenDialogIcon = withIcon(faSliders);

const CompositeField = (props: FieldProps) => {
    const { t, i18n } = useTranslation();

    const { generalConfig } = useAssistanceViewContext();

    // Dynamically determine the field component and its specific options
    const { data, isLoading, fieldName } = useAssistanceFieldContext();
    const [value, setValue] = useState(data?.summaryValue || data?.codeString || data?.variantText || '');
    const fieldProps = useFieldProps({ ...props });
    const { disabled, onValueChange, onFocus, onBlur } = fieldProps;

    useReselectEventListener(props.inputRef, props.onReselect);

    // this is kind of a hack - probably should be passed in or so, but tbh most of those fields here are more of a hack anyway
    const modalContainerRef = useRef(document.querySelector('.field-modal-root'));
    useEffect(() => {
        modalContainerRef.current = document.querySelector('.field-modal-root');
    }, []);

    const [isSubfieldModalVisible, setSubfieldModalVisible] = useState(false);

    const isEditable = !props.readOnly && !props.disabled;

    const modalFormFields = collectSubfields(data);

    const optionsBySubfield = Object.fromEntries(
        Object.entries(modalFormFields).map(([subfieldName, subfield], _) => [
            subfieldName,
            subfield?.options?.map((option) => {
                return {
                    value: option.value,
                    label: resolveLocalizedString(
                        option.label,
                        option.label?.locale?.includes(i18n.language) ? i18n.language : option.label?.locale?.[0]
                    ),
                    description: resolveLocalizedString(
                        option.description,
                        option.label?.locale?.includes(i18n.language) ? i18n.language : option.label?.locale?.[0]
                    ),
                    disabled: option.disabled,
                };
            }),
        ])
    );

    // inputValue is always the label of the selected option
    const [inputValues, setInputValues] = useState({});

    useEffect(() => {
        setInputValues(
            Object.fromEntries(
                Object.entries(modalFormFields).map(([subfieldName, subfield], _) => [
                    subfieldName,
                    optionsBySubfield[subfieldName]?.find((option) => option.value === subfield.value)?.label ||
                        subfield.value ||
                        '',
                ])
            )
        );

        if (data?.codeString === undefined && data?.variantText === undefined) {
            setValue(data?.summaryValue || '');
        } else {
            // the summaryValue property is not updated by the patching mechanism, so when the codeString (TROX) or variantText (AssaAbloy) are defined, we use them directly
            setValue(data?.codeString || data?.variantText || '');
        }
    }, [data?.summaryValue, data?.codeString, data?.variantText]);

    const resetFormValues = () => {
        return props.onReset?.();
    };

    const handleCancel = () =>
        Promise.resolve(isEditable && resetFormValues()).finally(() => {
            setSubfieldModalVisible(false);
        });

    return (
        <>
            <StringField
                {...fieldProps}
                value={value}
                controls={
                    <>
                        {!disabled && (
                            <Field.ControlButton onClick={() => setSubfieldModalVisible(true)}>
                                <OpenDialogIcon />
                            </Field.ControlButton>
                        )}

                        {props.controls}
                    </>
                }
                readOnly={true}
                disabled={disabled}
            />

            {isSubfieldModalVisible && (
                <Modal
                    title={data['summaryValue']}
                    visible={isSubfieldModalVisible}
                    buttons={
                        !isEditable
                            ? [
                                  <Button key="button-confirm" onClick={handleCancel}>
                                      {t('core:components.confirmModal.close')}
                                  </Button>,
                              ]
                            : [
                                  <Button key="button-reject" className="text-error" onClick={handleCancel}>
                                      {t('assistance:reset')}
                                  </Button>,
                                  <Button
                                      key="button-confirm"
                                      variant="primary"
                                      onClick={() => setSubfieldModalVisible(false)}
                                  >
                                      {t('core:components.confirmModal.ok')}
                                  </Button>,
                              ]
                    }
                    onClose={handleCancel}
                    containerRef={modalContainerRef}
                >
                    <div className="w-full flex flex-col gap-4">
                        {Object.entries(modalFormFields).map(([subfieldName, subfield]) => {
                            const options = optionsBySubfield[subfieldName];

                            const reverseOptionsMap = options?.reduce((acc, option) => {
                                acc[option.label] = option.value;
                                return acc;
                            }, {});

                            const handleValueChange = (value: string) => {
                                setInputValues({ ...inputValues, [subfieldName]: value });

                                if (reverseOptionsMap) {
                                    const selectedValue = reverseOptionsMap[value];
                                    const prevSelectedValue = inputValues[subfieldName];

                                    if (selectedValue === prevSelectedValue) {
                                        // prevent unnecessary updates
                                        return;
                                    }

                                    if (selectedValue) {
                                        onValueChange?.({ [subfieldName]: selectedValue });
                                    } else {
                                        onValueChange?.({ [subfieldName]: '' });
                                    }
                                } else {
                                    setInputValues({ ...inputValues, [subfieldName]: value });
                                    onValueChange?.({ [subfieldName]: value });
                                }
                            };

                            const label =
                                resolveLocalizedString(
                                    subfield.label,
                                    subfield.label?.locale?.includes(i18n.language)
                                        ? i18n.language
                                        : subfield.label?.locale?.[0]
                                ) || t(`assistance:itemsView.fieldNames.${fieldName}_${subfieldName}`);

                            // TODO: add useForceSelect for TROX
                            let [SubfieldComponent, fieldSpecificProps] = getFieldComponent({
                                ...generalConfig,
                                ...fieldDataToFieldConfig(subfield),
                                valueType: 'string',
                                filterOption: defaultFilterOption,
                                onSelectOption: (option) => handleValueChange(option?.value),
                                options: options?.map((option) => ({
                                    value: option.label,
                                    label: option.label,
                                    description: option.description,
                                    disabled: option.disabled,
                                })),
                            });

                            if (options) {
                                // basically a typeahead select field
                                SubfieldComponent = ForceSelectionAutoCompleteField;
                            }

                            return (
                                <FormField
                                    key={subfieldName}
                                    className="w-full relative flex flex-col gap-2"
                                    label={label}
                                >
                                    <SubfieldComponent
                                        value={inputValues[subfieldName]}
                                        onValueChange={handleValueChange}
                                        onBlur={onBlur}
                                        onFocus={onFocus}
                                        // NOTE: we don't make it readOnly here for when `isLoading` is true.
                                        // This can lead to racing conditions as we don't wait until
                                        // the data is updated and the fields are updated.
                                        // But at the end of the day this is what the customer (TROX) wants.
                                        readOnly={!isEditable}
                                        controls={
                                            <>
                                                {isLoading && (
                                                    <Field.ControlButton className={isLoading && 'opacity-100'}>
                                                        <LoadingIcon className="text-brand text-sm animate-spin" />
                                                    </Field.ControlButton>
                                                )}
                                            </>
                                        }
                                        {...fieldSpecificProps}
                                    />
                                </FormField>
                            );
                        })}

                        {data.errorMessage && (
                            <Alert severity="error" className="alert--no-margin alert--multiline modal__error_message">
                                <AlertTitle>{data.errorMessage}</AlertTitle>
                            </Alert>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export const FIELD_KEYS = {
    IS_COMPOSITE: 'isComposite',
    TYPENAME: '__typename',
};

const ALLOWED_FIELD_TYPES = [
    'BooleanField',
    'FloatField',
    'DecimalField',
    'IntegerField',
    'DateField',
    'TimeField',
    'StringField',
    'ReadOnlyField',
    'ArrayField',
    'ListField',
    'SelectField',
    'AutocompleteField',
    'TextField',
    'JSONField',
    'AddressField',
    'CustomerField',
    'ContactField',
];

/**
 * Helper that collects all subfield objects on the given composite field object (direct children only)
 *
 * @returns mapping from subfield keys to subfield objects
 */
const collectSubfields = (fieldObj: any): { [key: string]: any } => {
    const subfields = {};

    for (let key in fieldObj) {
        const potentialSubfield = fieldObj[key];
        if (!isObject(potentialSubfield)) {
            continue; // skip non-object attributes (i.e. not a field)
        }
        if (!potentialSubfield.hasOwnProperty(FIELD_KEYS.TYPENAME)) {
            continue; // skip object attributes that are not part of the graphene schema
        }
        const subfield = potentialSubfield;
        const subfieldTypeName = subfield[FIELD_KEYS.TYPENAME];
        const isFieldType = ALLOWED_FIELD_TYPES.includes(subfieldTypeName) || subfield[FIELD_KEYS.IS_COMPOSITE];
        if (!isFieldType) {
            continue; // skip subfields that are of unknown type
        }
        subfields[key] = subfield;
    }
    return subfields;
};

export default CompositeField;
