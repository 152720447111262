import { useTranslation } from 'react-i18next';
import classnames from '../../../core/utils/classnames.tsx';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { AssistanceField } from '../../../document/pages/Assistance/AssistanceField.tsx';
import AssistanceSection from '../../../document/pages/Assistance/AssistanceSection.tsx';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { withIcon } from '../../../core/components/Icon.tsx';
import {
    DEFAULT_VALUE_KEY,
    FormField,
    FormFieldConfig,
    getFormFieldGroups,
    GROUP_SEPARATOR,
    useDocumentConfig,
} from '../../../document/pages/Assistance/useDocumentConfig.ts';
import { useAssistanceContext } from '../../../document/pages/Assistance/AssistanceContext.tsx';
import { useConditionalComponent } from '../../../customizations/ConditionalComponentContext.tsx';
import CompleteButton from './CompleteButton.tsx';
import { camelCase } from 'lodash';

interface IFieldData {
    value?: string;
    predictionConfidence?: number;
    confidenceExplanation?: {
        translationKey: string;
        explanationDetails?: {
            confidence: number;
            field_value: string;
        };
    };
    choices?: string[]; // list of options comes from extracted data
    // ... and more but not sure if it's needed here
    [key: string]: any;
}

const RemoveIcon = withIcon(faTrash);

const HeaderAssistanceField = ({
    fields,
    fieldName,
    valueKey = 'value',
    value,
    placeholder = '',
    // event handler
    onUpdate = undefined,
    data = {},
    // config stuff
    config,
    generalConfig,
}: {
    fields: FormField[];
    fieldName: string;
    valueKey?: string;
    value: string;
    placeholder?: string;
    // event handler
    onUpdate?: (value: string) => Promise<any> | void;
    data?: IFieldData;
    // config stuff
    config?: FormFieldConfig;
    generalConfig?: any;
}) => {
    const { readOnly, loading: documentLoading } = useAssistanceContext();

    const [isUpdateActive, setIsUpdateActive] = useState(false);
    const [isFocusActive, setIsFocusActive] = useState(false);

    const handleUpdate = ({ payload }) => {
        // same way we do it for regular assistance
        const prevPayload = Object.fromEntries(
            fields.filter((field) => !!field.valueType).map((field) => [camelCase(field.valueKey), field.value ?? ''])
        );
        setIsUpdateActive(true);
        Promise.resolve(onUpdate?.({ ...prevPayload, ...payload })).finally(() => {
            setIsUpdateActive(false);
        });
    };

    const handleFocus = () => {
        setIsFocusActive(true);
    };

    const handleBlur = () => {
        setIsFocusActive(false);
    };

    return (
        <AssistanceField
            fieldName={fieldName}
            valueKey={valueKey}
            value={value}
            placeholder={placeholder}
            data={data}
            config={config}
            generalConfig={generalConfig}
            onUpdate={handleUpdate}
            onFocus={handleFocus}
            onBlur={handleBlur}
            isUpdateActive={isUpdateActive}
            isFocusActive={isFocusActive}
            documentReadOnly={readOnly}
            documentLoading={documentLoading}
        />
    );
};

const HeaderDataForm = ({
    className,
    values,
    onValueChange,
    onRemove,
    fieldConfigs,
    generalConfig,
}: {
    className?: string;
    values: any;
    onValueChange: (fieldName: string, payload: any) => void;
    onRemove?: () => void;
    fieldConfigs: any;
    generalConfig: any;
}) => {
    const { readOnly, loading } = useAssistanceContext();

    const { t } = useTranslation('assistance');

    const fieldGroups = getFormFieldGroups(values, fieldConfigs, '');

    return fieldGroups.map((fields: FormField[]) => {
        const groupName = fields[0].fieldName;
        const filterGroupField = (field) => !(field.path === groupName && fields.length > 1);

        const FieldGroupComponent = useConditionalComponent(
            'HeaderDataFieldGroup',
            {
                fieldName: groupName,
            },
            AssistanceSection.FieldGroup
        );

        return (
            <FieldGroupComponent
                title={t(`listOfServices.headerDataCard.fieldNames.${groupName}`)}
                controls={
                    onRemove && (
                        <AssistanceSection.FieldGroupIconButton
                            disabled={readOnly || loading}
                            onClick={onRemove}
                            className="hover:text-error hover:bg-error"
                        >
                            <RemoveIcon />
                        </AssistanceSection.FieldGroupIconButton>
                    )
                }
                fields={fields}
                className={className}
            >
                {fields.filter(filterGroupField).map((formField) => {
                    const fullFieldName =
                        formField.valueKey && formField.valueKey !== DEFAULT_VALUE_KEY
                            ? `${formField.fieldName}${GROUP_SEPARATOR}${formField.valueKey}`
                            : formField.fieldName;

                    return (
                        <AssistanceSection.FieldLabel
                            key={formField.config?.name}
                            label={t(`listOfServices.headerDataCard.fieldNames.${fullFieldName}`)}
                            required={formField.config.isRequired}
                        >
                            <HeaderAssistanceField
                                fields={fields}
                                fieldName={formField.fieldName}
                                value={formField.value}
                                valueKey={formField.valueKey}
                                onUpdate={(payload) => {
                                    return onValueChange(formField.fieldName, payload);
                                }}
                                data={formField.data}
                                config={formField.config}
                                generalConfig={generalConfig}
                                placeholder={t(`listOfServices.headerDataCard.fieldNames.${fullFieldName}`)}
                            />
                        </AssistanceSection.FieldLabel>
                    );
                })}
            </FieldGroupComponent>
        );
    });
};

const HeaderDataCard = ({
    className,
    values,
    onValueChange,
    isComplete,
    onIsCompleteChange,
    documentConfig,
}: {
    className?: string;
    values: any;
    onValueChange: (action: string, payload?: any) => void;
    isComplete: boolean;
    onIsCompleteChange: (isComplete: boolean) => void;
    documentConfig: any;
}) => {
    const { t } = useTranslation('assistance');

    const [isOpen, setIsOpen] = useState(true);

    const { generalConfig, fieldConfigsByPrefix } = useDocumentConfig(documentConfig, ['positions']);
    const articleFieldConfigs = fieldConfigsByPrefix[''];

    const handleIsCompleteChange = (isComplete: boolean) => {
        return onIsCompleteChange(isComplete);
    };

    return (
        <div className={classnames(className)}>
            <div
                className={classnames(
                    'flex flex-col rounded-lg shadow-md bg-primary border border-primary border-solid overflow-hidden'
                )}
            >
                <AssistanceSection
                    title={t('listOfServices.headerDataCard.title')}
                    open={isOpen}
                    onOpenChange={setIsOpen}
                    controls={<CompleteButton isComplete={isComplete} onIsCompleteChange={handleIsCompleteChange} />}
                >
                    <div className="flex flex-col gap-8">
                        <HeaderDataForm
                            values={values}
                            onValueChange={onValueChange}
                            fieldConfigs={articleFieldConfigs}
                            generalConfig={generalConfig}
                        />
                    </div>
                </AssistanceSection>
            </div>
        </div>
    );
};

export default HeaderDataCard;
