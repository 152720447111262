import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from '../../core/utils/classnames.tsx';

const InlineSelect = ({
    value,
    onValueChange,
    onBlur,
    allowCustomValue = false,
    customSample = undefined,
    options,
    className,
}: any) => {
    const { t } = useTranslation();
    const allowedValues = options.map((choice) => choice.value);
    const [customValue, setCustomValue] = useState(allowedValues.includes(value) ? '' : value);

    useEffect(() => {
        setCustomValue(allowedValues.includes(value) ? '' : value);
    }, [value]);

    if (customSample && typeof customSample === 'function') {
        customSample = customSample(customValue);
    }

    const handleUpdate = (value) => {
        if (allowedValues && allowedValues.includes(value)) {
            setCustomValue('');
        }

        if (value) {
            onValueChange(value);
        } else {
            onValueChange(options[0].value);
        }
    };

    return (
        <div className={classnames('flex flex-col w-full', className)}>
            {options.map((choice) => (
                <div
                    key={choice.value}
                    className={classnames(
                        'text-primary text-sm w-full flex justify-between items-center px-2.5 py-1.5 transition-all cursor-pointer border border-primary border-solid -mb-px',
                        'hover:bg-brand hover:border-brand hover:text-brand hover:z-10 focus:bg-brand focus:border-brand focus:text-brand focus:z-10 first:rounded-t-md last:rounded-b-md first:last:rounded-md',
                        !customValue && choice.value === value ? 'bg-brand border-brand text-brand z-10' : ''
                    )}
                    onClick={() => {
                        handleUpdate(choice.value);
                        onBlur?.(null);
                    }}
                >
                    <label className="leading-normal cursor-pointer">{choice.label}</label>
                    {choice.sample && <div className="opacity-50">{choice.sample}</div>}
                </div>
            ))}

            {allowCustomValue && (
                <div
                    className={classnames(
                        'text-sm w-full flex justify-between items-center px-2.5 py-1.5 transition-all cursor-pointer border border-primary border-solid -mb-px',
                        'hover:bg-brand hover:border-brand hover:text-brand hover:z-10 focus:bg-brand focus:border-brand focus:text-brand focus:z-10 focus-within:bg-brand focus-within:border-brand focus-within:text-brand focus-within:z-10 first:rounded-t-md last:rounded-b-md first:last:rounded-md',
                        customValue === value ? 'bg-brand border-brand text-brand z-10' : ''
                    )}
                >
                    <input
                        className="bg-transparent border-none p-0 w-full leading-normal"
                        placeholder={t('config:configPage.document.customValuePlaceholder')}
                        value={customValue}
                        onChange={(e) => {
                            setCustomValue(e.target.value);
                        }}
                        onBlur={(e) => {
                            handleUpdate(e.target.value);
                            onBlur?.(e);
                        }}
                    />
                    {customSample && <div className="opacity-50">{customSample}</div>}
                </div>
            )}
        </div>
    );
};

export default InlineSelect;
