import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(...registerables);
import { withIcon } from '../../core/components/Icon.tsx';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../core/utils/classnames.tsx';

const CHART_OPTIONS: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        xAxes: [
            {
                stacked: true,
                ticks: {
                    precision: 0,
                },
                gridLines: {
                    display: false,
                },
            },
        ],
        yAxes: [
            {
                stacked: true,
                ticks: {
                    stepSize: 1,
                },
                gridLines: {
                    display: true,
                },
            },
        ],
    },
    legend: {
        display: false,
        position: 'bottom',
        align: 'start',
        labels: {
            boxWidth: 12,
        },
    },
};

const InfoIcon = withIcon(faInfoCircle);

const AutomationRateChart = (props) => {
    const { data, automationRatio = 0, label, className } = props;
    const { t } = useTranslation('dashboard');

    const [chartData, setChartData] = useState(undefined);
    const chartNode = useRef(null);

    useEffect(() => {
        if (!chartNode?.current) return;

        const style = getComputedStyle(chartNode.current);
        const linkPrimary = style.getPropertyValue('--link-primary');
        const borderColor = style.getPropertyValue('--border-primary');

        setChartData({
            datasets: [
                {
                    backgroundColor: linkPrimary,
                    hoverBackgroundColor: linkPrimary,
                    data: data.map((p) => p.automaticCount),
                    label: t('assistance:reports.labels.automatedExecutions'),
                    maxBarThickness: 100,
                },
                {
                    backgroundColor: borderColor,
                    hoverBackgroundColor: borderColor,
                    data: data.map((p) => p.assistedCount),
                    label: t('assistance:reports.labels.assistedExecutions'),
                    maxBarThickness: 100,
                },
            ],
            labels: data.map((p) => p.date),
        });
    }, [chartNode, data]);

    return (
        <div className={classnames('flex flex-col gap-5', className)} ref={chartNode}>
            <div className="bg-secondary p-5 rounded-lg relative flex flex-col gap-4">
                <div className="flex justify-between items-center px-4">
                    <div className="text-sm leading-8 text-secondary">
                        {t('assistance:reports.automationRatioShort', {
                            percentage: Math.round(automationRatio * 10000) / 100,
                        })}
                    </div>

                    <div className="text-sm leading-8 text-secondary flex gap-2">
                        <span>{t('assistance:reports.channelReport.channel')}</span>
                        <span className="font-medium text-primary">
                            {label ? label : t('assistance:reports.channelReport.allChannels')}
                        </span>
                    </div>
                </div>

                <div className="h-[20rem]">{chartData && <Bar data={chartData} options={CHART_OPTIONS} />}</div>
            </div>

            <div className="flex-auto text-sm text-tertiary flex gap-2">
                <span>
                    <InfoIcon />
                </span>
                {t('assistance:reports.channelReport.info')}
            </div>
        </div>
    );
};

export default AutomationRateChart;
