import { useMutation, useQuery } from '@apollo/client';

import { faArrowDownToLine, faExternalLink, faPencil, faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../core/utils/classnames.tsx';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withIcon } from '../../core/components/Icon.tsx';
import { GET_NAVIGATION_CHANNELS } from '../../core/components/Navigation/internal/queries.ts';
import { CREATE_OUTLOOK_ADDIN, DELETE_OUTLOOK_ADDIN, GET_OUTLOOK_ADDINS, UPDATE_OUTLOOK_ADDIN } from '../queries.ts';
import {
    CUSTOMER_INTEGRATION_OUTLOOK_ADD_PATH,
    CUSTOMER_INTEGRATION_OUTLOOK_EDIT_PATH,
    CUSTOMER_INTEGRATION_OUTLOOK_PATH,
    OUTLOOK_MANIFEST_URL,
} from '../constants.ts';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../core/components/Button.tsx';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { url } from '../../core/utils/link.ts';
import FormField from '../../core/components/FormField.tsx';
import CheckboxGroup from '../../core/components/CheckboxGroup.tsx';
import Modal from '../../core/components/Modal.tsx';
import { useToaster } from '../../core/components/Toast.tsx';
import { Outlet } from 'react-router';
import Layout from '../../core/components/Layout.tsx';
import Page from '../../core/components/Page';
import Table, { RowControlIconButton } from '../../core/components/Table.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import SettingsNavigation from '../../core/components/SettingsNavigation.tsx';

interface OutlookFormModalOutletContext {
    addIns: any[];
    refetch: () => Promise<any>;
}

const AddIcon = withIcon(faPlus);
const EditIcon = withIcon(faPencil);
const DeleteIcon = withIcon(faTrashCan);
const DownloadIcon = withIcon(faArrowDownToLine);
const ExternalLinkIcon = withIcon(faExternalLink);

const FORM_DEFAULT_VALUES = {
    name: '',
    orderChannelIds: [],
    invoiceChannelIds: [],
    orderConfirmationChannelIds: [],
    rfqChannelIds: [],
    deliveryNoteChannelIds: [],
};

const OutlookFormModal = ({
    title,
    submitButtonLabel,
    onSubmit,
    onClose,
    addIn,
}: {
    title: string;
    submitButtonLabel: string;
    onSubmit: (data: any) => void;
    onClose: () => void;
    addIn?: any;
}) => {
    const { t } = useTranslation('integrations');

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (addIn) {
            form.reset({
                ...FORM_DEFAULT_VALUES,
                name: addIn.name,
                orderChannelIds: addIn.orderChannels.map((channel) => channel.id),
                invoiceChannelIds: addIn.invoiceChannels.map((channel) => channel.id),
                orderConfirmationChannelIds: addIn.orderConfirmationChannels.map((channel) => channel.id),
                rfqChannelIds: addIn.rfqChannels.map((channel) => channel.id),
                deliveryNoteChannelIds: addIn.deliveryNoteChannels.map((channel) => channel.id),
            });
        }
    }, [addIn]);

    const { data: channelData } = useQuery(GET_NAVIGATION_CHANNELS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: false,
    });
    const orderChannels = channelData?.orderProcessingChannels || [];
    const invoiceChannels = channelData?.invoiceProcessingChannels || [];
    const orderConfirmationChannels = channelData?.orderConfirmationProcessingChannels || [];
    const rfqChannels = channelData?.rfqProcessingChannels || [];
    const deliveryNoteChannels = channelData?.deliveryNoteProcessingChannels || [];

    return (
        <Modal
            visible={true}
            onClose={onClose}
            title={title}
            buttons={
                <>
                    <Button type="button" asChild>
                        <Link to={url(CUSTOMER_INTEGRATION_OUTLOOK_PATH)}>{t('outlook.form.cancelButton')}</Link>
                    </Button>
                    <Button variant="primary" type="submit" form="modal-form">
                        {submitButtonLabel}
                    </Button>
                </>
            }
        >
            <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)} id="modal-form">
                <Controller
                    name="name"
                    control={form.control}
                    rules={{ required: t('outlook.form.nameRequired') }}
                    render={({ field }) => (
                        <FormField label={t('outlook.form.name')} required={true} error={formErrors.name}>
                            <StringField required={true} {...field} />
                        </FormField>
                    )}
                />

                <Controller
                    name="orderChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('outlook.form.orderChannelIds')} error={formErrors.orderChannelIds}>
                            <CheckboxGroup
                                {...field}
                                options={
                                    orderChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="invoiceChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('outlook.form.invoiceChannelIds')} error={formErrors.invoiceChannelIds}>
                            <CheckboxGroup
                                {...field}
                                options={
                                    invoiceChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="orderConfirmationChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('outlook.form.orderConfirmationChannelIds')}
                            error={formErrors.orderConfirmationChannelIds}
                        >
                            <CheckboxGroup
                                {...field}
                                options={
                                    orderConfirmationChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="rfqChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField label={t('outlook.form.rfqChannelIds')} error={formErrors.rfqChannelIds}>
                            <CheckboxGroup
                                {...field}
                                options={
                                    rfqChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />

                <Controller
                    name="deliveryNoteChannelIds"
                    control={form.control}
                    render={({ field }) => (
                        <FormField
                            label={t('outlook.form.deliveryNoteChannelIds')}
                            error={formErrors.deliveryNoteChannelIds}
                        >
                            <CheckboxGroup
                                {...field}
                                options={
                                    deliveryNoteChannels.map((channel) => ({
                                        value: channel.id,
                                        label: channel.name,
                                    })) || []
                                }
                                onValueChange={(value) => field.onChange({ target: { value } })}
                            />
                        </FormField>
                    )}
                />
            </form>
        </Modal>
    );
};

export const AddOutlookFormModal = () => {
    const { t } = useTranslation('integrations');
    const { refetch } = useOutletContext<OutlookFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const [createAddIn] = useMutation(CREATE_OUTLOOK_ADDIN);
    const handleCreateAddIn = async (formData) => {
        try {
            await createAddIn({
                variables: {
                    name: formData.name,
                    orderChannels: formData.orderChannelIds,
                    invoiceChannels: formData.invoiceChannelIds,
                    orderConfirmationChannels: formData.orderConfirmationChannelIds,
                    rfqChannels: formData.rfqChannelIds,
                    deliveryNoteChannels: formData.deliveryNoteChannelIds,
                },
            });
            await refetch();
            publishToast({ description: t('outlook.createSuccess'), status: 'success' });
            navigate(url(CUSTOMER_INTEGRATION_OUTLOOK_PATH));
        } catch (error) {
            console.error(error);
            publishToast({ description: t('outlook.createError'), status: 'error' });
        }
    };

    return (
        <OutlookFormModal
            title={t('outlook.form.createTitle')}
            submitButtonLabel={t('outlook.form.createButton')}
            onSubmit={handleCreateAddIn}
            onClose={() => navigate(url(CUSTOMER_INTEGRATION_OUTLOOK_PATH))}
        />
    );
};

export const EditOutlookFormModal = () => {
    const { t } = useTranslation('integrations');
    const { addIns, refetch } = useOutletContext<OutlookFormModalOutletContext>();

    const { publishToast } = useToaster();
    const navigate = useNavigate();

    const { integrationId } = useParams();
    const addIn = addIns?.find((addIn) => addIn.id === integrationId);

    const [updateAddIn] = useMutation(UPDATE_OUTLOOK_ADDIN);
    const handleUpdateAddIn = async (formData) => {
        try {
            await updateAddIn({
                variables: {
                    id: addIn.id,
                    name: formData.name,
                    orderChannels: formData.orderChannelIds,
                    invoiceChannels: formData.invoiceChannelIds,
                    orderConfirmationChannels: formData.orderConfirmationChannelIds,
                    rfqChannels: formData.rfqChannelIds,
                    deliveryNoteChannels: formData.deliveryNoteChannelIds,
                },
            });
            await refetch();
            publishToast({ description: t('outlook.updateSuccess'), status: 'success' });
            navigate(url(CUSTOMER_INTEGRATION_OUTLOOK_PATH));
        } catch (error) {
            console.error(error);
            publishToast({ description: t('outlook.updateError'), status: 'error' });
        }
    };

    return (
        <OutlookFormModal
            title={t('outlook.form.updateTitle')}
            submitButtonLabel={t('outlook.form.updateButton')}
            onSubmit={handleUpdateAddIn}
            onClose={() => navigate(url(CUSTOMER_INTEGRATION_OUTLOOK_PATH))}
            addIn={addIn}
        />
    );
};

const OutlookRow = ({ addIn, refetch }: { addIn: any; refetch: any }) => {
    const { t } = useTranslation('integrations');
    const { publishToast } = useToaster();

    const [deleteAddIn] = useMutation(DELETE_OUTLOOK_ADDIN);
    const handleDeleteAddIn = async () => {
        try {
            await deleteAddIn({ variables: { id: addIn.id } });
            await refetch();
            publishToast({ description: t('outlook.deleteSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('outlook.deleteError'), status: 'error' });
        }
    };

    const channels = addIn
        ? [
              ...addIn.orderChannels,
              ...addIn.invoiceChannels,
              ...addIn.orderConfirmationChannels,
              ...addIn.rfqChannels,
              ...addIn.deliveryNoteChannels,
          ]
        : [];

    const downloadUrl = `${OUTLOOK_MANIFEST_URL}/${addIn.id}/download`;

    return (
        <>
            <Table.Row>
                <Table.Cell>{addIn.name}</Table.Cell>
                <Table.Cell>
                    <div className="flex gap-1.5 flex-wrap">
                        {channels.map((channel) => (
                            <span className="border-primary border border-solid bg-secondary flex gap-2 items-center px-1 rounded">
                                {channel.name}
                            </span>
                        ))}
                    </div>
                </Table.Cell>
                <Table.Cell>{t('outlook.version', { version: addIn.version })}</Table.Cell>
                <Table.Cell>{new Date(addIn.createdAt).toLocaleString()}</Table.Cell>
                <Table.Cell>
                    <div className="flex gap-2">
                        <RowControlIconButton asChild>
                            <a href={downloadUrl} download className="!text-primary hover:!text-primary">
                                <DownloadIcon />
                            </a>
                        </RowControlIconButton>

                        <RowControlIconButton asChild>
                            <Link
                                to={url(CUSTOMER_INTEGRATION_OUTLOOK_EDIT_PATH, {
                                    integrationId: addIn.id,
                                })}
                                className="!text-primary hover:!text-primary"
                            >
                                <EditIcon />
                            </Link>
                        </RowControlIconButton>

                        <RowControlIconButton
                            className="hover:bg-error text-error hover:text-error"
                            onClick={handleDeleteAddIn}
                        >
                            <DeleteIcon />
                        </RowControlIconButton>
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    );
};

const OutlookIntegrationPage = () => {
    const { t } = useTranslation('integrations');

    const {
        data: data,
        loading: loading,
        refetch: refetch,
    } = useQuery(GET_OUTLOOK_ADDINS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });
    const addIns = data?.outlookAddins || [];

    return (
        <Layout>
            <SettingsNavigation />

            <Page className="flex-1">
                <Page.Header className="justify-between">
                    <Page.HeaderTitle className="flex items-center gap-2">
                        <img src="/images/external_logos/outlook.png" className="h-5" />
                        <span>{t('outlook.title')}</span>
                    </Page.HeaderTitle>

                    <div className="flex gap-2">
                        <Page.HeaderButton asChild>
                            <a href={t('outlook.documentationLink')} target={'_blank'}>
                                <span className="flex gap-2 items-center">
                                    {t('outlook.documentation')}
                                    <ExternalLinkIcon />{' '}
                                </span>
                            </a>
                        </Page.HeaderButton>

                        <Page.HeaderButton asChild>
                            <Link to={url(CUSTOMER_INTEGRATION_OUTLOOK_ADD_PATH)}>
                                <AddIcon />
                                {t('outlook.addButton')}
                            </Link>
                        </Page.HeaderButton>
                    </div>
                </Page.Header>

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    {
                        <Table className={classnames('w-full table-auto', loading && 'opacity-50')}>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeadCell className="min-w-96 w-96">{t('outlook.table.name')}</Table.HeadCell>
                                    <Table.HeadCell>{t('outlook.table.channels')}</Table.HeadCell>
                                    <Table.HeadCell>{t('outlook.table.version')}</Table.HeadCell>
                                    <Table.HeadCell>{t('outlook.table.createdAt')}</Table.HeadCell>
                                    <Table.HeadCell sticky="right" className="min-w-14 w-14" />
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {addIns && addIns.length > 0 ? (
                                    addIns.map((addIn) => <OutlookRow key={addIn.id} addIn={addIn} refetch={refetch} />)
                                ) : (
                                    <Table.Row>
                                        <Table.Cell colSpan={5} className="text-center text-secondary">
                                            {t('outlook.noIntegrations')}
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    }
                </Page.Content>
            </Page>

            <Outlet context={{ addIns, refetch }} />
        </Layout>
    );
};

export default OutlookIntegrationPage;
