import React from 'react';
import AutoCompleteField, { ForceSelectionAutoCompleteField } from '../../core/components/Fields/AutoCompleteField';
import { useAssistanceFieldContext } from '../pages/Assistance/AssistanceFieldContext';
import { renderAddressOption } from './useLookup';
import { FieldProps, useFieldProps } from '../pages/Assistance/customizable/Field';
import { IConditionalComponent } from '../../customizations/ConditionalComponentContext';
import useForceSelectionLookupProps, { useForceSelectionReselectEventListener } from './useForceSelectionLookupProps';
import { COUNTRY_CODES } from '../../core/utils';
import { useTranslation } from 'react-i18next';

export const AddressIdField = (props: FieldProps) => {
    const { clientPartitionId, config } = useAssistanceFieldContext();
    const { lookupDefinitionId, lookupFieldName, allowInvalidIds } = config;

    const fieldProps = useFieldProps(props);
    const { value, onValueChange, onFocus, onBlur } = fieldProps;

    const forceSelectionLookupProps = useForceSelectionLookupProps({
        lookupType: 'AddressLookupType',
        lookupDefinitionId,
        lookupFieldName,
        partitionId: clientPartitionId,
        allowInvalidIds,
        value,
        onValueChange,
        onUpdatePayloadChange: props.onUpdatePayloadChange,
        onFocus,
        onBlur,
    });

    useForceSelectionReselectEventListener(props.inputRef, forceSelectionLookupProps.onValueChange);

    return <AutoCompleteField {...fieldProps} {...forceSelectionLookupProps} renderOption={renderAddressOption} />;
};

export const CountryField = (props: FieldProps) => {
    const fieldProps = useFieldProps(props);
    const { t, i18n } = useTranslation('core');

    const filterOption = (countryCode: string, search: string, isDirty?: boolean) => {
        const translatedValue = i18n.exists(`core:countries.${countryCode}`) ? t(`core:countries.${countryCode}`) : '';
        return !isDirty ||
            !search ||
            countryCode.toLowerCase().includes(search.toLowerCase()) ||
            translatedValue.toLowerCase().includes(search.toLowerCase())
            ? 1
            : 0;
    };

    return (
        <ForceSelectionAutoCompleteField
            options={COUNTRY_CODES.map((countryCode) => ({
                value: countryCode,
                label: countryCode,
                description: i18n.exists(`core:countries.${countryCode}`) ? t(`core:countries.${countryCode}`) : '',
            }))}
            filterOption={filterOption}
            {...fieldProps}
        />
    );
};

export const getAddressFieldConditionalComponents = (addressFieldKey: string): IConditionalComponent[] => [
    {
        condition: ({ fieldName }) => fieldName === `${addressFieldKey}__address_id`,
        component: AddressIdField,
    },
    {
        condition: ({ fieldName }) => fieldName === `${addressFieldKey}__country`,
        component: CountryField,
    },
];
