import * as React from 'react';
import { IModuleProps } from '../../core/utils/modules';

import CompositeField from '../components/CompositeField';

export default {
    name: 'assa_abloy_ch',
    components: {
        // Add custom components here
        Field: [
            {
                condition: ({ fieldName }) => fieldName === 'variant_type',
                component: (props: any) => {
                    return <CompositeField {...props} />;
                },
            },
        ],
    },
} as IModuleProps;
