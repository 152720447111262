import * as React from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import classnames from '../utils/classnames.tsx';
import { mergeRefs } from '../utils/mergeRefs.tsx';
import { Slot } from '@radix-ui/react-slot';
import { useDebounce } from '../utils/hooks/useDebounce.ts';
import { useControllableState } from '../utils/useControllableState.tsx';

interface CanvasContextProps {
    zoom?: number;
    onZoomChange?: (zoom: number) => void;
    maxZoom?: number;
    minZoom?: number;
    position?: { x: number; y: number };
    onPositionChange?: (position: { x: number; y: number }) => void;
    onResize?: () => void;
}

const CanvasContext = createContext<CanvasContextProps>({});
const CanvasProvider = ({ children, ...props }: CanvasContextProps & { children: any }) => (
    <CanvasContext.Provider value={props}>{children}</CanvasContext.Provider>
);
const useCanvas = () => useContext(CanvasContext);

interface CanvasViewPortContextProps {
    artboardContentDimensions?: { width: number; height: number };
    onArtboardContentDimensionsChange?: (dimensions: { width: number; height: number }) => void;
}

const CanvasViewPortContext = createContext<CanvasViewPortContextProps>({});
const CanvasViewPortProvider = ({ children, ...props }: CanvasViewPortContextProps & { children: any }) => (
    <CanvasViewPortContext.Provider value={props}>{children}</CanvasViewPortContext.Provider>
);
const useCanvasViewPort = () => useContext(CanvasViewPortContext);

interface CanvasControlButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    active?: boolean;
}

const CanvasControlButton = ({ children, className, active, ...props }: CanvasControlButtonProps) => (
    <button
        className={classnames(
            'flex text-primary justify-center items-center h-8 w-8 rounded bg-primary hover:bg-secondary transition-colors duration-200 disabled:opacity-50 disabled:pointer-events-none',
            active && '!text-brand !bg-brand',
            className
        )}
        {...props}
    >
        {children}
    </button>
);

const CanvasControls = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={classnames(
            'absolute bottom-6 left-1/2 -translate-x-1/2 rounded-lg bg-primary shadow-lg flex gap-1 p-1 border border-solid border-secondary',
            className
        )}
        {...props}
    >
        {children}
    </div>
);

interface CanvasViewportProps extends React.ComponentPropsWithRef<'div'> {
    spacing?: number;
    isDraggable?: boolean;
}

const CanvasViewport = ({ ref, children, className, spacing = 100, isDraggable, ...props }: CanvasViewportProps) => {
    const internalRef = useRef(null);

    const { zoom, onZoomChange, minZoom, maxZoom, position, onPositionChange, onResize } = useCanvas();

    const [isControlledScrolling, setIsControlledScrolling] = useState(false);

    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [positionDragStart, setPositionDragStart] = useState({ x: 0, y: 0 });

    const [viewportDimensions, setViewportDimensions] = useState({ width: 0, height: 0 });
    const [artboardContentDimensions, setArtboardContentDimensions] = useState({ width: 0, height: 0 });

    /* Utils */

    const getRelativePosition = (event, node = undefined, wrapper = undefined) => {
        const rect = (node || event.currentTarget)?.getBoundingClientRect();
        return {
            x: event.clientX - rect.left + (wrapper?.scrollLeft || 0),
            y: event.clientY - rect.top + (wrapper?.scrollTop || 0),
        };
    };

    const getSafePosition = (position) => {
        // get position within scroll container

        const maxScrollX = internalRef.current?.scrollWidth - internalRef.current?.clientWidth;
        const maxScrollY = internalRef.current?.scrollHeight - internalRef.current?.clientHeight;

        const safeX = Math.max(Math.min(position.x, maxScrollX), 0);
        const safeY = Math.max(Math.min(position.y, maxScrollY), 0);

        return { x: safeX, y: safeY };
    };

    const zoomToPointInArtboard = (newZoom, point) => {
        const spacingX = viewportDimensions.width - spacing / 2;
        const spacingY = viewportDimensions.height - spacing / 2;

        // min offset means cursor most left of artboard
        // max offset means cursor most right of artboard
        const cursorInArtboard = {
            x: Math.min(Math.max(point.x - spacingX, 0), artboardContentDimensions.width * zoom),
            y: Math.min(Math.max(point.y - spacingY, 0), artboardContentDimensions.height * zoom),
        };

        const nextCursorInArtboard = {
            x: cursorInArtboard.x * (newZoom / zoom),
            y: cursorInArtboard.y * (newZoom / zoom),
        };

        const offsetX = nextCursorInArtboard.x - cursorInArtboard.x;
        const offsetY = nextCursorInArtboard.y - cursorInArtboard.y;

        handlePositionChange({
            x: position.x + offsetX,
            y: position.y + offsetY,
        });
    };

    /* Handlers */

    const handlePositionChange = (newPosition) => onPositionChange?.(getSafePosition(newPosition));

    const handleWheel = (e) => {
        // prevent page scrolling
        e.preventDefault();
        e.stopPropagation();

        // Same zoom / scroll behavior as in Photoshop
        if (e.metaKey || e.ctrlKey) {
            const newZoom = Math.max(minZoom, Math.min(maxZoom, zoom - e.deltaY / 1000));
            onZoomChange(newZoom);

            const cursorPosition = getRelativePosition(e, internalRef.current, internalRef.current);
            zoomToPointInArtboard(newZoom, cursorPosition);
        } else {
            handlePositionChange({ x: position.x, y: position.y + e.deltaY });
        }
    };

    const handleDragStart = (e) => {
        if (!isDraggable) return;

        // prevent selecting text by accident
        e.preventDefault();

        setIsDragging(true);
        setPositionDragStart({ x: position.x, y: position.y });
        setDragStart(getRelativePosition(e));
    };

    const handleDrag = (e) => {
        if (!isDragging) return;

        // prevent selecting text by accident
        e.preventDefault();

        const relativePosition = getRelativePosition(e);

        const x = positionDragStart.x - (relativePosition.x - dragStart.x);
        const y = positionDragStart.y - (relativePosition.y - dragStart.y);

        handlePositionChange({ x, y });
    };

    const handleDragEnd = (e) => {
        setIsDragging(false);
    };

    const handleResize = () => {
        const viewportRect = internalRef.current?.getBoundingClientRect();
        setViewportDimensions({ width: viewportRect?.width || 0, height: viewportRect?.height || 0 });
    };

    const debouncedHandlePositionChange = useDebounce(handlePositionChange, 100);

    const handleScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // prevent duplicate scroll events
        if (!isControlledScrolling) {
            debouncedHandlePositionChange({
                x: e.target.scrollLeft,
                y: e.target.scrollTop,
            });
        } else {
            setIsControlledScrolling(false);
        }
    };

    /* Effects */

    useEffect(() => {
        if (!internalRef.current) return;
        if (!position) return;

        // this flag prevents the scroll event from triggering a cyclic position update
        setIsControlledScrolling(true);
        internalRef.current.scrollLeft = position.x;
        internalRef.current.scrollTop = position.y;
    }, [position]);

    useEffect(() => {
        if (!internalRef.current) return;

        // this is needed to prevent scrolling the page when scrolling the svg
        internalRef.current?.addEventListener('wheel', handleWheel, { passive: false, capture: true });
        internalRef.current?.addEventListener('scroll', handleScroll);
        internalRef.current?.addEventListener('mousedown', handleDragStart);
        internalRef.current?.addEventListener('mousemove', handleDrag);
        internalRef.current?.addEventListener('mouseup', handleDragEnd);

        return () => {
            internalRef.current?.removeEventListener('wheel', handleWheel);
            internalRef.current?.removeEventListener('scroll', handleScroll);
            internalRef.current?.removeEventListener('mousedown', handleDragStart);
            internalRef.current?.removeEventListener('mousemove', handleDrag);
            internalRef.current?.removeEventListener('mouseup', handleDragEnd);
        };
    }, [zoom, position, isDragging, isDraggable, isControlledScrolling]);

    useEffect(() => {
        if (!internalRef.current) return;

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(internalRef.current);

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        onResize?.();
    }, [
        // only trigger resize if the calculated dimensions change
        viewportDimensions.width * 2 + artboardContentDimensions.width,
        viewportDimensions.height * 2 + artboardContentDimensions.height,
    ]);

    return (
        <CanvasViewPortProvider
            artboardContentDimensions={artboardContentDimensions}
            onArtboardContentDimensionsChange={setArtboardContentDimensions}
        >
            <div
                className={classnames(
                    'absolute inset-0 overflow-scroll',
                    isDraggable && 'cursor-grab',
                    isDragging && 'cursor-grabbing',
                    className
                )}
                ref={mergeRefs(ref, internalRef)}
                {...props}
            >
                <div
                    className={classnames('flex items-center justify-center', isDraggable && 'pointer-events-none')}
                    style={{
                        width: viewportDimensions.width * 2 + artboardContentDimensions.width * zoom - spacing,
                        height: viewportDimensions.height * 2 + artboardContentDimensions.height * zoom - spacing,
                    }}
                >
                    {children}
                </div>
            </div>
        </CanvasViewPortProvider>
    );
};

interface CanvasArtboardProps extends React.SVGAttributes<SVGSVGElement> {
    height: number;
    width: number;
    asChild?: boolean;
}

const CanvasArtboard = ({ children, className, asChild, height = 0, width = 0, ...props }: CanvasArtboardProps) => {
    const { zoom } = useCanvas();
    const { onArtboardContentDimensionsChange } = useCanvasViewPort();

    useEffect(() => {
        onArtboardContentDimensionsChange({ width, height });
    }, [height, width]);

    const Comp: any = asChild ? Slot : 'div';
    return (
        <Comp
            className={classnames('shadow-lg', className)}
            style={{
                width: width * zoom,
                height: height * zoom,
            }}
            {...props}
        >
            {children}
        </Comp>
    );
};

interface CanvasRootProps extends React.HTMLAttributes<HTMLDivElement> {
    // zoom
    defaultZoom?: number;
    zoom?: number;
    onZoomChange?: (zoom: number) => void;
    maxZoom?: number;
    minZoom?: number;
    // position
    defaultPosition?: { x: number; y: number };
    position?: { x: number; y: number };
    onPositionChange?: (position: { x: number; y: number }) => void;
    onResize?: () => void;
    // generic
    className?: string;
    children?: any;
}

const CanvasRoot = ({
    // zoom
    defaultZoom = 1,
    zoom: propsZoom,
    onZoomChange,
    maxZoom = 2,
    minZoom = 0.5,
    // position
    defaultPosition = { x: 0, y: 0 },
    position: propsPosition,
    onPositionChange,
    // resize
    onResize,
    // generic
    className,
    children,
    ...props
}: CanvasRootProps) => {
    // const [mode, setMode] = useState('select');
    const [position, setPosition] = useControllableState(defaultPosition, propsPosition, onPositionChange);
    const [zoom, setZoom] = useControllableState(defaultZoom, propsZoom, onZoomChange);

    return (
        <CanvasProvider
            zoom={zoom}
            onZoomChange={setZoom}
            maxZoom={maxZoom}
            minZoom={minZoom}
            position={position}
            onPositionChange={setPosition}
            onResize={onResize}
        >
            <div className={classnames('relative w-full h-full', className)} {...props}>
                {children}
            </div>
        </CanvasProvider>
    );
};

const MemoizedCanvasRoot = React.memo(CanvasRoot);
const MemoizedCanvasViewport = React.memo(CanvasViewport);
const MemoizedCanvasArtboard = React.memo(CanvasArtboard);
const MemoizedCanvasControls = React.memo(CanvasControls);
const MemoizedCanvasControlButton = React.memo(CanvasControlButton);

export default Object.assign(
    {},
    {
        Root: MemoizedCanvasRoot,
        Viewport: MemoizedCanvasViewport,
        Artboard: MemoizedCanvasArtboard,
        Controls: MemoizedCanvasControls,
        ControlButton: MemoizedCanvasControlButton,
    }
);
