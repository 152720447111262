import { Command as CommandPrimitive } from 'cmdk';
import * as React from 'react';
import { type KeyboardEvent, useCallback, useRef, useState } from 'react';
import classnames from '../../utils/classnames.tsx';
import { withIcon } from '../Icon.tsx';
import {
    faCheck,
    faChevronDown,
    faChevronRight,
    faCircleNotch,
    faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { useControllableState } from '../../utils/useControllableState.tsx';
import Field, { FieldProps } from './Field.tsx';
import { mergeRefs } from '../../utils/mergeRefs.tsx';
import Popover from '../Popover.tsx';
import useForceSelection from '../../../document/components/useForceSelection.ts';

export interface Option {
    label: string;
    value: string;
    role?: string;
    description?: string;
    disabled?: boolean;
    [key: string]: any;
}

interface AutoCompleteProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue' | 'onSelect'>,
        FieldProps {
    options: Option[];
    filterOption?: (value: string, search: string, isDirty?: boolean) => number;
    renderOption?: (option: Option, value: string) => React.ReactNode;
    emptyMessage?: string;
    onSelect?: (event: any) => void;
    onSelectOption?: (option: Option) => void;
    isLoading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    forceSelection?: boolean;
}

const CheckIcon = withIcon(faCheck);
const LoadingIcon = withIcon(faSpinnerThird);
const ChevronRightIcon = withIcon(faChevronRight);
const ChevronDownIcon = withIcon(faChevronDown);
const LoadingCircleIcon = withIcon(faCircleNotch);

const CommandInput = ({
    className,
    readOnly,
    ...props
}: React.ComponentPropsWithRef<typeof CommandPrimitive.Input>) => (
    <CommandPrimitive.Input
        className={classnames('px-2 py-1.5 flex-1 min-w-0 bg-transparent outline-none', className)}
        readOnly={readOnly}
        cmdk-input-wrapper=""
        {...props}
    />
);

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = ({ className, ...props }: React.ComponentPropsWithRef<typeof CommandPrimitive.List>) => (
    <CommandPrimitive.List className={classnames(className)} {...props} />
);

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandGroup = ({ className, ...props }: React.ComponentPropsWithRef<typeof CommandPrimitive.Group>) => (
    <CommandPrimitive.Group
        className={classnames(
            'overflow-hidden  p-1 text-primary [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-slate-500 dark:text-slate-50 dark:[&_[cmdk-group-heading]]:text-slate-400',
            className
        )}
        {...props}
    />
);

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandItem = ({ className, ...props }: React.ComponentPropsWithRef<typeof CommandPrimitive.Item>) => (
    <CommandPrimitive.Item
        className={classnames(
            'relative flex w-full cursor-default select-none items-center rounded-md py-1.5 pl-8 pr-3 text-primary text-sm outline-none hover:bg-secondary focus:bg-secondary aria-selected:bg-secondary aria-selected:text-neutral-900 focus:text-accent-foreground data-[disabled="true"]:pointer-events-none data-[disabled="true"]:opacity-50',
            className
        )}
        {...props}
    />
);

CommandItem.displayName = CommandPrimitive.Item.displayName;

export const ValueSuggestions = ({
    children,
    inputRef,
    value,
    onValueChange,
    isOpen,
    onOpenChange,
    isDirty,
    onDirtyChange,
    onSelect,
    onSelectOption,
    options = [],
    filterOption = defaultFilterOption,
    renderOption = defaultRenderOption,
    isLoading = false,
    emptyMessage = undefined,
}: AutoCompleteProps & {
    inputRef: React.RefObject<HTMLInputElement>;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    isDirty?: boolean;
    onDirtyChange?: (dirty: boolean) => void;
}) => {
    const anchorRef = useRef(null);

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (!input) return;

        // Keep the options displayed when the user is typing
        if (!isOpen) onOpenChange(true);

        if (event.key === 'Escape') {
            handleOpenChange(false);
            event.preventDefault();
            event.stopPropagation();
        }

        if (event.key === 'Tab') {
            handleOpenChange(false);
        }
    };

    const handleSelectOption = (selectedOption: Option) => {
        const event = new CustomEvent('select', { cancelable: true, detail: selectedOption });
        onSelect?.(event);
        onSelectOption?.(selectedOption);

        if (event.defaultPrevented) return;

        onValueChange(selectedOption.value);
        handleOpenChange(false);
    };

    const handlePointerDownOutside = (event) => {
        // Prevent closing if the click is on the anchor element
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            event.preventDefault();
        }
    };
    const handleOpenChange = (newOpen: boolean) => {
        onOpenChange(newOpen);
        onDirtyChange?.(false);
    };

    const handleFilterOption = useCallback(
        (value: string, search: string) => {
            return filterOption(value, search, isDirty);
        },
        [filterOption, isDirty]
    );

    return (
        <CommandPrimitive onKeyDown={handleKeyDown} className="flex-1" filter={handleFilterOption}>
            <Popover open={(options.length > 0 || emptyMessage) && isOpen} onOpenChange={onOpenChange}>
                <Popover.Anchor ref={anchorRef}>
                    <div className="relative">{children}</div>
                </Popover.Anchor>

                <div className="relative z-50">
                    <Popover.Content
                        align="start"
                        className="p-0 z-[20000]"
                        onOpenAutoFocus={(e) => {
                            e.preventDefault();
                        }}
                        onFocusOutside={(e) => {
                            e.preventDefault();
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onPointerDownOutside={handlePointerDownOutside}
                    >
                        <CommandList className="rounded-lg">
                            {isLoading ? (
                                <CommandPrimitive.Loading>
                                    <div className="p-6 text-center">
                                        <LoadingIcon spin className="text-2xl text-tertiary" />
                                    </div>
                                </CommandPrimitive.Loading>
                            ) : null}

                            {options.length > 0 && !isLoading ? (
                                <CommandGroup>
                                    {options.map((option: Option) => {
                                        const isSelected = value === option.value;

                                        if (option.role === 'separator') {
                                            return (
                                                <CommandPrimitive.Separator
                                                    key={option.value}
                                                    className="w-full py-1.5 px-1.5"
                                                    onMouseDown={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                    alwaysRender
                                                >
                                                    <div className="border-primary border-solid border-b w-full" />
                                                </CommandPrimitive.Separator>
                                            );
                                        }

                                        if (option.role === 'message') {
                                            return (
                                                <CommandPrimitive.Item
                                                    key={option.value}
                                                    disabled={true}
                                                    value="__MESSAGE__"
                                                    className="px-3 py-1.5 text-left text-sm text-tertiary formatted"
                                                    onMouseDown={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    {option.label}
                                                </CommandPrimitive.Item>
                                            );
                                        }

                                        if (option.role === 'button') {
                                            return (
                                                <CommandItem
                                                    key={option.value}
                                                    value={option.value || '__BUTTON__'}
                                                    disabled={option.disabled}
                                                    onMouseDown={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                    onSelect={() => handleSelectOption(option)}
                                                    className="pl-3 py-2 cursor-pointer formatted justify-between"
                                                >
                                                    {option.label}

                                                    <ChevronRightIcon className="text-sm text-secondary" />
                                                </CommandItem>
                                            );
                                        }

                                        return (
                                            <>
                                                <CommandItem
                                                    key={option.id || option.key || option.value}
                                                    value={option.id || option.value}
                                                    disabled={option.disabled}
                                                    onMouseDown={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                    onSelect={() => handleSelectOption(option)}
                                                    className={classnames(
                                                        'flex items-center gap-2 w-full cursor-pointer',
                                                        !isSelected ? 'pl-8' : null
                                                    )}
                                                >
                                                    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                                                        {isSelected ? <CheckIcon className="h-4 w-4" /> : null}
                                                    </span>

                                                    {renderOption(option, value)}
                                                </CommandItem>
                                            </>
                                        );
                                    })}
                                </CommandGroup>
                            ) : null}

                            {!isLoading && options.length === 0 ? (
                                <div className="p-4 text-left text-sm text-tertiary">{emptyMessage}</div>
                            ) : null}
                        </CommandList>
                    </Popover.Content>
                </div>
            </Popover>
        </CommandPrimitive>
    );
};

ValueSuggestions.Input = CommandInput;

export const defaultRenderOption = (option: Option) => {
    return (
        <span>
            <span>{option.label}</span>
            {option.label !== option.value ? <span className="block text-xs text-tertiary">{option.value}</span> : null}
            {option.description ? <span className="block text-xs text-tertiary">{option.description}</span> : null}
        </span>
    );
};

export const defaultFilterOption = (value: string, search: string, isDirty?: boolean) => {
    // TODO: replace with similarity score
    return !isDirty || !search || value.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
};

export const showAllFilterOption = (value: string, search: string) => 1;

const AutoCompleteField = ({
    ref,
    defaultValue,
    value: propsValue,
    onValueChange,
    onSelect,
    onSelectOption,
    onBlur,
    onFocus,
    readOnly,
    disabled,
    controls,
    className,
    // field specific props
    options = [],
    filterOption = defaultFilterOption,
    renderOption = defaultRenderOption,
    isLoading = false,
    emptyMessage = undefined,

    inputRef: propsInputRef = undefined,
    ...props
}: AutoCompleteProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isOpen, setOpen] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

    const handleFocus = (e) => {
        if (!isOpen) setOpen(true);
        onFocus?.(e);
    };

    const handleBlur = (e) => {
        if (isOpen) setOpen(false);
        onBlur?.(e);
    };

    const handleValueChange = (value: string) => {
        setValue(value);
        setDirty(true);
    };

    return (
        <ValueSuggestions
            inputRef={inputRef}
            value={value}
            onValueChange={handleValueChange}
            isOpen={isOpen}
            onOpenChange={setOpen}
            isDirty={isDirty}
            onDirtyChange={setDirty}
            onSelect={onSelect}
            onSelectOption={onSelectOption}
            filterOption={filterOption}
            renderOption={renderOption}
            isLoading={isLoading}
            options={options}
            emptyMessage={emptyMessage}
        >
            <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
                <Field.Input>
                    <ValueSuggestions.Input
                        ref={mergeRefs(inputRef, propsInputRef)}
                        value={value}
                        onValueChange={handleValueChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={disabled}
                        readOnly={readOnly}
                        {...props}
                    />
                </Field.Input>
                <Field.Controls>{controls}</Field.Controls>
            </Field>
        </ValueSuggestions>
    );
};

export const ForceSelectionAutoCompleteField = ({
    defaultValue,
    value,
    onValueChange,
    onSelect,
    onSelectOption,
    onBlur,
    onFocus,
    readOnly,
    disabled,
    controls,
    className,
    // field specific props
    options = [],
    filterOption = defaultFilterOption,
    renderOption = defaultRenderOption,
    isLoading = false,
    emptyMessage = undefined,

    inputRef: propsInputRef = undefined,
    ...props
}: AutoCompleteProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const forceSelectionProps = useForceSelection({
        options,
        defaultValue,
        value,
        onValueChange,
        forceSelection: true,
        resetInvalidOnBlur: true,
        onFocus,
        onBlur,
        onSelectOption,
    });

    return (
        <AutoCompleteField
            {...forceSelectionProps}
            onSelect={onSelect}
            readOnly={readOnly}
            disabled={disabled}
            controls={
                <>
                    {!readOnly && (
                        <Field.ControlButton className="group-hover:opacity-100 point !bg-transparent">
                            {isLoading ? (
                                <LoadingCircleIcon spin />
                            ) : (
                                <ChevronDownIcon
                                    onClick={() => {
                                        // trigger dropdown to be opened
                                        if (inputRef?.current) {
                                            inputRef?.current.focus();
                                        }
                                    }}
                                />
                            )}
                        </Field.ControlButton>
                    )}
                    {controls}
                </>
            }
            className={className}
            options={options}
            filterOption={filterOption}
            renderOption={renderOption}
            isLoading={isLoading}
            emptyMessage={emptyMessage}
            inputRef={mergeRefs(inputRef, propsInputRef)}
            {...props}
        />
    );
};

export default AutoCompleteField;
