import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { ApolloClient, ApolloLink, from, InMemoryCache, useQuery } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { useEffect } from 'react';
import i18n from 'i18next';
import { GET_VIEWER } from '../queries';
import { LOGIN_USER, LOGOUT_USER } from '../../login/queries';
import { loadCustomizations } from '../../customizations/framework';
import { AI } from '../analytics/applicationInsights';

export const LOGOUT_LOCAL_STORAGE_KEY = 'logout';

export const httpLink = createUploadLink({
    uri: process.env.GRAPHQL_URL,
    credentials: 'include',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: ${message} Path: ${path}`);
        });

    if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(),
});

export const login = (user, pass) => {
    // client without authLink
    const loginClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([httpLink]),
    });

    return loginClient
        .mutate({
            mutation: LOGIN_USER,
            variables: { email: user, password: pass },
        })
        .then((res) => {
            return res?.data?.login;
        })
        .catch((err) => {
            console.error(err);
        });
};

export const logout = () => {
    return client
        .mutate({
            mutation: LOGOUT_USER,
        })
        .then((res) => {
            client.clearStore().then((_) => console.debug('Apollo cache cleared.'));
            localStorage.setItem(LOGOUT_LOCAL_STORAGE_KEY, Date.now().toString());

            // refresh to make sure all intervals are disabled
            window.location.reload();
            return true;
        })
        .catch((err) => {
            console.error(err);
            return false;
        });
};

export const useAuth = () => {
    const { loading, error, data, refetch } = useQuery(GET_VIEWER, {
        fetchPolicy: 'no-cache',
    });

    const user = data?.viewer || {};
    const loggedIn = !!user?.id;

    useEffect(() => {
        if (!loggedIn) return;

        loadCustomizations(user.customer);

        // establish user context for analytics
        AI?.clearAuthenticatedUserContext();
        AI?.setAuthenticatedUserContext(user.id, user.customer?.id);

        if (user.language) {
            void i18n.changeLanguage(user.language.toLowerCase());
            // Set locale is handled by i18n
        }

        const handleStorageChange = (event) => {
            if (event.key == LOGOUT_LOCAL_STORAGE_KEY) {
                console.debug('Logged out from storage.');
                // refresh to make sure all intervals are disabled
                window.location.reload();
            }
        };
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [loggedIn]);

    return {
        user,
        refetchUser: refetch,
        loggedIn,
        loading,
        error,
    };
};
