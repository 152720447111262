import * as React from 'react';
import { ComponentProps, useMemo } from 'react';
import classnames from '../../../../core/utils/classnames.tsx';
import { useAssistanceFieldContext } from '../AssistanceFieldContext';
import StringField from '../../../../core/components/Fields/StringField';
import useReselectEventListener, { ReselectEvent } from '../useReselectEventListener';
import { fieldIsGreen, fieldIsRed, fieldIsYellow } from '../utils';

export interface FieldProps {
    disabled?: boolean;
    readOnly?: boolean;
    placeholder: string;
    value: any;
    onValueChange: (value: any, updatePayload?: any) => void;
    onUpdatePayloadChange?: (updatePayload: any) => void; // this is needed for lookup fields
    onFocus: (e: any) => void;
    onBlur: (e: any) => void;
    onReselect: (e: ReselectEvent) => void;
    onReset?: () => void;

    inputRef: any;
    controls: React.ReactNode;

    // maybe move to some context
    confidence: number;
    severity: string;
    documentLoading: boolean;
    documentReadOnly: boolean;
}

export const useFieldProps = <T extends React.ElementType>({
    disabled,
    readOnly,
    placeholder,
    value,
    onValueChange,
    onFocus,
    onBlur,
    inputRef,
    controls,
    confidence,
    severity,
    documentLoading,
    documentReadOnly,
}: FieldProps): ComponentProps<T> => {
    const { isUpdated, isInvalid, isRequired, isUpdateActive, isReselectActive, isFocusActive, data, config } =
        useAssistanceFieldContext();

    const isGreen = useMemo(() => fieldIsGreen({ config, value, data }), [config, value, data]);
    const isYellow = useMemo(() => fieldIsYellow({ config, value, data }), [config, value, data]);
    const isRed = useMemo(() => fieldIsRed({ config, value, data }), [config, value, data]);

    const showErrorColors = !isFocusActive && !isUpdateActive && !isReselectActive;

    return {
        className: classnames(
            isUpdated &&
                'border-success bg-success hover:border-success focus-within:border-success focus-within:outline-success',
            showErrorColors &&
                isInvalid &&
                'border-error bg-error hover:border-error focus-within:border-error focus-within:outline-error',

            showErrorColors &&
                isGreen &&
                'border-confidence-high hover:border-confidence-high focus-within:border-confidence-high focus-within:outline-success',
            showErrorColors &&
                isYellow &&
                'border-confidence-medium hover:border-confidence-medium focus-within:border-confidence-medium focus-within:outline-warning',
            showErrorColors &&
                isRed &&
                'border-confidence-low hover:border-confidence-low focus-within:border-confidence-low focus-within:outline-error'
        ),
        placeholder,
        readOnly: readOnly || documentReadOnly || documentLoading || isUpdateActive || isReselectActive,
        disabled: disabled || documentReadOnly,
        required: isRequired,
        value,
        onValueChange,
        onFocus,
        onBlur,
        inputRef,
        controls,
    } as ComponentProps<T>;
};

export const Field = (props: FieldProps) => {
    /*
    NOTE: This is just a sample field component, the actual field component will be determined
    dynamically by using DynamicField component in the parent component.
    */

    const fieldProps = useFieldProps<typeof StringField>(props);

    useReselectEventListener(props.inputRef, props.onReselect);

    return <StringField {...fieldProps} />;
};

export default Field;
