export const OVERVIEW_PATH = '/orders';
export const OVERVIEW_FINISHED_PATH = '/orders/finished';
export const OVERVIEW_TESTING_PATH = '/orders/testing';

export const CHANNEL_PATH = '/orders/channels/:channelId';
export const CHANNEL_FINISHED_PATH = '/orders/channels/:channelId/finished';
export const CHANNEL_TESTING_PATH = '/orders/channels/:channelId/testing';
export const CHANNEL_CONFIG_PATH = '/orders/channels/:channelId/config/:tab?';
export const CHANNEL_MASTERDATA_PATH = '/orders/channels/:channelId/masterdata';

export const ASSISTANCE_PATH = '/orders/:recordId';
export const ASSISTANCE_TAB_PATH = '/orders/:recordId/:tab';
