import DocumentAssistance, { ALL_TABS, TABS_OPTIONS } from '../../../document/pages/Assistance';
import {
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    GET_RELATED_DOCUMENT,
    RE_UPLOAD_FILE,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../queries.ts';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../constants.ts';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentType } from '../../../document/constants.ts';
import {
    getCustomerFieldConditionalComponents,
    getCustomerFieldGroupConditionalComponents,
} from '../../../document/components/CustomerField.tsx';
import { useAssistanceContext } from '../../../document/pages/Assistance/AssistanceContext.tsx';
import classnames from '../../../core/utils/classnames.tsx';
import Tooltip from '../../../core/components/Tooltip.tsx';
import Button from '../../../core/components/Button.tsx';
import { withIcon } from '../../../core/components/Icon.tsx';
import { faBarsFilter } from '@fortawesome/pro-regular-svg-icons';
import { EXTERNAL_STATUS_FILTER_CHOICES } from '../components/ExternalStatusFilter.tsx';

const FastTrackIcon = withIcon(faBarsFilter);

const PropertyBillsSidebarControls = ({ isFastTrackEnabled, onFastTrackEnabledChange, fastTrackCount = 0 }: any) => {
    const { t } = useTranslation('assistance');
    const { handlers, loading, record, readOnly } = useAssistanceContext();

    const canFinishAssistance = record?.canFinishAssistance;

    // if property bill we need to handle the "manuell erfassen" case
    const needsManualCapture =
        record?.deliveryRecord?.externalStatus == EXTERNAL_STATUS_FILTER_CHOICES.ENTER_MANUALLY_API_STATUS;

    const handleCaptureManuallyClick = () => {
        return handlers.onUpdate({
            action: 'action:manual_capture',
            payload: {
                externalMessage: '',
            },
            forceRefetchRecord: true,
        });
    };

    const handleCapturedManuallyClick = () => {
        return handlers.onUpdate({
            action: 'action:captured_manually',
            payload: {
                stepRunId: record.stepRun.id,
            },
            forceRefetchRecord: true,
        });
    };

    if (readOnly) return null;

    return (
        <div className="flex flex-col gap-4 flex-none overflow-hidden p-6 border-t border-solid border-primary field-modal-root">
            {!readOnly && (
                <div className="flex gap-2">
                    <Button
                        onClick={() => onFastTrackEnabledChange(!isFastTrackEnabled)}
                        className={classnames(
                            'flex gap-2 items-center bg-primary text-primary shadow-sm rounded px-3 py-1.5 font-medium text-sm border border-solid border-secondary outline-none',
                            'hover:bg-secondary transition-colors',
                            isFastTrackEnabled && 'bg-brand text-brand border-brand'
                        )}
                        disabled={readOnly}
                    >
                        <FastTrackIcon /> {fastTrackCount}
                    </Button>

                    {needsManualCapture ? (
                        <Button
                            className="flex items-center justify-center w-full p-2 !bg-brand-default rounded-md text-inverted font-medium text-sm hover:enabled:!bg-brand-hover transition-colors duration-200 focus:enabled:!bg-brand-focus active:enabled:!bg-brand-active disabled:opacity-50"
                            disabled={readOnly}
                            onClick={handleCapturedManuallyClick}
                        >
                            {t('nextButton.manualCapturedButton')}
                        </Button>
                    ) : (
                        <>
                            <Button
                                className="flex flex-1 gap-2 items-center text-center justify-center bg-primary text-primary shadow-sm rounded px-3 py-1.5 font-medium text-sm border border-solid border-secondary outline-none whitespace-nowrap"
                                disabled={readOnly}
                                onClick={handleCaptureManuallyClick}
                            >
                                {t('nextButton.manualCaptureButton')}
                            </Button>

                            <Tooltip
                                content={
                                    loading || !canFinishAssistance
                                        ? t('finishButton.finishDisabledNote')
                                        : t('finishButton.finishNote')
                                }
                                side="top"
                                long
                                className="tooltip--flex"
                                open={readOnly ? false : undefined}
                            >
                                <Button
                                    className="flex items-center justify-center w-full p-2 !bg-brand-default rounded-md text-inverted font-medium text-sm hover:enabled:!bg-brand-hover transition-colors duration-200 focus:enabled:!bg-brand-focus active:enabled:!bg-brand-active disabled:opacity-50"
                                    disabled={loading || readOnly || !canFinishAssistance}
                                    onClick={() => handlers?.onFinishAndContinue()}
                                >
                                    {t('finishButton.finishAndContinueButton')}
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export const getHeaderFieldConfigs = (client, recordId, masterDataConfig, headerFields) => {
    return {
        kreditor: {
            groupFieldProps: {
                customerNumber: {
                    hidden: false,
                },
            },
        },
    };
};

export const getLineItemFieldConfigs = (client, recordId, masterDataConfig) => {
    return {};
};

const PropertyBillAssistance = (props) => {
    const { t } = useTranslation('assistance');

    const documentConfiguration = useMemo(
        () => ({
            documentType: DocumentType.PropertyBill,
            documentTypeName: 'propertyBill',
            GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
            GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
            GET_RELATED_DOCUMENT: GET_RELATED_DOCUMENT,
            RETRY_STEP: RETRY_STEP,
            ASSISTANCE_PATH: ASSISTANCE_PATH,
            ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
            OVERVIEW_PATH: OVERVIEW_PATH,
            OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
            OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
            CHANNEL_PATH: CHANNEL_PATH,
            CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
            CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
            RE_UPLOAD_FILE: RE_UPLOAD_FILE,
            SEND_TO_LABELING: SEND_TO_LABELING,
            getHeaderFieldConfigs: getHeaderFieldConfigs,
            getLineItemFieldConfigs: getLineItemFieldConfigs,
            itemTypes: ['objekte', 'spitzabrechnungen', 'abschlagszahlungen'],
            components: {
                Field: [...getCustomerFieldConditionalComponents('kreditor')],
                HeaderDataFieldGroup: [...getCustomerFieldGroupConditionalComponents('kreditor')],
                SidebarControls: [
                    {
                        condition: ({ documentTypeName }) => documentTypeName === 'propertyBill',
                        component: PropertyBillsSidebarControls,
                    },
                ],
            },
            tabs: {
                [TABS_OPTIONS.Source]: ALL_TABS[TABS_OPTIONS.Source],
                [TABS_OPTIONS.Document]: ALL_TABS[TABS_OPTIONS.Document],
            },
            defaultTab: TABS_OPTIONS.Document,
            onAddLineItem: ({ onFieldAction, itemIndex, itemType }) => {
                onFieldAction({
                    action: 'action:add_empty_item',
                    itemIndex,
                    itemType,
                });
            },
            firstItemsTab: 'objekte',
            getAlert: ({ record }) =>
                (record?.deliveryRecord?.externalStatus ===
                    EXTERNAL_STATUS_FILTER_CHOICES.ENTER_MANUALLY_API_STATUS && {
                    title: t('header.alerts.manualCapture.title'),
                    message: record?.deliveryRecord?.externalMessage
                        ? t('header.alerts.manualCapture.message', {
                              message: record?.deliveryRecord?.externalMessage || '',
                          })
                        : t('header.alerts.manualCapture.messageWithoutMessage'),
                    severity: 'info',
                }) ||
                (record?.deliveryRecord?.externalStatus === EXTERNAL_STATUS_FILTER_CHOICES.INCOMPLETE_API_STATUS && {
                    title: t('header.alerts.incomplete.title'),
                    message: record?.deliveryRecord?.externalMessage
                        ? t('header.alerts.incomplete.message', {
                              message: record?.deliveryRecord?.externalMessage || '',
                          })
                        : t('header.alerts.incomplete.messageWithoutMessage'),
                    severity: 'info',
                }),
        }),
        []
    );

    return <DocumentAssistance documentConfiguration={documentConfiguration} {...props} />;
};

export default PropertyBillAssistance;
