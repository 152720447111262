import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { url } from '../../core/utils/link.ts';
import Logo from '../../core/components/Logo.tsx';
import { LOGIN_PATH } from '../constants.ts';
import { PROVIDER_AUTHORIZED, PROVIDER_LOGIN, PROVIDER_PROVIDER_LOGIN } from '../queries.ts';
import { httpLink } from '../../core/utils/authentication.ts';
import { withIcon } from '../../core/components/Icon.tsx';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import AuthPage from '../components/AuthPage.tsx';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../core/components/Button.tsx';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';

const providerLogin = (email, payload = {}) => {
    // client without authLink
    const publicClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([httpLink]),
    });

    return publicClient
        .mutate({
            mutation: PROVIDER_LOGIN,
            variables: {
                input: {
                    email,
                    payload: JSON.stringify(payload),
                },
            },
        })
        .then((res) => {
            return res.data?.oauthLogin?.redirectUrl;
        });
};

const providerProviderLogin = (provider, payload = {}) => {
    // client without authLink
    const publicClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([httpLink]),
    });

    return publicClient
        .mutate({
            mutation: PROVIDER_PROVIDER_LOGIN,
            variables: {
                input: {
                    provider,
                    payload: JSON.stringify(payload),
                },
            },
        })
        .then((res) => {
            return res.data?.oauthProviderLogin?.redirectUrl;
        });
};

const providerAuthorized = (provider, payload = {}) => {
    // client without authLink
    const publicClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([httpLink]),
    });

    return publicClient
        .mutate({
            mutation: PROVIDER_AUTHORIZED,
            variables: {
                input: {
                    provider,
                    payload: JSON.stringify(payload),
                },
            },
        })
        .then((res) => {
            return res?.data?.oauthAuthorized;
        });
};

const LoadingIcon = withIcon(faSpinnerThird);

const FORM_DEFAULT_VALUES = {
    email: '',
};

const ProviderLoginPage = (props) => {
    const { loggedIn, user, providerAuthorized: isProviderAuthorized } = props;

    const { t } = useTranslation('user');
    const { provider } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const payload = Object.fromEntries(searchParams.entries());
    const nextLocation = searchParams.get('next') || '/';

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (loggedIn && user) {
            navigate(nextLocation);
        }
    }, [user, loggedIn]);

    useEffect(() => {
        if (!provider || formErrors.root) return;

        if (isProviderAuthorized) {
            providerAuthorized(provider, payload)
                .then((data) => {
                    if (data) {
                        window.location.href = data.redirectUrl || nextLocation;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    form.setError('root', {
                        type: 'providerError',
                        message: t('login.providers.providerError', { provider, error: err.message }),
                    });
                });
        } else {
            providerProviderLogin(provider, payload)
                .then((redirectUrl) => {
                    if (redirectUrl) window.location.href = redirectUrl;
                })
                .catch((err) => {
                    console.error(err);
                    form.setError('root', {
                        type: 'providerError',
                        message: t('login.providers.loginError'),
                    });
                });
        }
    }, []);

    const handleSubmit = async ({ email }) => {
        try {
            const redirectUrl = await providerLogin(email, payload);
            if (redirectUrl) window.location.href = redirectUrl;
        } catch (err) {
            console.error(err);
            form.setError('root', { type: 'providerError', message: t('login.providers.loginError') });
        }
    };

    return (
        <AuthPage>
            <AuthPage.Content>
                <Logo className="w-32 h-auto" />

                <AuthPage.Card>
                    {formErrors.root ? (
                        <div className="flex flex-col items-center gap-6">
                            <AuthPage.Error>{formErrors.root.message}</AuthPage.Error>

                            <Button asChild>
                                <Link to={url(LOGIN_PATH)}>{t('login.providers.goBack')}</Link>
                            </Button>
                        </div>
                    ) : isProviderAuthorized ? (
                        <>
                            <div className="text-brand text-center text-3xl">
                                <LoadingIcon spin />
                            </div>
                            <div className="text-primary font-medium text-center">{t('login.providers.login')}</div>
                            <div className="text-secondary text-center">{t('login.providers.pleaseWait')}</div>
                        </>
                    ) : (
                        <>
                            <AuthPage.Title>{t('login.title')}</AuthPage.Title>

                            <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(handleSubmit)}>
                                <Controller
                                    name="email"
                                    control={form.control}
                                    rules={{
                                        required: t('login.form.emailRequired'),
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: t('login.form.emailInvalid'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <FormField label={t('login.form.email')} error={formErrors.email} required>
                                            <StringField {...field} placeholder={t('login.form.emailPlaceholder')} />
                                        </FormField>
                                    )}
                                />

                                <div className="mt-4">
                                    <Button variant="primary" className="w-full" loading={form.formState.isSubmitting}>
                                        {t('login.form.submitButton')}
                                    </Button>
                                </div>

                                <div className="text-xs text-tertiary">{t('login.providers.redirectNote')}</div>
                            </form>
                        </>
                    )}
                </AuthPage.Card>

                <div className="text-xs text-center">
                    <Link
                        to={`${LOGIN_PATH}?next=${nextLocation}`}
                        className="text-brand-default hover:text-brand-hover"
                    >
                        {t('login.withPassword')}
                    </Link>
                </div>
            </AuthPage.Content>

            <AuthPage.Footer />
        </AuthPage>
    );
};

export default ProviderLoginPage;
