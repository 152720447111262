import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import i18n from 'i18next';
import Logo from '../../core/components/Logo.tsx';
import { CREATE_USER } from '../queries.ts';
import { LOGIN_PATH } from '../constants.ts';
import AuthPage from '../components/AuthPage.tsx';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import Button from '../../core/components/Button.tsx';

const FORM_DEFAULT_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    customerName: '',
};

const SignUpPage = () => {
    const { t } = useTranslation('user');

    const [submitted, setSubmitted] = useState(false);
    const [createUser] = useMutation(CREATE_USER);

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    const handleSubmit = async (formData) => {
        try {
            await createUser({ variables: { input: { ...formData, language: i18n.language } } });
            setSubmitted(true);
        } catch (error) {
            console.error(error);
            form.setError('root', { message: t('signUp.submitError') });
        }
    };

    return (
        <AuthPage>
            <AuthPage.Content>
                <Logo className="w-32 h-auto" />

                {!submitted ? (
                    <AuthPage.Card>
                        <AuthPage.Title>{t('signUp.title')}</AuthPage.Title>

                        {formErrors.root && <AuthPage.Error>{formErrors.root.message}</AuthPage.Error>}

                        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4">
                            <Controller
                                name="email"
                                control={form.control}
                                rules={{
                                    required: t('signUp.emailRequired'),
                                }}
                                render={({ field }) => (
                                    <FormField label={t('signUp.email')} error={formErrors.email} required>
                                        <StringField {...field} placeholder={t('signUp.emailPlaceholder')} />
                                    </FormField>
                                )}
                            />

                            <Controller
                                name="firstName"
                                control={form.control}
                                rules={{
                                    required: t('signUp.firstNameRequired'),
                                }}
                                render={({ field }) => (
                                    <FormField label={t('signUp.firstName')} error={formErrors.firstName} required>
                                        <StringField {...field} placeholder={t('signUp.firstNamePlaceholder')} />
                                    </FormField>
                                )}
                            />

                            <Controller
                                name="lastName"
                                control={form.control}
                                rules={{
                                    required: t('signUp.lastNameRequired'),
                                }}
                                render={({ field }) => (
                                    <FormField label={t('signUp.lastName')} error={formErrors.lastName} required>
                                        <StringField {...field} placeholder={t('signUp.lastNamePlaceholder')} />
                                    </FormField>
                                )}
                            />

                            <Controller
                                name="customerName"
                                control={form.control}
                                rules={{
                                    required: t('signUp.customerNameRequired'),
                                }}
                                render={({ field }) => (
                                    <FormField
                                        label={t('signUp.customerName')}
                                        error={formErrors.customerName}
                                        required
                                    >
                                        <StringField {...field} placeholder={t('signUp.customerNamePlaceholder')} />
                                    </FormField>
                                )}
                            />

                            <div className="mt-4">
                                <Button variant="primary" className="w-full" loading={form.formState.isSubmitting}>
                                    {t('signUp.submit')}
                                </Button>
                            </div>
                        </form>
                    </AuthPage.Card>
                ) : (
                    <AuthPage.Card>
                        <AuthPage.Title>{t('signUp.completeTitle')}</AuthPage.Title>

                        <p className="text-sm text-secondary">{t('signUp.completeDescription')}</p>
                    </AuthPage.Card>
                )}

                <div className="text-xs text-center">
                    <Link to={LOGIN_PATH} className="text-brand-default hover:text-brand-hover">
                        {t('signUp.back')}
                    </Link>
                </div>
            </AuthPage.Content>

            <AuthPage.Footer />
        </AuthPage>
    );
};

export default SignUpPage;
