import * as userPaths from '../../users/constants.ts';
import * as customerPaths from '../../customer/constants.ts';
import * as teamPaths from '../../teams/constants.ts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApplicationContext } from '../contexts/ApplicationContext.tsx';
import PageHeader from './Page/internal/PageHeader.tsx';
import PageHeaderTitle from './Page/internal/PageHeaderTitle.tsx';
import { Link, matchPath } from 'react-router-dom';
import SecondaryNavigation from './SecondaryNavigation.tsx';

const USER_ITEMS = [
    {
        label: 'components.secondaryNavigation.profile',
        path: userPaths.USER_PROFILE_PATH,
    },
    {
        label: 'components.secondaryNavigation.notifications',
        path: userPaths.USER_NOTIFICATIONS_PATH,
    },
    {
        label: 'components.secondaryNavigation.password',
        path: userPaths.USER_PASSWORD_PATH,
    },
];

const COMPANY_ITEMS = [
    {
        label: 'components.secondaryNavigation.companyProfile',
        path: customerPaths.CUSTOMER_PROFILE_PATH,
    },
    {
        label: 'components.secondaryNavigation.companyNotifications',
        path: customerPaths.CUSTOMER_NOTIFICATIONS_PATH,
    },
    {
        label: 'components.secondaryNavigation.teams',
        path: teamPaths.TEAMS_PATH,
    },
    {
        label: 'components.secondaryNavigation.employees',
        path: userPaths.USERS_PATH,
    },
    {
        label: 'components.secondaryNavigation.singleSignOn',
        path: customerPaths.CUSTOMER_SSO_PATH,
    },

    {
        label: 'components.secondaryNavigation.customizations',
        path: customerPaths.CUSTOMER_CUSTOMIZATIONS_PATH,
    },
];

const INTEGRATION_ITEMS = [
    {
        label: 'components.secondaryNavigation.authenticationTokens',
        path: customerPaths.CUSTOMER_INTEGRATION_AUTHENTICATION_TOKENS_PATH,
    },
    {
        label: 'components.secondaryNavigation.sftpPublicKeys',
        path: customerPaths.CUSTOMER_INTEGRATION_SFTP_PUBLIC_KEYS_PATH,
    },
    {
        label: 'components.secondaryNavigation.microsoftOutlook365',
        path: customerPaths.CUSTOMER_INTEGRATION_OUTLOOK_PATH,
    },
    {
        label: 'components.secondaryNavigation.weclapp',
        path: customerPaths.CUSTOMER_INTEGRATION_WECLAPP_PATH,
    },
    {
        label: 'components.secondaryNavigation.sage100',
        path: customerPaths.CUSTOMER_INTEGRATION_SAGE100_PATH,
    },
    {
        label: 'components.secondaryNavigation.sage200',
        path: customerPaths.CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH,
    },
    {
        label: 'components.secondaryNavigation.smartInbox',
        path: customerPaths.CUSTOMER_INTEGRATION_SMART_INBOX_PATH,
    },
];

const getIsActive = (path: string) => {
    return !!matchPath(path, window.location.pathname);
};

const SettingsNavigation = ({ className, ...props }: Omit<React.ComponentPropsWithRef<'nav'>, 'children'>) => {
    const { t } = useTranslation('core');
    const { user } = useApplicationContext();

    return (
        <SecondaryNavigation {...props}>
            <PageHeader>
                <PageHeaderTitle>{t('components.secondaryNavigation.title')}</PageHeaderTitle>
            </PageHeader>

            <div className="flex flex-col px-4 p-5 gap-1">
                <SecondaryNavigation.SectionLabel>
                    {user?.firstName} {user?.lastName}
                </SecondaryNavigation.SectionLabel>

                {USER_ITEMS.map((item) => (
                    <SecondaryNavigation.Button key={item.label} active={getIsActive(item.path)} asChild>
                        <Link to={item.path}>{t(item.label)}</Link>
                    </SecondaryNavigation.Button>
                ))}

                <SecondaryNavigation.SectionLabel>{user?.customer?.name}</SecondaryNavigation.SectionLabel>

                {COMPANY_ITEMS.map((item) => (
                    <SecondaryNavigation.Button key={item.label} active={getIsActive(item.path)} asChild>
                        <Link to={item.path}>{t(item.label)}</Link>
                    </SecondaryNavigation.Button>
                ))}

                <SecondaryNavigation.SectionLabel>
                    {t('components.secondaryNavigation.integrationsGroup')}
                </SecondaryNavigation.SectionLabel>

                {INTEGRATION_ITEMS.map((item) => (
                    <SecondaryNavigation.Button key={item.label} active={getIsActive(item.path)} asChild>
                        <Link to={item.path}>{t(item.label)}</Link>
                    </SecondaryNavigation.Button>
                ))}
            </div>
        </SecondaryNavigation>
    );
};

export default SettingsNavigation;
