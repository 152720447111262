import OutlookIntegrationPage, { AddOutlookFormModal, EditOutlookFormModal } from './pages/OutlookIntegrationPage.tsx';

import { canManageCustomer, canManageIntegrations } from '../users/utils';

import {
    CUSTOMER_CUSTOMIZATIONS_PATH,
    CUSTOMER_INTEGRATION_AUTHENTICATION_TOKENS_PATH,
    CUSTOMER_INTEGRATION_OUTLOOK_ADD_PATH,
    CUSTOMER_INTEGRATION_OUTLOOK_EDIT_PATH,
    CUSTOMER_INTEGRATION_OUTLOOK_PATH,
    CUSTOMER_INTEGRATION_SAGE100_ADD_PATH,
    CUSTOMER_INTEGRATION_SAGE100_EDIT_PATH,
    CUSTOMER_INTEGRATION_SAGE100_OAUTH2_CALLBACK_PATH,
    CUSTOMER_INTEGRATION_SAGE100_PATH,
    CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_ADD_PATH,
    CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_EDIT_PATH,
    CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_OAUTH2_CALLBACK_PATH,
    CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH,
    CUSTOMER_INTEGRATION_SFTP_PUBLIC_KEYS_PATH,
    CUSTOMER_INTEGRATION_SMART_INBOX_ADD_PATH,
    CUSTOMER_INTEGRATION_SMART_INBOX_PATH,
    CUSTOMER_INTEGRATION_WECLAPP_ADD_PATH,
    CUSTOMER_INTEGRATION_WECLAPP_EDIT_PATH,
    CUSTOMER_INTEGRATION_WECLAPP_PATH,
    CUSTOMER_NOTIFICATIONS_PATH,
    CUSTOMER_PROFILE_PATH,
    CUSTOMER_SSO_PATH,
} from './constants';
import CustomerProfilePage from './pages/CustomerProfilePage.tsx';
import CustomerNotificationsPage from './pages/CustomerNotificationsPage.tsx';
import CustomerSsoPage from './pages/CustomerSsoPage.tsx';
import CustomerCustomizationsPage from './pages/CustomerCustomizationsPage.tsx';
import AuthenticationTokensPage from './pages/AuthenticationTokensPage.tsx';
import SftpPublicKeysPage from './pages/SftpPublicKeysPage.tsx';
import Sage100IntegrationPage, {
    AddSage100FormModal,
    EditSage100FormModal,
    Sage100OauthCallbackModal,
} from './pages/Sage100IntegrationPage.tsx';
import Sage200ProfessionalIntegrationPage, {
    AddSage200ProfessionalFormModal,
    EditSage200ProfessionalFormModal,
    Sage200ProfessionalOauthCallbackModal,
} from './pages/Sage200IntegrationPage.tsx';
import WeclappIntegrationPage, { AddWeclappFormModal, EditWeclappFormModal } from './pages/WeclappIntegrationPage.tsx';
import SmartInboxIntegrationPage, { AddSmartInboxFormModal } from './pages/SmartInboxIntegrationPage.tsx.tsx';

export default [
    {
        path: CUSTOMER_PROFILE_PATH,
        condition: ({ user }) => canManageCustomer(user),
        component: CustomerProfilePage,
    },
    {
        path: CUSTOMER_NOTIFICATIONS_PATH,
        condition: ({ user }) => canManageCustomer(user),
        component: CustomerNotificationsPage,
    },
    {
        path: CUSTOMER_SSO_PATH,
        condition: ({ user }) => canManageCustomer(user),
        component: CustomerSsoPage,
    },
    {
        path: CUSTOMER_CUSTOMIZATIONS_PATH,
        condition: ({ user }) => canManageCustomer(user),
        component: CustomerCustomizationsPage,
    },
    {
        path: CUSTOMER_INTEGRATION_AUTHENTICATION_TOKENS_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: AuthenticationTokensPage,
    },
    {
        path: CUSTOMER_INTEGRATION_SFTP_PUBLIC_KEYS_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: SftpPublicKeysPage,
    },
    {
        path: CUSTOMER_INTEGRATION_OUTLOOK_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: OutlookIntegrationPage,
        children: [
            {
                path: CUSTOMER_INTEGRATION_OUTLOOK_ADD_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: AddOutlookFormModal,
            },
            {
                path: CUSTOMER_INTEGRATION_OUTLOOK_EDIT_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: EditOutlookFormModal,
            },
        ],
    },
    {
        path: CUSTOMER_INTEGRATION_WECLAPP_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: WeclappIntegrationPage,
        children: [
            {
                path: CUSTOMER_INTEGRATION_WECLAPP_ADD_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: AddWeclappFormModal,
            },
            {
                path: CUSTOMER_INTEGRATION_WECLAPP_EDIT_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: EditWeclappFormModal,
            },
        ],
    },
    {
        path: CUSTOMER_INTEGRATION_SAGE100_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: Sage100IntegrationPage,
        children: [
            {
                path: CUSTOMER_INTEGRATION_SAGE100_ADD_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: AddSage100FormModal,
            },
            {
                path: CUSTOMER_INTEGRATION_SAGE100_EDIT_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: EditSage100FormModal,
            },
            {
                path: CUSTOMER_INTEGRATION_SAGE100_OAUTH2_CALLBACK_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: Sage100OauthCallbackModal,
            },
        ],
    },
    {
        path: CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: Sage200ProfessionalIntegrationPage,
        children: [
            {
                path: CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_ADD_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: AddSage200ProfessionalFormModal,
            },
            {
                path: CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_EDIT_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: EditSage200ProfessionalFormModal,
            },
            {
                path: CUSTOMER_INTEGRATION_SAGE200PROFESSIONAL_OAUTH2_CALLBACK_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: Sage200ProfessionalOauthCallbackModal,
            },
        ],
    },

    {
        path: CUSTOMER_INTEGRATION_SMART_INBOX_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: SmartInboxIntegrationPage,
        children: [
            {
                path: CUSTOMER_INTEGRATION_SMART_INBOX_ADD_PATH,
                condition: ({ user }) => canManageIntegrations(user),
                component: AddSmartInboxFormModal,
            },
        ],
    },
];
