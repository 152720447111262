import { faBarsFilter, faEdit, faListDots } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AssistanceAlertBar,
    AssistanceHeaderControls,
    AssistanceHeaderPagination,
    AssistanceHeaderTitleGroup,
    AssistanceHeaderViewsToggle,
} from '../../../assistance/components/AssistanceHeader.tsx';
import Tooltip from '../../../core/components/Tooltip.tsx';
import { withIcon } from '../../../core/components/Icon.tsx';
import { useAssistanceContext } from '../../../document/pages/Assistance/AssistanceContext.tsx';
import Button from '../../../core/components/Button.tsx';
import { default as FilterButton } from '../../../core/components/Button.tsx';
import classnames from '../../../core/utils/classnames.tsx';

const FilterIcon = withIcon(faBarsFilter);

const ListOfServicesAssistanceHeaderButtons = ({
    filterListOfServicesPositions,
    onFilterListOfServicesPositionsChange,
    hideFilterButton,
}: {
    filterListOfServicesPositions: boolean;
    onFilterListOfServicesPositionsChange: (filterListOfServicesPositions: boolean) => void;
    hideFilterButton?: boolean;
}) => {
    const { record, loading, handlers, readOnly } = useAssistanceContext();

    const { t } = useTranslation('assistance');

    return (
        <div className="flex gap-2 select-none flex-none">
            {!hideFilterButton && (
                <FilterButton
                    onClick={() => {
                        onFilterListOfServicesPositionsChange(!filterListOfServicesPositions);
                    }}
                    active={filterListOfServicesPositions}
                    className={classnames('flex flex-none gap-2 items-center')}
                >
                    <FilterIcon className="text-lg" />{' '}
                    {(record?.listOfServicesUnsaved?.numberOfRelevantPositions || 0) +
                        ' ' +
                        t('listOfServicesView.relevantPositions') +
                        ' ' +
                        t('header.pagination.of') +
                        ' ' +
                        (record?.listOfServicesUnsaved?.numberOfPositions || 0)}
                </FilterButton>
            )}

            {!record?.canFinishAssistance ? (
                <Tooltip
                    content={!record?.canFinishAssistance && t('finishButton.finishDisabledNote')}
                    side="top"
                    long
                    className="flex"
                >
                    <Button variant="primary" disabled={loading || !record?.canFinishAssistance || readOnly}>
                        {t('finishButton.finishDocument')}
                    </Button>
                </Tooltip>
            ) : (
                <Button
                    variant="primary"
                    disabled={loading || !record?.canFinishAssistance || readOnly}
                    onClick={handlers.onFinish}
                >
                    {t('finishButton.finishDocument')}
                </Button>
            )}
        </div>
    );
};

const EditorIcon = withIcon(faEdit);
const SummaryIcon = withIcon(faListDots);

const ModeButton = ({
    children,
    className,
    active,
    ...props
}: React.ComponentPropsWithoutRef<'button'> & { active?: boolean }) => (
    <button
        className={classnames(
            'flex flex-col justify-between gap-1 text-sm font-medium text-primary group',
            active && 'text-brand-default',
            className
        )}
        {...props}
    >
        <div className="h-[3px] w-full" />
        <div className="px-4 flex gap-2 items-center">{children}</div>
        <div className={classnames('h-[3px] w-full', active ? 'bg-brand-hover' : 'group-hover:bg-secondary')} />
    </button>
);

export const LIST_OF_SERVICE_ASSISTANCE_MODES = {
    EDITOR: 'EDITOR',
    SUMMARY: 'SUMMARY',
};

const ListOfServicesAssistanceHeader = ({
    mode = LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR,
    onModeChange,
    filterListOfServicesPositions,
    onFilterListOfServicesPositionsChange,
}: {
    mode?: string;
    onModeChange?: (mode: 'EDITOR' | 'SUMMARY') => void;
    filterListOfServicesPositions: boolean;
    onFilterListOfServicesPositionsChange: (filterListOfServicesPositions: boolean) => void;
}) => {
    const { t } = useTranslation('assistance');
    return (
        <>
            <div className="flex items-center px-4 py-2 gap-4 border-b border-solid border-primary">
                <div className="flex justify-between items-center flex-1 gap-2 min-w-0">
                    <AssistanceHeaderTitleGroup />
                    <AssistanceHeaderViewsToggle />
                </div>

                <AssistanceHeaderControls hideOriginalDataButton />

                <div className="h-6 border-r border-solid border-primary" />

                <AssistanceHeaderPagination />
            </div>

            <div className="flex items-center px-4 gap-4 border-b border-solid border-primary">
                <div className="flex justify-between flex-1 gap-2 min-w-0">
                    <div className="flex gap-4 items-stretch">
                        <ModeButton
                            active={mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR}
                            onClick={() => onModeChange?.(LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR as 'EDITOR')}
                        >
                            <EditorIcon /> {t('listOfServicesView.editor')}
                        </ModeButton>

                        <ModeButton
                            active={mode === LIST_OF_SERVICE_ASSISTANCE_MODES.SUMMARY}
                            onClick={() => onModeChange?.(LIST_OF_SERVICE_ASSISTANCE_MODES.SUMMARY as 'SUMMARY')}
                        >
                            <SummaryIcon /> {t('listOfServicesView.summary')}
                        </ModeButton>
                    </div>

                    <div className="py-2">
                        <ListOfServicesAssistanceHeaderButtons
                            filterListOfServicesPositions={
                                mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR ? filterListOfServicesPositions : true
                            }
                            onFilterListOfServicesPositionsChange={onFilterListOfServicesPositionsChange}
                            hideFilterButton={mode === LIST_OF_SERVICE_ASSISTANCE_MODES.SUMMARY}
                        />
                    </div>
                </div>
            </div>

            <AssistanceAlertBar />
        </>
    );
};

export default ListOfServicesAssistanceHeader;
