import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToaster } from '../../core/components/Toast.tsx';
import { Controller, useForm } from 'react-hook-form';
import FormField from '../../core/components/FormField.tsx';
import StringField from '../../core/components/Fields/StringField.tsx';
import Button from '../../core/components/Button.tsx';
import BooleanField from '../../core/components/Fields/BooleanField.tsx';
import SettingsFormCard from '../../core/components/SettingsFormCard.tsx';

const FORM_DEFAULT_VALUES = {
    enabled: false,
    targetPathFileExport: '',
    targetPathOriginalDocument: '',
    sendOriginalDocument: false,
    sendOriginalEmail: false,
    deliveryMethod: '',
};

const UploadConfig = ({ config, onActivate, onSubmit }) => {
    const { t } = useTranslation('config');
    const { publishToast } = useToaster();

    const form = useForm({ defaultValues: FORM_DEFAULT_VALUES, mode: 'onTouched' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            enabled: config?.enabled,
            targetPathFileExport: config?.targetPathFileExport || '',
            targetPathOriginalDocument: config?.targetPathOriginalDocument || '',
            sendOriginalDocument: config?.sendOriginalDocument,
            sendOriginalEmail: config?.sendOriginalEmail,
            deliveryMethod: config?.deliveryMethod || '',
        });
    }, [config]);

    const handleSubmit = async (formData) => {
        try {
            if (!config) await onActivate();
            await onSubmit(formData);
            publishToast({ description: t('configPage.upload.updateSuccess'), status: 'success' });
        } catch (e) {
            console.error(e);
            publishToast({ description: t('configPage.upload.updateError'), status: 'error' });
        }
    };

    return (
        <SettingsFormCard onSubmit={form.handleSubmit(handleSubmit)} title={t('configPage.upload.title')}>
            <Controller
                name="enabled"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.upload.form.enabled')} error={formErrors.enabled}>
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="targetPathFileExport"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.upload.form.targetPathFileExport')}
                        error={formErrors.targetPathFileExport}
                    >
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="targetPathOriginalDocument"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.upload.form.targetPathOriginalDocument')}
                        error={formErrors.targetPathOriginalDocument}
                    >
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <Controller
                name="sendOriginalDocument"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.upload.form.sendOriginalDocument')}
                        error={formErrors.sendOriginalDocument}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="sendOriginalEmail"
                control={form.control}
                render={({ field }) => (
                    <FormField
                        label={t('configPage.upload.form.sendOriginalEmail')}
                        error={formErrors.sendOriginalEmail}
                    >
                        <BooleanField {...field} value={field.value ? 'true' : ''} />
                    </FormField>
                )}
            />

            <Controller
                name="deliveryMethod"
                control={form.control}
                render={({ field }) => (
                    <FormField label={t('configPage.upload.form.deliveryMethod')} error={formErrors.deliveryMethod}>
                        <StringField {...field} />
                    </FormField>
                )}
            />

            <div className="flex justify-end">
                <Button type="submit" variant="primary">
                    {t('configPage.upload.form.submitButton')}
                </Button>
            </div>
        </SettingsFormCard>
    );
};

export default UploadConfig;
