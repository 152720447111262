import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../core/components/Modal.tsx';
import { camelCase, snakeCase } from 'lodash';
import { Alert, AlertContent, AlertTitle } from '../../core/components/Alert.tsx';
import { NavigationContext } from '../../document/routes.tsx';

import Table, { RowControlIconButton, TableScrollWrapper } from '../../core/components/Table.tsx';
import { useDebounce } from '../../core/utils/hooks/useDebounce.ts';
import StringField from '../../core/components/Fields/StringField.tsx';
import { faExternalLink, faPenField } from '@fortawesome/pro-regular-svg-icons';
import { withIcon } from '../../core/components/Icon.tsx';
import classnames from '../../core/utils/classnames.tsx';
import { url } from '../../core/utils/link.ts';

export const RESULTS_PAGE_SIZE = 20;

// used when rendering a masterdata entity (e.g. in the masterdata browser)
export const browsableSchemaByMasterdataEntity = {
    ClientLookupType: [
        'clientId1',
        'companyName',
        'address1',
        'zipCode',
        'city',
        'country',
        'clientContactPerson1',
        'internalNote',
    ],
    AddressLookupType: [
        'addressId1',
        'name',
        'address1',
        'misc',
        'zipCode',
        'city',
        'country',
        //'email', TBD
        'phone',
        'contactPerson',
    ],
    ArticleLookupType: [
        'articleId1',
        'articleId2',
        'articleId3',
        'articlePartitionSpecificNumber',
        'description',
        'grossPrice',
        'orderUnits',
        'salesUnits',
        'salesOrganisation',
    ],
    ContactLookupType: ['contactId', 'firstName', 'lastName', 'phone', 'email'],
    FrameworkContractLookupType: ['contractId1', 'contractId2', 'contractName', 'clientId', 'clientName'],
    OrderMatchingLookupType: [
        'articleId1',
        'articleId2',
        'articleId3',
        'positionNumber',
        'articleDescription',
        'quantity',
        'unit',
    ],
};

const SelectIcon = withIcon(faPenField);
const ExternalLinkIcon = withIcon(faExternalLink);

const HighlightedText = ({ text, pattern }) => {
    if (pattern == null || text == null) {
        return text;
    }
    const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedPattern})`, 'gi'));
    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {part.toLowerCase() === pattern.toLowerCase() ? (
                <b style={{ fontWeight: 700, background: 'rgba(0,0,0,0.05)' }}>{part}</b>
            ) : (
                part
            )}
        </React.Fragment>
    ));
};

const MasterDataBrowserRow = ({
    entry,
    searchTerm,
    targetColumn,
    columns,
    onSelect,
    ...props
}: {
    entry: any;
    searchTerm?: string;
    targetColumn?: string;
    columns: string[];
    onSelect?: (entry: any) => void;
} & React.ComponentPropsWithRef<typeof Table.Row>) => {
    return (
        <Table.Row {...props}>
            <Table.Cell sticky="left">
                <RowControlIconButton onClick={() => onSelect(entry)} disabled={!onSelect}>
                    <SelectIcon />
                </RowControlIconButton>
            </Table.Cell>

            {targetColumn && (
                <Table.Cell sticky="left" className="left-16">
                    <HighlightedText text={entry[targetColumn]} pattern={searchTerm} />
                </Table.Cell>
            )}

            {columns
                .filter((key) => key !== targetColumn)
                .map((key) => (
                    <Table.Cell key={key}>{entry[key]}</Table.Cell>
                ))}
        </Table.Row>
    );
};

export const MasterDataBrowser = ({
    searchTerm: initialSearchTerm,
    lookupType,
    targetField,
    getItems,
    dropdownMessageBuilder,
    searchMessageBuilder,
    onSelectRecord,
}: any) => {
    const { t } = useTranslation('masterdata');
    const { paths, channelId } = useContext(NavigationContext);

    const [loading, setLoading] = useState(false);
    const [activeSearchTerm, setActiveSearchTerm] = useState(initialSearchTerm);
    const setActiveSearchTermDebounced = useDebounce(setActiveSearchTerm, 500);
    const [searchInputText, setSearchInputText] = useState(initialSearchTerm);

    useEffect(() => {
        // Synchronize state with external prop, i.e. re-initialize when search term prop changes
        setSearchInputText(initialSearchTerm);
        setActiveSearchTerm(initialSearchTerm);
    }, [initialSearchTerm]);

    const [rows, setRows] = useState([]);

    useEffect(() => {
        // Re-fetch results when search term changes
        setLoading(true);
        getItems(activeSearchTerm, 0, RESULTS_PAGE_SIZE)
            .then((items) => items.map((item) => item.data._lookupEntity))
            .then((entities) => {
                setRows(entities);
                setLoading(false);
            })
            .then(() => {
                scrollRef?.current?.scrollTo({ top: 0 });
            });
    }, [activeSearchTerm]);

    const isAtBottom = ({ currentTarget }) =>
        currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;

    const handleScroll = (event) => {
        if (loading || !isAtBottom(event)) return;
        setLoading(true);
        getItems(activeSearchTerm, rows.length, RESULTS_PAGE_SIZE)
            .then((items) => items.map((item) => item.data._lookupEntity))
            .then((entities) => {
                setRows([...rows, ...entities]);
                setLoading(false);
            });
    };

    const dropdownMessage = dropdownMessageBuilder?.(t, activeSearchTerm, rows);
    const searchMessage = searchMessageBuilder?.(t, activeSearchTerm, rows);
    const scrollRef = useRef(null);

    const columns = rows.length > 0 ? browsableSchemaByMasterdataEntity[rows[0].__typename] : [];
    const targetColumn = columns.includes(camelCase(targetField)) ? camelCase(targetField) : undefined;

    return (
        <div className="flex flex-col gap-6">
            <div className="flex gap-4 justify-between items-center">
                <span className="text-secondary">{searchMessage || ''}</span>

                <StringField
                    placeholder={t('browser.searchInputPlaceholder')}
                    value={searchInputText}
                    onValueChange={(value) => {
                        setSearchInputText(value);
                        setActiveSearchTermDebounced(value);
                    }}
                    className="max-w-[20rem]"
                />
            </div>

            {!dropdownMessage && rows.length > 0 && (
                <TableScrollWrapper className="max-h-[50vh]" onScroll={handleScroll} ref={scrollRef}>
                    <Table>
                        <Table.Head sticky>
                            <Table.Row>
                                <Table.HeadCell sticky="left" />

                                {targetColumn && (
                                    <Table.HeadCell sticky="left" className="data-[state=sticky]:shadow left-16">
                                        {t(`schema.${lookupType}.${targetColumn}`)}
                                    </Table.HeadCell>
                                )}

                                {columns
                                    .filter((key) => key !== targetColumn)
                                    .map((key) => (
                                        <Table.HeadCell
                                            key={key}
                                            className={classnames(key === 'description' ? 'min-w-[20rem]' : '')}
                                            data-key={key}
                                        >
                                            {t(`schema.${lookupType}.${key}`)}
                                        </Table.HeadCell>
                                    ))}
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {rows.map((row) => (
                                <MasterDataBrowserRow
                                    entry={row}
                                    searchTerm={activeSearchTerm}
                                    targetColumn={targetColumn}
                                    columns={columns}
                                    onSelect={onSelectRecord}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                </TableScrollWrapper>
            )}

            {!!dropdownMessage && (
                <Alert severity="info" className="masterdata-browser__content--info">
                    <span>{dropdownMessage}</span>
                </Alert>
            )}

            <Alert severity="info" className="masterdata-browser__content--disclaimer">
                <AlertTitle>{t('browser.disclaimer.title')}</AlertTitle>
                <AlertContent>
                    <span>{t('browser.disclaimer.description')}</span>
                    <a href={url(paths.channelMasterdata, { channelId })} target="_blank">
                        &nbsp;&nbsp;
                        <ExternalLinkIcon />
                        &nbsp;&nbsp;
                        {t('browser.disclaimer.linkText')}
                    </a>
                </AlertContent>
            </Alert>
        </div>
    );
};

export const MasterDataBrowserModal = ({ visible, onClose, ...browserProps }: any) => {
    const { t } = useTranslation('masterdata');

    const lookupTypeLocalizationKey = snakeCase(browserProps.lookupType?.replace('Type', '') || '').toUpperCase(); // e.g. "ARTICLE_LOOKUP"
    return (
        <Modal
            className="min-w-[72rem]"
            visible={visible}
            onClose={onClose}
            title={t('browser.title', { lookupType: t(`importRun.lookupType.${lookupTypeLocalizationKey}`) })}
            allowMaximize
        >
            <MasterDataBrowser {...browserProps} />
        </Modal>
    );
};
