import DocumentConfiguration, {
    CONFIG_GROUP,
    CONFIG_OPTION,
    customKebabCase,
    customSnakeCase,
    DEFAULT_CONFIG_GROUPS,
    DEFAULT_CONFIGS,
} from '../../../document/pages/Configuration';
import * as _documentQueries from '../queries.ts';
import { GET_CHANNEL_CONFIG, GET_OVERVIEW_DATA, UPDATE_FIELDS_CONFIG } from '../queries.ts';
import { CHANNEL_CONFIG_PATH, CHANNEL_PATH, OVERVIEW_PATH } from '../constants.ts';
import React, { useCallback, useMemo, useState } from 'react';
import { DocumentType } from '../../../document/constants.ts';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import UniversalFieldsConfig, {
    filterUniversalFields,
    isGroupField,
    isHeaderField,
    isItemField,
} from '../components/UniversalFieldsConfig.tsx';
import { convertFieldsToEnabledFieldInputs } from '../../../document/components/FieldsConfig.tsx';
import UniversalItemTypeFormModal from '../components/UniversalItemTypeFormModal.tsx';
import { useTranslation } from 'react-i18next';
import { url } from '../../../core/utils/link.ts';
import ConfirmModal from '../../../core/components/ConfirmModal.tsx';
import { withIcon } from '../../../core/components/Icon.tsx';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const ADD_ITEM_TYPE = 'ADD_ITEM_TYPE';

const AddIcon = withIcon(faPlus);

const getFieldsTab = (itemTypeName) => customKebabCase(`${itemTypeName}Fields`);
const getFieldsOptionKey = (itemTypeName) => customSnakeCase(`${itemTypeName}Fields`).toUpperCase();

const createItemConfig =
    (itemType) =>
    ({ config, onFieldsUpdate, onDelete, onUpdate }) => {
        const { t } = useTranslation('config');

        const handleUpdate = (formData) => {
            setEditModalVisible(false);
            return onUpdate(formData);
        };

        const handleDelete = () => {
            setDeleteModalVisible(false);
            return onDelete();
        };

        const [editModalVisible, setEditModalVisible] = useState(false);
        const [deleteModalVisible, setDeleteModalVisible] = useState(false);

        const itemTypeDefinition = useMemo(() => config?.fields.find((field) => field.name === itemType), [config]);

        return (
            <>
                <UniversalFieldsConfig
                    config={config}
                    onSubmit={onFieldsUpdate}
                    onEdit={() => setEditModalVisible(true)}
                    onDelete={() => setDeleteModalVisible(true)}
                    itemType={itemType}
                />

                {editModalVisible && (
                    <UniversalItemTypeFormModal
                        title={t('configPage.document.universal.modal.editTitle')}
                        submitButtonLabel={t('configPage.document.universal.modal.editButton')}
                        onClose={() => setEditModalVisible(false)}
                        onSubmit={(formData) => handleUpdate(formData)}
                        data={{
                            tableDataLabelName: itemTypeDefinition.options.find(
                                (option) => option.name === 'data_label_name'
                            )?.value,
                            tableFieldLabelName: itemTypeDefinition.options.find(
                                (option) => option.name === 'field_label_name'
                            )?.value,
                        }}
                    />
                )}

                {deleteModalVisible && (
                    <ConfirmModal
                        title={t('configPage.document.universal.deleteModal.title')}
                        onCancel={() => setDeleteModalVisible(false)}
                        onConfirm={() => handleDelete()}
                        danger
                    >
                        {t('configPage.document.universal.deleteModal.message', { tableName: itemType })}
                    </ConfirmModal>
                )}
            </>
        );
    };

const Configuration = (props) => {
    const { t } = useTranslation('config');
    const { channelId, tab } = useParams();

    const navigate = useNavigate();

    const { data, refetch: refetchNavigation } = useQuery(GET_CHANNEL_CONFIG, {
        notifyOnNetworkStatusChange: false,
        variables: { id: channelId },
    });

    const itemTypeNameAndLabels = useMemo(() => {
        const channel = data?.universalProcessingChannel;
        const fields = channel?.documentConfig?.fields || [];

        const nameAndLabels = fields
            .filter((field) => isGroupField(field))
            .map((field) => [field.name, field.options?.find((option) => option.name === 'field_label_name')?.value]);

        nameAndLabels.sort((a, b) => a[1].localeCompare(b[1]));
        nameAndLabels.unshift(['header', 'header']);

        return nameAndLabels;
    }, [data]);

    const [updateDocumentConfig] = useMutation(UPDATE_FIELDS_CONFIG);
    const updateFields = (fieldInputs) =>
        updateDocumentConfig({
            variables: {
                channelId,
                documentType: 'universal',
                documentConfig: null,
                fieldConfigs: fieldInputs,
            },
        });

    const itemTypeConfigOptions = useMemo(() => {
        return Object.fromEntries(
            itemTypeNameAndLabels.map((itemTypeNameAndLabel) => {
                const itemTypeName = itemTypeNameAndLabel[0];
                const ItemTypeConfig = createItemConfig(itemTypeName === 'header' ? '' : itemTypeName);
                return [
                    getFieldsOptionKey(itemTypeName),
                    ({ channel, channelRefetch }) => {
                        const handleUpdate = async (itemTypeName, { tableDataLabelName, tableFieldLabelName }) => {
                            const fields = channel?.documentConfig?.fields || [];
                            const updatedFields = fields.map((field) => {
                                if (field.name !== itemTypeName) return field;
                                return {
                                    ...field,
                                    options: field.options.map((option) => {
                                        if (option.name === 'data_label_name')
                                            return { ...option, value: tableDataLabelName };
                                        if (option.name === 'field_label_name')
                                            return { ...option, value: tableFieldLabelName };
                                        return option;
                                    }),
                                };
                            });
                            await updateFields(convertFieldsToEnabledFieldInputs(updatedFields));
                            await channelRefetch();
                            return await refetchNavigation();
                        };

                        const handleFieldsUpdate = async (itemTypeName, fieldInputs) => {
                            const fields = channel?.documentConfig?.fields || [];
                            const groupNames = fields
                                ?.filter((field) => isGroupField(field))
                                .map((field) => field.name);

                            // if there are any leftovers, they get deleted here
                            const validFields = fields.filter(
                                (field) => isGroupField(field) || isHeaderField(field) || isItemField(field, groupNames)
                            );

                            // current item type fields
                            const fieldNames = filterUniversalFields(validFields, itemTypeName).map(
                                (field) => field.name
                            );

                            // we need to make sure the complete list of enabled fields is send back on save including the header fields
                            const otherFields = validFields?.filter(
                                (field) => !fieldNames.includes(field.name) || isGroupField(field)
                            );

                            await updateFields([...convertFieldsToEnabledFieldInputs(otherFields), ...fieldInputs]);
                            return channelRefetch();
                        };

                        const handleDelete = async (itemTypeName) => {
                            const fields = channel?.documentConfig?.fields || [];
                            // header cannot be deleted so we don't need a special case for it
                            const remainingFields = fields.filter(
                                (f) => f.name !== itemTypeName || f.name.startsWith(`${itemTypeName}__`)
                            );

                            await updateFields(convertFieldsToEnabledFieldInputs(remainingFields));
                            await channelRefetch();
                            await refetchNavigation();
                            return navigate(
                                url(CHANNEL_CONFIG_PATH, {
                                    channelId,
                                    tab: getFieldsTab('header'),
                                })
                            );
                        };

                        return (
                            <ItemTypeConfig
                                config={channel?.documentConfig}
                                onFieldsUpdate={(formData) =>
                                    handleFieldsUpdate(itemTypeName === 'header' ? '' : itemTypeName, formData)
                                }
                                onDelete={() => handleDelete(itemTypeName)}
                                onUpdate={(formData) =>
                                    handleUpdate(itemTypeName === 'header' ? '' : itemTypeName, formData)
                                }
                            />
                        );
                    },
                ];
            })
        );
    }, [data, tab]);

    const AddItemTypeOption = useCallback(
        ({ channel, channelRefetch }) => {
            const handleCreate = async ({ tableDataLabelName, tableFieldLabelName }) => {
                await updateFields([
                    ...convertFieldsToEnabledFieldInputs(channel?.documentConfig?.fields || []),
                    {
                        fieldName: tableDataLabelName,
                        options: [
                            { name: 'data_label_name', value: tableDataLabelName },
                            { name: 'field_label_name', value: tableFieldLabelName },
                            { name: 'group', value: true },
                        ],
                    },
                ]);
                navigate(
                    url(CHANNEL_CONFIG_PATH, {
                        channelId,
                        tab: getFieldsTab(tableDataLabelName),
                    })
                );
                channelRefetch();
                return refetchNavigation();
            };

            return (
                <UniversalItemTypeFormModal
                    title={t('configPage.document.universal.modal.addTitle')}
                    submitButtonLabel={t('configPage.document.universal.modal.addButton')}
                    onClose={() => navigate(url(CHANNEL_CONFIG_PATH, { channelId, tab: getFieldsTab('header') }))}
                    onSubmit={handleCreate}
                />
            );
        },
        [tab, data]
    );

    const configOptions = {
        [CONFIG_OPTION.General]: DEFAULT_CONFIGS[CONFIG_OPTION.General],

        ...itemTypeConfigOptions,
        [ADD_ITEM_TYPE]: AddItemTypeOption,

        [CONFIG_OPTION.Email]: DEFAULT_CONFIGS[CONFIG_OPTION.Email],
        [CONFIG_OPTION.MasterData]: DEFAULT_CONFIGS[CONFIG_OPTION.MasterData],
        [CONFIG_OPTION.SFTP]: DEFAULT_CONFIGS[CONFIG_OPTION.SFTP],
        [CONFIG_OPTION.AS2]: DEFAULT_CONFIGS[CONFIG_OPTION.AS2],
        [CONFIG_OPTION.Upload]: DEFAULT_CONFIGS[CONFIG_OPTION.Upload],
        [CONFIG_OPTION.Discard]: DEFAULT_CONFIGS[CONFIG_OPTION.Discard],
    };

    const configGroups = cloneDeep(DEFAULT_CONFIG_GROUPS);
    configGroups[CONFIG_GROUP.Processing] = [
        // @ts-ignore
        ...Object.keys(itemTypeConfigOptions),
        // @ts-ignore
        ADD_ITEM_TYPE,
        // @ts-ignore
        CONFIG_OPTION.MasterData,
        // @ts-ignore
        CONFIG_OPTION.Discard,
    ];

    const configLabels = Object.fromEntries(
        itemTypeNameAndLabels
            .filter((itemTypeNameAndLabel) => itemTypeNameAndLabel[0] !== 'header')
            .map((itemTypeNameAndLabel) => [getFieldsOptionKey(itemTypeNameAndLabel[0]), itemTypeNameAndLabel[1]])
    );

    const documentConfiguration = {
        service: 'universal',
        documentType: DocumentType.Universal,
        documentTypeName: 'universal',
        activeTab: 'universal',
        documentQueries: _documentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
        configPath: CHANNEL_CONFIG_PATH,
        configOptions: configOptions,
        configGroups: configGroups,
        configLabels: {
            ...configLabels,
            [ADD_ITEM_TYPE]: (
                <span>
                    <AddIcon /> {t('configPage.document.universal.addNewItemType')}
                </span>
            ),
        },
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
