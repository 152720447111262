import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { createPortal } from 'react-dom';
import { TOAST_DURATION } from '../../../constants.ts';
import classnames from '../utils/classnames.tsx';
import { withIcon } from './Icon.tsx';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck, faCircleXmark, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

export interface ToastMessage {
    id?: number; // assigned automatically
    title?: string | React.ReactNode;
    description: string | React.ReactNode;
    status?: 'success' | 'error' | 'info' | 'warning';
}

interface IToasterStore {
    toastMessages: Array<ToastMessage>;
    publishToast: (message: ToastMessage) => void;
}

const CloseIcon = withIcon(faXmark);
const SuccessIcon = withIcon(faCircleCheck);
const WarningIcon = withIcon(faTriangleExclamation);
const ErrorIcon = withIcon(faCircleXmark);

const ToasterContext = createContext<IToasterStore>(null);

// Hook-based imperative API for publishing toasts
export const useToaster = () => useContext(ToasterContext);

export const Toast = ({
    id,
    title,
    description,
    status = 'info',
    ...toastProps
}: Omit<React.ComponentPropsWithRef<typeof ToastPrimitive.Root>, 'title' | 'id'> & ToastMessage) => {
    const [open, setOpen] = useState(true);

    return (
        <ToastPrimitive.Root
            className="bg-[rgba(0,0,0,0.9)] text-[#fff] backdrop-blur-md rounded-2xl py-5 px-6 flex gap-4 transition-transform duration-200 ease-out data-[state=open]:animate-slide-in data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=end]:animate-swipe-out pointer-events-auto"
            open={open}
            onOpenChange={setOpen}
            {...toastProps}
        >
            <div className="flex gap-4 items-center">
                {status === 'success' && <SuccessIcon className="text-success-hover text-lg" />}
                {status === 'warning' && <WarningIcon className="text-warning-hover text-lg" />}
                {status === 'error' && <ErrorIcon className="text-error-hover text-lg" />}

                <div className="flex flex-col gap-2 justify-center flex-1">
                    {title && <ToastPrimitive.Title className="font-semibold text-sm">{title}</ToastPrimitive.Title>}
                    {description && (
                        <ToastPrimitive.Description className="text-sm font-medium">
                            {description}
                        </ToastPrimitive.Description>
                    )}
                </div>
            </div>
            <ToastPrimitive.Close className="toast__action" aria-label="Close" asChild>
                <button
                    className="w-8 h-8 flex-none hover:bg-[rgba(255,255,255,0.2)] active:bg-[rgba(255,255,255,0.3)] transition-all rounded -mt-1 -mr-2 -mb-1 opacity-50 hover:opacity-100"
                    onClick={() => setOpen(false)}
                >
                    <CloseIcon />
                </button>
            </ToastPrimitive.Close>
        </ToastPrimitive.Root>
    );
};

/**
 * Provides:
 * 1. The Radix primitive provider which injects shared configuration into all toast components
 * 2. A custom context (ToasterContext) that holds the state & API for the imperative useToaster hook
 */
export const Provider = (props: any) => {
    const [toastMessages, setToastMessages] = useState<Array<ToastMessage>>([]);
    const [counter, setCounter] = useState(0);
    const maxBufferSize = 5;

    const publishToast = (toastMessage: ToastMessage) => {
        // Update the toast message buffer while respecting the max buffer size
        const keepToastMessages =
            toastMessages.length > maxBufferSize ? toastMessages.slice(1, toastMessages.length) : toastMessages;
        // Assign an incrementing ID used as the Radix component key
        setToastMessages([...keepToastMessages, { ...toastMessage, id: counter }]);
        setCounter(counter + 1);
    };

    return (
        <ToasterContext.Provider value={{ toastMessages, publishToast }}>
            <ToastPrimitive.Provider duration={TOAST_DURATION} swipeDirection={null} {...props} />
        </ToasterContext.Provider>
    );
};

/**
 * Renderer for all toasts (declarative and imperative ones). This does two things:
 * 1. It mounts the Radix-managed viewport into the modal root so all toasts are rendered there
 * 2. It renders the toasts published imperatively via the useToaster hook as components (which will be picked up by
 *    Radix & portaled into the Radix Viewport via 1. (Note: since visibility of toasts is managed by Radix, we
 *    always render a buffer of the latest published toasts here with a unique key per toast message)
 *
 * See https://www.radix-ui.com/primitives/docs/components/toast
 */
export const Viewport = (props: any) => {
    const { className, ...passThroughProps } = props;
    const { toastMessages } = useToaster();

    const modalRoot = document.getElementById('modal-root');
    return (
        <>
            {toastMessages.map((message: ToastMessage) => (
                <Toast key={message.id} {...message} />
            ))}
            {createPortal(
                <ToastPrimitive.Viewport
                    className={classnames(
                        'w-[32rem] flex flex-col items-end gap-3 z-10 right-0 bottom-0 fixed p-6',
                        className
                    )}
                    {...passThroughProps}
                />,
                modalRoot
            )}
        </>
    );
};

export default {
    Toast,
    Provider,
    Viewport,
};
