import * as React from 'react';
import { useEffect, useState } from 'react';
import classnames from '../utils/classnames.tsx';

export const Title = ({ className }: any) => (
    <div className={classnames('animate-pulse w-8/12 h-[1.5rem] bg-text-secondary rounded', className)} />
);
export const Line = ({ className }: any) => (
    <div className={classnames('animate-pulse w-full h-[1.5rem] bg-text-tertiary rounded', className)} />
);

interface IProps {
    children?: any;
    className?: string;
    minLoadTime?: number;
}

const withDelay =
    (WrappedComponent) =>
    ({ minLoadTime = 120, ...props }) => {
        const [isVisible, setIsVisible] = useState(false);
        // only show the loader after minLoadTime to prevent flickering for short loadtimes
        useEffect(() => {
            setTimeout(() => {
                setIsVisible(true);
            }, minLoadTime);
        }, []);

        if (!isVisible) {
            return null;
        }

        return <WrappedComponent {...props} />;
    };

const Loader = withDelay((props: IProps) => {
    const { children, className } = props;
    return (
        <div className={classnames('w-full my-12 flex flex-col gap-3 opacity-10', className)}>
            {children ? (
                children
            ) : (
                <React.Fragment>
                    <Title />
                    <Line />
                    <Line className="w-5/12" />
                </React.Fragment>
            )}
        </div>
    );
});

export const LoaderLine = withDelay(() => <Line />);

export default Loader;
