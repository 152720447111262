import DocumentAssistance, { ALL_TABS, TABS_OPTIONS } from '../../../document/pages/Assistance';
import {
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    GET_RELATED_DOCUMENT,
    RE_UPLOAD_FILE,
    REOPEN_FOR_ASSISTANCE,
    RETRY_STEP,
    SEND_TO_LABELING,
    UNIVERSAL_EXTRACT_TEXT,
} from '../queries.ts';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../constants.ts';
import * as React from 'react';
import { useMemo } from 'react';
import { DocumentType } from '../../../document/constants.ts';
import { HeaderDataFieldGroupControls } from '../../../document/pages/Assistance/HeaderDataSection.tsx';
import { FocusActionClickButton, ItemMoreActionsButton } from '../../../document/pages/Assistance/ItemDataSection.tsx';

export const UniversalHeaderDataFieldGroupControls = ({
    fields,
    fieldName,
    onFieldFocus,
    onFieldReselect,
    focusField,
}: any) => {
    return (
        <HeaderDataFieldGroupControls
            fields={fields}
            fieldName={fieldName}
            onFieldFocus={onFieldFocus}
            onFieldReselect={onFieldReselect}
            focusField={focusField}
            // Not supported for Universal product
            onClear={null}
            onFocus={null}
        />
    );
};

export const UniversalFocusActionClickButton = ({ previewRef, viewMode, itemIndex }: any) => {
    return (
        <FocusActionClickButton
            previewRef={previewRef}
            viewMode={viewMode}
            itemIndex={itemIndex}
            // Not supported for Universal product
            isButtonEnabled={false}
        />
    );
};

export const UniversalItemMoreActionsButton = ({
    item,
    onActionClick,
    itemIndex,
    fieldNames,
    children,
    className,
    ...props
}: any) => {
    return (
        <ItemMoreActionsButton
            item={item}
            onActionClick={onActionClick}
            itemIndex={itemIndex}
            // Not supported for Universal product
            fieldNames={null}
            isCorrectActionDisabled={true}
            {...props}
        />
    );
};

const UniversalAssistance = (props) => {
    const documentConfiguration = useMemo(
        () => ({
            documentType: DocumentType.Universal,
            documentTypeName: 'universal',
            GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
            GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
            GET_RELATED_DOCUMENT: GET_RELATED_DOCUMENT,
            RETRY_STEP: RETRY_STEP,
            REOPEN_FOR_ASSISTANCE: REOPEN_FOR_ASSISTANCE,
            ASSISTANCE_PATH: ASSISTANCE_PATH,
            ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
            OVERVIEW_PATH: OVERVIEW_PATH,
            OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
            OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
            CHANNEL_PATH: CHANNEL_PATH,
            CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
            CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
            RE_UPLOAD_FILE: RE_UPLOAD_FILE,
            SEND_TO_LABELING: SEND_TO_LABELING,
            EXTRACT_TEXT: UNIVERSAL_EXTRACT_TEXT,
            tabs: {
                [TABS_OPTIONS.Source]: ALL_TABS[TABS_OPTIONS.Source],
                [TABS_OPTIONS.Document]: ALL_TABS[TABS_OPTIONS.Document],
            },
            defaultTab: TABS_OPTIONS.Document,
            useDefaultItemTypes: false,
            components: {
                // Add custom components here
                HeaderDataFieldGroupControls: [
                    {
                        condition: () => true,
                        component: UniversalHeaderDataFieldGroupControls,
                    },
                ],
                FocusActionClickButton: [
                    {
                        condition: () => true,
                        component: UniversalFocusActionClickButton,
                    },
                ],
                ItemMoreActionsButton: [
                    {
                        condition: () => true,
                        component: UniversalItemMoreActionsButton,
                    },
                ],
            },
        }),
        []
    );

    return <DocumentAssistance documentConfiguration={documentConfiguration} {...props} />;
};

export default UniversalAssistance;
