import { useApplicationContext } from '../../../../core/contexts/ApplicationContext';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { canSeeTestingDocuments } from '../../../../users/utils';
import ConfirmModal from '../../../../core/components/ConfirmModal.tsx';
import classnames from '../../../../core/utils/classnames.tsx';
import DropArea from '../../../../core/components/DropArea.tsx';
import FormField from '../../../../core/components/FormField.tsx';
import SelectField from '../../../../core/components/Fields/SelectField.tsx';
import BooleanField from '../../../../core/components/Fields/BooleanField.tsx';

const UploadFileModal = ({
    activeChannel,
    channels,
    uploadFile,
    onUploadDone,
    onConfirm,
    onCancel,
    className,
    ...modalProps
}: any) => {
    const { t } = useTranslation('assistance');

    const { user } = useApplicationContext();

    const channelChoices = channels?.map((channel) => ({
        value: channel.id,
        label: channel.name,
    }));

    const [files, setFiles] = useState([]);
    const [channel, setChannel] = useState(
        activeChannel?.id || (channelChoices.length === 1 ? channelChoices[0][0] : '')
    );

    useEffect(() => {
        if (activeChannel) {
            setChannel(activeChannel.id);
        }
    }, [activeChannel?.id]);

    // For safety reasons, when support users upload a document on production it is marked as a
    // testing document per default.
    const [isTestDocument, setIsTestDocument] = useState(
        process.env.MARK_UPLOAD_DOCUMENT_AS_TEST == 'True' && canSeeTestingDocuments(user) ? true : false
    );

    const handleSubmitFiles = () => {
        return uploadFile({
            variables: {
                files: files,
                channelId: channel,
                isTestingDocument: isTestDocument,
            },
        }).then((res) => {
            setFiles([]);
            if (onUploadDone) return onUploadDone(isTestDocument);
        });
    };

    return (
        <ConfirmModal
            className={classnames('add-channel-modal', className)}
            title={t('overview.uploadFileModal.title')}
            onConfirm={handleSubmitFiles}
            onCancel={onCancel}
            labelConfirm={t('overview.uploadFileModal.confirm')}
            labelCancel={t('overview.uploadFileModal.cancel')}
            noHeader={false}
            confirmDisabled={!(files.length > 0 && channel)}
            {...modalProps}
        >
            <div className="flex flex-col gap-5">
                <FormField label={t('overview.uploadFileModal.fileLabel')}>
                    <DropArea
                        placeholder={t('overview.uploadFileModal.dropPlaceholder')}
                        onFilesChange={setFiles}
                        files={files}
                    />
                </FormField>

                <FormField label={t('overview.uploadFileModal.channelLabel')}>
                    <SelectField
                        value={channel}
                        options={channelChoices}
                        disabled={activeChannel}
                        placeholder={t('overview.uploadFileModal.channelPlaceholder')}
                        onValueChange={setChannel}
                    />
                </FormField>

                {
                    // Only support users are allowed to upload documents for testing
                    canSeeTestingDocuments(user) && (
                        <FormField label={t('overview.uploadFileModal.markAsTestButton')}>
                            <BooleanField
                                value={isTestDocument ? 'true' : ''}
                                onValueChange={(value) => setIsTestDocument(value)}
                            />
                        </FormField>
                    )
                }
            </div>
        </ConfirmModal>
    );
};

export default UploadFileModal;
