import { useTranslation } from 'react-i18next';
import AssistanceSection, { useAssistanceSectionContext } from '../AssistanceSection';
import { useAssistanceViewContext } from '../AssistanceViewContext';
import classnames from '../../../../core/utils/classnames.tsx';

import * as React from 'react';

export interface ItemDataFieldGroupProps {
    itemIndex: number;
    title: React.ReactNode;
    fields: any[];
    children: React.ReactNode;
    controls: React.ReactNode;
}

export const useItemDataFieldGroupProps = ({ itemIndex, title, controls }: ItemDataFieldGroupProps) => {
    const { t } = useTranslation('assistance');
    const { viewMode } = useAssistanceSectionContext();
    const { onItemMouseEnter, onItemMouseLeave } = useAssistanceViewContext();

    return {
        title,
        className: classnames('js-form-item-group', viewMode !== 'list' ? 'items-start scroll-mt-10' : 'scroll-mt-20'),
        onMouseEnter: () => onItemMouseEnter(itemIndex),
        onMouseLeave: () => onItemMouseLeave(itemIndex),
        controls,
    };
};

export const ItemDataFieldGroup = (props: ItemDataFieldGroupProps) => {
    const fieldGroupProps = useItemDataFieldGroupProps(props);
    return <AssistanceSection.FieldGroup {...fieldGroupProps}>{props.children}</AssistanceSection.FieldGroup>;
};

export default ItemDataFieldGroup;
